import React from 'react';
import {Link} from 'react-router-dom';

const HeaderPage = () => (
  <div>
    <div className="navbar navbar-default">
      <div className="navbar-container">
        <div className="navbar-header pull-left">
          <Link to="/app/login" className="navbar-brand" />
        </div>
      </div>
    </div>
  </div>
);

export default HeaderPage;
