import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import TextareaContainer from 'components/form/textareaContainer';

const Observation = ({txobspagamento, txobsvenda, onFieldChanged}) => {
  const {errors, touched} = useFormikContext();
  return (
    <Row>
      <Col xs={12}>
        <h4 className="header smaller lighter blue">Observações</h4>

        <TextareaContainer
          label="Observações de Pagamento (Contrato)"
          showError={!!errors.txobspagamento && !!touched.txobspagamento}
          text={txobspagamento}
          onFieldChanged={e => {
            onFieldChanged('txobspagamento', e.target.value);
          }}
          errorText={errors.txobspagamento}
        />

        <TextareaContainer
          label="Observações gerais (Interna)"
          showError={!!errors.txobsvenda && !!touched.txobsvenda}
          text={txobsvenda}
          onFieldChanged={e => {
            onFieldChanged('txobsvenda', e.target.value);
          }}
          errorText={errors.txobsvenda}
        />
      </Col>
    </Row>
  );
};

export default Observation;

Observation.defaultProps = {
  txobspagamento: '',
  txobsvenda: '',
};

Observation.propTypes = {
  txobspagamento: PropTypes.string,
  txobsvenda: PropTypes.string,
  onFieldChanged: PropTypes.func.isRequired,
};
