import * as Yup from 'yup';
import {messages} from 'helpers/validator/messages';

export const rules = Yup.object().shape({
  email: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  password: Yup.string().nullable().required(messages.required),
});
