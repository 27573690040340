import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';

import InputContainer from 'components/form/inputContainer';

import TermosUso from '../termosUso';

const Step3 = props => {
  const {
    isAcepted,
    isBlackFriday,
    handleIsAceptedChange,
    handleChangeSignature,
    nmdirnome,
    nmassinatura,
    backStep,
    nextStep,
    cdproduto,
    lang,
  } = props;
  const {t} = useTranslation();
  return (
    <div className="step-pane no-padding active">
      <div className="">
        <div className="">
          <div className="widget-container-col">
            <div className="widget-box transparent">
              <div className="widget-header center">
                <h3 className="widget-title">{t('paginaCliente.step3')}</h3>
              </div>

              <div id="div-contrato" className="widget-body" data-size="250">
                <div className="widget-main no-padding">
                  <TermosUso
                    lang={lang}
                    cdproduto={cdproduto}
                    isBlackFriday={isBlackFriday}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix" />

        <div className="row">
          <div className="col-sm-12" style={{paddingBottom: '15px'}}>
            <hr />
            <a
              href="https://light.desbravador.com.br/cookies"
              rel="noopener noreferrer"
              target="_blank">
              <b>{t('paginaCliente.termosUso.usoCookies')}</b>
            </a>
          </div>
          <div className="col-sm-12" style={{paddingBottom: '15px'}}>
            <a
              href="https://light.desbravador.com.br/requisitos-hardware"
              rel="noopener noreferrer"
              target="_blank">
              <b>{t('paginaCliente.termosUso.requisitosHardware')}</b>
            </a>
            <hr />
          </div>
        </div>

        <div className="clearfix" />

        <div className="row">
          <div className="col-sm-12">
            <div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="ace"
                    checked={isAcepted}
                    onChange={() => {
                      handleIsAceptedChange();
                    }}
                  />
                  <span className="lbl" style={{marginLeft: '-20px'}}>
                    &nbsp;
                    {t('paginaCliente.termosUso.aceitoTermos')}
                  </span>
                </label>
              </div>

              {isAcepted ? (
                <div>
                  <div className="row">
                    <label className="col-sm-9 col-sm-offset-3 control-label no-padding-right grey">
                      <span>Nome do representante legal:</span>
                      &nbsp;
                      <span className="label-nome-responsavel label label-white middle">
                        {nmdirnome}
                      </span>
                    </label>

                    {/* Assinatura */}
                    <InputContainer
                      type="text"
                      placeholder={t('paginaCliente.termosUso.placeholder')}
                      label={`${t(
                        'paginaCliente.termosUso.labelAssineAqui',
                      )} *`}
                      showError={false}
                      text={nmassinatura}
                      onFieldChanged={e => {
                        handleChangeSignature(e.target.value);
                      }}
                      errorText=""
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="wizard-actions">
        <Button
          type="button"
          className="btn btn-prev"
          onClick={() => {
            backStep();
          }}>
          <i className="ace-icon fa fa-arrow-left" />
          <span>{t('paginaCliente.botao.anterior')}</span>
        </Button>
        &nbsp;
        <Button
          type="button"
          className="btn-success btn-next"
          onClick={() => {
            nextStep();
          }}>
          <span>{t('paginaCliente.botao.proximo')}</span>
          <i className="ace-icon fa fa-arrow-right icon-on-right" />
        </Button>
      </div>
    </div>
  );
};

Step3.defaultProps = {
  nmdirnome: '',
  nmassinatura: '',
};

Step3.propTypes = {
  isAcepted: PropTypes.bool.isRequired,
  handleIsAceptedChange: PropTypes.func.isRequired,
  handleChangeSignature: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  cdproduto: PropTypes.string.isRequired,
  isBlackFriday: PropTypes.bool.isRequired,
  nmdirnome: PropTypes.string,
  nmassinatura: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

export default Step3;
