import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LangActions from 'store/actions/LangActions';

import {myIpFetch} from 'services/getIp';
import api from 'services/api';
import {getCurrentPosition} from 'services/geolocation';

import alert2 from 'components/alert2';
import notification from 'components/notification';
import Loading from 'components/loading';

import {
  getModulosString,
  getITC,
  getManutencao,
  getRegiao,
} from 'helpers/dslFunctions';
import {now} from 'helpers/date/date';
import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';

import {
  PRODUCT_LIGHTWEB_CODE,
  getCurrencyFromTabPriceCode,
  getProductName,
  BLACK_FRIDAY_CODE,
} from 'helpers/tabPrice';
import {makePermutaText} from 'helpers/permuta';

import {withRouter} from 'routes/withRouter';

import CadastroSuccess from './components/cadastroSuccess';
import CadastroContainer from './components/cadastroContainer';
import StepsBreadcrumb from './components/stepsBreadcrumb';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';

class CadastroCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isFinished: false,

      moeda: 'R$',
      cdproduto: PRODUCT_LIGHTWEB_CODE,

      cdpedidonr: '',
      cdpedido: '',
      dtdatapedido: '',
      cdidioma: '1',
      fgtipomanut: '',
      modulos: '',
      fgmodalidademanut: '',
      cdlocalatende: '',
      nroipusuario: '',
      nmipaceite: '',
      txobspagamento: '',
      cdnroaptos: '',
      cdusuarios: '',
      vlvalorvenda: null,
      vlvalormensal: null,
      nmemailvendedor: '',

      nmrazaosocial: '',
      nmfantasia: '',
      tipopessoa: 'J',
      nmdoc1: '',
      nmdoc2: '',
      nmpais: '',
      nmcep: '',
      nmendereco: '',
      nmendnumero: '',
      nmbairro: '',
      nmcidade: '',
      nmestado: '',
      nmnomeresponsavel: '',
      nmemailresponsavel: '',
      nmdirnome: '',
      nmdiremail: '',
      nmnomegerente: '',
      nmemailgerente: '',
      nmnomefinanceiro: '',
      nmemailfinanceiro: '',
      nmtelefone: '',
      fgcovid: false,
      cdpromocao: '',

      isAcepted: false,
      nmassinatura: '',

      vlvalorpermuta: null,
      vlqtdepermuta: null,
      txobspermuta: '',
      txobspermuta2: '',

      lat: null,
      lng: null,

      step: 1,
    };
  }

  componentDidMount() {
    document.title = 'Cadastro';
    this.loadData();
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });

    const ipRes = await myIpFetch();
    console.log('IP:', ipRes.ip);

    let lat = null;
    let lng = null;
    try {
      const position = await getCurrentPosition();
      const {coords} = position;
      console.log('COORDS:', coords);
      lat = coords.latitude;
      lng = coords.longitude;
    } catch (e) {
      console.log(e);
    }

    const payload = {
      codigo: this.props.params.codigo,
      numero: this.props.params.numero,
    };

    api
      .post('/cadastro/data', payload)
      .then(response => {
        const data = response.data;

        if (data?.dados && !data?.dados?.nmipaceite) {
          const dados = data.dados;

          const datapedido = dados.dtdatapedido;
          const ano = datapedido.slice(0, 4);
          const mes = datapedido.slice(5, 7);
          const dia = datapedido.slice(8, 10);

          if (dados.nmpais === 'Brasil' || dados.cdtabprecos === '442') {
            this.setState({
              nmpais: 'Brasil',
            });
          }

          const moeda = getCurrencyFromTabPriceCode(dados.cdtabprecos);

          const address = String(dados.nmendereco || '').split(',');

          this.setState(
            {
              cdproduto: dados.cdproduto,
              moeda,
              cdpedidonr: dados.cdpedidonr,
              cdpedido: dados.cdpedido,
              dtdatapedido: `${dia}/${mes}/${ano}`,
              cdidioma: dados.cdidioma,
              fgtipomanut: dados.fgtipomanut,
              modulos: dados.modulos,
              fgmodalidademanut: dados.fgmodalidademanut,
              cdlocalatende: dados.cdlocalatende,
              txobspagamento: dados.txobspagamento,
              cdnroaptos: dados.cdnroaptos,
              cdusuarios: dados.cdusuarios,
              cdusuariostef: dados.cdusuariostef,
              vlvalorvenda: parseFloat(dados.vlvalorvenda),
              vlvalormensal: parseFloat(dados.vlvalormensal),
              nmemailvendedor: dados.emailvendedor,
              nmnomeresponsavel: dados.nmnomeresponsavel,
              nmemailresponsavel: dados.nmemailresponsavel,

              nmrazaosocial: dados.nmrazaosocial,
              nmfantasia: dados.nmfantasia,
              tipopessoa: String(dados.nmdoc1 || '').length === 18 ? 'J' : 'F',
              nmdoc1: dados.nmdoc1,
              nmdoc2: dados.nmdoc2,
              nmpais: dados.nmpais,
              nmcep: dados.nmcep,
              nmendereco: address[0],
              nmendnumero: address[address.length - 1],
              nmbairro: dados.nmbairro,
              nmcidade: dados.nmcidade,
              nmestado: dados.nmestado,
              nmnomefinanceiro: dados.nmnomefinanceiro,
              nmemailfinanceiro: dados.nmemailfinanceiro,
              nmdirnome: dados.nmdirnome,
              nmdiremail: dados.nmdiremail,
              nmnomegerente: dados.nmnomegerente,
              nmemailgerente: dados.nmemailgerente,
              nmtelefone: dados.nmtelefone,
              fgcovid: dados.fgcovid,
              cdpromocao: dados.cdpromocao,

              nroipusuario: ipRes.ip,
              nmipaceite: ipRes.ip,

              vlvalorpermuta: parseFloat(dados.vlvalorpermuta),
              vlqtdepermuta: dados.vlqtdepermuta,
              txobspermuta: dados.txobspermuta,
              txobspermuta2: makePermutaText(dados.permutas, dados.cdidioma),
              lat,
              lng,

              isLoading: false,
            },
            () => {
              if (parseInt(this.state.cdidioma, 10) === 3) {
                this.props.setLang('es');
              } else {
                this.props.setLang('pt');
              }
            },
          );
        } else {
          this.setState({
            isLoading: false,
            isFinished: true,
            cdproduto: data?.dados?.cdproduto || PRODUCT_LIGHTWEB_CODE,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  goToStep = step => {
    this.setState({step});
  };

  finishCadastro = () => {
    const {isAcepted, nmdirnome, nmassinatura, cdproduto} = this.state;
    const productName = getProductName(cdproduto);
    if (!isAcepted) {
      alert2({
        title: `Antes de utilizar o ${productName}, é necessário que você leia, entenda e concorde com estes termos.`,
        icon: 'warning',
      });
    } else if (
      nmdirnome.toUpperCase().trim() !== nmassinatura.toUpperCase().trim()
    ) {
      alert2({
        title: `Antes de utilizar o ${productName}, é necessário que você leia e assine o nome do responsável.`,
        icon: 'warning',
      });
    } else {
      this.enviarCadastro();
    }
  };

  enviarCadastro = () => {
    this.setState({
      isLoading: true,
    });

    const totalLicenciamento = formatNumberToSend(
      this.state.vlvalormensal,
      this.state.moeda,
    );
    const totalItc = formatNumberToSend(
      this.state.vlvalorvenda,
      this.state.moeda,
    );

    const data = {
      cdproduto: this.state.cdproduto,
      cdpedidonr: this.state.cdpedidonr,
      cdpedido: this.state.cdpedido,
      cdidioma: this.state.cdidioma,
      nroipusuario: this.state.nroipusuario,
      nmipaceite: this.state.nmipaceite,
      dtaceitecontrato: now(),
      observacao: this.state.txobspagamento,
      quantidadeUh: this.state.cdnroaptos,
      quantidadeUsuario: this.state.cdusuarios,
      quantidadeTef: this.state.cdusuariostef,
      totalLicenciamento,
      totalItc,
      itcFree: parseInt(this.state.vlvalorvenda) === 0,
      vendedor: this.state.nmemailvendedor,
      nropedido: this.state.cdpedidonr,
      emailvendedor: this.state.nmemailvendedor,
      nmrazaosocial: this.state.nmrazaosocial.toUpperCase(),
      nmfantasia: this.state.nmfantasia.toUpperCase(),
      nmdoc1: this.state.nmdoc1,
      nmdoc2: this.state.nmdoc2,
      nmpais: this.state.nmpais,
      nmcep: this.state.nmcep,
      nmendereco: this.state.nmendereco,
      nmendnumero: this.state.nmendnumero,
      nmbairro: this.state.nmbairro,
      nmcidade: this.state.nmcidade,
      nmestado: this.state.nmestado,
      nmnomeresponsavel: this.state.nmnomeresponsavel,
      nmemailresponsavel: this.state.nmemailresponsavel,
      nmdirnome: this.state.nmdirnome,
      nmdiremail: this.state.nmdiremail,
      nmnomegerente: this.state.nmnomegerente,
      nmemailgerente: this.state.nmemailgerente,
      nmnomefinanceiro: this.state.nmnomefinanceiro,
      nmemailfinanceiro: this.state.nmemailfinanceiro,
      nmtelefone: this.state.nmtelefone,
      fgcovid: this.state.fgcovid,
      fgblackfriday: this.state.cdpromocao === BLACK_FRIDAY_CODE,

      lat: this.state.lat,
      lng: this.state.lng,

      regiao: getRegiao(this.state.cdlocalatende),
      itc: getITC(this.state.fgmodalidademanut, this.state.cdidioma),
      modulos: _.map(
        getModulosString(this.state.modulos, this.state.cdidioma),
      ).join('; '),
      licenciamento: getManutencao(this.state.fgtipomanut, this.state.cdidioma),

      vlvalorpermuta: this.state.vlvalorpermuta
        ? formatNumberToSend(this.state.vlvalorpermuta, this.state.moeda)
        : null,
      txobspermuta: this.state.txobspermuta,
      vlqtdepermuta: this.state.vlqtdepermuta,
      txobspermuta2: this.state.txobspermuta2,
    };

    api
      .post('/cadastro/save', {data})
      .then(() => {
        this.setState({
          isLoading: false,
          isFinished: true,
        });

        notification.success('Cadastro efetuado com sucesso!', '');
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });

        notification.error('Não foi possível realizar o cadastro', '');
      });
  };

  renderStep1 = () => {
    const {
      cdpedidonr,
      dtdatapedido,
      modulos,
      cdidioma,
      fgtipomanut,
      fgmodalidademanut,
      txobspagamento,
      cdnroaptos,
      cdusuarios,
      cdusuariostef,
      cdlocalatende,
      moeda,
      vlvalormensal,
      vlvalorvenda,
      vlvalorpermuta,
      vlqtdepermuta,
      txobspermuta,
      txobspermuta2,
    } = this.state;
    return (
      <div>
        <Step1
          cdpedidonr={cdpedidonr}
          dtdatapedido={dtdatapedido}
          modulos={modulos}
          cdidioma={cdidioma}
          fgtipomanut={fgtipomanut}
          fgmodalidademanut={fgmodalidademanut}
          txobspagamento={txobspagamento}
          cdnroaptos={cdnroaptos}
          cdusuarios={cdusuarios}
          cdusuariostef={cdusuariostef}
          cdlocalatende={cdlocalatende}
          moeda={moeda}
          vlvalormensal={vlvalormensal}
          vlvalorvenda={vlvalorvenda}
          vlvalorpermuta={vlvalorpermuta}
          vlqtdepermuta={vlqtdepermuta}
          txobspermuta={txobspermuta}
          txobspermuta2={txobspermuta2}
          nextStep={() => {
            this.goToStep(2);
          }}
        />
      </div>
    );
  };

  renderStep2 = () => {
    const {
      nmrazaosocial,
      nmfantasia,
      nmpais,
      tipopessoa,
      nmdoc1,
      nmdoc2,
      nmcep,
      nmendereco,
      nmendnumero,
      nmbairro,
      nmcidade,
      nmestado,
      nmnomeresponsavel,
      nmemailresponsavel,
      nmnomefinanceiro,
      nmemailfinanceiro,
      nmdirnome,
      nmdiremail,
      nmnomegerente,
      nmemailgerente,
      nmtelefone,
    } = this.state;
    return (
      <Step2
        nmrazaosocial={nmrazaosocial}
        nmfantasia={nmfantasia}
        nmpais={nmpais}
        tipopessoa={tipopessoa}
        nmdoc1={nmdoc1}
        nmdoc2={nmdoc2}
        nmcep={nmcep}
        nmendereco={nmendereco}
        nmendnumero={nmendnumero}
        nmbairro={nmbairro}
        nmcidade={nmcidade}
        nmestado={nmestado}
        nmnomeresponsavel={nmnomeresponsavel}
        nmemailresponsavel={nmemailresponsavel}
        nmnomefinanceiro={nmnomefinanceiro}
        nmemailfinanceiro={nmemailfinanceiro}
        nmdirnome={nmdirnome}
        nmdiremail={nmdiremail}
        nmnomegerente={nmnomegerente}
        nmemailgerente={nmemailgerente}
        nmtelefone={nmtelefone}
        backStep={() => {
          this.goToStep(1);
        }}
        nextStep={values => {
          this.setState({...values}, () => {
            this.goToStep(3);
          });
        }}
      />
    );
  };

  renderStep3 = () => {
    const {isAcepted, nmdirnome, nmassinatura, cdproduto, cdpromocao} =
      this.state;

    return (
      <div>
        <Step3
          lang={this.state.cdidioma}
          cdproduto={cdproduto}
          isBlackFriday={cdpromocao === BLACK_FRIDAY_CODE}
          isAcepted={isAcepted}
          nmdirnome={nmdirnome}
          nmassinatura={nmassinatura}
          handleIsAceptedChange={() => {
            this.setState({
              isAcepted: !isAcepted,
              nmassinatura: isAcepted ? nmassinatura : '',
            });
          }}
          handleChangeSignature={value => {
            this.setState({nmassinatura: value});
          }}
          backStep={() => {
            this.goToStep(2);
          }}
          nextStep={() => {
            this.finishCadastro();
          }}
        />
      </div>
    );
  };

  renderStepBreadcrumb = () => {
    const {step} = this.state;
    return <StepsBreadcrumb step={step} />;
  };

  renderStep = () => {
    const {step} = this.state;

    if (step === 1) {
      return <div>{this.renderStep1()}</div>;
    }
    if (step === 2) {
      return <div>{this.renderStep2()}</div>;
    }
    return <div>{this.renderStep3()}</div>;
  };

  renderSucesso = () => {
    const {cdidioma, cdproduto} = this.state;
    return <CadastroSuccess lang={cdidioma} cdproduto={cdproduto} />;
  };

  render() {
    const {isLoading, cdproduto, isFinished} = this.state;

    if (isFinished) {
      return <div>{this.renderSucesso()}</div>;
    }
    return (
      <div>
        <Loading show={isLoading} />

        <CadastroContainer cdproduto={cdproduto} loading={isLoading}>
          {!isLoading ? (
            <>
              <div className="steps-container">
                {this.renderStepBreadcrumb()}
              </div>
              <hr />

              <div className="step-content">{this.renderStep()}</div>
            </>
          ) : (
            <div />
          )}
        </CadastroContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
});

const mapDispathToProps = dispatch => bindActionCreators(LangActions, dispatch);

CadastroCliente.propTypes = {
  setLang: PropTypes.func.isRequired,
};

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispathToProps)(CadastroCliente),
  ),
);
