import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import Currency from 'components/currency';

import {getManutencao} from 'helpers/dslFunctions';

const AdicaoValues = ({
  moeda,
  vlvalorvenda,
  vltotalmensal,
  fgtipomanut,
  cdidioma,
  valortotaladicao,
  valorAdicionalITC,
}) => (
  <Row>
    <Col xs={12}>
      <div className="hr hr8 hr-double hr-dotted" />
    </Col>

    <Col xs={12} sm={6}>
      <h5 className=" ">
        <span>Total ITC&nbsp;</span>
        <small className="blue">
          (Instalação, treinamento e configuração):&nbsp;
        </small>
        <span className="red">
          {valorAdicionalITC ? (
            <Currency moeda={moeda} value={vlvalorvenda + valorAdicionalITC} />
          ) : (
            <Currency moeda={moeda} value={vlvalorvenda} />
          )}
        </span>
      </h5>
      <h5 className="">
        <span>Total a pagar&nbsp;</span>
        <span className="blue bolder">
          {getManutencao(fgtipomanut, cdidioma)}
        </span>
        <span>:&nbsp;</span>
        <span className="red">
          {valortotaladicao ? (
            <Currency moeda={moeda} value={vltotalmensal + valortotaladicao} />
          ) : (
            <Currency moeda={moeda} value={vltotalmensal} />
          )}
        </span>
      </h5>
    </Col>
  </Row>
);

AdicaoValues.defaultProps = {
  vlvalorvenda: null,
  vltotalmensal: null,
  fgtipomanut: null,
  valortotaladicao: null,
  valorAdicionalITC: null,
};

AdicaoValues.propTypes = {
  moeda: PropTypes.string.isRequired,
  vlvalorvenda: PropTypes.number,
  vltotalmensal: PropTypes.number,
  fgtipomanut: PropTypes.number,
  cdidioma: PropTypes.string.isRequired,
  valortotaladicao: PropTypes.number,
  valorAdicionalITC: PropTypes.number,
};

export default AdicaoValues;
