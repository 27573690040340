import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import InputDecimal from 'components/form/inputDecimal';
import Currency from 'components/currency';

const IService = ({
  checked,
  handleChange,
  moeda,
  ITC,
  ITCplus,
  LM,
  LMplus,
  visible,
  disabled,
  showPlus,
  handleChangeITCplus,
  handleChangeLMplus,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <div>
      <div className="hr hr8 hr-double hr-dotted" />

      <Row>
        <Col sm={12}>
          <h4 className="header smaller lighter blue">iService:</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Row>
            <Col xs={12} sm={3}>
              <div className="pull-left" style={{marginBottom: '5px'}}>
                <div className="checkbox mb-2">
                  <label>
                    <input
                      disabled={disabled}
                      type="checkbox"
                      className="ace"
                      checked={checked}
                      onChange={() => {
                        handleChange();
                      }}
                    />
                    <span className="lbl" style={{marginLeft: '-20px'}}>
                      &nbsp;Habilitar iService
                    </span>
                  </label>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={4}>
                  <h5 className=" ">
                    ITC&nbsp;
                    <span className="red">
                      <Currency moeda={moeda} value={ITC} />
                    </span>
                  </h5>

                  {showPlus ? (
                    <>
                      <div className="text-center">
                        <i className="fa fa-plus fa-1x" />
                      </div>

                      <InputDecimal
                        disabled={!checked}
                        placeholder="0,00"
                        className="form-control"
                        value={ITCplus}
                        onValueChange={value => {
                          handleChangeITCplus(value || 0);
                        }}
                        onBlur={() => {}}
                      />

                      <div className="text-center">
                        <i className="fa fa-arrow-down fa-1x" />
                      </div>

                      <div className="text-center">
                        <span className="red">
                          <Currency moeda={moeda} value={ITC + ITCplus} />
                        </span>
                      </div>
                    </>
                  ) : null}
                </Col>

                <Col xs={4}>
                  <h5 className="">
                    LM&nbsp;
                    <span className="red">
                      <Currency moeda={moeda} value={LM} />
                    </span>
                  </h5>

                  {showPlus ? (
                    <>
                      <div className="text-center">
                        <i className="fa fa-plus fa-1x" />
                      </div>

                      <InputDecimal
                        disabled={!checked}
                        placeholder="0,00"
                        className="form-control"
                        value={LMplus}
                        onValueChange={value => {
                          handleChangeLMplus(value || 0);
                        }}
                        onBlur={() => {}}
                      />

                      <div className="text-center">
                        <i className="fa fa-arrow-down fa-1x" />
                      </div>

                      <div className="text-center">
                        <span className="red">
                          <Currency moeda={moeda} value={LM + LMplus} />
                        </span>
                      </div>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="clearfix" />
        </Col>
      </Row>
    </div>
  );
};

IService.defaultProps = {
  ITC: null,
  ITCplus: null,
  LM: null,
  LMplus: null,
  visible: true,
  disabled: false,
  showPlus: false,
  handleChangeITCplus: () => {},
  handleChangeLMplus: () => {},
};

IService.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  moeda: PropTypes.string.isRequired,
  ITC: PropTypes.number,
  ITCplus: PropTypes.number,
  LM: PropTypes.number,
  LMplus: PropTypes.number,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  showPlus: PropTypes.bool,
  handleChangeITCplus: PropTypes.func,
  handleChangeLMplus: PropTypes.func,
};

export default IService;
