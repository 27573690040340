const geoOptions = {
  enableHighAccuracy: true,
};

export const getCurrentPosition = () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, geoOptions);
    });
  }
  return Promise.reject(new Error('GEOLICATION NOT AVAILABLE'));
};
