import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';

import Login from 'pages/protected/pages/login';

// PROTECTED
import LayoutProtected from 'pages/protected/LayoutProtected';
import HomePedidos from 'pages/protected/pages/homePedido/HomePedido';
import NovoPedidos from 'pages/protected/pages/novoPedido';
import AdicaoPedidos from 'pages/protected/pages/adicaoPedido';
import ReimpressaoContrato from 'pages/protected/pages/reimpressao';
import ReenvioEmail from 'pages/protected/pages/reenvioemail';
import VoucherGenerator from 'pages/protected/pages/voucherGenerator';
import ConsultaPedido from 'pages/protected/pages/consultaPedido';

import NotFound from 'pages/protected/pages/notfound';

// CLIENTE
import LayoutCliente from 'pages/client/LayoutCliente';
import CadastroCliente from 'pages/client/pages/cadastro';
import AdicaoCliente from 'pages/client/pages/adicao';

import {ScrollToTop} from './ScrollToTop';

const RedirectAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/app/login');
  }, [navigate]);

  return null;
};

const RedirectOrder = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/app/pedidos');
  }, [navigate]);

  return null;
};

const ProtectedRoute = ({children}) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return <RedirectAuth />;
  }

  return <LayoutProtected>{children}</LayoutProtected>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppRoutes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route
          path="/app/login"
          element={
            <LayoutProtected>
              <Login />
            </LayoutProtected>
          }
        />

        <Route
          path="/app/pedidos"
          element={
            <ProtectedRoute>
              <HomePedidos />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/pedidos/novo"
          element={
            <ProtectedRoute>
              <NovoPedidos />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/pedidos/adicao"
          element={
            <ProtectedRoute>
              <AdicaoPedidos />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/reimpressaocontrato"
          element={
            <ProtectedRoute>
              <ReimpressaoContrato />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/voucher-generator"
          element={
            <ProtectedRoute>
              <VoucherGenerator />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/reenvioemail"
          element={
            <ProtectedRoute>
              <ReenvioEmail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/pedidos/consulta"
          element={
            <ProtectedRoute>
              <ConsultaPedido />
            </ProtectedRoute>
          }
        />

        <Route
          path="/app/cadastro/:codigo/:numero"
          element={
            <LayoutCliente>
              <CadastroCliente />
            </LayoutCliente>
          }
        />

        <Route
          path="/app/adicao/:codigo/:numero"
          element={
            <LayoutCliente>
              <AdicaoCliente />
            </LayoutCliente>
          }
        />

        <Route path="/app" element={<RedirectOrder />} />

        <Route path="/" element={<RedirectAuth />} />

        <Route path="/app/check" element={<div>CHECK</div>} />

        <Route
          path="*"
          element={
            <LayoutProtected>
              <NotFound />
            </LayoutProtected>
          }
        />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

const mapStateToProps = state => ({
  lang: state.lang,
});

export default connect(mapStateToProps)(AppRoutes);
