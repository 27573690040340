import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import {
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  PRODUCT_WEB30_CODE,
} from 'helpers/tabPrice';

const AdicaoType = ({
  cdproduto,
  optionSelected,
  activeAdicaoModulos,
  activeAdicaoUhs,
  activeAdicaoUsers,
}) => (
  <Row>
    <Col md={12}>
      <div className="text-center">
        {cdproduto === PRODUCT_LIGHTWEB_CODE ||
        cdproduto === PRODUCT_WEB30_CODE ||
        cdproduto === PRODUCT_EASYWEB_CODE ? (
          <Button
            type="button"
            id="btn-adicao-modulo"
            className={`btn radius-4 btn-lg btn-tipo-adicao ${
              optionSelected === 'render-adicao-modulos'
                ? 'btn-primary'
                : 'btn-light'
            }`}
            data-tipo="modulos"
            onClick={() => {
              activeAdicaoModulos();
            }}
            style={{width: '250px', marginBottom: '15px'}}>
            <i className="ace-icon fa fa-cogs bigger-180" />
            Adição de Módulos
          </Button>
        ) : null}
        &nbsp;&nbsp;
        {cdproduto === PRODUCT_LIGHTWEB_CODE ||
        cdproduto === PRODUCT_WEB30_CODE ||
        cdproduto === PRODUCT_EASYWEB_CODE ? (
          <Button
            type="button"
            id="btn-adicao-uhs"
            className={`btn radius-4 btn-lg btn-tipo-adicao ${
              optionSelected === 'render-adicao-uhs'
                ? 'btn-primary'
                : 'btn-light'
            }`}
            data-tipo="uhs"
            onClick={() => {
              activeAdicaoUhs();
            }}
            style={{width: '250px', marginBottom: '15px'}}>
            <i className="ace-icon fa fa-building bigger-180" />
            Adição de UHs
          </Button>
        ) : null}
        &nbsp;&nbsp;
        {cdproduto === PRODUCT_LIGHTWEB_CODE ||
        cdproduto === PRODUCT_WEB30_CODE ? (
          <Button
            type="button"
            id="btn-adicao-users"
            className={`btn radius-4 btn-lg btn-tipo-adicao ${
              optionSelected === 'render-adicao-users'
                ? 'btn-primary'
                : 'btn-light'
            }`}
            data-tipo="users"
            onClick={() => {
              activeAdicaoUsers();
            }}
            style={{width: '250px', marginBottom: '15px'}}>
            <i className="ace-icon fa fa-user bigger-180" />
            Adição de Usuários
          </Button>
        ) : null}
      </div>
    </Col>
  </Row>
);

AdicaoType.defaultProps = {
  optionSelected: '',
};

AdicaoType.propTypes = {
  cdproduto: PropTypes.string.isRequired,
  optionSelected: PropTypes.string,
  activeAdicaoModulos: PropTypes.func.isRequired,
  activeAdicaoUhs: PropTypes.func.isRequired,
  activeAdicaoUsers: PropTypes.func.isRequired,
};

export default AdicaoType;
