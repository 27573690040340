import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

export const ScrollToTop = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    console.log('LOCATION CHANGED', location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired,
};
