import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, FormGroup} from 'react-bootstrap';

import {Alert} from 'components/alert';
import TextareaContainer from 'components/form/textareaContainer';
import InputDecimal from 'components/form/inputDecimal';
import InputContainer from 'components/form/inputContainer';

import {makePermutaText} from 'helpers/permuta';

const PermutaForm = ({
  disabled,
  checked,
  handleChange,
  vlvalorpermuta,
  vlqtdepermuta,
  txobspermuta,
  handleChangeValue,
  handleChangeObservation,
  handleChangeQtd,
  permutas,
}) => (
  <div>
    <div className="hr hr8 hr-double hr-dotted" />

    <Row>
      <Col sm={12}>
        <h4 className="header smaller lighter blue">Permuta:</h4>

        {permutas.total > 0 ? (
          <Alert>
            <strong>Lembrar!&nbsp;</strong>
            <br />

            <span>{makePermutaText(permutas)}</span>
          </Alert>
        ) : null}
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div className="pull-left" style={{marginBottom: '5px'}}>
          <div className="checkbox mb-2">
            <label>
              <input
                disabled={disabled}
                type="checkbox"
                className="ace"
                checked={checked}
                onChange={() => {
                  handleChange();
                }}
              />
              <span className="lbl" style={{marginLeft: '-20px'}}>
                &nbsp;Habilitar
              </span>
            </label>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <FormGroup>
          <label className="col-sm-3 control-label no-padding-right">
            Valor:
          </label>

          <div className="col-sm-2">
            <InputDecimal
              label="Valor permuta"
              disabled={!checked}
              placeholder="0,00"
              className="form-control"
              value={vlvalorpermuta}
              onValueChange={value => {
                handleChangeValue(value || 0);
              }}
              onBlur={() => {}}
            />
          </div>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <InputContainer
          type="number"
          label="Quantidade"
          disabled={!checked}
          placeholder="0"
          showError={false}
          text={String(vlqtdepermuta || '')}
          onFieldChanged={e => {
            handleChangeQtd(
              String(e.target.value).replace(/[^0-9]+/g, '') || 0,
            );
          }}
          errorText=""
        />
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <TextareaContainer
          disabled={!checked}
          label="Observações"
          showError={false}
          text={txobspermuta}
          onFieldChanged={e => {
            handleChangeObservation(e.target.value);
          }}
          errorText=""
        />
      </Col>
    </Row>
  </div>
);

PermutaForm.defaultProps = {
  checked: true,
  disabled: false,
  txobspermuta: '',
  vlvalorpermuta: null,
  vlqtdepermuta: null,
  permutas: {},
};

PermutaForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChangeValue: PropTypes.func.isRequired,
  handleChangeObservation: PropTypes.func.isRequired,
  handleChangeQtd: PropTypes.func.isRequired,
  txobspermuta: PropTypes.string,
  vlvalorpermuta: PropTypes.number,
  vlqtdepermuta: PropTypes.number,
  permutas: PropTypes.shape({
    total: PropTypes.number,
    tipo: PropTypes.string,
    moeda: PropTypes.string,
  }),
};

export default PermutaForm;
