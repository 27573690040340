import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Collapse, Button} from 'react-bootstrap';

import SelectContainer from 'components/form/selectContainer';
import InputContainer from 'components/form/inputContainer';
import SearchContainer from 'components/form/searchContainer';
import InputMaskContainer from 'components/form/inputMaskContainer';

import notification from 'components/notification';

import {getEstados, getPaises} from 'helpers/dslFunctions';
import {cepSearch} from 'services/cepSearch';

const FullForm = ({
  collapsed,
  toggleCollapse,
  nmrazaosocial,
  nmfantasia,
  nmpais,
  nmdoc1,
  nmdoc2,
  nmcep,
  nmendereco,
  nmendnumero,
  nmbairro,
  nmcidade,
  nmtelefone,
  nmnomefinanceiro,
  nmemailfinanceiro,
  tipopessoa,
  nmestado,
  onFieldChanged,
}) => (
  <Row>
    <Col md={12}>
      {/* Dados completos do cliente */}
      <div>
        <Button
          className="btn btn-info pull-right"
          type="button"
          onClick={() => {
            toggleCollapse();
          }}>
          <i className="ace-icon fa fa-file bigger-110" />
          Preenchimento Completo
        </Button>

        <div className="clearfix" />

        <Collapse in={collapsed}>
          <div style={{marginTop: 20}}>
            {/* Razao Social */}
            <InputContainer
              type="text"
              label="Razao Social"
              showError={false}
              text={nmrazaosocial}
              onFieldChanged={e => {
                onFieldChanged('nmrazaosocial', e.target.value);
              }}
              errorText=""
              toUppercase
            />

            {/* Nome Fantasia */}
            <InputContainer
              type="text"
              label="Nome fantasia"
              showError={false}
              text={nmfantasia}
              onFieldChanged={e => {
                onFieldChanged('nmfantasia', e.target.value);
              }}
              errorText=""
              toUppercase
            />

            {/* País */}
            <SelectContainer
              label="País"
              showError={false}
              text={nmpais}
              onFieldChanged={e => {
                onFieldChanged('nmpais', e.target.value);
                onFieldChanged('nmdoc1', '');
                onFieldChanged('nmdoc2', '');
                onFieldChanged('tipopessoa', 'J');
              }}
              errorText=""
              options={getPaises()}
              colWidth="9"
            />

            {nmpais === 'Brasil' ? (
              <>
                <SelectContainer
                  label="Tipo Pessoa"
                  showError={false}
                  text={tipopessoa}
                  onFieldChanged={e => {
                    onFieldChanged('tipopessoa', e.target.value);
                  }}
                  errorText=""
                  options={[
                    {
                      codigo: 'F',
                      nome: 'Pessoa Física',
                    },
                    {
                      codigo: 'J',
                      nome: 'Pessoa Jurídica',
                    },
                  ]}
                  colWidth="9"
                />

                {tipopessoa === 'F' ? (
                  <InputMaskContainer
                    type="text"
                    label="CPF"
                    showError={false}
                    text={nmdoc1}
                    onFieldChanged={e => {
                      onFieldChanged('nmdoc1', e.target.value);
                    }}
                    errorText=""
                    inputId="cadastro-cpf"
                    mask="111.111.111-11"
                    onFieldBlur={e => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      if (value && value.length !== 11) {
                        onFieldChanged('nmdoc1', '');
                      }
                    }}
                  />
                ) : (
                  <InputMaskContainer
                    type="text"
                    label="CNPJ"
                    showError={false}
                    text={nmdoc1}
                    onFieldChanged={e => {
                      onFieldChanged('nmdoc1', e.target.value);
                    }}
                    errorText=""
                    inputId="cadastro-cnpj"
                    mask="11.111.111/1111-11"
                    onFieldBlur={e => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      if (value && value.length !== 14) {
                        onFieldChanged('nmdoc1', '');
                      }
                    }}
                  />
                )}
              </>
            ) : (
              <InputContainer
                type="text"
                label="Documento"
                showError={false}
                text={nmdoc1}
                onFieldChanged={e => {
                  onFieldChanged('nmdoc1', e.target.value);
                }}
                errorText=""
              />
            )}

            {/* Inscricao Estadual */}
            <InputContainer
              type="text"
              label={nmpais === 'Brasil' ? 'Inscrição Estadual' : 'Documento 2'}
              showError={false}
              text={nmdoc2}
              onFieldChanged={e => {
                onFieldChanged('nmdoc2', e.target.value);
              }}
              errorText=""
            />

            {/* CEP */}
            <SearchContainer
              type="number"
              label="CEP"
              showError={false}
              text={nmcep}
              onFieldChanged={e => {
                onFieldChanged('nmcep', e.target.value);
              }}
              placeholder=""
              onSearchClick={() => {
                const cep = nmcep ? nmcep.replace(/\D/g, '') : '';
                const valida = /^[0-9]{8}$/;
                if (cep !== '' && valida.test(cep)) {
                  cepSearch(cep)
                    .then(data => {
                      onFieldChanged('nmendereco', data.logradouro);
                      onFieldChanged('nmestado', data.uf);
                      onFieldChanged('nmcidade', data.localidade);
                      onFieldChanged('nmbairro', data.bairro);
                    })
                    .catch(() => {
                      notification.error('CEP não encontrado', '');
                    });
                } else {
                  notification.warning('CEP Inválido', '');
                }
              }}
              colWidth={4}
              errorText=""
            />

            {/* Logradouro */}
            <InputContainer
              type="text"
              label="Logradouro"
              showError={false}
              text={nmendereco}
              onFieldChanged={e => {
                onFieldChanged('nmendereco', e.target.value);
              }}
              errorText=""
            />

            {/* Numero */}
            <InputContainer
              type="text"
              label="Número"
              showError={false}
              text={nmendnumero}
              onFieldChanged={e => {
                onFieldChanged('nmendnumero', e.target.value);
              }}
              errorText=""
            />

            {/* Bairro */}
            <InputContainer
              type="text"
              label="Bairro"
              showError={false}
              text={nmbairro}
              onFieldChanged={e => {
                onFieldChanged('nmbairro', e.target.value);
              }}
              errorText=""
            />

            {/* Cidade */}
            <InputContainer
              type="text"
              label="Cidade"
              showError={false}
              text={nmcidade}
              onFieldChanged={e => {
                onFieldChanged('nmcidade', e.target.value);
              }}
              errorText=""
            />

            {/* Unidade Federativa */}
            {nmpais === 'Brasil' ? (
              <SelectContainer
                label="Estado"
                showError={false}
                text={nmestado}
                onFieldChanged={e => {
                  onFieldChanged('nmestado', e.target.value);
                }}
                errorText=""
                options={getEstados()}
                colWidth="9"
              />
            ) : (
              <InputContainer
                type="text"
                label="Estado"
                showError={false}
                text={nmestado}
                onFieldChanged={e => {
                  onFieldChanged('nmestado', e.target.value);
                }}
                errorText=""
              />
            )}

            {/* Telefone responsável */}
            <InputContainer
              type="number"
              label="Telefone"
              showError={false}
              text={nmtelefone}
              onFieldChanged={e => {
                onFieldChanged('nmtelefone', e.target.value);
              }}
              errorText=""
            />

            {/* Responsável Financeiro */}
            <InputContainer
              type="text"
              label="Nome Financeiro"
              showError={false}
              text={nmnomefinanceiro}
              onFieldChanged={e => {
                onFieldChanged('nmnomefinanceiro', e.target.value);
              }}
              errorText=""
            />

            {/* E-mail financeiro */}
            <InputContainer
              type="email"
              label="Email Financeiro"
              showError={false}
              text={nmemailfinanceiro}
              onFieldChanged={e => {
                onFieldChanged('nmemailfinanceiro', e.target.value);
              }}
              errorText=""
            />

            <div className="hr hr32 hr-dotted" />
          </div>
        </Collapse>

        <div className="clearfix" />
        <br />
      </div>
    </Col>
  </Row>
);

FullForm.defaultProps = {
  nmrazaosocial: '',
  nmfantasia: '',
  nmpais: '',
  nmdoc1: '',
  nmdoc2: '',
  nmcep: '',
  nmendereco: '',
  nmendnumero: '',
  nmbairro: '',
  nmcidade: '',
  nmtelefone: '',
  nmnomefinanceiro: '',
  nmemailfinanceiro: '',
  tipopessoa: '',
  nmestado: '',
};

FullForm.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  nmrazaosocial: PropTypes.string,
  nmfantasia: PropTypes.string,
  nmpais: PropTypes.string,
  nmdoc1: PropTypes.string,
  nmdoc2: PropTypes.string,
  nmcep: PropTypes.string,
  nmendereco: PropTypes.string,
  nmendnumero: PropTypes.string,
  nmbairro: PropTypes.string,
  nmcidade: PropTypes.string,
  nmtelefone: PropTypes.string,
  nmnomefinanceiro: PropTypes.string,
  nmemailfinanceiro: PropTypes.string,
  tipopessoa: PropTypes.string,
  nmestado: PropTypes.string,
  onFieldChanged: PropTypes.func.isRequired,
};

export default FullForm;
