import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Row, Col, Alert} from 'react-bootstrap';
import _ from 'lodash';

import {
  getModulosString,
  getITC,
  getManutencao,
  getRegiao,
} from 'helpers/dslFunctions';
import Currency from 'components/currency';

const Step1 = props => {
  const {t} = useTranslation();
  const {nextStep} = props;

  const hasTef = useMemo(
    () => parseInt(props.cdusuariostef),
    [props.cdusuariostef],
  );

  return (
    <div>
      <div className="step-pane no-padding active">
        <div className="widget-box transparent">
          <div className="widget-header widget-header-large">
            <h3 className="widget-title grey lighter">
              {t('paginaCliente.dadoscompra')}
            </h3>
            <div className="widget-toolbar no-border invoice-info">
              <span className="invoice-info-label">{`${t(
                'paginaCliente.pedido',
              )}:`}</span>
              &nbsp;
              <span className="red">{`#${props.cdpedidonr}`}</span>
              <br />
              <span className="invoice-info-label">
                {t('paginaCliente.data')}
              </span>
              &nbsp;
              <span className="blue">{props.dtdatapedido}</span>
            </div>
          </div>

          <div className="widget-body">
            <div className="widget-main no-padding-right no-padding-left">
              <Row>
                <Col xs={12} sm={6}>
                  <div className="">
                    <div className="col-xs-12 col-xs-offset-2 label label-lg label-info arrowed-in arrowed-right">
                      <b>{t('paginaCliente.informacoesistema')}</b>
                    </div>
                  </div>
                  <div>
                    <div className="clearfix" />
                    <ul className="list-unstyled spaced">
                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>Módulos:</span>
                        &nbsp;
                        <b className="red">
                          {(() => {
                            const modulos = getModulosString(
                              props.modulos,
                              props.cdidioma,
                            );
                            return _.map(modulos).join(', ');
                          })()}
                        </b>
                      </li>

                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{`${t(
                          'paginaCliente.formalicenciamento',
                        )}:`}</span>
                        &nbsp;
                        <b className="red">
                          {(() =>
                            getManutencao(props.fgtipomanut, props.cdidioma))()}
                        </b>
                      </li>

                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{`${t('paginaCliente.itc')}:`}</span>
                        &nbsp;
                        <b className="red">
                          {(() =>
                            getITC(props.fgmodalidademanut, props.cdidioma))()}
                        </b>
                      </li>

                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{`${t('paginaCliente.observacoes')}:`}</span>
                        &nbsp;
                        <b className="red">{props.txobspagamento}</b>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} sm={6}>
                  <div className="">
                    <div className="col-xs-12 label label-lg label-success arrowed-in arrowed-right">
                      <b>{t('paginaCliente.informacoeshotel')}</b>
                    </div>
                  </div>

                  <div>
                    <div className="clearfix" />
                    <ul className="list-unstyled spaced">
                      <li>
                        <i className="ace-icon fa fa-caret-right green" />
                        <span>{`${t('paginaCliente.quantidadeUh')}:`}</span>
                        &nbsp;
                        <b className="red">{props.cdnroaptos}</b>
                      </li>

                      <li>
                        <i className="ace-icon fa fa-caret-right green" />
                        <span>{`${t(
                          'paginaCliente.quantidadeUsuario',
                        )}:`}</span>
                        &nbsp;
                        <b className="red">{props.cdusuarios}</b>
                      </li>

                      {hasTef ? (
                        <li>
                          <i className="ace-icon fa fa-caret-right green" />
                          <span>{`${t(
                            'paginaCliente.quantidadeUsuariosTef',
                          )}:`}</span>
                          &nbsp;
                          <b className="red">{props.cdusuariostef}</b>
                        </li>
                      ) : null}

                      <li>
                        <i className="ace-icon fa fa-caret-right green" />
                        <span>{`${t('paginaCliente.regiao')}:`}</span>
                        &nbsp;
                        <b className="red">
                          {(() => getRegiao(props.cdlocalatende))()}
                        </b>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="space" />
                  <div className="hr hr8 hr-double hr-dotted" />
                </Col>

                {props.vlvalorpermuta && props.vlvalorpermuta > 0 ? (
                  <Col xs={12}>
                    <Alert color="info">
                      <span>{`${t('paginaCliente.permuta.title')}:`}</span>
                      <br />
                      <span>{t('paginaCliente.permuta.value')}:&nbsp;</span>
                      <span className="red">
                        <Currency
                          moeda={props.moeda}
                          value={props.vlvalorpermuta}
                        />
                      </span>
                      <br />
                      <span>{t('paginaCliente.permuta.qtd')}:&nbsp;</span>
                      <span className="red">{props.vlqtdepermuta}</span>
                      <br />
                      <span>{props.txobspermuta}</span>
                    </Alert>
                  </Col>
                ) : null}

                {props.txobspermuta2 ? (
                  <Col xs={12}>
                    <Alert color="info">
                      <span>{props.txobspermuta2}</span>
                    </Alert>
                  </Col>
                ) : null}
              </Row>

              <div className="space" />
              <div className="hr hr8 hr-double hr-dotted" />

              <div className="row">
                <div className="col-sm-offset-7 col-sm-5 pull-right">
                  <h4 className="pull-right">
                    <span>{`${t('paginaCliente.totalLicenciamento')}:`}</span>
                    &nbsp;
                    <span className="red">
                      <Currency
                        moeda={props.moeda}
                        value={props.vlvalormensal}
                      />
                    </span>
                  </h4>
                </div>
                <div className="col-sm-offset-6 col-sm-6 pull-right">
                  <h4 className="pull-right">
                    <span>{`${t('paginaCliente.totalItc')}:`}</span>
                    &nbsp;
                    <span className="red">
                      <Currency
                        moeda={props.moeda}
                        value={props.vlvalorvenda}
                      />
                    </span>
                  </h4>
                </div>
              </div>

              <div className="space-6" />
              <div className="well">{t('paginaCliente.confirirDados')}</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="wizard-actions">
        <Button
          type="button"
          className="btn-success btn-next"
          onClick={() => {
            nextStep();
          }}>
          <span>{t('paginaCliente.botao.proximo')}</span>
          <i className="ace-icon fa fa-arrow-right icon-on-right" />
        </Button>
      </div>
    </div>
  );
};

Step1.defaultProps = {
  txobspagamento: '',
  cdnroaptos: '',
  cdusuarios: '',
  cdusuariostef: '',
  cdlocalatende: '',
  moeda: '',
  vlvalormensal: '',
  vlvalorvenda: '',
  modulos: [],
  vlvalorpermuta: null,
  vlqtdepermuta: null,
  txobspermuta: '',
  txobspermuta2: '',
};

Step1.propTypes = {
  nextStep: PropTypes.func.isRequired,
  cdpedidonr: PropTypes.string.isRequired,
  dtdatapedido: PropTypes.string.isRequired,
  modulos: PropTypes.arrayOf(PropTypes.string),
  cdidioma: PropTypes.string.isRequired,
  fgtipomanut: PropTypes.string.isRequired,
  fgmodalidademanut: PropTypes.string.isRequired,
  txobspagamento: PropTypes.string,
  cdnroaptos: PropTypes.string,
  cdusuarios: PropTypes.string,
  cdusuariostef: PropTypes.string,
  cdlocalatende: PropTypes.string,
  moeda: PropTypes.string,
  vlvalormensal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vlvalorvenda: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vlvalorpermuta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vlqtdepermuta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  txobspermuta: PropTypes.string,
  txobspermuta2: PropTypes.string,
};

export default Step1;
