import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Well} from 'react-bootstrap';

import AceSpinner from 'components/aceSpinner';
import InputDecimal from 'components/form/inputDecimal';
import {getManutencao} from 'helpers/dslFunctions';

const AdicaoUser = ({
  cdusuariosAdd,
  cdusuarios,
  fgtipomanut,
  cdidioma,
  valortotaladicao,
  handleChangeValorTotalAdicao,
  onSpinnerChangedUser,
  onBlurAdicaoUser,
}) => (
  <>
    <Row>
      <Col xs={12}>
        <h4 className="header smaller lighter blue">
          Quantas usuários deseja adicionar?
        </h4>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={2}>
        <AceSpinner
          value={cdusuariosAdd}
          onChange={value => {
            onSpinnerChangedUser(value);
          }}
        />
      </Col>

      <Col xs={12} md={10}>
        <Well>
          <div>
            <h5 className="">
              <span>Total de Usuários:&nbsp;</span>
              <span className="blue bolder">
                {parseInt(cdusuarios, 10) + parseInt(cdusuariosAdd, 10)}
              </span>
            </h5>
          </div>

          <div>
            <h5 className="">
              <span>Acrescenta-se o valor:&nbsp;</span>
              <span className="blue bolder">
                {getManutencao(fgtipomanut, cdidioma)}
              </span>
            </h5>
            <Row>
              <Col xs={12} md={4}>
                <InputDecimal
                  className="form-control"
                  value={valortotaladicao}
                  onValueChange={value => {
                    handleChangeValorTotalAdicao(value);
                  }}
                  onBlur={() => {
                    onBlurAdicaoUser();
                  }}
                />
              </Col>
            </Row>
          </div>
        </Well>
      </Col>
    </Row>
  </>
);

AdicaoUser.defaultProps = {
  cdusuariosAdd: null,
  cdusuarios: null,
  fgtipomanut: null,
  valortotaladicao: null,
};

AdicaoUser.propTypes = {
  cdusuariosAdd: PropTypes.number,
  cdusuarios: PropTypes.number,
  fgtipomanut: PropTypes.number,
  cdidioma: PropTypes.string.isRequired,
  valortotaladicao: PropTypes.number,
  handleChangeValorTotalAdicao: PropTypes.func.isRequired,
  onSpinnerChangedUser: PropTypes.func.isRequired,
  onBlurAdicaoUser: PropTypes.func.isRequired,
};

export default AdicaoUser;
