import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {STORAGE_10GB, STORAGE_30GB, STORAGE_50GB} from 'helpers/modules';
import {
  TAB_PRECO_LIGHTWEB_DOLAR,
  TAB_PRECO_LIGHTWEB_EURO,
  TAB_PRECO_LIGHTWEB_REAL,
} from 'helpers/tabPrice';
import {
  LM_STORAGE_10GB_DOLAR,
  LM_STORAGE_10GB_EURO,
  LM_STORAGE_10GB_REAL,
  LM_STORAGE_30GB_REAL,
  LM_STORAGE_30GB_DOLAR,
  LM_STORAGE_30GB_EURO,
  LM_STORAGE_50GB_REAL,
  LM_STORAGE_50GB_DOLAR,
  LM_STORAGE_50GB_EURO,
} from 'helpers/calc/calcStorage';

const AttachmentStorage = ({
  storageSelected,
  handleChangeStorage,
  currency = 'R$',
  tablePriceCode = null,
}) => {
  const STORAGE_10GB_VALUE = useMemo(() => {
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_REAL) {
      return LM_STORAGE_10GB_REAL;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_DOLAR) {
      return LM_STORAGE_10GB_DOLAR;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_EURO) {
      return LM_STORAGE_10GB_EURO;
    }
    return LM_STORAGE_10GB_REAL;
  }, [tablePriceCode]);

  const STORAGE_30GB_VALUE = useMemo(() => {
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_REAL) {
      return LM_STORAGE_30GB_REAL;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_DOLAR) {
      return LM_STORAGE_30GB_DOLAR;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_EURO) {
      return LM_STORAGE_30GB_EURO;
    }
    return LM_STORAGE_30GB_REAL;
  }, [tablePriceCode]);

  const STORAGE_50GB_VALUE = useMemo(() => {
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_REAL) {
      return LM_STORAGE_50GB_REAL;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_DOLAR) {
      return LM_STORAGE_50GB_DOLAR;
    }
    if (tablePriceCode === TAB_PRECO_LIGHTWEB_EURO) {
      return LM_STORAGE_50GB_EURO;
    }
    return LM_STORAGE_50GB_REAL;
  }, [tablePriceCode]);

  return (
    <Row>
      <Col xs={12}>
        <h4 className="header smaller lighter blue">
          Armazenamento de Anexos:
        </h4>
      </Col>

      <Col sm={12} lg={12} md={12}>
        <div className="radio">
          <label style={{paddingLeft: '10px'}}>
            <input
              name="form-field-radio"
              type="radio"
              className="ace"
              value="1"
              checked={!storageSelected}
              onChange={() => {
                handleChangeStorage(null);
              }}
            />
            <span className="lbl"> Nenhum</span>
          </label>
        </div>

        <div className="radio">
          <label style={{paddingLeft: '10px'}}>
            <input
              name="form-field-radio"
              type="radio"
              className="ace"
              value={STORAGE_10GB}
              checked={storageSelected === STORAGE_10GB}
              onChange={() => {
                handleChangeStorage(STORAGE_10GB);
              }}
            />
            <span className="lbl">
              {' '}
              Familia WEB - 10 GB – {currency} {STORAGE_10GB_VALUE},00 (mês)
            </span>
          </label>
        </div>

        <div className="radio">
          <label style={{paddingLeft: '10px'}}>
            <input
              name="form-field-radio"
              type="radio"
              className="ace"
              value={STORAGE_30GB}
              checked={storageSelected === STORAGE_30GB}
              onChange={() => {
                handleChangeStorage(STORAGE_30GB);
              }}
            />
            <span className="lbl">
              {' '}
              Familia WEB - 30 GB – {currency} {STORAGE_30GB_VALUE},00 (mês)
            </span>
          </label>
        </div>

        <div className="radio">
          <label style={{paddingLeft: '10px'}}>
            <input
              name="form-field-radio"
              type="radio"
              className="ace"
              value={STORAGE_50GB}
              checked={storageSelected === STORAGE_50GB}
              onChange={() => {
                handleChangeStorage(STORAGE_50GB);
              }}
            />
            <span className="lbl">
              {' '}
              Familia WEB - 50 GB – {currency} {STORAGE_50GB_VALUE},00 (mês)
            </span>
          </label>
        </div>
      </Col>
    </Row>
  );
};

AttachmentStorage.defaultProps = {
  handleChangeStorage: () => {},
  storageSelected: null,
  tablePriceCode: null,
};

AttachmentStorage.propTypes = {
  handleChangeStorage: PropTypes.func,
  storageSelected: PropTypes.string,
  currency: PropTypes.string.isRequired,
  tablePriceCode: PropTypes.string,
};

export default AttachmentStorage;
