import {GET_LANG, SET_LANG} from '../actions/ActionTypes';

let idioma = navigator.language;
idioma = idioma.substring(0, 2);
if (idioma !== 'pt' && idioma !== 'es' && idioma !== 'en') {
  idioma = 'pt';
}

export default (lang = idioma, action = {}) => {
  switch (action.type) {
    case GET_LANG: {
      return lang;
    }
    case SET_LANG: {
      return action.lang;
    }
    default:
      return lang;
  }
};
