import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export function withRouter(Child) {
  return () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Child navigate={navigate} location={location} params={params} />;
  };
}
