import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import InputDecimal from 'components/form/inputDecimal';
import IntegrationsSelect from '../../../components/integrationsSelect';

const IntegrationModules = ({
  checked,
  handleChange,
  integrationsSelected,
  handleChangeIntegrations,
  ITC,
  LM,
  handleChangeITC,
  handleChangeLM,
}) => (
  <>
    <Row>
      <Col sm={12}>
        <h4 className="header smaller lighter blue">Integrações</h4>
        <Row>
          <Col xs={12} sm={3}>
            <div className="pull-left" style={{marginBottom: '5px'}}>
              <div className="checkbox mb-2">
                <label>
                  <input
                    disabled={false}
                    type="checkbox"
                    className="ace"
                    checked={checked}
                    onChange={() => {
                      handleChange();
                    }}
                  />
                  <span className="lbl" style={{marginLeft: '-20px'}}>
                    &nbsp;Habilitar Integrações
                  </span>
                </label>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={4}>
                <h5 className=" ">ITC</h5>

                <>
                  <InputDecimal
                    disabled={!checked}
                    placeholder="0,00"
                    className="form-control"
                    value={ITC}
                    onValueChange={value => {
                      handleChangeITC(value || 0);
                    }}
                    onBlur={() => {}}
                  />
                </>
              </Col>

              <Col xs={4}>
                <h5 className="">LM</h5>

                <>
                  <InputDecimal
                    disabled={!checked}
                    placeholder="0,00"
                    className="form-control"
                    value={LM}
                    onValueChange={value => {
                      handleChangeLM(value || 0);
                    }}
                    onBlur={() => {}}
                  />
                </>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="clearfix" />
      </Col>
    </Row>

    <Row>
      <Col>
        <br />
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <IntegrationsSelect
          isDisabled={!checked}
          onChange={value => {
            if (value) {
              handleChangeIntegrations([...value]);
            } else {
              handleChangeIntegrations([]);
            }
          }}
          value={integrationsSelected}
        />
      </Col>
    </Row>
  </>
);

IntegrationModules.defaultProps = {
  integrationsSelected: [],
  handleChangeITC: () => {},
  handleChangeLM: () => {},
  ITC: null,
  LM: null,
};

IntegrationModules.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  integrationsSelected: PropTypes.arrayOf(PropTypes.shape({})),
  handleChangeIntegrations: PropTypes.func.isRequired,
  ITC: PropTypes.number,
  LM: PropTypes.number,
  handleChangeITC: PropTypes.func,
  handleChangeLM: PropTypes.func,
};

export default IntegrationModules;
