import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControl,
  HelpBlock,
  InputGroup,
  Button,
} from 'react-bootstrap';

class SearchContainer extends Component {
  renderLabel = () => {
    const {label} = this.props;
    if (label) {
      return (
        <label className="col-sm-3 control-label no-padding-right">
          {label}
        </label>
      );
    }
    return null;
  };

  render() {
    const {
      showError,
      errorText,
      colWidth,
      label,
      toUppercase,
      inputId,
      placeholder,
      type,
      text,
      disabled,
      onFieldChanged,
      onSearchClick,
    } = this.props;
    const hasError = showError && !!errorText ? 'has-error' : '';
    const colW = colWidth ? `col-sm-${colWidth}` : 'col-sm-9';
    const hasLabelClass = label ? colW : '';
    const style = toUppercase ? {textTransform: 'uppercase'} : {};
    const id = inputId || undefined;

    return (
      <FormGroup className={hasError}>
        {this.renderLabel()}

        <div className={hasLabelClass}>
          <InputGroup>
            <FormControl
              type={type || 'search'}
              className="form-control"
              placeholder={placeholder}
              value={text}
              onChange={e => {
                onFieldChanged(e);
              }}
              disabled={disabled}
              style={style}
              id={id}
            />

            <span className="input-group-btn">
              <Button
                type="button"
                className="btn btn-inverse btn-white"
                onClick={() => {
                  onSearchClick();
                }}>
                <span className="ace-icon fa fa-search icon-on-right bigger-110" />
              </Button>
            </span>
          </InputGroup>
          {showError && errorText !== '' ? (
            <HelpBlock>{errorText}</HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

SearchContainer.defaultProps = {
  label: '',
  errorText: '',
  colWidth: '',
  toUppercase: false,
  inputId: '',
  placeholder: '',
  type: 'search',
  text: '',
  disabled: false,
};

SearchContainer.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toUppercase: PropTypes.bool,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default SearchContainer;
