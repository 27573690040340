import React from 'react';

export const ContratoPT = () => (
  <div className="contrato-br">
    <div>
      <p>
        Este Contrato de Licença Do Uso De Software, Manutenção E Suporte
        Técnico Por Prazo Determinado (&quot;CONTRATO&quot;) é um acordo legal
        entre a LICENCIADA, devidamente qualificada no ato do formulário de
        cadastramento (a &quot;LICENCIADA&quot;) e a DESBRAVADOR SOFTWARE LTDA,
        pessoa jurídica de direito privado, com sede na Rua Marechal José
        Bormann, 1001 - E, Bairro Jardim Itália, Chapecó – SC, CEP: 89802-121,
        com a matriz inscrita no cadastro nacional de pessoas jurídicas (CNPJ)
        sob o nº 82.176.983/0001-86, e suas respectivas filiais
        82.176.983/0004-29 (São Paulo - SP), 82.176.983/0005-00 (Foz do Iguaçu -
        PR), 82.176.983/0006-90 (Florianópolis – SC), 82.176.983/0007-71
        (Brasília – DF) e 82.176.983/0008-52 (Fortaleza – CE), (a
        &quot;DESBRAVADOR&quot;), para concessão da licença de uso ao software
        de propriedade intelectual exclusiva da DESBRAVADOR, pelo prazo
        determinado neste instrumento. Ao utilizar o produto, mesmo que parcial
        ou a título de teste, a LICENCIADA estará vinculada aos termos deste
        CONTRATO, concordando principalmente, CONSENTINDO com as condições aqui
        estipuladas. Em caso de discordância dos termos aqui apresentados, a
        utilização do software deve ser imediatamente interrompida.
      </p>

      <p>
        A LICENCIADA ESTÁ DE ACORDO COM O ACESSO, COLETA, USO, ARMAZENAMENTO,
        TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES da LICENCIADA para a
        integral execução das funcionalidades ofertadas pelos produtos/produtos
        da DESBRAVADOR. Em caso de discordância dos termos aqui apresentados, a
        utilização dos produtos/software deve ser imediatamente interrompida.
      </p>

      <h5>DEFINIÇÕES:</h5>

      <p>
        Os termos abaixo terão os seguintes significados quando usados neste
        Contrato:
      </p>

      <p>
        LICENCIADA: o responsável pela contratação com qualificação prevista nos
        termos de contratação a qual a licença de uso do software é concedida
        pela DESBRAVADOR, nos termos deste instrumento.
      </p>

      <p>
        DESBRAVADOR: a Licenciante DESBRAVADOR SOFTWARE LTDA, qualificada
        anteriormente, empresa especializada no fornecimento de produtos e
        serviços em tecnologia e titular da propriedade intelectual do produto
        objeto desta licença, ou autorizada para tanto.
      </p>

      <p>
        Software ou Produto: programa de computador de propriedade intelectual
        exclusiva da DESBRAVADOR.
      </p>

      <p>
        Versão: versão do Software que incorpora e compila diversas alterações e
        melhorias em relação à sua versão anterior.
      </p>

      <p>
        Portal: local onde o software será disponibilizado pela DESBRAVADOR para
        acesso da LICENCIADA, através do seguinte endereço eletrônico:
        desbravadorweb.com.br, mediante usuário e senha que serão
        disponibilizados pela DESBRAVADOR.
      </p>

      <p>
        Suporte Técnico: é a assistência técnica operacional ao Serviço que será
        prestada nos termos dispostos no presente Contrato.
      </p>

      <p>
        Manutenção: é a correção de eventuais &quot;erros&quot; ou defeitos no
        Software.
      </p>

      <p>
        Licença: é o direito temporário, não exclusivo, limitado e
        intransferível de uso do Software mediante remuneração, sob as condições
        e restrições contidas no presente instrumento.
      </p>

      <p>
        Dados de Contratação: parte do presente instrumento, identifica a
        LICENCIADA e indica o produto e a quantidade de unidades habitacionais e
        usuários que irão compor a licença, além dos módulos e os valores de
        instalação/treinamento e/ou configuração (ITC) e/ou licença, manutenção
        e/ou suporte técnico (LM).
      </p>

      <p>
        Cloud Computing: traduzido o inglês para &quot;computação em
        nuvem&quot;, significa a tecnologia através da qual os recursos de dados
        estão armazenados, com servidores acessados por meio da Internet,
        remotamente.
      </p>

      <p>
        Data Centers: também conhecido como centro de processamento de dados,
        são os equipamentos de informática - infraestrutura física - que fazem o
        armazenamento, processamento e controle de acesso dos dados.
      </p>

      <p>
        (SaaS): licença concedida na modalidade software como serviço, em que
        são fornecidos aplicativos prontos para uso, acessíveis pela Internet,
        nos quais os usuários podem se inscrever e usar sem precisar instalar ou
        gerenciar o software.
      </p>

      <p>
        Informações: o conjunto de dados operacionais, de natureza dinâmica e
        intrínseco às atividades comerciais da LICENCIADA, registrados por estas
        no software e que constituirá seu banco de dados e sobre os quais
        operarão os Softwares contratados.
      </p>

      <p>
        Treinamento: processo do qual a DESBRAVADOR ensinará o usuário da
        LICENCIADA a usar corretamente o Software.
      </p>

      <p>
        Instalação: entenda-se realizar as necessárias conexões e acionar os
        necessários comandos para deixar o Produto acessível a LICENCIADA.
      </p>

      <p>
        Pagamento imediato: pagamento em ser realizado em até 7 (sete) dias.
      </p>

      <p>
        Implantação/Configuração: trata-se de configurar o Software às
        necessidades do usuário, preparar bases de dados, cadastrar dados
        iniciais e preparar o programa para funcionar em benefício do usuário.
      </p>

      <h5>DECLARAÇÃO DE VONTADE:</h5>

      <p>
        A LICENCIADA DECLARA TER CIÊNCIA DOS DIREITOS E OBRIGAÇÕES DECORRENTES
        DO PRESENTE TERMO, CONSTITUINDO ESTE INSTRUMENTO O ACORDO COMPLETO ENTRE
        AS PARTES. DECLARA, AINDA, TER LIDO, COMPREENDIDO E ACEITO TODOS OS
        TERMOS E CONDIÇÕES DESTE INSTRUMENTO.
      </p>

      <p>
        As disposições aqui constantes regerão a relação entre a DESBRAVADOR e a
        LICENCIADA, razão pela qual é recomendável que a LICENCIADA imprima uma
        cópia deste documento para futura referência. Igualmente se aplicará no
        caso de utilização do produto, uma vez que se condiciona às condições
        previstas neste instrumento.
      </p>

      <p>
        A LICENCIADA se declara ciente de que as operações que corresponderem à
        aceitação de determinadas opções serão registradas nos bancos de dados
        da DESBRAVADOR, juntamente com a data e hora em que o aceite foi
        manifestado pela LICENCIADA, podendo tal informação ser utilizada como
        prova da aceitação da opção pela LICENCIADA, independentemente de outra
        formalidade
      </p>

      <p>
        As informações referentes à data e hora de acesso e ao endereço de
        protocolo de internet utilizado pela LICENCIADA para acessar o Portal ou
        o Aplicativo permanecerão armazenadas pelo Produto por, no mínimo, 6
        (meses) a contar da data de cada acesso realizado, independentemente do
        término da relação jurídica e comercial entre o Produto e a LICENCIADA,
        em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014.
      </p>

      {/* <h5>VIGÊNCIA:</h5>

      <p>
        12/24 meses (a ser selecionada pelo vendedor quando do lançamento do
        pedido). Observação embaixo dessa info (se possível): A LICENCIADA
        reconhece que o prazo de vigência deste contrato está diretamente
        associado a uma tabela de preços progressiva, de acordo com o desconto
        aplicado na Instalação, Configuração e Treinamento (ITC) do produto. Em
        caso de rescisão antecipada imotivada, será devida uma multa
        compensatória correspondente a 50% (cinquenta por cento) do valor da
        licença mensal vigente, multiplicado pelo número de meses faltantes até
        o término normal do contrato, para pagamento imediato.
      </p> */}

      <h5>1. OBJETO</h5>

      <p>
        1.1. Através do presente Termo a DESBRAVADOR outorga à LICENCIADA, em
        caráter temporário, não exclusivo, intransferível e oneroso, o direito
        de uso do Software para processamento de suas informações, através do
        acesso ao Portal.
      </p>

      <p>
        1.2. Em caráter complementar à licença do &quot;Produto&quot;,
        DESBRAVADOR prestará serviços de manutenção e suporte técnico ao
        Produto, conforme as condições estabelecidas neste instrumento,
        especialmente na cláusula &quot;Serviços de Manutenção e Suporte
        Técnico&quot;.
      </p>

      <p>
        1.2.1. Se a LICENCIADA contratava outro Produto com DESBRAVADOR, a
        licença do Produto contratado pelo presente instrumento revoga a
        anterior, constituindo-se o presente instrumento a licença atual do
        &quot;Produto&quot;, mantendo-se, entretanto, válidos eventuais diárias,
        carta de crédito e/ou vale consumos negociados anteriormente com base em
        permuta, passíveis de uso pela DESBRAVADOR e garantidos pela LICENCIADA,
        mantendo-os exercitáveis a qualquer tempo, sem restrições.
      </p>

      <h5>2. LICENÇA DE SOFTWARE – DIREITOS E RESTRIÇÕES</h5>

      <p>2.1. Licença de Software e Direitos</p>

      <p>
        2.2. DESBRAVADOR concede a &quot;Licença&quot; somente para uso nos
        limites e condições autorizados pelas disposições contidas neste
        Contrato, mediante a liberação de acessos ao &quot;Produto&quot; para o
        número máximo de usuários simultâneos e unidades habitacionais dispostos
        nos DADOS DA CONTRATAÇÃO. A contratação de usuários/unidades
        habitacionais adicionais deverá ser realizada separadamente, mediante
        acréscimo de valores.
      </p>

      <p>2.3. Restrições de Uso</p>

      <p>
        2.3.1. A LICENCIADA não adquire quaisquer direitos sobre o Produto, além
        daqueles expressamente previstos neste Contrato, sendo-lhe expressamente
        vedado:
      </p>

      <p>
        a) transferir, comercializar, sublicenciar, emprestar, alugar, arrendar
        ou de qualquer outra forma alienar o Produto;
      </p>

      <p>
        b) efetuar modificações, acréscimos ou derivações do Produto, por si
        própria ou através da contratação de terceiros;
      </p>

      <p>
        c) fazer engenharia reversa, decompilar ou desmontar o Produto, ou
        qualquer outra medida que possibilite o acesso ao código fonte deste,
        sem o consentimento prévio e por escrito de DESBRAVADOR;
      </p>

      <p>
        d) copiar, alterar, adaptar, aprimorar, corrigir, traduzir, atualizar,
        desenvolver novas versões ou elaborar obras derivadas do Produto ou
        qualquer de suas partes ou componentes, ou ainda, usar de modo diverso
        do expressamente estipulado no presente Contrato.
      </p>

      <p>
        e) remover os avisos de direitos autorais ou quaisquer outros avisos de
        direitos de propriedade contidos no Produto e na documentação.
      </p>

      <p>
        2.3.2. Além das vedações contidas na presente Cláusula (Restrições de
        Uso), é vedado à LICENCIADA duplicar ou copiar o Produto ou sua
        Documentação, no todo ou em parte.
      </p>

      <p>
        2.3.3. A LICENCIADA reconhece que o Produto, a estrutura da base de
        dados, a documentação e quaisquer eventuais modificações, acréscimos,
        atualizações, customizações e novas versões, ainda que não previstas
        neste Contrato, são protegidos pela legislação de Direitos do Autor e de
        proteção aos programas de computador. A LICENCIADA deverá limitar o
        acesso ao Produto àquele cujo conhecimento seja necessário para a
        realização de seus serviços à LICENCIADA.
      </p>

      <p>
        2.3.4. Em razão do critério adotado de seleção, organização ou
        disposição do conteúdo, a DESBRAVADOR não realiza o compartilhamento de
        senhas de acesso ao banco de dados.
      </p>

      <h5>3. SUPORTE TÉCNICO E MANUTENÇÃO</h5>

      <p>
        3.1. Durante a vigência da presente &quot;Licença&quot;, DESBRAVADOR
        prestará à LICENCIADA o seguinte serviço de manutenção:
      </p>

      <p>
        3.1.1. DESBRAVADOR corrigirá erros ou defeitos relatados pela LICENCIADA
        do Produto, salvo se os erros e/ou defeitos surgirem em razão da
        introdução, pela LICENCIADA ou por terceiros, de qualquer alteração ou
        modificação no Produto, sem a autorização prévia e por escrito de
        DESBRAVADOR.
      </p>

      <p>
        3.1.2. DESBRAVADOR identificará eventuais anormalidades existentes no
        Produto, assim como analisará alternativas para a solução da
        anormalidade.
      </p>

      <p>
        3.2. DESBRAVADOR prestará à LICENCIADA os seguintes serviços de suporte
        técnico/consultoria:
      </p>

      <p>
        3.2.1. Serviço de Instalação, Treinamento Inicial e Configuração (ITC)
        ao &quot;Produto&quot;, conforme contratado dentre as seguintes formas:
      </p>

      <p>
        assistência remota (via telefone/ web), com disponibilidade máxima de
        até 16 (dezesseis) horas de consultoria online, disponível em horário
        comercial, de segunda a sexta-feira, exceto em feriados e datas festivas
        facultativas, através dos canais de atendimento divulgados pela
        DESBRAVADOR e nos horários previstos em seu sítio eletrônico (
        <a href="https://www.desbravador.com.br/">
          https://www.desbravador.com.br/
        </a>
        ), mediante agendamento a ser realizado pela equipe técnica da
        DESBRAVADOR. Consultoria adicional, seja treinamento, seja horas
        adicionais de treinamento, ou alterações de configuração, só serão
        concedidas mediante pagamento da correspondente remuneração.
      </p>

      <p>
        assistência local, nas mesmas condições previstas na alínea anterior,
        mediante pagamento de remuneração adicional, além das despesas com
        deslocamento, alimentação e estadia de no máximo 02 (dois) técnicos de
        DESBRAVADOR. No caso de permanência da equipe por um período superior a
        7 (sete) dias corridos, deverá a LICENCIADA arcar ainda com custos de
        lavanderia, todos indispensáveis para a execução dos serviços, exceto as
        despesas decorrentes do consumo de bebidas alcoólicas e frigobar.
      </p>

      <p>
        DESBRAVADOR prestará, ainda, suporte técnico adicional pós-conclusão da
        implantação do &quot;Produto&quot;, a fim de auxiliar a LICENCIADA na
        solução de dúvidas de baixa grau de complexidade e para prestar
        assistência na operação do Produto, através dos canais de atendimento
        divulgados pela DESBRAVADOR e nos horários previstos em seu sítio
        eletrônico (
        <a href="https://www.desbravador.com.br/">
          https://www.desbravador.com.br/
        </a>
        ), desde que estes não configurem a necessidade de treinamento
        suplementares. A DESBRAVADOR formalizará via e-mail a informação da
        filial/região responsável pelo suporte da LICENCIADA, após a
        contratação. Por necessidades operacionais, a DESBRAVADOR
        resguardando-se ao direito de alterar o local responsável pelo suporte
        técnico.
      </p>

      <p>
        No caso de treinamentos suplementares, estes poderão ser prestados
        mediante disponibilidade da DESBRAVADOR e o pagamento de correspondente
        remuneração, no valor de R$ 80,00 (oitenta reais) por hora técnica, a
        ser reajustado anualmente e comunicado pela DESBRAVADOR em seus canais
        oficiais.
      </p>

      <p>
        3.3. Não se compreendem como serviços de suporte e manutenção os
        serviços consistentes em:
      </p>

      <p>a) mudança de linguagem de programação;</p>

      <p>
        b) realização de treinamentos de linguagem de programação, utilitários e
        editores de texto;
      </p>

      <p>c) mudança de ambiente operacional;</p>

      <p>
        d) alocação de equipe de profissionais de DESBRAVADOR às dependências
        internas da LICENCIADA, uma vez que a DESBRAVADOR não presta suporte
        técnico na modalidade presencial para este produto. Eventuais exceções,
        deverão ser acordadas previamente entre as partes, mediante orçamento.
      </p>

      <p>
        e) correções de erros provenientes de operação, uso indevido do Produto
        ou qualquer outra origem em que não exista culpa de DESBRAVADOR;
      </p>

      <p>
        f) recuperação de arquivos de dados, quando possível, provocados por
        erros de operação, falhas do equipamento, sistema operacional e
        instalação elétrica;
      </p>

      <p>
        g) desenvolvimento e/ou correções de erros oriundos de programas que
        visem atender particularidades do negócio da LICENCIADA (específicos
        e/ou customizações), os quais serão tratados em instrumento contratual
        separado deste;
      </p>

      <p>
        h) serviços de migração e conversão de dados de/para outros
        equipamentos;
      </p>

      <p>
        i) desenvolvimento e/ou correções de programas desenvolvidos sobre o
        Produto que visem atender particularidades do negócio da LICENCIADA
        (customizações). Entenda-se customização qualquer necessidade de ajuste
        do Produto padrão para alterar uma funcionalidade existente ou adicionar
        uma nova funcionalidade ao Produto para atender particularidade do
        negócio da LICENCIADA.
      </p>

      <p>
        3.4. A LICENCIADA, de forma a cooperar com a DESBRAVADOR no procedimento
        de implantação do Produto, colocará à disposição de DESBRAVADOR no
        mínimo um funcionário da LICENCIADA, sendo este responsável pela
        replicação do conhecimento aos demais funcionários da LICENCIADA ou,
        alternativamente, contratar treinamentos suplementares, nos termos do
        item 3.2.1.
      </p>

      <p>
        3.5. Se a estada e a alimentação para prestação dos serviços não forem
        fornecidas no próprio estabelecimento de hospedagem da LICENCIADA, esta
        responsabilizar-se-á pelo reembolso das correspondentes despesas em
        outro estabelecimento de hospedagem e alimentação fornecidas pela
        LICENCIADA, em estabelecimento de categoria mínima 2 (dois) estrelas,
        situado nas proximidades do estabelecimento em que serão prestados os
        serviços, a sua escolha. A LICENCIADA poderá optar pelo simples
        reembolso das correspondentes despesas mediante apresentação dos
        respectivos comprovantes.
      </p>

      <p>
        3.6. Em caso de extensão dos treinamentos por razões atribuíveis à
        LICENCIADA, tais como, mas a tanto não se limitando, indisponibilidade
        de funcionários para o treinamento agendado e treinamento concomitante
        ao desempenho de atividades funcionais de rotina, haverá cobrança de
        remuneração pelo tempo adicional despendido.
      </p>

      <h5>4. OBRIGAÇÕES DA LICENCIADA</h5>

      <p>
        Além das obrigações já previstas neste Contrato, constituem obrigações
        da LICENCIADA:
      </p>

      <p>
        4.1. Auxiliar e cooperar com DESBRAVADOR na análise e solução de
        supostos erros e defeitos no Produto.
      </p>

      <p>
        4.2. Fornecer para a DESBRAVADOR, quando solicitado, todos os
        equipamentos, materiais, recursos de comunicações, dados e informações
        relevantes, para viabilizar e facilitar a prestação dos serviços de
        manutenção e suporte técnico.
      </p>

      <p>
        4.3. Implementar os procedimentos recomendados por DESBRAVADOR para
        remediar, corrigir ou contornar problemas ocorridos no Produto.
      </p>

      <p>
        4.4. Notificar a DESBRAVADOR informando acerca de eventual não solução
        do problema/defeito, no prazo máximo de 30 (trinta) dias, contado da
        data em que DESBRAVADOR fornecer à LICENCIADA a correção ou solução
        alternativa. A falta de notificação por parte da LICENCIADA será
        considerada como aceitação da solução do problema/defeito pela
        LICENCIADA.
      </p>

      <p>
        4.5. Garantir que o Produto esteja sendo usado em conformidade com o
        disposto no presente Contrato de Licença e de acordo com as
        recomendações da DESBRAVADOR No caso de não atenção às orientações
        fornecidas pela DESBRAVADOR, caberá à LICENCIADA suportar exclusivamente
        os ônus decorrentes.
      </p>

      <p>
        4.6. Permitir o acesso de técnicos de DESBRAVADOR, devidamente
        identificados, em dependência internas da LICENCIADA necessárias à
        prestação dos serviços no local, se contratados.
      </p>

      <p>
        4.6.1. A LICENCIADA deverá dispor de acesso para a rede mundial
        Internet.
      </p>

      <h5>5. GARANTIA</h5>

      <p>5.1. Garantia</p>

      <p>
        5.1.1. DESBRAVADOR garante que é o legítimo titular do
        &quot;Produto&quot; licenciado, que é detentor dos direitos a ele
        relativos e possui autoridade para conceder a presente licença.
      </p>

      <p>
        5.1.2. DESBRAVADOR garante o funcionamento do programa e se
        responsabiliza pela correção de problemas ou erros oriundos de falhas
        que possam causar impedimento da execução do aplicativo, esclarecendo
        que, criação de novas rotinas, funcionalidades, adaptação das rotinas
        disponíveis, Layouts, e a não compreensão ou inexistência de relatórios
        em formatos diferentes daqueles disponibilizados pelo programa, não são
        compreendidos como falhas e erros.
      </p>

      <p>
        5.1.3. DESBRAVADOR não se responsabiliza por problemas, erros, danos ou
        prejuízos advindos de alterações não autorizadas efetuadas pelo cliente
        ou por terceiros no programa – as quais não são permitidas sob nenhuma
        forma ou modo e constituem violação de direitos de autor – ou oriundos
        de má operação de equipamentos e de decisões tomadas com base em
        informações geradas pelo programa.
      </p>

      <p>
        5.1.4. Com relação ao Produto, DESBRAVADOR garante que cada versão
        desenvolvida funcionará substancialmente de acordo com a finalidade para
        a qual foi concebida.
      </p>

      <p>5.1.5. Esta garantia não se aplicará:</p>

      <p>
        a) se uma falha no Produto resultar de acidente, violação, mau uso ou de
        culpa exclusiva da LICENCIADA ou de terceiros;
      </p>

      <p>
        b) na ocorrência de problemas, erros ou danos causados por uso
        concomitante de outros softwares que não tenham sido licenciados ou
        desenvolvidos por DESBRAVADOR;
      </p>

      <p>
        c) decorrência de qualquer descumprimento do presente Contrato pela
        LICENCIADA.
      </p>

      <p>
        5.1.6. DESBRAVADOR não garante à LICENCIADA que possa resolver problemas
        no Produto ou em suas eventuais modificações, acréscimos, atualizações,
        customizações e novas versões em decorrência de ataques de programas de
        terceiros ao Produto, com ou sem o conhecimento/consentimento da
        LICENCIADA, incluindo os referentes a vírus.
      </p>

      <p>
        5.1.7. Nenhuma outra garantia é oferecida além das expressamente
        descritas no presente instrumento, ainda que facultadas por lei,
        ressalvadas apenas as de caráter mandatório legal.
      </p>

      <p>
        5.1.8. DESBRAVADOR não oferece garantias, nem assegura ou aceita
        qualquer condição ou reclamação, expressa ou implícita, de que o
        &quot;Produto&quot; licenciado deverá cumprir com exigências e
        propósitos particulares da LICENCIADA ou apresentar condições comerciais
        específicas, assim como não oferece garantias de que o
        &quot;Produto&quot; licenciado estará completamente isento de erros ou
        funcionará sem ocorrência de qualquer erro, responsabilizando-se nos
        termos do disposto em 6.1.
      </p>

      <h5>6. LIMITAÇÕES DE RESPONSABILIDADE</h5>

      <p>
        6.1. Não obstante às garantias estabelecidas pelo presente instrumento,
        qualquer indenização devida pela DESBRAVADOR em decorrência do presente
        Contrato, ficará limitada ao valor pago pela LICENCIADA nos últimos 12
        (doze) meses à título de licença pelo Produto objeto deste instrumento.
      </p>

      <p>
        6.2. DESBRAVADOR não será responsável por quaisquer perdas e danos
        indiretos, de qualquer natureza, inclusive lucros cessantes deles
        decorrentes, reclamações de terceiros, perdas ou danos de registros ou
        dados, custo de fornecimento de bens substitutos, de serviços ou de
        tecnologia alternativos, custo com paralisações ou qualquer fato fora de
        seu controle razoável, mesmo se DESBRAVADOR tiver ciência da
        possibilidade de tais danos ocorrerem.
      </p>

      <p>
        6.3. A introdução, pela LICENCIADA, de quaisquer modificações ou
        alterações no Produto, ou mesmo sua manutenção pela LICENCIADA ou por
        terceiros, acarretará a exoneração de DESBRAVADOR de qualquer
        responsabilidade pelos produtos/softwares ora contratados, podendo a
        DESBRAVADOR rescindir este Contrato se tal fato afetar o funcionamento
        do Produto ou a prestação dos serviços ou disponibilização da licença
        dos produtos contratados.
      </p>

      <p>
        6.4. DESBRAVADOR está isento de qualquer responsabilidade pela
        utilização incorreta, sobre as configurações, regras, dados e valores
        utilizados nas funcionalidades de reservas online, (DSL ROL, Motores de
        Reservas, além do sistema MIDDLEWARE), pela LICENCIADA, seus
        estabelecimentos associados e/ou pelos agentes de viagens, empresas e
        demais terceiros.
      </p>

      <p>
        6.5. DESBRAVADOR não se responsabiliza por eventuais interrupções do
        serviço de reservas online, originárias de causas externas (internet,
        hardware, software de terceiros, entre outros), comprometendo-se a
        gerenciar as situações a fim de minimizar, ao máximo, tais interrupções
        e dar, sempre, o melhor apoio na solução dos eventuais problemas.
      </p>

      <p>
        6.6. DESBRAVADOR isenta-se de responsabilidade por quaisquer danos
        causados por overbooking a LICENCIADA, assim como a terceiros pela
        utilização das funcionalidades de reservas online (DSL – ROL e/ou
        Motores de Reservas).
      </p>

      <h5>7. AUTONOMIA ENTRE AS PARTES</h5>

      <p>
        7.1. As Partes declaram e concordam que este Contrato não implica a
        existência de qualquer vínculo empregatício entre si, seus
        administradores, empregados ou contratados, inclusive e especialmente
        para expressamente desobrigar qualquer das Partes em relação a encargos
        trabalhistas, previdenciários (INSS) ou fundiários (FGTS) da outra
        Parte, seus administradores, empregados ou contratados.
      </p>

      <p>
        7.1.1. DESBRAVADOR executará os serviços de maneira independente e será
        responsável pelo pagamento de salários, encargos e demais ônus
        associados a seus respectivos funcionários.
      </p>

      <p>
        7.1.2. DESBRAVADOR poderá subcontratar terceiros, desde que continue
        assegurando o cumprimento das obrigações previstas neste Contrato.
      </p>

      <p>
        7.1.2.1. Caracterizam-se por terceiros, para fins deste subitem, os
        profissionais certificados e treinados pela DESBRAVADOR.
      </p>

      <h5>8. ATUALIZAÇÕES, VERSÕES FUTURAS E UPGRADES</h5>

      <p>
        8.1. O presente Contrato não contempla direitos ou expectativas de
        futuras versões e nem de upgrades, embora DESBRAVADOR poderá liberá-los
        segundo política interna de conveniência e de remuneração. Além disso,
        DESBRAVADOR não possui obrigação de fornecer atualizações a LICENCIADA
        que não forem expressamente contratadas com DESBRAVADOR. Estes somente
        serão exigíveis se estabelecidos em acordo separado entre DESBRAVADOR e
        a LICENCIADA. Na ausência de tal acordo entre DESBRAVADOR e a
        LICENCIADA, DESBRAVADOR empenhará esforços razoáveis para fornecer
        suporte contínuo e medidas para identificar erros e defeitos, mediante a
        remuneração vigente ao tempo da sua ocorrência.
      </p>

      <p>
        8.1.1. Entenda-se por atualização a adequação das funcionalidades
        existentes no Produto às exigências legais.
      </p>

      <p>
        8.1.2. Entenda-se por versão a liberação de um conjunto de alterações
        e/ou aprimoramentos ao Produto, a critério de DESBRAVADOR.
      </p>

      <p>
        8.1.3. Entenda-se por upgrade a evolução de um Produto para outro
        Produto com aperfeiçoamentos, funcionalidades adicionais e/ou inovações
        tecnológicas.
      </p>

      <h5>9. CONFIDENCIALIDADE</h5>

      <p>
        9.1. DESBRAVADOR e a LICENCIADA, por si ou através de seus prepostos,
        contratados ou sucessores, se obrigam a manter a mais absoluta
        confidencialidade dos dados e informações que vierem a ter acesso em
        decorrência deste Contrato, durante o período de vigência e por 05
        (cinco) anos após o seu encerramento. A não obediência do previsto nesta
        Cláusula poderá ensejar a rescisão deste Contrato e sujeitará a
        infratora às penalidades e indenizações cabíveis.
      </p>

      <p>
        9.1.1. As informações confidenciais contemplam todas as informações
        sobre o Produto, suas atualizações, modificações e derivações, sua
        Documentação, bem como qualquer outra informação que seja designada como
        tal por DESBRAVADOR e pela LICENCIADA.
      </p>

      <h5>10. PRAZO</h5>

      <p>
        10.1. O presente Contrato vigorará pelo prazo de 36 (trinta e seis)
        meses, sendo automaticamente renovado pelo mesmo período sucessivo, caso
        nenhuma das Partes apresente oposição. Havendo renovação, as Partes
        deverão comunicar a intenção de extinguir o contrato por meio de aviso
        prévio por escrito, no prazo de no mínimo 90 (noventa) dias antes do
        término do contrato.
      </p>

      <h5>11. REMUNERAÇÃO E FORMA DE PAGAMENTO</h5>

      <p>
        11.1. A licença de uso, manutenção e suporte técnico ao
        &quot;Produto&quot; (LM) e os serviços de Instalação, Treinamentos e
        Configuração (ITC) acordados através do presente Contrato serão
        remunerados e sofrerão reajustes, juros e multa de mora conforme os
        valores, percentuais, forma e condições de pagamentos acordados e
        publicados nos dados de contratação.
      </p>

      <p>
        11.1.1 A adesão de novos módulos/funcionalidades/unidades habitacionais
        ou usuários após sem acréscimos financeiros na licença de uso ao sistema
        serão interpretadas como CORTESIA, de modo que poderão ser revogadas a
        qualquer tempo, sem aviso prévio.
      </p>

      <p>
        11.1.2. Os pagamentos a serem realizados pela LICENCIADA, mediante
        boleto bancário disponível para impressão no website
        www.desbravador.com.br, referem-se a importâncias líquidas.
      </p>

      <p>
        11.1.3. Os pagamentos referentes aos serviços de Instalação,
        Treinamentos e Configuração (ITC) serão efetuados iniciando-se 7 (sete)
        dias após a primeira data agendada para a implantação. Os pagamentos
        referentes a licença de uso, manutenção e suporte técnico (LM) serão
        efetuados no 3º (terceiro) dia do mês subsequente ao da disponibilização
        do software. Os pagamentos efetuados após a data fixada para o
        vencimento sofrerão acréscimos de multa de 2% (dois por cento) aplicável
        uma única vez e juros mensais de 6% (seis por cento).
      </p>

      <p>
        11.1.3.1 A alteração da data agendada para a implantação não modificará
        os vencimentos dos pagamentos referente a Instalação, Treinamentos e
        Configuração (ITC).
      </p>

      <p>
        11.2. O atendimento local (nas dependências da LICENCIADA), para
        execução de serviços, assim como Treinamentos Suplementares/ Adicionais,
        nos termos do item 3.2.1., somente serão prestados mediante o pagamento
        da correspondente remuneração, inclusive despesas com locomoção,
        alimentação, lavanderia e estada de técnicos, exceto as despesas
        decorrentes do consumo de bebidas alcoólicas e frigobar.
      </p>

      <p>
        11.3. Os valores referentes à ITC e a LM poderão sofrer atualização
        conforme preços vigentes a data da ativação do acesso se a mesma não
        ocorrer em até 30 (trinta) dias da data da aceitação do presente
        Contrato.
      </p>

      <p>
        11.4. Fica expressamente entendido e acordado pela LICENCIADA que o
        recebimento do principal por DESBRAVADOR não significará a quitação dos
        demais encargos e multa, sendo que DESBRAVADOR poderá rescindir o
        presente Contrato, caso tais irregularidades não sejam sanadas.
      </p>

      <p>
        11.5. O atraso no pagamento da contraprestação pecuniária por mais de 15
        (quinze) dias, pela LICENCIADA, ensejará na suspensão de acesso ao
        Produto (desligamento de funções), assim como na suspensão do serviço de
        suporte técnico, sem prejuízo da obrigação de pagamento referente ao
        período suspenso. Realizado os pagamentos em atraso DESBRAVADOR terá o
        prazo de 48 (quarenta e oito) horas úteis para restabelecer o acesso ao
        Produto à LICENCIADA.
      </p>

      <p>
        11.6. Sobre os valores estabelecidos neste instrumento estão acrescidos
        os tributos que incidam ou venham a incidir por força do objeto deste
        contrato, de acordo com a alíquota do local onde serão emitidas as notas
        fiscais.
      </p>

      <p>
        11.6.1. Se houver majoração das alíquotas que incidem sobre os serviços,
        ou até mesmo, a criação de um novo tributo, o valor correspondente será
        reajustado objetivando contemplar tal alteração.
      </p>

      <p>
        11.7. Os valores estabelecidos neste Contrato serão atualizados
        anualmente ou na menor periodicidade permitida pela legislação em vigor
        à época, de acordo com o índice que melhor refletir a perda do poder
        aquisitivo no período, entre eles, IGP- DI, IGP -M, INPC, IPC, IPCA, ou
        outro que &quot;melhor&quot; reflita a inflação do período, para cada um
        dos períodos de 12 (doze) meses sucessivos.
      </p>

      <p>
        11.7.1 A LICENCIADA que contratar com DESBRAVADOR através de uma Rede de
        Estabelecimentos Empresariais, terá os valores estabelecidos neste
        Contrato atualizados na data base do primeiro Contrato da Rede, de
        acordo com o índice que melhor refletir a perda do poder aquisitivo no
        período, entre eles, IGP-DI, IGP-M, INPC, IPC, IPCA, ou outro que
        &quot;melhor&quot; reflita a inflação do período.
      </p>

      <p>
        11.7.2 Em razão da flutuabilidade da economia e os riscos de os índices
        econômicos publicados não demonstrarem capazes de recuperar o
        reequilíbrio econômico-financeiro da negociação e remediar os efeitos da
        desvalorização da moeda, as partes estipulam como reajuste mínimo o
        percentual de 3% (três por cento) , caso nenhum dos índices acima
        mencionados estejam acima deste valor. O reajuste não será aplicado se
        acarretar a diminuição do valor monetário pactuado, ficando o montante
        estipulado neste contrato como valor mínimo de remuneração.
      </p>

      <p>
        11.7.3. Nos casos em que for contratada nova licença de produtos em
        razão de substituição de produtos em que seja mantido o valor mensal
        anterior licenciado, a data de reajuste do contrato de licença anterior
        será mantida.
      </p>

      <p>
        11.7.3.4 Os valores adicionais correspondentes à adesão de novos
        módulos/funcionalidades/unidades habitacionais ou usuários incorporarão
        o valor da licença, mantendo-se as datas de vencimento e reajuste da
        licença principal, conforme previsto neste instrumento.
      </p>

      <p>
        11.8. Sem prejuízo do disposto no item 1.2.1, no caso de existência de
        contrato anterior ao presente instrumento com a DESBRAVADOR:
      </p>

      <p>
        a) a LICENCIADA reconhece, neste ato, os vouchers e/ou carta de crédito
        decorrentes de permuta formalizada em contratos e/ou negociações
        anteriores, pela qual, manifesta pela manutenção destes, mantendo-os
        exercitável a qualquer tempo.
      </p>

      <p>
        b) em caso de término do contrato por qualquer causa, o pagamento das
        importâncias devidas convencionadas em permuta far-se-á mediante
        conversão em prestação pecuniária do valor permutado ou seu
        remanescente, se parcial. Da mesma forma, ocorrerá conversão em
        prestação pecuniária a recusa em providenciar os serviços/hospedagem
        mediante utilização do crédito/permuta consubstanciados nos vouchers ou
        carta de crédito supramencionados.
      </p>

      <p>
        c) a prestação pecuniária corresponderá ao valor permutado constante no
        contrato, independente do preço da diária no momento da sua formação ou
        do seu término, sendo que sobre o valor convertido em prestação
        pecuniária incidirá atualização monetária, consoante o índice pactuado
        para cada uma das renovações, até seu efetivo pagamento.
      </p>

      <p>
        11.9 Em eventual negociação por permuta presente nos dados da
        contratação, a hospedagem será representada por &quot;Vouchers&quot; dos
        quais deverão ser no ato da contratação assinados, registrando-se o
        nome, data, horário e endereço de I.P do responsável pela assinatura,
        sendo que a quitação da importância permutada está condicionada à
        efetiva utilização dos vouchers pela DESBRAVADOR, ou quem a sua ordem.
      </p>

      <p>
        11.10 Para os pagamentos excepcionalmente realizados mediante permuta
        deverão ser observadas as seguintes condições:
      </p>

      <p>
        a) em caso de término do contrato por qualquer causa, o pagamento das
        importâncias devidas convencionadas em permuta far-se-á mediante
        conversão em prestação pecuniária do valor permutado ou seu
        remanescente, se parcial. Da mesma forma, ocorrerá conversão em
        prestação pecuniária a recusa em providenciar hospedagem mediante
        utilização das permutas convencionadas como meio de pagamento.
      </p>

      <p>
        b) a prestação pecuniária corresponderá ao valor permutado constante no
        contrato, independente do preço da diária no momento da sua formação ou
        do seu término, para pagamento imediato.
      </p>

      <p>
        c) sobre o valor convertido em prestação pecuniária incidirá atualização
        monetária, consoante o índice pactuado para cada uma das renovações, até
        seu efetivo pagamento.
      </p>

      <p>
        11.11 Considerando-se que alguns das tecnologias concernentes a este
        contrato possam ser disponibilizados por empresas internacionais – IDS
        ou IDS/GDS, caso haja alteração de preços pelas empresas internacionais,
        criação de impostos no Brasil, variação cambial ou qualquer outro evento
        que venha a impactar o valor cobrado por reserva/UH efetiva, ambas
        partes LICENCIADAS se dispõem a rever os valores, de forma a torná-los
        compatíveis com os valores cobrados internacionalmente e/ou retomar o
        equilíbrio financeiro do contrato.
      </p>

      <h5>12. DO NÍVEL DE SERVIÇO DE DISPONIBILIDADE</h5>

      <p>
        12.1. O acesso ao Software estará disponível para acesso da LICENCIADA
        24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, com
        disponibilidade de 99,3% (noventa e nove vírgulas três por cento) por
        mês, exceto em caso de paradas emergenciais ou programadas, ou ainda,
        nos casos de caso fortuito ou força maior, conforme determina o artigo
        393 do Código Civil.
      </p>

      <p>
        12.1.1. O DESBRAVADOR está autorizado a realizar manutenção preventiva
        programada ou emergencial no Software ou Portal, e estes poderão ficar
        impossibilitados de transmitir e/ou receber dados pelo tempo necessário
        para a solução. Ao final do procedimento de emergência, a LICENCIADA
        será avisada sobre o assunto e motivo de tal interação. No caso de
        manutenções programadas a LICENCIADA será comunicada via e-mail sobre o
        período em que se farão as paradas nos Software ou Portal e o tempo
        necessário para a solução da situação. Neste caso a LICENCIADA poderá
        ficar impossibilitada de acessá-los, sem que isto gere qualquer
        penalidade para a DESBRAVADOR.
      </p>

      <h5>13. PROPRIEDADE INTELECTUAL</h5>

      <p>
        13.1 Todos os direitos de propriedade intelectual no tocante ao Produto
        e do material impresso ou disposto on-line (&quot;Documentação&quot;)
        são e permanecerão propriedade exclusiva de DESBRAVADOR incluindo, sem
        limitação, quaisquer aprimoramentos, correções, traduções, alterações,
        novas versões ou obras derivadas realizadas por DESBRAVADOR
      </p>

      <p>
        13.2 DESBRAVADOR se reserva o direito de retomar o Produto, objeto deste
        contrato nos casos em que a LICENCIADA:
      </p>

      <p>
        a) provoque prejuízos à propriedade intelectual de DESBRAVADOR, usando
        indevidamente o Produto, como nos casos de reprodução ilegal de
        programas;
      </p>

      <p>
        b) divulgue para terceiros todo ou parte do Produto licenciado, sua
        documentação, manuais e descrições técnicas sem prévia autorização por
        escrito de DESBRAVADOR;
      </p>

      <p>
        c) altere o Produto sem o consentimento de DESBRAVADOR, seja esta
        alteração a retirada ou substituição de trechos ou rotinas, ou um
        acréscimo ou conjunto de acréscimos intercalados de rotinas
        desenvolvidas, pela própria LICENCIADA ou por terceiros contratados, com
        o propósito de adulterar e tornar irreconhecível o programa original e;
      </p>

      <p>
        d) use o Produto de forma diversa daquela estipulada no presente
        instrumento.
      </p>

      <h5>14. RESTITUIÇÃO DAS INFORMAÇÕES</h5>

      <p>
        14.1. Suspenso o acesso ao Software ou rescindido este Contrato, a
        DESBRAVADOR manterá as informações da LICENCIADA armazenadas pelo
        período de 60 (sessenta) dias, contados da suspensão de acesso ou do
        pedido de rescisão. Durante este período, a LICENCIADA poderá solicitar,
        uma única vez, um relatório de suas informações contendo dados de
        pessoas, títulos, UHs, itens e reservas no formato &quot;.csv&quot;.
      </p>

      <p>
        14.2. Conforme descrito na cláusula 13.1. acima, passados os 60
        (sessenta) dias da suspensão do acesso da LICENCIADA ao Software, todas
        as informações da LICENCIADA em poder da DESBRAVADOR poderão ser
        excluídas permanentemente do banco de dados da Contratada,
        independentemente de terem sido extraídas ou não pela LICENCIADA.
      </p>

      <h5>15. RESCISÃO</h5>

      <p>
        15.1. Qualquer das Partes poderá rescindir este Contrato, se a outra
        Parte cometer uma infração ou descumprir qualquer das disposições
        estabelecidas neste Contrato.
      </p>

      <p>
        15.1.1. A Parte afetada pela infração contratual deverá notificar a
        Parte inadimplente, a qual terá o direito de sanar a infração ou
        descumprimento em até 30 (trinta) dias, contados do recebimento da
        notificação formal neste sentido, encaminhada ao e-mail
        juridico@desbravador.com.br
      </p>

      <p>
        15.1.2 A rescisão imediata não desobriga o pagamento dos valores das
        licenças mensais (LM) vencidas, sem prejuízo da inscrição junto ao
        Serviço de Proteção ao Crédito - SPC ou congêneres, nos termos destes
        termos de uso.
      </p>

      <p>
        15.2. Além das hipóteses de rescisão prevista anteriormente, quaisquer
        das Partes poderá resilir o presente contrato, mediante notificação
        prévia no prazo de 90 (noventa) dias. Nesta hipótese, será devido o
        pagamento integral da licença mensal (LM) correspondente ao mês de
        solicitação e mais ao período de 90 (noventa) dias de aviso prévio,
        período pelo qual subsiste a obrigação da DESBRAVADOR de continuar a
        prestação de suporte técnico ao Produto contratado.
      </p>

      <p>
        15.2.1 Sem prejuízo do cumprimento do prazo de aviso prévio estipulado
        no item 15.2, ocorrida uma situação que autorize a extinção do contrato
        antes de findo o primeiro período contratual, seja decorrente de simples
        vontade e/ou iniciativa da LICENCIADA, ou ainda, por esta utilizar
        programa ou plataforma semelhante ou concorrente a DESBRAVADOR,
        competirá à LICENCIADA suportar o pagamento de multa correspondente a
        50% (cinquenta por cento) do valor da licença mensal vigente,
        multiplicado pelo número de meses faltantes até o término normal do
        contrato, para pagamento imediato.
      </p>

      <p>
        15.3 No caso de opção de pagamento de Licença Anual ou Licença
        Semestral, a fim de adequar-se à conveniência e administração da
        LICENCIADA, a resilição do contrato antes de findo o prazo do contrato
        não importa em devolução dos valores pagos. A medida se justifica em
        razão da concessão extraordinária de desconto nos valores contratados
        nas referidas modalidades de pagamento.
      </p>

      <p>
        15.3.1 No caso de rescisão antecipada de Produto pago por licença anual
        ou semestral, a LICENCIADA suportará ainda o pagamento de multa
        correspondente a 50% (cinquenta por cento) do valor da licença vigente
        referente ao período remanescente do contrato. Caso já quitado, a
        DESBRAVADOR não realizará a restituição de valores eventualmente pagos,
        em razão dos descontos extraordinários concedidos para essa modalidade
        de contratação.
      </p>

      <p>
        15.4 Em caso solicitação de rescisão deste contrato, o valor relativo à
        implantação e treinamento, eventualmente parcelado e devido pela
        LICENCIADA, ou conversão em prestação pecuniária de permutas, terá seu
        vencimento antecipado e deverá ser imediatamente pago.
      </p>

      <p>
        15.5 Em nenhuma hipótese, haverá devolução de quaisquer importâncias
        pagas pela LICENCIADA à DESBRAVADOR, mesmo em caso de não utilização do
        sistema, uma vez que está à disposição da LICENCIADA.
      </p>

      <p>
        15.6 No caso de a LICENCIADA possuir reservas on-line e motores de
        reservas ou integração com plataformas terceiras, esta deve ainda
        providenciar contato com a empresa parceira responsável pela integração
        do Software e dos motores de reserva para comunicar que não ocorrerá
        mais a integração entre o Software da DESBRAVADOR e da parceira,
        isentando a DESBRAVADOR de quaisquer ônus oriundos deste assunto.
      </p>

      <h5>16. PROTEÇÃO DE DADOS</h5>

      <p>
        16.1 As Partes reconhecem e concordam que deverão observar as Regras de
        proteção de dados pessoais definidas na Política de Tratamento de Dados
        Pessoais, que estão publicadas no website da Desbravador
        (http://www.desbravador.com.br/), a qual é parte integrante e
        indissociável deste Contrato.
      </p>

      <p>16.2 Com a assinatura do presente, a LICENCIADA desde já autoriza:</p>

      <p>
        a) a receber informação sobre produtos, novidades, ofertas e demais
        conteúdo da DESBRAVADOR, por meio dos canais de comunicação, incluindo e
        não se limitando: e-mail, contato telefônico, SMS, aplicativos de
        conversas, e demais redes sociais;
      </p>

      <p>
        b) a divulgação nos canais oficiais e materiais da DESBRAVADOR do nome e
        imagem da LICENCIADA como pertencente ao quadro de clientes da
        DESBRAVADOR;
      </p>

      <p>
        c) a indicação da LICENCIADA (razão social, CNPJ e telefone comercial)
        para que empresas parceiras possam ofertar produtos e benefícios
        direcionados ao seu negócio.
      </p>

      <h5>17. INTEGRAÇÃO COM EMPRESA &quot;PARCEIRA&quot; </h5>

      <p>
        17.1 Considerando que a LICENCIADA poderá contratar plataforma/solução
        fornecida por empresas &quot;PARCEIRAS&quot;, indicada nos dados da
        contratação, para auxiliá-la na gestão e fomento de sua atividade e que,
        para tanto, necessita dos dados operados por meio do software
        DESBRAVADOR, de forma que possui interesse na Integração dos respectivos
        Sistemas, a fim de facilitar o tráfego e compartilhamento desses dados
        com o software da &quot;PARCEIRA&quot;, através do presente, a
        LICENCIADA formaliza seu consentimento de compartilhamento de todos os
        dados e informações que eventualmente façam-se necessários para o
        correto desempenho das funcionalidades do software Parceiro e o PMS da
        DESBRAVADOR.
      </p>

      <p>
        17.2 É de responsabilidade da LICENCIADA a manutenção do contrato e
        eventual adimplemento de pagamento do produto contratado com o Parceiro.
        Ficando, desde já, ciente que eventual inadimplemento, tanto com o
        Parceiro como com a DESBRAVADOR, poderá acarretar a suspensão da
        integração.
      </p>

      <p>
        17.3 Declara a LICENCIADA estar ciente que é de responsabilidade do
        Parceiro, o compromisso de manter o sistema dele e a Integração dos
        Sistemas em pleno funcionamento, sendo o único e exclusivo responsável
        pela manutenção, atualização e quaisquer outros ajustes que venham a ser
        necessários nos referidos sistemas. Assim, ocorrendo qualquer falha,
        inconsistência, bem como quaisquer necessidades de ajustes e suporte na
        integração, deverá a LICENCIADA contatar diretamente o Parceiro,
        isentando a DESBRAVADOR de qualquer responsabilidade.
      </p>

      <p>
        17.4 Considerando que a Integração dos Sistemas é realizada por meio de
        empresas distintas, esta poderá deixar de ser ofertada pela DESBRAVADOR
        e/ou pelo Parceiro, hipótese em que a LICENCIADA será comunicada com 30
        (trinta) dias de antecedência, acerca da descontinuidade da integração,
        não havendo qualquer garantia e direito à indenização por parte da
        DESBRAVADOR.
      </p>

      <p>
        17.5 Em virtude das diretrizes estabelecidas pela Lei Geral de Proteção
        de Dados – LGPD, deve a LICENCIADA informar aos seus hóspedes/usuários
        acerca do compartilhamento das informações, devendo observar e assegurar
        as prerrogativas da referida Lei.
      </p>

      <h5>18. DA CONTRATAÇÃO DO TEF</h5>

      <p>
        18.1 Caso contratado o módulo TEF (Transferência Eletrônica de Fundos) -
        sistema eletrônico que permite a transferência de valores monetários
        entre diferentes instituições financeiras - a liberação ao módulo
        condiciona-se à comunicação pela LICENCIADA à equipe de suporte técnico
        e implantação da DESBRAVADOR do envio de fichas e outras informações a
        serem solicitadas.
      </p>

      <p>
        18.2 É responsabilidade da LICENCIADA promover a aquisição do hardware,
        certificando junto à DESBRAVADOR que este cumpre os requisitos de
        operação do sistema, desde já, ciente que, para o início da ITC é
        necessário que os equipamentos estejam em seu estabelecimento e
        devidamente instalados.
      </p>

      <p>
        18.2.1 No caso de eventual solicitação pela LICENCIADA da liberação da
        licença sem a presença da infraestrutura necessária mínima ou, ainda,
        por qualquer outro motivo a utilização da licença não seja possível em
        razão de pendências decorrente de obrigação da LICENCIADA, esta
        responderá pelos pagamentos conforme teor da cláusula segunda –
        pagamentos que serão devidos desde a data da solicitação, mesmo em caso
        de não utilização da licença, uma vez que esta está à disposição da
        LICENCIADA.
      </p>

      <p>
        18.3 A DESBRAVADOR recomenda que as configurações do equipamento deverão
        pertencer ao idêntico CNPJ cadastrado da LICENCIADA, isentando-se,
        entretanto, de qualquer responsabilidade decorrente de créditos aos
        destinatários indicados pela LICENCIADA, quando não a própria, ao uso do
        SITEF.
      </p>

      <p>
        18.4 Após a confirmação da instalação do módulo pelo funcionário
        designado da LICENCIADA ao técnico da DESBRAVADOR, a DESBRAVADOR contará
        com um prazo mínimo de 48 (quarenta e oito) horas para a liberação do
        módulo.
      </p>

      <p>
        18.5 A licença do TEF só poderá ser cancelada após 01 (um) mês, contados
        da data da solicitação, ficando a LICENCIADA ciente que é obrigação
        realizar os pagamentos referente ao mês de solicitação de acesso e do
        mês de solicitação de cancelamento da licença.
      </p>

      <p>
        18.6 É de responsabilidade da LICENCIADA a manutenção do contrato e
        eventual adimplemento de pagamento do produto contratado com o
        fornecedor responsável pela administração da bandeira de cartão e/ou
        referente ao hardware/equipamento necessário para a devida
        operacionalidade do módulo ora contratado.
      </p>

      <h5>19. RESERVAS ONLINE (ROL/MOTORES)</h5>

      <p>
        19.1. A configuração do equipamento, na data deste contrato, necessária
        à operacionalização do sistema de reservas online e do sistema
        MIDDLEWARE encontra-se descriminada no site
        https://light.desbravador.com.br.
      </p>

      <p>
        19.2 DESBRAVADOR não tem qualquer responsabilidade com relação a
        quaisquer formas de pagamento ou linhas de crédito estipuladas entre a
        LICENCIADA e os agentes de viagem, operadoras, empresas ou quaisquer
        reservantes.
      </p>

      <p>
        19.3 A LICENCIADA inserirá obrigatoriamente as descrições
        (configurações, regras de utilização, dados e valores) do seu
        estabelecimento no sistema de reservas online, respeitada a orientação
        da DESBRAVADOR quanto ao formato das informações, sendo-lhe vedado o uso
        de termos ou expressões não apropriadas e cabendo-lhe a responsabilidade
        pela veracidade das informações.
      </p>

      <p>
        19.4 Na utilização do serviço de reservas online e/ou do uso das páginas
        do DSL ROL, além do sistema MIDDLEWARE, algumas recomendações são
        necessárias:
      </p>

      <p>
        a) Os dados cadastrados no administrativo do DSL ROL, assim como em
        qualquer complemento (integração) do serviço de reservas online que
        vigorem sobre as configurações, regras, dados e valores utilizados neste
        serviço deverão ser validados pela LICENCIADA.
      </p>

      <p>
        b) No caso de intermediação por Agências de Viagens Online (OTA&apos;s –
        Online Travel Agencies) os dados cadastrados no extranet de cada
        OTA&apos;s (subtarifas, tarifas de promoções, restrições, pacotes, assim
        como a data de validade das regras) devem ser cadastrados, verificados e
        confirmados pela LICENCIADA.
      </p>

      <p>
        c) As reservas anteriores à integração, quando alteráveis, poderão ser
        duplicadas no Módulo Gerência Hoteleira, pois inexistem referências
        iniciais. Da mesma forma, poderá ocorrer com as reservas canceladas.
      </p>

      <p>
        d) As tarifas devem ser cadastradas dentre as seguintes opções:
        reembolsável ou não reembolsável.
      </p>

      <p>
        e) Recomenda-se, por questão de segurança, que a LICENCIADA trabalhe com
        disponibilidade de no máximo 80%, a fim de evitar ocorrência de
        overbooking (considerar o tempo de delay para integração e atualização
        das reservas e da disponibilidade).
      </p>

      <p>
        f) Atentar que os pagamentos das reservas online não constituem
        obrigação de DESBRAVADOR. A página acessada para este fim é de
        responsabilidade da operadora de cartão de crédito.
      </p>

      <p>
        g) Certificar-se que todos os pagamentos confirmados junto à operadora
        de cartão de crédito, também sejam comunicadas a DESBRAVADOR para
        confirmação e integração da reserva no sistema.
      </p>

      <p>
        h) Acessar a opção Relatório/Mapas (administrativo do Reservas Online)
        para visualizar todas as tarifas ativas para utilização pelo
        estabelecimento da LICENCIADA.
      </p>

      <p>
        19.5 DESBRAVADOR reserva-se o direito de alterar o layout visual do
        Desbravador Reservas Online, sem prévia comunicação, sem qualquer
        comprometimento do serviço de reservas online.
      </p>

      <h5>20. DO ARMAZENAMENTO DE ARQUIVOS</h5>

      <p>
        20.1 Os espaços de armazenamento eventualmente contratados são não
        cumulativos, com renovação mensal, mediante remuneração.
      </p>

      <p>
        20.2 No caso de inadimplemento, a DESBRAVADOR reserva-se ao direito de
        revogar ou restringir o acesso, ou ainda, apagar deletar ou excluir ao
        conteúdo armazenado. No caso do aumento ou redução do plano, a nova
        capacidade de armazenamento entrará em vigor após o comunicado na
        plataforma da DESBRAVADOR.
      </p>

      <p>
        20.3 Caberá à LICENCIADA a gestão dos dados registrados e usuários de
        acesso, responsabilizando-se exclusivamente pelo controle e conteúdo de
        todas as informações armazenadas, inclusive no tocante a exclusão,
        intencional ou acidental, dos arquivos. Por se tratar de um arquivo em
        nuvem, a LICENCIADA declara ciência que a exclusão, ainda que acidental
        ou derivada de rescisão, é definitiva, sem possibilidade de recuperação
        dos arquivos.
      </p>

      <p>
        20.4 A DESBRAVADOR recomenda a realização de um backup alternativo das
        informações e dados armazenados rotineiramente, em intervalos não
        maiores que 30 (trinta) dias, não se responsabilizando por eventuais
        perdas ou danos.
      </p>

      <p>
        20.5 Na data de solicitação de cancelamento da contratação ao espaço de
        armazenamento, a LICENCIADA deverá realizar o backup de todas as
        informações, uma vez que perderá o acesso a estes após o processamento
        da solicitação pela DESBRAVADOR.
      </p>

      <p>
        20.5.1 No prazo de 07 (sete) dias contados da solicitação do
        cancelamento, a DESBRAVADOR facultará/promoverá a exclusão total dos
        dados e informações armazenadas, ainda que estes não tenham sido
        extraídos pela LICENCIADA.
      </p>

      <h5>21. DISPOSIÇÕES GERAIS</h5>

      <p>
        21.1. Sucessores e Cessões. O presente Contrato obriga as Partes e seus
        herdeiros ou sucessores, a qualquer título.
      </p>

      <p>
        21.1.1. A LICENCIADA NÃO poderá ceder ou transferir seus direitos e/ou
        deveres decorrentes deste Contrato sem o consentimento prévio, por
        escrito, de DESBRAVADOR. Toda cessão ou transferência feita infringindo
        o disposto acima será nula.
      </p>

      <p>
        21.2. Uso de Cookies. DESBRAVADOR utiliza cookies para melhor executar
        suas operações através do website em que o Produto está instalado. Ao
        contratar a licença do Produto, a LICENCIADA concorda com a Política de
        utilização de cookies de DESBRAVADOR que se encontra publicado no site
        https://light.desbravador.com.br.
      </p>

      <p>
        21.3. Totalidade do acordo, Caso Fortuito e Força Maior. Este Contrato
        constitui a totalidade do acordo entre DESBRAVADOR e a LICENCIADA, e
        nenhum aditamento aos termos deste Contrato gerarão efeitos, salvo se
        por escrito e com registro do aceite das Partes.
      </p>

      <p>
        21.3.1. Salvo a obrigação da LICENCIADA de pagar pela licença do Produto
        ou por serviços já prestados, se qualquer uma das Partes for impedida de
        cumprir, na totalidade ou em parte, quaisquer disposições do presente
        Contrato em virtude de situação de caso fortuito ou de força maior,
        poderá requerer à outra Parte a suspensão das obrigações decorrentes de
        tais disposições, mediante notificação por escrito e desde que informe
        imediatamente a outra Parte sobre o fato, descrevendo a sua natureza e
        dando uma estimativa do atraso.
      </p>

      <p>
        21.3.2. A Parte afetada pelo descumprimento poderá rejeitar o
        requerimento se não ficar comprovada a ocorrência de caso fortuito e/ou
        força maior.
      </p>

      <p>
        21.4. Relação entre as Partes. Qualquer omissão ou tolerância das Partes
        ao exigir o fiel cumprimento dos termos e condições deste Contrato, ou
        no exercício de prerrogativas dele decorrentes, não constituirá e nem
        será considerada como novação contratual ou renúncia, nem afetará o
        direito da Parte tolerante de, a qualquer tempo, exigir da outra,
        integralmente, o cumprimento das condições estipuladas ou exercer as
        prerrogativas cabíveis.
      </p>

      <p>
        21.5. Invalidade de provimentos/disposições. No caso de um juízo
        competente julgar qualquer disposição deste Contrato ilegal, ineficaz ou
        inexequível, as disposições remanescentes permanecerão em pleno vigor e
        manterão sua eficácia e as Partes alterarão o presente contrato para dar
        efeito à cláusula declarada ilegal, ineficaz ou inexequível, no limite
        máximo possível.
      </p>

      <p>
        21.6. Notificações. Todas as notificações/comunicações entre as Partes
        serão feitas por escrito, por e-mail ou por carta, ou outro meio de
        comunicação em que se comprove a entrega a representante competente da
        Parte destinatária do objeto da notificação. As
        notificações/comunicações deverão ser encaminhadas aos respectivos
        endereços indicados no preâmbulo do presente Contrato, a menos que
        outros endereços venham a ser fornecidos pelas Partes, por escrito.
      </p>

      <p>
        21.7. Competência para representação. A LICENCIADA declara, sob as penas
        da lei, que o responsável pelo preenchimento e assinatura do presente
        possui poderes para determinar as informações acima mencionadas e é
        procurador/representante legal da empresa responsável pela licença de
        uso do sistema contratado, tendo sido devidamente constituído (a) na
        forma de seus respectivos atos constitutivos e demais documentos que
        demonstrem vínculo empresarial.
      </p>

      <p>
        21.7.1 No caso de alteração de representação, caberá a LICENCIADA a
        obrigação de notificar a DESBRAVADOR sobre eventuais desligamentos,
        revogações de procurações e/ou alterações em seu quadro societário, sob
        pena de permanecerem válidos todos os atos praticados, ainda que não
        autorizados. O mesmo aplicar-se-á aos atos praticados por
        ex-funcionários, principalmente aqueles que exerciam funções de
        gerência, cujo desligamento ou perda da condição de confiança não é
        comunicada à DESBRAVADOR.
      </p>

      <p>
        21.8. Jurisdição / Lei Aplicável. As Partes elegem como competente o
        foro de Chapecó, Santa Catarina, Brasil, e lei aplicável a legislação
        brasileira, para dirimir quaisquer dúvidas e conduzir quaisquer ações
        atinentes a este Contrato que não puderem ser solucionadas
        amigavelmente, com renúncia expressa de qualquer outro, por mais
        privilegiado que seja ou venha a ser.
      </p>

      <p>
        21.9. Revisão deste Acordo/Contrato. DESBRAVADOR reserva-se o direito de
        alterar os termos deste Contrato a qualquer momento, sem aviso prévio,
        bastando, para tanto, postar uma versão revisada em seu website.
        Qualquer nova versão revisada entrará em vigor assim que postada no
        website acima mencionado. Se tal versão incluir alguma alteração
        substancial, a DESBRAVADOR procederá à devida informação com no mínimo
        30 (trinta) dias de antecedência por e-mail ou postando um aviso na
        página &quot;atualizações dos contratos&quot; de nosso website.
      </p>

      <p>
        21.10. A continuidade no uso dos produtos e serviços da DESBRAVADOR pela
        LICENCIADA após a entrada em vigor de nova versão revisada deste
        Contrato implicará automaticamente a plena ciência e aceitação de todos
        os seus termos e condições.
      </p>
    </div>
  </div>
);

ContratoPT.propTypes = {};
