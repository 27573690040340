import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Footer = ({sendPdfProposta, handleSubmitClicked, showProposta}) => (
  <Row>
    <Col xs={12} sm={12}>
      <div className="clearfix form-actions">
        {showProposta ? (
          <div className="btn-group pull-left">
            <Button
              className="btn btn-info"
              type="button"
              onClick={e => {
                sendPdfProposta(e);
              }}>
              <i className="ace-icon fa fa-file bigger-110" />
              Proposta
            </Button>
          </div>
        ) : null}

        <div className="btn-group pull-right">
          <Link to="/app/pedidos" className="btn">
            <i className="ace-icon fa fa-undo bigger-110" />
            <span>Voltar</span>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Button
            className="btn btn-info btn-enviar-proposta pull-right"
            type="submit"
            onClick={e => {
              handleSubmitClicked(e);
            }}>
            <i className="ace-icon fa fa-send bigger-110" />
            <span>Enviar</span>
          </Button>
        </div>
      </div>
    </Col>
  </Row>
);

Footer.propTypes = {
  sendPdfProposta: PropTypes.func.isRequired,
  handleSubmitClicked: PropTypes.func.isRequired,
  showProposta: PropTypes.bool.isRequired,
};

export default Footer;
