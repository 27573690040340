export const CHANNELS = [
  {
    value: '515',
    label: '515 - OMNIBEES',
  },
  {
    value: '510',
    label: '510 - Synxis',
  },
  {
    value: '524',
    label: '524 - B2B',
  },
  {
    value: '536',
    label: '536 - HSYSTEM',
  },
  {
    value: '522',
    label: '522 - Decolar',
  },
  {
    value: '520',
    label: '520 - TravelClick',
  },
  {
    value: '514',
    label: '514 - Booking',
  },
  {
    value: '511',
    label: '511 - CM Reservas',
  },
  {
    value: '532',
    label: '532 - CVC',
  },
  {
    value: '517',
    label: '517 - Expedia',
  },
  {
    value: '533',
    label: '533 - Siteminder',
  },
  {
    value: '1108',
    label: '1108 - CHANNEX - AIRBNB - HOSTELWORLD - HOTEL BEDS',
  },
  {
    value: '526',
    label: '526 - THN',
  },
  {
    value: '518',
    label: '518 - ROL - Integração',
  },
];
