import React, {useEffect, useState} from 'react';

import Loading from 'components/loading';

import api from 'services/api';

import notification from 'components/notification';

import SubHeader from '../partials/SubHeader';
import HeaderForm from './components/headerForm';
import {PedidoData} from './components/pedidoData';

const ConsultaPedido = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState(null);

  useEffect(() => {
    document.title = 'Consultar Pedido';
  }, []);

  const handlePressSearch = () => {
    if (search) {
      setLoading(true);

      api
        .get(`/pedido/search?q=${search}`)
        .then(response => {
          setLoading(false);

          if (response?.data?.dados) {
            setData(response?.data?.dados);
          } else {
            notification.warning('Não encontrado', '');
          }
        })
        .catch(() => {
          setLoading(false);
          setData(null);
          notification.error('Algo deu errado!', '');
        });
    }
  };

  return (
    <div>
      <Loading show={loading} />

      <SubHeader text="Preencha os campos" />

      <div className="container">
        <form
          className="form-horizontal"
          id="formik-consulta"
          onSubmit={e => e.preventDefault()}>
          <HeaderForm
            search={search}
            handleChangeSearch={value => {
              setSearch(value);
            }}
            onSearchClick={() => {
              handlePressSearch();
            }}
          />
        </form>

        <PedidoData data={data} />
      </div>
    </div>
  );
};

export default ConsultaPedido;
