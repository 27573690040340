import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
} from './ActionTypes';

export const loginUserSuccess = token => ({
  type: LOGIN_USER_SUCCESS,
  payload: {
    token,
  },
});

export const loginUserFailure = error => {
  const status = error.status;
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      status,
    },
  };
};

export const loginUserRequest = () => ({
  type: LOGIN_USER_REQUEST,
});

export const logout = () => ({
  type: LOGOUT_USER,
});
