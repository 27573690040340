import i18n from 'i18next';

import pt from './pt/pt';
import en from './en/en';
import es from './es/es';

const locales = {
  pt,
  en,
  es,
};

i18n.init({
  lng: 'pt',
  ns: ['translation'],
  defaultNS: 'translation',
  fallbackLng: 'pt',
  debug: false,
  resources: locales,
  interpolation: {
    formatSeparator: ',',
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
});

export default i18n;
