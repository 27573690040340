import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Footer = ({handleSubmitClicked}) => (
  <Row>
    <Col xs={12} sm={12}>
      <div className="clearfix form-actions">
        <div className="btn-group pull-right">
          <Link to="/app/pedidos" className="btn">
            <i className="ace-icon fa fa-undo bigger-110" />
            <span>Voltar</span>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Button
            className="btn btn-info btn-enviar-proposta pull-right"
            type="submit"
            onClick={e => {
              handleSubmitClicked(e);
            }}>
            <i className="ace-icon fa fa-send bigger-110" />
            <span>Enviar</span>
          </Button>
        </div>
      </div>
    </Col>
  </Row>
);

Footer.propTypes = {
  handleSubmitClicked: PropTypes.func.isRequired,
};

export default Footer;
