import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  PRODUCT_WEB30_CODE,
} from 'helpers/tabPrice';

const ProductType = ({value}) => (
  <>
    <Row>
      <Col xs={12} md={12}>
        <h4 className="header smaller lighter blue">Produto do cliente</h4>
      </Col>

      {value === PRODUCT_LIGHTWEB_CODE ? (
        <Col sm={12} md={2}>
          <div className="checkbox">
            <label className="block">
              <input
                disabled
                name="produto-lightweb"
                value={PRODUCT_LIGHTWEB_CODE}
                type="checkbox"
                className="ace ace-checkbox-2 input-lg"
                checked={value === PRODUCT_LIGHTWEB_CODE}
                onChange={() => {}}
              />
              <span className="lbl bigger-120">&nbsp;Light Web</span>
            </label>
          </div>
        </Col>
      ) : null}

      {value === PRODUCT_EASYWEB_CODE ? (
        <Col sm={12} md={2}>
          <div className="checkbox">
            <label className="block">
              <input
                disabled
                name="produto-easyweb"
                value={PRODUCT_EASYWEB_CODE}
                type="checkbox"
                className="ace ace-checkbox-2 input-lg"
                checked={value === PRODUCT_EASYWEB_CODE}
                onChange={() => {}}
              />
              <span className="lbl bigger-120">&nbsp;Easy Web</span>
            </label>
          </div>
        </Col>
      ) : null}

      {value === PRODUCT_WEB30_CODE ? (
        <Col sm={12} md={2}>
          <div className="checkbox">
            <label className="block">
              <input
                disabled
                name="produto-web30"
                value={PRODUCT_WEB30_CODE}
                type="checkbox"
                className="ace ace-checkbox-2 input-lg"
                checked={value === PRODUCT_WEB30_CODE}
                onChange={() => {}}
              />
              <span className="lbl bigger-120">&nbsp;3.0 Web</span>
            </label>
          </div>
        </Col>
      ) : null}
    </Row>
    <div className="hr hr32 hr-dotted" />
  </>
);

ProductType.defaultProps = {};

ProductType.propTypes = {
  value: PropTypes.oneOf([PRODUCT_LIGHTWEB_CODE, PRODUCT_EASYWEB_CODE])
    .isRequired,
};

export default ProductType;
