import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  dismiss = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const {type} = this.props;
    let icon = 'info-circle';
    switch (type) {
      case 'info':
        icon = 'info-circle';
        break;
      case 'danger':
        icon = 'times';
        break;
      case 'success':
        icon = 'check';
        break;
      default:
        icon = 'info-circle';
    }
    return this.state.show ? (
      <div className={`alert alert-${type}`}>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          onClick={this.dismiss}>
          <i className="ace-icon fa fa-times" />
        </button>
        <span className={`fa fa-${icon}`} />
        &nbsp;
        {this.props.children}
      </div>
    ) : (
      ''
    );
  }
}

Alert.defaultProps = {
  type: 'warning',
};

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'danger', 'warning', 'success']),
  children: PropTypes.node.isRequired,
};
