import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const PedidoSuccess = () => (
  <div>
    <div className="container">
      <Row>
        <Col xs={12}>
          <div className="error-container">
            <div className="well text-center">
              <h4 className="grey lighter smaller">
                <span className="blue bigger-125">
                  <i className="ace-icon fa fa-check" />
                  Sucesso ao enviar o pedido
                </span>
              </h4>

              <hr />
              <h3 className="lighter smaller">Bons negócios!</h3>

              <hr />
              <div className="space" />

              <div className="center">
                <Link to="/app/pedidos" className="btn btn-primary">
                  <i className="ace-icon fa fa-tachometer" />
                  <span>Pagina principal</span>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default PedidoSuccess;
