import * as Yup from 'yup';
import {messages} from 'helpers/validator/messages';

export const rules = Yup.object().shape({
  nmemailresponsavel: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmnomeresponsavel: Yup.string().nullable().required(messages.required),
  nmdiremail: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmdirnome: Yup.string().nullable().required(messages.required),
  txobspagamento: Yup.string().nullable().required(messages.required),
  txobsvenda: Yup.string().nullable().required(messages.required),

  nmexpectativa: Yup.string().nullable(),
  nmdemonstrado: Yup.string().nullable(),
  dtdatademo: Yup.string().nullable(),
});
