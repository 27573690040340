import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

class Loading extends Component {
  renderLoading() {
    if (this.props.show) {
      const style = {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 998,
        backgroundColor: 'rgba(139,154,163,0.6)',
      };

      const style2 = {
        position: 'fixed',
        width: '65px',
        height: '65px',
        zIndex: 999,
        top: '150px',
        right: '0',
        left: '0',
        marginRight: 'auto',
        marginLeft: 'auto',
      };

      return (
        <div>
          <div style={style}>
            <div style={style2}>
              <ReactLoading
                type="spinningBubbles"
                color="#006DA5"
                height={50}
                width={50}
                className="center-block"
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return <div>{this.renderLoading()}</div>;
  }
}

Loading.propTypes = {
  show: PropTypes.bool,
};

Loading.defaultProps = {
  show: false,
};

export default Loading;
