import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, HelpBlock} from 'react-bootstrap';

class SelectContainer extends Component {
  renderLabel = () => {
    const {label} = this.props;
    if (label) {
      return (
        <label className="col-sm-3 control-label no-padding-right">
          {label}
        </label>
      );
    }
    return null;
  };

  renderOptions() {
    return this.props.options.map((option, index) => (
      <option key={index} value={option.codigo}>
        {option.nome}
      </option>
    ));
  }

  render() {
    const {
      showError,
      errorText,
      colWidth,
      label,
      toUppercase,
      inputId,
      placeholder,
      text,
      disabled,
      multiple,
      onFieldChanged,
    } = this.props;
    const hasError = showError && !!errorText ? 'has-error' : '';
    const colW = colWidth ? `col-sm-${colWidth}` : 'col-sm-9';
    const hasLabelClass = label ? colW : '';
    const style = toUppercase ? {textTransform: 'uppercase'} : {};
    const id = inputId || undefined;

    return (
      <FormGroup className={hasError}>
        {this.renderLabel()}

        <div className={hasLabelClass}>
          <FormControl
            multiple={multiple}
            componentClass="select"
            placeholder={placeholder}
            value={text}
            onChange={e => {
              onFieldChanged(e);
            }}
            onBlur={() => {}}
            disabled={disabled}
            style={style}
            id={id}>
            {this.renderOptions()}
          </FormControl>
          {showError && errorText !== '' ? (
            <HelpBlock>{errorText}</HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

SelectContainer.defaultProps = {
  label: '',
  errorText: '',
  colWidth: '',
  toUppercase: false,
  inputId: '',
  placeholder: '',
  text: '',
  disabled: false,
  multiple: false,
};

SelectContainer.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toUppercase: PropTypes.bool,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  showError: PropTypes.bool.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  multiple: PropTypes.bool,
};

export default SelectContainer;
