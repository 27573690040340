import {round} from '../calcRounding';
import {
  LIGHT_REAL_BI,
  LIGHT_REAL_CE,
  LIGHT_REAL_CM,
  LIGHT_REAL_PDV,
  LIGHT_REAL_GF,
  LIGHT_REAL_GH,
  LIGHT_REAL_ROLRM,
  LIGHT_REAL_TT,
  LIGHT_DOLAR_BI,
  LIGHT_DOLAR_CE,
  LIGHT_DOLAR_CM,
  LIGHT_DOLAR_PDV,
  LIGHT_DOLAR_GF,
  LIGHT_DOLAR_GH,
  LIGHT_DOLAR_ROLRM,
  LIGHT_DOLAR_TT,
  LIGHT_EURO_BI,
  LIGHT_EURO_CE,
  LIGHT_EURO_CM,
  LIGHT_EURO_PDV,
  LIGHT_EURO_GF,
  LIGHT_EURO_GH,
  LIGHT_EURO_ROLRM,
  LIGHT_EURO_TT,
  LIGHT_DOLAR_ITC_GH,
  LIGHT_DOLAR_ITC_TT,
  LIGHT_DOLAR_ITC_ROLRM,
  LIGHT_DOLAR_ITC_GF,
  LIGHT_DOLAR_ITC_CE,
  LIGHT_DOLAR_ITC_CM,
  LIGHT_DOLAR_ITC_PDV,
  LIGHT_DOLAR_ITC_BI,
} from '../values';

export const recalcReal = total => ({
  GH: round(total * LIGHT_REAL_GH),
  TT: round(total * LIGHT_REAL_TT),
  ROLRM: round(total * LIGHT_REAL_ROLRM),
  GF: round(total * LIGHT_REAL_GF),
  CE: round(total * LIGHT_REAL_CE),
  CM: round(total * LIGHT_REAL_CM),
  PDV: round(total * LIGHT_REAL_PDV),
  BI: round(total * LIGHT_REAL_BI),
  TOTAL: round(total),
});

export const recalcDolar = total => ({
  GH: round(total * LIGHT_DOLAR_GH),
  TT: round(total * LIGHT_DOLAR_TT),
  ROLRM: round(total * LIGHT_DOLAR_ROLRM),
  GF: round(total * LIGHT_DOLAR_GF),
  CE: round(total * LIGHT_DOLAR_CE),
  CM: round(total * LIGHT_DOLAR_CM),
  PDV: round(total * LIGHT_DOLAR_PDV),
  BI: round(total * LIGHT_DOLAR_BI),
  TOTAL: round(total),
});

export const recalcDolarITC = total => ({
  GH: round(total * LIGHT_DOLAR_ITC_GH),
  TT: round(total * LIGHT_DOLAR_ITC_TT),
  ROLRM: round(total * LIGHT_DOLAR_ITC_ROLRM),
  GF: round(total * LIGHT_DOLAR_ITC_GF),
  CE: round(total * LIGHT_DOLAR_ITC_CE),
  CM: round(total * LIGHT_DOLAR_ITC_CM),
  PDV: round(total * LIGHT_DOLAR_ITC_PDV),
  BI: round(total * LIGHT_DOLAR_ITC_BI),
  TOTAL: round(total),
});

export const recalcEuro = total => ({
  GH: round(total * LIGHT_EURO_GH),
  TT: round(total * LIGHT_EURO_TT),
  ROLRM: round(total * LIGHT_EURO_ROLRM),
  GF: round(total * LIGHT_EURO_GF),
  CE: round(total * LIGHT_EURO_CE),
  CM: round(total * LIGHT_EURO_CM),
  PDV: round(total * LIGHT_EURO_PDV),
  BI: round(total * LIGHT_EURO_BI),
  TOTAL: round(total),
});
