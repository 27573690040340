import {TAB_PRECO_EASYWEB_REAL} from 'helpers/tabPrice';
import {round} from '../calcRounding';
import {
  EASY_REAL_BI,
  EASY_REAL_CE,
  EASY_REAL_CM,
  EASY_REAL_PDV,
  EASY_REAL_GF,
  EASY_REAL_GH,
  EASY_REAL_ROLRM,
  EASY_REAL_TT,
  EASY_REAL_MESAL_UH,
  EASY_REAL_SEMESTRAL_UH,
  EASY_REAL_ANUAL_UH,
} from '../values';
import {
  getQtdUserReal,
  calculaValorTabelaMensalReal,
  calculaValorTabelaSemestralReal,
  calculaValorTabelaAnualReal,
} from './calcReal';

export const calcAdicaoUser = (
  qtdUh,
  qtdUser,
  qtdUserToAdd,
  licenciamento,
  modulos,
  cdtabprecos,
) => {
  if (licenciamento === 0) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
      calc1 = calculaValorTabelaMensalReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaMensalReal(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LM_GH;
          totalCalc2 += calc2.LM_GH;
          break;
        case '801':
          totalCalc1 += calc1.LM_TT;
          totalCalc2 += calc2.LM_TT;
          break;
        case '802':
          totalCalc1 += calc1.LM_ROLRM;
          totalCalc2 += calc2.LM_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LM_CE;
          totalCalc2 += calc2.LM_CE;
          break;
        case '805':
          totalCalc1 += calc1.LM_GF;
          totalCalc2 += calc2.LM_GF;
          break;
        case '806':
          totalCalc1 += calc1.LM_CM;
          totalCalc2 += calc2.LM_CM;
          break;
        case '506':
          totalCalc1 += calc1.LM_BI;
          totalCalc2 += calc2.LM_BI;
          break;
        case '808':
          totalCalc1 += calc1.LM_PDV;
          totalCalc2 += calc2.LM_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round(totalCalc2 - totalCalc1);
  }

  if (licenciamento === 1) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
      calc1 = calculaValorTabelaSemestralReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaSemestralReal(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LS_GH;
          totalCalc2 += calc2.LS_GH;
          break;
        case '801':
          totalCalc1 += calc1.LS_TT;
          totalCalc2 += calc2.LS_TT;
          break;
        case '802':
          totalCalc1 += calc1.LS_ROLRM;
          totalCalc2 += calc2.LS_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LS_CE;
          totalCalc2 += calc2.LS_CE;
          break;
        case '805':
          totalCalc1 += calc1.LS_GF;
          totalCalc2 += calc2.LS_GF;
          break;
        case '806':
          totalCalc1 += calc1.LS_CM;
          totalCalc2 += calc2.LS_CM;
          break;
        case '506':
          totalCalc1 += calc1.LS_BI;
          totalCalc2 += calc2.LS_BI;
          break;
        case '808':
          totalCalc1 += calc1.LS_PDV;
          totalCalc2 += calc2.LS_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round(totalCalc2 - totalCalc1);
  }

  if (licenciamento === 2) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
      calc1 = calculaValorTabelaAnualReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaAnualReal(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LA_GH;
          totalCalc2 += calc2.LA_GH;
          break;
        case '801':
          totalCalc1 += calc1.LA_TT;
          totalCalc2 += calc2.LA_TT;
          break;
        case '802':
          totalCalc1 += calc1.LA_ROLRM;
          totalCalc2 += calc2.LA_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LA_CE;
          totalCalc2 += calc2.LA_CE;
          break;
        case '805':
          totalCalc1 += calc1.LA_GF;
          totalCalc2 += calc2.LA_GF;
          break;
        case '806':
          totalCalc1 += calc1.LA_CM;
          totalCalc2 += calc2.LA_CM;
          break;
        case '506':
          totalCalc1 += calc1.LA_BI;
          totalCalc2 += calc2.LA_BI;
          break;
        case '808':
          totalCalc1 += calc1.LA_PDV;
          totalCalc2 += calc2.LA_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round(totalCalc2 - totalCalc1);
  }
  return null;
};

export const calcAdicaoUh = (
  qtdUhToAdd,
  qtdUser,
  licenciamento,
  modulos,
  cdtabprecos,
) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
    const valorUsuario = getQtdUserReal(qtdUser);

    let valorTotalPorUh;
    if (licenciamento === 0) {
      valorTotalPorUh = EASY_REAL_MESAL_UH + valorUsuario;
    } else if (licenciamento === 1) {
      valorTotalPorUh = EASY_REAL_SEMESTRAL_UH + valorUsuario;
    } else {
      valorTotalPorUh = EASY_REAL_ANUAL_UH + valorUsuario;
    }

    const valorGH = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_GH));
    const valorTT = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_TT));
    const valorCE = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_CE));
    const valorGF = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_GF));
    const valorCM = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_CM));
    const valorPDV = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_PDV));
    const valorBI = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_BI));
    const valorROLRM = round(qtdUhToAdd * (valorTotalPorUh * EASY_REAL_ROLRM));

    const mapModulos = new Map();
    mapModulos.set('800', valorGH);
    mapModulos.set('801', valorTT);
    mapModulos.set('802', valorROLRM);
    mapModulos.set('803', 0);
    mapModulos.set('804', valorCE);
    mapModulos.set('805', valorGF);
    mapModulos.set('806', valorCM);
    mapModulos.set('808', valorPDV);
    mapModulos.set('506', valorBI);

    let valorTotal = 0;

    Array.from(mapModulos.keys()).forEach(item => {
      if (modulos.includes(item)) {
        valorTotal += mapModulos.get(item);
      }
    });

    return valorTotal;
  }

  return 0;
};
