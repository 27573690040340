import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, HelpBlock} from 'react-bootstrap';

class InputContainer extends Component {
  renderLabel = () => {
    const {label} = this.props;
    if (label) {
      return (
        <label className="col-sm-3 control-label no-padding-right">
          {label}
        </label>
      );
    }
    return null;
  };

  render() {
    const {
      showError,
      errorText,
      colWidth,
      label,
      toUppercase,
      inputId,
      placeholder,
      type,
      text,
      disabled,
      onFieldChanged,
    } = this.props;
    const hasError = showError && !!errorText ? 'has-error' : '';
    const colW = colWidth ? `col-sm-${colWidth}` : 'col-sm-9';
    const hasLabelClass = label ? colW : '';
    const style = toUppercase ? {textTransform: 'uppercase'} : {};
    const id = inputId || undefined;

    return (
      <FormGroup className={hasError}>
        {this.renderLabel()}

        <div className={hasLabelClass}>
          <FormControl
            type={type || 'text'}
            placeholder={placeholder}
            value={text}
            onChange={e => {
              onFieldChanged(e);
            }}
            onBlur={() => {}}
            disabled={disabled}
            style={style}
            id={id}
          />
          {showError && errorText !== '' ? (
            <HelpBlock>{errorText}</HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

InputContainer.defaultProps = {
  label: '',
  errorText: '',
  colWidth: '',
  inputId: undefined,
  placeholder: '',
  type: 'text',
  text: '',
  disabled: false,
  toUppercase: false,
};

InputContainer.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  toUppercase: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputContainer;
