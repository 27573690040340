import React from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';

import {getProductName} from 'helpers/tabPrice';

import {ContratoPT} from './ContratoPT';
import {ContratoES} from './ContratoES';

import './style.css';

const TermosUso = ({lang, cdproduto, isBlackFriday}) => {
  const productName = getProductName(cdproduto);
  return (
    <Scrollbars style={{width: '100%', height: 300}}>
      <div className="content">
        {parseInt(lang, 10) === 3 ? (
          <ContratoES productName={productName} />
        ) : (
          <ContratoPT productName={productName} isBlackFriday={isBlackFriday} />
        )}
      </div>
    </Scrollbars>
  );
};

TermosUso.propTypes = {
  isBlackFriday: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  cdproduto: PropTypes.string.isRequired,
};

export default TermosUso;
