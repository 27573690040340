import _ from 'lodash';
import {STORAGE_10GB, STORAGE_30GB, STORAGE_50GB} from 'helpers/modules';
import {recalcReal} from 'helpers/calc/easyweb/recalc';
import {getITC, getLM} from 'helpers/calc/calcIService';
import {
  LM_STORAGE_10GB_REAL,
  LM_STORAGE_30GB_REAL,
  LM_STORAGE_50GB_REAL,
} from 'helpers/calc/calcStorage';
import {TAB_PRECO_EASYWEB_REAL, BLACK_FRIDAY_CODE} from 'helpers/tabPrice';
import {getLmTef} from 'helpers/calc/calcTef';
import {
  calcAdicaoUh as calcAdicaoUhEasy,
  calcAdicaoUser as calcAdicaoUserEasy,
} from 'helpers/calc/easyweb/calcAdicao';
import {
  calcItcRealFunc,
  calculaValorTabelaSemestralRealFunc,
  calculaValorTabelaMensalRealFunc,
  calculaValorTabelaAnualRealFunc,
} from '../../../novoPedido/functions/easyweb/real';

export const calcTotalWithIService = async (
  cdtabprecos,
  licenciamento,
  modulos,
  modulosSelecionados,
  ITC,
  LS,
  LM,
  LA,
  hasIService,
  uhs,
  iserviceITCplus,
  iserviceLMplus,
  cdpromocao,
  storageSelected,
  hasTef,
  tefITC,
  qtdTef,
  qtdTefCurrent,
) =>
  new Promise(resolve => {
    if (licenciamento !== null) {
      let totalmes = 0;
      let totalitc = 0;
      let total = 0;

      _.forEach(modulosSelecionados, value => {
        if (_.includes(modulos, value)) {
          // MODULO JÁ CONTRATADO - IGNORANDO
        } else {
          switch (parseInt(value, 10)) {
            case 800:
              totalitc += ITC.GH;
              if (licenciamento === 0) {
                totalmes += LM.GH;
              } else if (licenciamento === 1) {
                totalmes += LS.GH;
              } else if (licenciamento === 2) {
                totalmes += LA.GH;
              }
              break;
            case 801:
              totalitc += ITC.TT;
              if (licenciamento === 0) {
                totalmes += LM.TT;
              } else if (licenciamento === 1) {
                totalmes += LS.TT;
              } else if (licenciamento === 2) {
                totalmes += LA.TT;
              }
              break;
            case 802:
              totalitc += ITC.ROLRM;
              if (licenciamento === 0) {
                totalmes += LM.ROLRM;
              } else if (licenciamento === 1) {
                totalmes += LS.ROLRM;
              } else if (licenciamento === 2) {
                totalmes += LA.ROLRM;
              }
              break;
            case 804:
              totalitc += ITC.CE;
              if (licenciamento === 0) {
                totalmes += LM.CE;
              } else if (licenciamento === 1) {
                totalmes += LS.CE;
              } else if (licenciamento === 2) {
                totalmes += LA.CE;
              }
              break;
            case 805:
              totalitc += ITC.GF;
              if (licenciamento === 0) {
                totalmes += LM.GF;
              } else if (licenciamento === 1) {
                totalmes += LS.GF;
              } else if (licenciamento === 2) {
                totalmes += LA.GF;
              }
              break;
            case 806:
              totalitc += ITC.CM;
              if (licenciamento === 0) {
                totalmes += LM.CM;
              } else if (licenciamento === 1) {
                totalmes += LS.CM;
              } else if (licenciamento === 2) {
                totalmes += LA.CM;
              }
              break;
            case 808:
              totalitc += ITC.PDV;
              if (licenciamento === 0) {
                totalmes += LM.PDV;
              } else if (licenciamento === 1) {
                totalmes += LS.PDV;
              } else if (licenciamento === 2) {
                totalmes += LA.PDV;
              }
              break;
            case 506:
              totalitc += ITC.BI;
              if (licenciamento === 0) {
                totalmes += LM.BI;
              } else if (licenciamento === 1) {
                totalmes += LS.BI;
              } else if (licenciamento === 2) {
                totalmes += LA.BI;
              }
              break;
            default:
          }
        }
      });

      if (hasTef) {
        totalitc += tefITC;

        const lmValueTotal = getLmTef(qtdTef + qtdTefCurrent);
        const lmValueCurrent = getLmTef(qtdTefCurrent);

        totalmes += lmValueTotal - lmValueCurrent;
      }

      if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
        if (storageSelected) {
          if (storageSelected === STORAGE_10GB) {
            totalmes += LM_STORAGE_10GB_REAL;
          }
          if (storageSelected === STORAGE_30GB) {
            totalmes += LM_STORAGE_30GB_REAL;
          }
          if (storageSelected === STORAGE_50GB) {
            totalmes += LM_STORAGE_50GB_REAL;
          }
        }
      }

      if (licenciamento === 0) {
        total += totalmes;
      } else if (licenciamento === 1) {
        total += totalmes * 6;
      } else if (licenciamento === 2) {
        total += totalmes * 12;
      }

      if (hasIService) {
        const iserviceITC =
          cdpromocao === BLACK_FRIDAY_CODE ? 0 : getITC(cdtabprecos);
        totalitc += iserviceITC;

        if (iserviceITCplus) {
          totalitc += iserviceITCplus;
        }

        const iserviceLM = getLM(uhs, cdtabprecos);
        totalmes += iserviceLM;

        if (iserviceLMplus) {
          totalmes += iserviceLMplus;
        }

        if (licenciamento === 0) {
          total += iserviceLM + iserviceLMplus;
        } else if (licenciamento === 1) {
          total += (iserviceLM + iserviceLMplus) * 6;
        } else if (licenciamento === 2) {
          total += (iserviceLM + iserviceLMplus) * 12;
        }

        resolve({
          vlvalormensal: totalmes,
          vltotalmensal: total,
          vlvalorvenda: totalitc,
          iserviceITC,
          iserviceLM,
        });
      } else {
        resolve({
          vlvalormensal: totalmes,
          vltotalmensal: total,
          vlvalorvenda: totalitc,
          iserviceITC: 0,
          iserviceLM: 0,
        });
      }
    } else {
      resolve({
        vlvalormensal: 0,
        vltotalmensal: 0,
        vlvalorvenda: 0,
        iserviceITC: 0,
        iserviceLM: 0,
      });
    }
  });

export const calcTotal = async (
  licenciamento,
  modulos,
  modulosSelecionados,
  ITC,
  LS,
  LM,
  LA,
) =>
  new Promise(resolve => {
    if (licenciamento !== null) {
      let totalmes = 0;
      let totalitc = 0;
      let total = 0;

      _.forEach(modulosSelecionados, value => {
        if (_.includes(modulos, value)) {
          // MODULO JÁ CONTRATADO - IGNORANDO
        } else {
          switch (parseInt(value, 10)) {
            case 800:
              totalitc += ITC.GH;
              if (licenciamento === 0) {
                totalmes += LM.GH;
              } else if (licenciamento === 1) {
                totalmes += LS.GH;
              } else if (licenciamento === 2) {
                totalmes += LA.GH;
              }
              break;
            case 801:
              totalitc += ITC.TT;
              if (licenciamento === 0) {
                totalmes += LM.TT;
              } else if (licenciamento === 1) {
                totalmes += LS.TT;
              } else if (licenciamento === 2) {
                totalmes += LA.TT;
              }
              break;
            case 802:
              totalitc += ITC.ROLRM;
              if (licenciamento === 0) {
                totalmes += LM.ROLRM;
              } else if (licenciamento === 1) {
                totalmes += LS.ROLRM;
              } else if (licenciamento === 2) {
                totalmes += LA.ROLRM;
              }
              break;
            case 804:
              totalitc += ITC.CE;
              if (licenciamento === 0) {
                totalmes += LM.CE;
              } else if (licenciamento === 1) {
                totalmes += LS.CE;
              } else if (licenciamento === 2) {
                totalmes += LA.CE;
              }
              break;
            case 805:
              totalitc += ITC.GF;
              if (licenciamento === 0) {
                totalmes += LM.GF;
              } else if (licenciamento === 1) {
                totalmes += LS.GF;
              } else if (licenciamento === 2) {
                totalmes += LA.GF;
              }
              break;
            case 806:
              totalitc += ITC.CM;
              if (licenciamento === 0) {
                totalmes += LM.CM;
              } else if (licenciamento === 1) {
                totalmes += LS.CM;
              } else if (licenciamento === 2) {
                totalmes += LA.CM;
              }
              break;
            case 808:
              totalitc += ITC.PDV;
              if (licenciamento === 0) {
                totalmes += LM.PDV;
              } else if (licenciamento === 1) {
                totalmes += LS.PDV;
              } else if (licenciamento === 2) {
                totalmes += LA.PDV;
              }
              break;
            case 506:
              totalitc += ITC.BI;
              if (licenciamento === 0) {
                totalmes += LM.BI;
              } else if (licenciamento === 1) {
                totalmes += LS.BI;
              } else if (licenciamento === 2) {
                totalmes += LA.BI;
              }
              break;
            default:
          }
        }
      });

      if (licenciamento === 0) {
        total = totalmes;
      } else if (licenciamento === 1) {
        total = totalmes * 6;
      } else if (licenciamento === 2) {
        total = totalmes * 12;
      }

      resolve({
        vlvalormensal: totalmes,
        vltotalmensal: total,
        vlvalorvenda: totalitc,
      });
    } else {
      resolve({
        vlvalormensal: 0,
        vltotalmensal: 0,
        vlvalorvenda: 0,
      });
    }
  });

export const calcPrice = async (
  cdtabprecos,
  uhs,
  users,
  licenciamento,
  modulosSelecionados,
  modulos,
  hasIService,
  iserviceITCplus,
  iserviceLMplus,
  cdpromocao,
) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
    const LM = await calculaValorTabelaMensalRealFunc(uhs, users);
    const LS = await calculaValorTabelaSemestralRealFunc(uhs, users);
    const LA = await calculaValorTabelaAnualRealFunc(uhs, users);
    const ITC = await calcItcRealFunc();

    const total = await calcTotalWithIService(
      cdtabprecos,
      licenciamento,
      modulos,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
      hasIService,
      uhs,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    return {
      LM,
      LS,
      LA,
      ITC,
      ...total,
    };
  }
  return null;
};

export const recalc = (cdtabprecos, total) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_REAL) {
    return recalcReal(total);
  }
  return null;
};

export const calcAdicaoUh = (...rest) => calcAdicaoUhEasy(...rest);

export const calcAdicaoUser = (...rest) => calcAdicaoUserEasy(...rest);
