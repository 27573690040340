import axios from 'axios';
import config from 'config/config';

const api = axios.create({
  baseURL: `${config.BACKEND_URL}/api`,
  responseType: 'json',
  timeout: 40000,
});

export default api;
