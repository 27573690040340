import _ from 'lodash';
import {STORAGE_10GB, STORAGE_30GB, STORAGE_50GB} from 'helpers/modules';
import {
  recalcReal,
  recalcDolar,
  recalcDolarITC,
  recalcEuro,
} from 'helpers/calc/easywebplus/recalc';
import {getITC, getLM} from 'helpers/calc/calcIService';
import {
  LM_STORAGE_10GB_DOLAR,
  LM_STORAGE_10GB_EURO,
  LM_STORAGE_10GB_REAL,
  LM_STORAGE_30GB_DOLAR,
  LM_STORAGE_30GB_EURO,
  LM_STORAGE_30GB_REAL,
  LM_STORAGE_50GB_DOLAR,
  LM_STORAGE_50GB_EURO,
  LM_STORAGE_50GB_REAL,
} from 'helpers/calc/calcStorage';
import {
  TAB_PRECO_EASYWEB_PLUS_REAL,
  TAB_PRECO_EASYWEB_PLUS_DOLAR,
  TAB_PRECO_EASYWEB_PLUS_EURO,
  BLACK_FRIDAY_CODE,
} from 'helpers/tabPrice';
import {getLmTef} from 'helpers/calc/calcTef';
import {
  calcItcRealFunc,
  calculaValorTabelaSemestralRealFunc,
  calculaValorTabelaMensalRealFunc,
  calculaValorTabelaAnualRealFunc,
} from './real';
import {
  calcItcDolarFunc,
  calculaValorTabelaSemestralDolarFunc,
  calculaValorTabelaMensalDolarFunc,
  calculaValorTabelaAnualDolarFunc,
} from './dolar';
import {
  calcItcEuroFunc,
  calculaValorTabelaSemestralEuroFunc,
  calculaValorTabelaMensalEuroFunc,
  calculaValorTabelaAnualEuroFunc,
} from './euro';

export const calcTotalWithIService = async (
  cdtabprecos,
  licenciamento,
  modulosSelecionados,
  ITC,
  LS,
  LM,
  LA,
  hasIService,
  uhs,
  iserviceITCplus,
  iserviceLMplus,
  cdpromocao,
  hasIntegracoes,
  integracoesITC,
  integracoesLM,
  storageSelected,
  hasTef,
  tefITC,
  qtdTef,
) =>
  new Promise(resolve => {
    if (licenciamento !== null) {
      let totalmes = 0;
      let totalitc = 0;
      let total = 0;

      _.forEach(modulosSelecionados, value => {
        totalitc += ITC[value];

        if (licenciamento === 0) {
          totalmes += LM[value];
        } else if (licenciamento === 1) {
          totalmes += LS[value];
        } else if (licenciamento === 2) {
          totalmes += LA[value];
        }
      });

      if (hasIntegracoes) {
        totalitc += integracoesITC;
        totalmes += integracoesLM;
      }

      if (hasTef) {
        totalitc += tefITC;
        totalmes += getLmTef(qtdTef);
      }

      if (storageSelected) {
        if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
          if (storageSelected === STORAGE_10GB) {
            totalmes += LM_STORAGE_10GB_REAL;
          }
          if (storageSelected === STORAGE_30GB) {
            totalmes += LM_STORAGE_30GB_REAL;
          }
          if (storageSelected === STORAGE_50GB) {
            totalmes += LM_STORAGE_50GB_REAL;
          }
        }

        if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_DOLAR) {
          if (storageSelected === STORAGE_10GB) {
            totalmes += LM_STORAGE_10GB_DOLAR;
          }
          if (storageSelected === STORAGE_30GB) {
            totalmes += LM_STORAGE_30GB_DOLAR;
          }
          if (storageSelected === STORAGE_50GB) {
            totalmes += LM_STORAGE_50GB_DOLAR;
          }
        }

        if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_EURO) {
          if (storageSelected === STORAGE_10GB) {
            totalmes += LM_STORAGE_10GB_EURO;
          }
          if (storageSelected === STORAGE_30GB) {
            totalmes += LM_STORAGE_30GB_EURO;
          }
          if (storageSelected === STORAGE_50GB) {
            totalmes += LM_STORAGE_50GB_EURO;
          }
        }
      }

      if (licenciamento === 0) {
        total += totalmes;
      } else if (licenciamento === 1) {
        total += totalmes * 6;
      } else if (licenciamento === 2) {
        total += totalmes * 12;
      }

      if (hasIService) {
        const iserviceITC =
          cdpromocao === BLACK_FRIDAY_CODE ? 0 : getITC(cdtabprecos);
        totalitc += iserviceITC;

        if (iserviceITCplus) {
          totalitc += iserviceITCplus;
        }

        const iserviceLM = getLM(uhs, cdtabprecos);
        totalmes += iserviceLM;

        if (iserviceLMplus) {
          totalmes += iserviceLMplus;
        }

        if (licenciamento === 0) {
          total += iserviceLM + iserviceLMplus;
        } else if (licenciamento === 1) {
          total += (iserviceLM + iserviceLMplus) * 6;
        } else if (licenciamento === 2) {
          total += (iserviceLM + iserviceLMplus) * 12;
        }

        resolve({
          vlvalormensal: totalmes,
          vltotalmensal: total,
          vlvalorvenda: totalitc,
          iserviceITC,
          iserviceLM,
        });
      } else {
        resolve({
          vlvalormensal: totalmes,
          vltotalmensal: total,
          vlvalorvenda: totalitc,
          iserviceITC: 0,
          iserviceLM: 0,
        });
      }
    } else {
      resolve({
        vlvalormensal: 0,
        vltotalmensal: 0,
        vlvalorvenda: 0,
        iserviceITC: 0,
        iserviceLM: 0,
      });
    }
  });

export const calcTotal = async (
  licenciamento,
  modulosSelecionados,
  ITC,
  LS,
  LM,
  LA,
) =>
  new Promise(resolve => {
    if (licenciamento !== null) {
      let totalmes = 0;
      let totalitc = 0;
      let total = 0;

      _.forEach(modulosSelecionados, value => {
        totalitc += ITC[value];

        if (licenciamento === 0) {
          totalmes += LM[value];
        } else if (licenciamento === 1) {
          totalmes += LS[value];
        } else if (licenciamento === 2) {
          totalmes += LA[value];
        }
      });

      if (licenciamento === 0) {
        total = totalmes;
      } else if (licenciamento === 1) {
        total = totalmes * 6;
      } else if (licenciamento === 2) {
        total = totalmes * 12;
      }

      resolve({
        vlvalormensal: totalmes,
        vltotalmensal: total,
        vlvalorvenda: totalitc,
      });
    } else {
      resolve({
        vlvalormensal: 0,
        vltotalmensal: 0,
        vlvalorvenda: 0,
      });
    }
  });

export const calcPrice = async (
  cdtabprecos,
  uhs,
  users,
  licenciamento,
  modulosSelecionados,
  hasIService,
  iserviceITCplus,
  iserviceLMplus,
  cdpromocao,
) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_DOLAR) {
    const LM = await calculaValorTabelaMensalDolarFunc(uhs, users);
    const LS = await calculaValorTabelaSemestralDolarFunc(uhs, users);
    const LA = await calculaValorTabelaAnualDolarFunc(uhs, users);
    const ITC = await calcItcDolarFunc();

    const total = await calcTotal(
      licenciamento,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
    );

    return {
      LM,
      LS,
      LA,
      ITC,
      ...total,
    };
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_EURO) {
    const LM = await calculaValorTabelaMensalEuroFunc(uhs, users);
    const LS = await calculaValorTabelaSemestralEuroFunc(uhs, users);
    const LA = await calculaValorTabelaAnualEuroFunc(uhs, users);
    const ITC = await calcItcEuroFunc();

    const total = await calcTotal(
      licenciamento,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
    );

    return {
      LM,
      LS,
      LA,
      ITC,
      ...total,
    };
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
    const LM = await calculaValorTabelaMensalRealFunc(uhs, users);
    const LS = await calculaValorTabelaSemestralRealFunc(uhs, users);
    const LA = await calculaValorTabelaAnualRealFunc(uhs, users);
    const ITC = await calcItcRealFunc();

    const total = await calcTotalWithIService(
      cdtabprecos,
      licenciamento,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
      hasIService,
      uhs,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    return {
      LM,
      LS,
      LA,
      ITC,
      ...total,
    };
  }
  return null;
};

export const recalc = (cdtabprecos, total) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
    return recalcReal(total);
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_DOLAR) {
    return recalcDolar(total);
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_EURO) {
    return recalcEuro(total);
  }
  return null;
};

export const recalcITC = (cdtabprecos, total) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
    return recalcReal(total);
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_DOLAR) {
    return recalcDolarITC(total);
  }
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_EURO) {
    return recalcEuro(total);
  }
  return null;
};
