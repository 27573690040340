import React from 'react';
import PropTypes from 'prop-types';

import nuvemTexto from 'assets/img/cadastro/nuvem_texto.png';
import nuvemTextoEs from 'assets/img/cadastro/nuvem_es.png';
import dispositivos from 'assets/img/cadastro/dispositivos.png';
import iconeTopo from 'assets/img/cadastro/icone_topo.svg';

import {getProductName} from 'helpers/tabPrice';

const AdicaoSuccess = ({lang, cdproduto}) => {
  const idioma = parseInt(lang, 10);
  const productName = getProductName(cdproduto);
  let texto = `Em breve você poderá desfrutar toda a experiência do Desbravador ${productName}.`;
  if (idioma === 3) {
    texto = `En breve usted podrá disfrutar de la experiencia del Desbravador ${productName}.`;
  }
  const img = idioma === 3 ? nuvemTextoEs : nuvemTexto;

  return (
    <div>
      <div className="page-content">
        <div className="col-xs-12">
          <div className="col-xs-12 center ">
            <span className="label label-desbravador">Registrado</span>
            <span>
              <img height="3%" width="3%" src={iconeTopo} alt="icone" />
            </span>
          </div>
        </div>
        <div className="col-xs-12 margin-10">
          <div className="col-lg-offset-2 col-lg-3 col-xs-12">
            <img
              src={dispositivos}
              alt="dispositivos"
              className="img-responsive center-block"
            />
          </div>
          <div className="col-lg-offset-1 col-lg-4 col-xs-12 blue">
            <h3 className="text-center">{texto}</h3>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-inner">
          <img
            src={img}
            height="30%"
            width="30%"
            alt="nuvem texto"
            className="img-responsive center-block"
            style={{marginBottom: '15px'}}
          />
          <div className="footer-content" />
        </div>
      </div>
    </div>
  );
};

AdicaoSuccess.propTypes = {
  lang: PropTypes.string.isRequired,
  cdproduto: PropTypes.string.isRequired,
};

export default AdicaoSuccess;
