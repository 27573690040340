import {round} from '../calcRounding';
import {
  EASY_REAL_TOTAL_ITC,
  EASY_REAL_BI,
  EASY_REAL_CE,
  EASY_REAL_CM,
  EASY_REAL_PDV,
  EASY_REAL_GF,
  EASY_REAL_GH,
  EASY_REAL_ROLRM,
  EASY_REAL_TT,
  EASY_REAL_MESAL_UH,
  EASY_REAL_SEMESTRAL_UH,
  EASY_REAL_ANUAL_UH,
} from '../values';

export const getQtdUserReal = () => 0;

export const getQtdUhReal = qtduh => {
  if (qtduh < 10) {
    return 10;
  }
  if (qtduh > 50) {
    return 50;
  }
  return qtduh;
};

const calcPercentual = (valorMesUh, qtdUh) => {
  // Gerencia Hoteleira
  const gh = round(qtdUh * (valorMesUh * EASY_REAL_GH));
  // Tarifador
  const tt = round(qtdUh * (valorMesUh * EASY_REAL_TT));
  // Estoque
  const ce = round(qtdUh * (valorMesUh * EASY_REAL_CE));
  // Financeiro
  const gf = round(qtdUh * (valorMesUh * EASY_REAL_GF));
  // Channel
  const cm = round(qtdUh * (valorMesUh * EASY_REAL_CM));
  // PDV
  const pdv = round(qtdUh * (valorMesUh * EASY_REAL_PDV));
  // BI
  const bi = round(qtdUh * (valorMesUh * EASY_REAL_BI));
  // Reservas Online
  const rolrm = round(qtdUh * (valorMesUh * EASY_REAL_ROLRM));

  const total = round(gh + tt + rolrm + gf + ce + cm + pdv + bi);

  return {
    gh,
    tt,
    ce,
    gf,
    cm,
    pdv,
    bi,
    rolrm,
    total,
  };
};

export const calcItcReal = () => ({
  ITC_GH: EASY_REAL_TOTAL_ITC,
  ITC_TT: 0.0,
  ITC_ROLRM: 0.0,
  ITC_GF: 0.0,
  ITC_CE: 0.0,
  ITC_CM: 0.0,
  ITC_PDV: 0.0,
  ITC_BI: 0.0,
  TOTAL: EASY_REAL_TOTAL_ITC,
});

export const calculaValorTabelaMensalReal = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_REAL_MESAL_UH + getQtdUserReal(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhReal(qtdUh));

  return {
    LM_GH: result.gh,
    LM_TT: result.tt,
    LM_CE: result.ce,
    LM_GF: result.gf,
    LM_CM: result.cm,
    LM_PDV: result.pdv,
    LM_BI: result.bi,
    LM_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};

export const calculaValorTabelaSemestralReal = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_REAL_SEMESTRAL_UH + getQtdUserReal(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhReal(qtdUh));

  return {
    LS_GH: result.gh,
    LS_TT: result.tt,
    LS_CE: result.ce,
    LS_GF: result.gf,
    LS_CM: result.cm,
    LS_PDV: result.pdv,
    LS_BI: result.bi,
    LS_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};

export const calculaValorTabelaAnualReal = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_REAL_ANUAL_UH + getQtdUserReal(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhReal(qtdUh));

  return {
    LA_GH: result.gh,
    LA_TT: result.tt,
    LA_CE: result.ce,
    LA_GF: result.gf,
    LA_CM: result.cm,
    LA_PDV: result.pdv,
    LA_BI: result.bi,
    LA_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};
