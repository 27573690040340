import React, {useEffect} from 'react';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 - Page not found';
  }, []);

  return (
    <div>
      <section id="site-404">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWgAAACWCAMAAADE1OI6AAAAilBMVEUAAACampqZmZmZmZmampqampqZmZmampqYmJiampqZmZmYmJiZmZmZmZmZmZmXl5fMzMycnJzLy8uZmZnm5ubMzMzm5ubMzMyZmZmZmZmZmZmZmZmrq6uZmZmZmZnm5ubMzMyZmZmZmZmZmZmZmZmZmZnMzMzLy8vLy8uZmZmYmJjm5uaZmZnMzMw5de9WAAAAK3RSTlMA58AohsXipC+Dm9qomfEh3AYi+fryglUTSLE8GlpT77qSNdF6ac6ATmIlW6YtdAAAAzNJREFUeNrt3WtTozAUgOGD1VatpcviBQpF6cXLevr//96CWGlnJ0PtEiX4Pp/O53cyaTITVAAAAAAAQA/d3Wwa3NxJo9vr1/93fSs99rBp9CCN7l/bcC89xooGAAAAAAAAAAAAgG5L56uLl6gYovnqdL0zoE3BVagFP5FRNcQyeRvCTNCmkWoZdimTahhLXg2e9NN3vakYTBbBVDWU6eUiSEPVIBolQeSrRpbfk6S/d6XSim6/ElqXK7o0L1d0KVP1LL+QetrsexKjXqxokaDcNBIp5MUQS+G02qOtrujHzb5H6buV6nDxvrCHyfvC9hKx7PnXvmfpu5n6kZRG22GiYSpoW5LF1bDYHdC6aH6+HRb1gNYNVavSY9Uq8FKVJd26INxm9VUzKXmqL4K2zWengZSyWV4NcTkAAL5aPLo61kpwuBM9FrfFT4b2x0fxVAeCT4Q+k6OcEprQXURoA0I7itAGhHYUoQ3shY7zySHmfQpt8XWEOfRSD+KnPQpt8XWEOfRQQ79RqHreo9BWX0eYQ19NG+V7obv/RsXM/usIc+iRNLrYC+3Cq6uu+GxoVnSXQ4PQRoR2FKEb2A2dZoF8CLKU0HZC/xnquu480xNC2whddg6Tnc56QWgboadF52y384Q92kbooOgcy9ZENefH0EroyN/pLDPNOXXY2jqmUgsWHO84R7uE0AaEdpQxdDCfEtpq6Po+SGiroev7IKFthq7vg+zRNkPXnfkxtBm67sypw2boujPHO/uhi84552j7oVPVnAuL2A8tL2tuhnu4grvkwNCDAaG/IPT5WHU5ILTt0MFYC8uA0JZDJ/pmQWjLoWN9kxDacujI08KQrcN2aIk9VS/hx9B6aIniOOJ4x4XFBYRu6buPDoQ+9huzZi79/egWQlv6atKMFe3kijZjj3YRoQ0I7ShCGxDaUYQ2ILSjCG1AaEcR2oDQjiK0AaEdRWgDQjuK0AaEdhShDQjtKEIbENpRhDYgtKMIbUDofznxjufnhP6Ol2mryw9jHb5Pvi4vG52ojvamigv/2vAbVnQSasvCpFsvCjsiOvNadhZ1640sftaKBgAAAAAAAAD011+/GsivmZ5EPAAAAABJRU5ErkJggg=="
                id="error-img-top"
                className="center-block img-responsive"
                alt="Not Found"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <h1 id="error-title">404</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <h2 id="error-description">Página não encontrada</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
