import React, {useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {Link, useNavigate} from 'react-router-dom';
import {Formik, Form} from 'formik';
import jwtDecode from 'jwt-decode';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactLoading from 'react-loading';
import * as AuthActions from 'store/actions/AuthActions';

import InputContainer from 'components/form/inputContainer';

import api from 'services/api';
import notification from 'components/notification';

import LogoLightWeb from 'assets/img/lightweb/Light-Web-marca.svg';
// import LogoEasyWeb from 'assets/img/easyweb/Easy-Web-marca.svg';
// import Logo30Web from 'assets/img/30web/30-Web-marca.svg';

import {rules} from './formRules';

const Login = props => {
  useEffect(() => {
    document.title = 'Login';
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    const {logout} = props;
    logout();
    notification.success('Logout efetuado com sucesso!', '');
  };

  const handleSubmit = (email, password, resetForm) => {
    const {loginUserRequest, loginUserFailure, loginUserSuccess} = props;
    loginUserRequest();

    api
      .post('/auth/login', {
        email,
        password,
      })
      .then(response => {
        const data = response.data;
        console.log(data);
        try {
          if (!jwtDecode(data.token)) {
            loginUserFailure({
              status: 500,
            });
            notification.error('Não foi possível realizar o login', '');
            return;
          }
          loginUserSuccess(data.token);
          notification.success('Login efetuado com sucesso!', '');

          navigate('/app/pedidos', {replace: true});
        } catch (e) {
          loginUserFailure({
            status: 401,
          });
          notification.error('Login inválido', '');
          resetForm();
        }
      })
      .catch(error => {
        let status = 500;

        if (error.response) {
          status = error.response.status;
        }

        loginUserFailure({
          status,
        });
        if (status === 401) {
          notification.error('Login inválido', '');
        } else {
          notification.error('Não foi possível realizar o login', '');
        }
        resetForm();
      });
    return false;
  };

  const renderLoading = () => {
    const {auth} = props;
    if (auth.isAuthenticating) {
      return (
        <div>
          <br />
          <ReactLoading
            type="spinningBubbles"
            color="#006DA5"
            height={50}
            width={50}
            className="center-block"
          />
        </div>
      );
    }
    return null;
  };

  const {auth} = props;
  const isAuthenticated = auth.isAuthenticated;

  return (
    <section id="site-login">
      <div className="container">
        <div id="login-content">
          <Row>
            {/* <Col md={2} mdOffset={3}>
              <img
                src={LogoEasyWeb}
                alt="logo light web"
                className="center-block img-responsive login-logo"
              />
            </Col> */}
            <Col md={2} mdOffset={5}>
              <img
                src={LogoLightWeb}
                alt="logo light web"
                className="center-block img-responsive login-logo"
              />
            </Col>
            {/* <Col md={2}>
              <img
                src={Logo30Web}
                alt="logo light web"
                className="center-block img-responsive login-logo"
              />
            </Col> */}
          </Row>

          <Row>
            <Col md={12}>
              <div id="login-panel">
                <div className="space-6" />
                {!isAuthenticated ? (
                  <Formik
                    initialValues={{email: '', password: ''}}
                    validationSchema={rules}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                      setSubmitting(false);
                      const {email, password} = values;

                      handleSubmit(email, password, resetForm);
                    }}>
                    {({errors, touched, values, setFieldValue}) => (
                      <Form id="login-form">
                        <InputContainer
                          placeholder="E-mail"
                          showError={!!errors.email && !!touched.email}
                          text={values.email}
                          onFieldChanged={e => {
                            setFieldValue('email', e.target.value);
                          }}
                          errorText={errors.email}
                        />

                        <InputContainer
                          type="password"
                          placeholder="Password"
                          showError={!!errors.password && !!touched.password}
                          text={values.password}
                          onFieldChanged={e => {
                            setFieldValue('password', e.target.value);
                          }}
                          errorText={errors.password}
                        />

                        <div className="clearfix" />

                        <Button
                          block
                          bsStyle="primary"
                          bsSize="sm"
                          type="submit"
                          disabled={auth.isAuthenticating}
                          className="full-width m-b">
                          Entrar
                        </Button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <p className="user-name">{auth.nome}</p>
                    <p className="user-email">{auth.email}</p>

                    <p>
                      <Link
                        className="btn btn-sm btn-primary btn-block"
                        to="/app/pedidos">
                        Entrar
                      </Link>
                    </p>

                    <p>
                      <button
                        type="button"
                        className="btn btn-sm btn-gray btn-block"
                        onClick={() => {
                          handleLogout();
                        }}>
                        Logout
                      </button>
                    </p>
                  </div>
                )}
              </div>

              {renderLoading()}
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    isAuthenticating: PropTypes.bool,
    nome: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  loginUserRequest: PropTypes.func.isRequired,
  loginUserSuccess: PropTypes.func.isRequired,
  loginUserFailure: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispathToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispathToProps)(Login);
