import _ from 'lodash';

export const modulesDSL = [
  '800' /* Gerencia Hoteleira */,
  '801' /* Tarifador */,
  '802' /* Reservas On Line */,
  '803' /* Revenue Management */,
  '805' /* Gerencia Financeira */,
  '804' /* Controle de Estoque */,
  '806' /* Channel Manager */,
  '808' /* 'PDV + Monitor + iPDV' */,
  '506' /* Business Intelligence */,
  '598' /* iService */,
  '1140' /* Armazenamento Anexos - Familia WEB - 10 GB */,
  '1141' /* Armazenamento Anexos - Familia WEB - 30 GB */,
  '1142' /* Armazenamento Anexos - Familia WEB - 50 GB */,
];

export const channelModules = [
  '515', // OMNIBEES
  '510', // Synxis
  '524', // B2B
  '536', // HSYSTEM
  '522', // Decolar
  '520', // TravelClick
  '514', // Booking
  '511', // CM Reservas
  '532', // CVC
  '517', // Expedia
  '533', // Siteminder
  '1108', // CHANNEX - AIRBNB - HOSTELWORLD - HOTEL BEDS
  '526', // THN
  '518', // ROL - Integração
];

export const filterModulesChannel = (modules = []) => {
  const intersection = _.intersection(modules, channelModules);
  const res = [];
  intersection.forEach(m => {
    if (m === '515') {
      res.push({
        label: 'OMNIBEES',
        value: m,
        isFixed: true,
      });
    }
    if (m === '510') {
      res.push({
        label: 'Synxis',
        value: m,
        isFixed: true,
      });
    }
    if (m === '524') {
      res.push({
        label: 'B2B',
        value: m,
        isFixed: true,
      });
    }
    if (m === '536') {
      res.push({
        label: 'HSYSTEM',
        value: m,
        isFixed: true,
      });
    }
    if (m === '522') {
      res.push({
        label: 'Decolar',
        value: m,
        isFixed: true,
      });
    }
    if (m === '520') {
      res.push({
        label: 'TravelClick',
        value: m,
        isFixed: true,
      });
    }
    if (m === '514') {
      res.push({
        label: 'Booking',
        value: m,
        isFixed: true,
      });
    }
    if (m === '511') {
      res.push({
        label: 'CM Reservas',
        value: m,
        isFixed: true,
      });
    }
    if (m === '532') {
      res.push({
        label: 'CVC',
        value: m,
        isFixed: true,
      });
    }
    if (m === '517') {
      res.push({
        label: 'Expedia',
        value: m,
        isFixed: true,
      });
    }
    if (m === '533') {
      res.push({
        label: 'Siteminder',
        value: m,
        isFixed: true,
      });
    }
    if (m === '1108') {
      res.push({
        label: 'CHANNEX - AIRBNB - HOSTELWORLD - HOTEL BEDS',
        value: m,
        isFixed: true,
      });
    }
    if (m === '526') {
      res.push({
        label: 'THN',
        value: m,
        isFixed: true,
      });
    }
    if (m === '518') {
      res.push({
        label: 'ROL - Integração',
        value: m,
        isFixed: true,
      });
    }
  });
  return res;
};

export const filterModulesExceptChannelTypes = (modules = []) =>
  _.intersection(modules, modulesDSL);

export const filterModulesOnlyChannelTypes = (modules = []) =>
  _.intersection(modules, channelModules);

export const STORAGE_10GB = '1140';
export const STORAGE_30GB = '1141';
export const STORAGE_50GB = '1142';

export const MODULE_ISERVICE = '598';
export const MODULE_TEF = '593';
