import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  PRODUCT_WEB30_CODE,
  PRODUCT_EASYWEB_PLUS_CODE,
  tabprecosLight,
  tabprecosEasy,
  tabprecosEasyPlus,
} from 'helpers/tabPrice';

const ProductSelect = ({value, onChange}) => {
  const showLightWeb = useMemo(() => false, []);
  const showEasyWeb = useMemo(() => true, []);
  const showEasyWebPlus = useMemo(() => true, []);
  const show30Web = useMemo(() => false, []);

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <h4 className="header smaller lighter blue">Qual o produto?</h4>
        </Col>
        {showLightWeb ? (
          <Col sm={12} md={2}>
            <div className="checkbox">
              <label className="block">
                <input
                  name="produto-lightweb"
                  value={PRODUCT_LIGHTWEB_CODE}
                  type="checkbox"
                  className="ace ace-checkbox-2 input-lg"
                  checked={value === PRODUCT_LIGHTWEB_CODE}
                  onChange={() => {
                    onChange(PRODUCT_LIGHTWEB_CODE, tabprecosLight[0].codigo);
                  }}
                />
                <span className="lbl bigger-120">&nbsp;Light Web</span>
              </label>
            </div>
          </Col>
        ) : null}

        {showEasyWeb ? (
          <Col sm={12} md={2}>
            <div className="checkbox">
              <label className="block">
                <input
                  name="produto-easyweb"
                  value={PRODUCT_EASYWEB_CODE}
                  type="checkbox"
                  className="ace ace-checkbox-2 input-lg"
                  checked={value === PRODUCT_EASYWEB_CODE}
                  onChange={() => {
                    onChange(PRODUCT_EASYWEB_CODE, tabprecosEasy[0].codigo);
                  }}
                />
                <span className="lbl bigger-120">&nbsp;Easy Web</span>
              </label>
            </div>
          </Col>
        ) : null}

        {showEasyWebPlus ? (
          <Col sm={12} md={2}>
            <div className="checkbox">
              <label className="block">
                <input
                  name="produto-easyweb-plus"
                  value={PRODUCT_EASYWEB_PLUS_CODE}
                  type="checkbox"
                  className="ace ace-checkbox-2 input-lg"
                  checked={value === PRODUCT_EASYWEB_PLUS_CODE}
                  onChange={() => {
                    onChange(
                      PRODUCT_EASYWEB_PLUS_CODE,
                      tabprecosEasyPlus[0].codigo,
                    );
                  }}
                />
                <span className="lbl bigger-120">&nbsp;Easy Web Plus</span>
              </label>
            </div>
          </Col>
        ) : null}

        {show30Web ? (
          <Col sm={12} md={2}>
            <div className="checkbox">
              <label className="block">
                <input
                  name="produto-web30"
                  value={PRODUCT_WEB30_CODE}
                  type="checkbox"
                  className="ace ace-checkbox-2 input-lg"
                  checked={value === PRODUCT_WEB30_CODE}
                  onChange={() => {
                    onChange(PRODUCT_WEB30_CODE, tabprecosLight[0].codigo);
                  }}
                />
                <span className="lbl bigger-120">&nbsp;3.0 Web</span>
              </label>
            </div>
          </Col>
        ) : null}
      </Row>
      <div className="hr hr32 hr-dotted" />
    </>
  );
};

ProductSelect.defaultProps = {};

ProductSelect.propTypes = {
  value: PropTypes.oneOf([
    PRODUCT_LIGHTWEB_CODE,
    PRODUCT_EASYWEB_CODE,
    PRODUCT_EASYWEB_PLUS_CODE,
    PRODUCT_WEB30_CODE,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProductSelect;
