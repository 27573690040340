import React from 'react';
import {Alert} from 'components/alert';

const Header = () => (
  <div className="container">
    <Alert>
      <strong>Lembrar! </strong>
      <span>
        Caso o hotel tenha eventos e controla a sala, adicionar uma UH para essa
        função.
      </span>
    </Alert>
  </div>
);

export default Header;
