import * as Yup from 'yup';
import {messages} from 'helpers/validator/messages';

export const rules = Yup.object().shape({
  nmdiremail: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmdirnome: Yup.string().nullable().required(messages.required),
  txobspagamento: Yup.string().nullable().required(messages.required),
  txobsvenda: Yup.string().nullable().required(messages.required),
  searchCliente: Yup.number()
    .nullable()
    .required(messages.required)
    .positive(messages.required),
});
