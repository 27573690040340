import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, FormGroup} from 'react-bootstrap';

import {useFormikContext} from 'formik';

import InputContainer from 'components/form/inputContainer';
import SearchContainer from 'components/form/searchContainer';

import {Alert} from 'components/alert';

const HeaderForm = ({
  // UPGRADE
  isUpgrade,
  disableIsUpgrade,
  handleIsUpdateChange,
  // DOWNGRADE
  isDowngrade,
  disableIsDowngrade,
  handleIsDowngradeChange,
  // RETORNO
  isReturn,
  disableIsReturn,
  handleIsReturnChange,
  nmnomeresponsavel,
  nmemailresponsavel,
  nmdirnome,
  nmdiremail,
  onFieldChanged,
  fantasia,
  searchCliente,
  onSearchClick,
}) => {
  const {errors, touched} = useFormikContext();
  return (
    <Row>
      <Col md={12}>
        <h4 className="header smaller lighter blue">
          Dados básicos do cliente
        </h4>

        {/* isUpgrade */}
        <div>
          <FormGroup>
            <div className="col-md-2 col-md-offset-3">
              <div className="checkbox mb-2">
                <label>
                  <input
                    type="checkbox"
                    className="ace ace-checkbox-2 input-lg"
                    checked={isUpgrade}
                    onChange={() => {
                      handleIsUpdateChange();
                    }}
                    disabled={disableIsUpgrade}
                  />
                  <span className="lbl" style={{marginLeft: '-20px'}}>
                    &nbsp;Upgrade
                  </span>
                </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="ace ace-checkbox-2 input-lg"
                    checked={isDowngrade}
                    onChange={() => {
                      handleIsDowngradeChange();
                    }}
                    disabled={disableIsDowngrade}
                  />
                  <span className="lbl" style={{marginLeft: '-20px'}}>
                    &nbsp;Downgrade
                  </span>
                </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="ace ace-checkbox-2 input-lg"
                    checked={isReturn}
                    onChange={() => {
                      handleIsReturnChange();
                    }}
                    disabled={disableIsReturn}
                  />
                  <span className="lbl" style={{marginLeft: '-20px'}}>
                    &nbsp;Retorno
                  </span>
                </label>
              </div>
            </div>
          </FormGroup>

          {isUpgrade || isDowngrade || isReturn ? (
            <div>
              <SearchContainer
                type="number"
                label="Código do cliente"
                text={searchCliente}
                onFieldChanged={e => {
                  onFieldChanged('searchCliente', e.target.value);
                }}
                placeholder="Informe o código do cliente"
                onSearchClick={() => {
                  onSearchClick();
                }}
                showError={false}
              />

              <br />

              <Row>
                <div className="col-xs-9 col-xs-offset-3">
                  <Alert>
                    <strong>Atenção!&nbsp;</strong>
                    {isUpgrade ? <span>Esse pedido é um Upgrade.</span> : null}
                    {isDowngrade ? (
                      <span>Esse pedido é um Downgrade.</span>
                    ) : null}
                    {isReturn ? <span>Esse pedido é um Retorno.</span> : null}
                    <br />
                    <strong>Nome fantasia:&nbsp;</strong>
                    <label className="">{fantasia}</label>
                    <br />
                  </Alert>
                </div>
              </Row>
            </div>
          ) : null}
        </div>

        <Alert>
          <strong>Lembrar! </strong>
          Deve ser preenchido com os dados do representante legal do hotel.
        </Alert>

        <InputContainer
          type="text"
          label="Nome do responsável (Pelo pedido) *"
          showError={!!errors.nmnomeresponsavel && !!touched.nmnomeresponsavel}
          text={nmnomeresponsavel}
          onFieldChanged={e => {
            onFieldChanged('nmnomeresponsavel', e.target.value);
          }}
          errorText={errors.nmnomeresponsavel}
        />

        <InputContainer
          type="email"
          label="Email do responsável (Pelo pedido) *"
          showError={
            !!errors.nmemailresponsavel && !!touched.nmemailresponsavel
          }
          text={nmemailresponsavel}
          onFieldChanged={e => {
            onFieldChanged('nmemailresponsavel', e.target.value);
          }}
          errorText={errors.nmemailresponsavel}
        />

        <InputContainer
          type="text"
          label="Nome do responsável (Diretor) *"
          showError={!!errors.nmdirnome && !!touched.nmdirnome}
          text={nmdirnome}
          onFieldChanged={e => {
            onFieldChanged('nmdirnome', e.target.value);
          }}
          errorText={errors.nmdirnome}
        />

        <InputContainer
          type="email"
          label="Email do responsável (Diretor) *"
          showError={!!errors.nmdiremail && !!touched.nmdiremail}
          text={nmdiremail}
          onFieldChanged={e => {
            onFieldChanged('nmdiremail', e.target.value);
          }}
          errorText={errors.nmdiremail}
        />
      </Col>
    </Row>
  );
};

HeaderForm.defaultProps = {
  nmnomeresponsavel: '',
  nmemailresponsavel: '',
  nmdirnome: '',
  nmdiremail: '',
  fantasia: '',
  searchCliente: '',
};

HeaderForm.propTypes = {
  isUpgrade: PropTypes.bool.isRequired,
  disableIsUpgrade: PropTypes.bool.isRequired,
  handleIsUpdateChange: PropTypes.func.isRequired,
  isDowngrade: PropTypes.bool.isRequired,
  disableIsDowngrade: PropTypes.bool.isRequired,
  handleIsDowngradeChange: PropTypes.func.isRequired,
  isReturn: PropTypes.bool.isRequired,
  disableIsReturn: PropTypes.bool.isRequired,
  handleIsReturnChange: PropTypes.func.isRequired,
  nmnomeresponsavel: PropTypes.string,
  nmemailresponsavel: PropTypes.string,
  nmdirnome: PropTypes.string,
  nmdiremail: PropTypes.string,
  fantasia: PropTypes.string,
  searchCliente: PropTypes.string,
  onFieldChanged: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default HeaderForm;
