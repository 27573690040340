export const MODULE_STATE_OBJ = {
  GH: 0,
  TT: 0,
  ROLRM: 0,
  GF: 0,
  CE: 0,
  CM: 0,
  PDV: 0,
  BI: 0,
  TOTAL: 0,
};
