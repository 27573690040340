import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Currency from '../currency';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: props.TOTAL || '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.total === '' && props.TOTAL === 0) {
      return {
        total: '',
      };
    }
    if (Number.isNaN(state.total) && props.TOTAL === 0) {
      return {
        total: '',
      };
    }
    if (props.TOTAL !== state.total) {
      return {
        total: props.TOTAL,
      };
    }
    return null;
  }

  renderButton = () => {
    const {selected, btn, handleLicenciamento, showButton, title} = this.props;
    return showButton && title !== 'ITC' ? (
      <Button
        type="button"
        className={`btn btn-block btn-sm btn-${btn}`}
        disabled={selected}
        onClick={() => {
          handleLicenciamento();
        }}>
        {selected ? (
          <>
            <i className="fa fa-check-circle-o" aria-hidden="true" />{' '}
            <span>
              <b>Selecionado</b>
            </span>
          </>
        ) : (
          <span>Selecionar</span>
        )}
      </Button>
    ) : null;
  };

  onBlur = () => {
    const {onBlurTotal} = this.props;
    let value = this.state.total;
    if (
      Number.isNaN(value) ||
      value === '' ||
      value === null ||
      value === undefined
    ) {
      value = 0;
    }
    onBlurTotal(value);
  };

  onValueChange = values => {
    const value = values.floatValue;
    const {onChangeTotal} = this.props;
    onChangeTotal(value);
  };

  renderTotal() {
    const inputStyle = {
      background: '#333333',
      border: 'none',
      maxWidth: '55px',
      textAlign: 'center',
      fontSize: '10px',
      height: '20px',
      color: '#FFF',
    };

    return (
      <span
        className="label label-lg label-inverse arrowed-in arrowed-in-right"
        style={{width: '80%'}}>
        {this.props.moeda}

        <NumberFormat
          style={inputStyle}
          value={this.state.total}
          allowNegative={false}
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          onBlur={e => {
            this.onBlur(e);
          }}
          onValueChange={values => {
            this.onValueChange(values);
          }}
          displayType="input"
          isNumericString={false}
        />

        {this.props.mensal ? <small>&nbsp;&nbsp;/mês</small> : null}
      </span>
    );
  }

  render() {
    const liStyle = {
      padding: '11px',
    };

    return (
      <div>
        <div className="pricing-span">
          <div
            className={`widget-box pricing-box-small widget-color-${this.props.color}`}>
            <div className="widget-header">
              <h5 className="widget-title bigger lighter">
                {this.props.title}
              </h5>
            </div>

            <div className="widget-body">
              <div className="text-center">
                <div className="widget-main no-padding">
                  <ul className="list-unstyled list-striped pricing-table">
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.GH}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.TT}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.ROLRM}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.GF}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.CE}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.CM}
                      />
                    </li>
                    <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.PDV}
                      />
                    </li>
                    {/* <li style={liStyle}>
                      <Currency
                        moeda={this.props.moeda}
                        value={this.props.BI}
                      />
                    </li> */}
                  </ul>

                  <div className="price">{this.renderTotal()}</div>
                </div>

                <div>{this.renderButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Pricing.defaultProps = {
  mensal: true,
  moeda: 'R$',
  TOTAL: undefined,
  showButton: true,
};

Pricing.propTypes = {
  color: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  mensal: PropTypes.bool,
  moeda: PropTypes.oneOf(['R$', 'U$', '€']),
  handleLicenciamento: PropTypes.func.isRequired,
  GH: PropTypes.number.isRequired,
  TT: PropTypes.number.isRequired,
  ROLRM: PropTypes.number.isRequired,
  GF: PropTypes.number.isRequired,
  CE: PropTypes.number.isRequired,
  CM: PropTypes.number.isRequired,
  PDV: PropTypes.number.isRequired,
  // BI: PropTypes.number.isRequired,
  TOTAL: PropTypes.number,
  onChangeTotal: PropTypes.func.isRequired,
  onBlurTotal: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
};

export default Pricing;
