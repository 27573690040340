export const cepSearch = cep =>
  new Promise((resolve, reject) => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {method: 'GET'})
      .then(response => response.json())
      .then(data => {
        if (data.erro) {
          reject();
        } else {
          resolve(data);
        }
      })
      .catch(() => {
        reject();
      });
  });
