import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {useFormikContext} from 'formik';

import SearchContainer from 'components/form/searchContainer';
import SelectContainer from 'components/form/selectContainer';
import InputContainer from 'components/form/inputContainer';

import {
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  tabprecosLight,
  tabprecosEasy,
  PRODUCT_WEB30_CODE,
} from 'helpers/tabPrice';

const idiomas = [
  {
    codigo: 1,
    nome: 'Português',
  },
  {
    codigo: 2,
    nome: 'Inglês',
  },
  {
    codigo: 3,
    nome: 'Espanhol',
  },
];

const HeaderForm = ({
  cdpromocao,
  promocoes,
  cdproduto,
  searchCliente,
  nmdirnome,
  nmdiremail,
  cdidioma,
  cdtabprecos,
  onFieldChanged,
  handleTabelaPrecoChange,
  onSearchClick,
  isSearched,
}) => {
  const {errors, touched} = useFormikContext();
  const tabPriceOptions = useMemo(() => {
    let options = [];
    if (
      cdproduto === PRODUCT_LIGHTWEB_CODE ||
      cdproduto === PRODUCT_WEB30_CODE
    ) {
      options = tabprecosLight;
    } else if (cdproduto === PRODUCT_EASYWEB_CODE) {
      options = tabprecosEasy;
    }
    return options;
  }, [cdproduto]);

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <SearchContainer
            type="number"
            label="Código do cliente"
            text={searchCliente}
            onFieldChanged={e => {
              onFieldChanged('searchCliente', e.target.value);
            }}
            placeholder="Informe o código do cliente"
            onSearchClick={() => {
              onSearchClick();
            }}
            errorText={errors.searchCliente}
            showError={!!errors.searchCliente && !!touched.searchCliente}
          />

          {isSearched ? (
            <>
              <h4 className="header smaller lighter blue">Dados do cliente</h4>

              <InputContainer
                type="text"
                label="Nome do responsável (Diretor) *"
                showError={!!errors.nmdirnome && !!touched.nmdirnome}
                text={nmdirnome}
                onFieldChanged={e => {
                  onFieldChanged('nmdirnome', e.target.value);
                }}
                errorText={errors.nmdirnome}
              />

              <InputContainer
                type="email"
                label="Email do responsável (Diretor) *"
                showError={!!errors.nmdiremail && !!touched.nmdiremail}
                text={nmdiremail}
                onFieldChanged={e => {
                  onFieldChanged('nmdiremail', e.target.value);
                }}
                errorText={errors.nmdiremail}
              />

              <SelectContainer
                label="Idioma do cliente"
                showError={false}
                text={cdidioma}
                onFieldChanged={e => {
                  onFieldChanged('cdidioma', e.target.value);
                }}
                errorText=""
                options={idiomas}
                colWidth="4"
              />

              <SelectContainer
                label="Tabela de Preços"
                showError={false}
                text={cdtabprecos}
                onFieldChanged={e => {
                  handleTabelaPrecoChange(e.target.value);
                }}
                errorText=""
                options={tabPriceOptions}
                colWidth="4"
              />

              {/* Promoção */}
              <SelectContainer
                label="Promoção"
                showError={false}
                text={cdpromocao}
                onFieldChanged={e => {
                  onFieldChanged('cdpromocao', e.target.value);
                }}
                errorText=""
                options={promocoes}
                colWidth="4"
              />
            </>
          ) : null}
        </Col>
      </Row>
      <div className="hr hr32 hr-dotted" />
    </>
  );
};

HeaderForm.defaultProps = {
  searchCliente: '',
  nmdirnome: '',
  nmdiremail: '',
  promocoes: [],
};

HeaderForm.propTypes = {
  cdpromocao: PropTypes.string.isRequired,
  promocoes: PropTypes.arrayOf(PropTypes.shape({})),
  cdproduto: PropTypes.string.isRequired,
  searchCliente: PropTypes.string,
  nmdirnome: PropTypes.string,
  nmdiremail: PropTypes.string,
  cdidioma: PropTypes.string.isRequired,
  cdtabprecos: PropTypes.string.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  handleTabelaPrecoChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  isSearched: PropTypes.bool.isRequired,
};

export default HeaderForm;
