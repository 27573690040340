import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class ErroBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // Você também pode registrar o erro em um serviço de relatórios de erro
    const msg = `Info ${errorInfo} - Error: ${error}`;
    if (process.env.development) console.error(msg);
  }

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer UI alternativa
      return (
        <div className="error-container">
          <div className="well">
            <h1 className="grey lighter smaller text-center">
              Something Went Wrong
            </h1>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErroBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
