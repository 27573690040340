// LIGHT WEB
export const LIGHT_REAL_TOTAL_ITC = 3100.0;
export const LIGHT_REAL_GH = 0.38;
export const LIGHT_REAL_TT = 0.02;
export const LIGHT_REAL_ROLRM = 0.11;
export const LIGHT_REAL_GF = 0.13;
export const LIGHT_REAL_CE = 0.06;
export const LIGHT_REAL_CM = 0.15;
export const LIGHT_REAL_PDV = 0.15;
export const LIGHT_REAL_BI = 0.0;

export const LIGHT_REAL_MESAL_UH = 28;
export const LIGHT_REAL_SEMESTRAL_UH = 26;
export const LIGHT_REAL_ANUAL_UH = 23;

export const LIGHT_DOLAR_TOTAL_ITC = 990.0;
export const LIGHT_DOLAR_ITC_GH = 0.38;
export const LIGHT_DOLAR_ITC_TT = 0.018947368421053;
export const LIGHT_DOLAR_ITC_ROLRM = 0.110526315789474;
export const LIGHT_DOLAR_ITC_GF = 0.130526315789474;
export const LIGHT_DOLAR_ITC_CE = 0.058947368421053;
export const LIGHT_DOLAR_ITC_CM = 0.150526315789474;
export const LIGHT_DOLAR_ITC_PDV = 0.150526315789474;
export const LIGHT_DOLAR_ITC_BI = 0.0;
export const LIGHT_DOLAR_GH = 0.38;
export const LIGHT_DOLAR_TT = 0.02;
export const LIGHT_DOLAR_ROLRM = 0.11;
export const LIGHT_DOLAR_GF = 0.13;
export const LIGHT_DOLAR_CE = 0.06;
export const LIGHT_DOLAR_CM = 0.15;
export const LIGHT_DOLAR_PDV = 0.15;
export const LIGHT_DOLAR_BI = 0.0;

export const LIGHT_DOLAR_MESAL_UH = 8;
export const LIGHT_DOLAR_SEMESTRAL_UH = 7.5;
export const LIGHT_DOLAR_ANUAL_UH = 7;

export const LIGHT_EURO_TOTAL_ITC = 890.0;
export const LIGHT_EURO_GH = 0.38;
export const LIGHT_EURO_TT = 0.02;
export const LIGHT_EURO_ROLRM = 0.11;
export const LIGHT_EURO_GF = 0.13;
export const LIGHT_EURO_CE = 0.06;
export const LIGHT_EURO_CM = 0.15;
export const LIGHT_EURO_PDV = 0.15;
export const LIGHT_EURO_BI = 0.0;

export const LIGHT_EURO_MESAL_UH = 7;
export const LIGHT_EURO_SEMESTRAL_UH = 6.6;
export const LIGHT_EURO_ANUAL_UH = 6;

// EASY WEB
export const EASY_REAL_TOTAL_ITC = 990.0;
export const EASY_REAL_GH = 1.0;
export const EASY_REAL_TT = 0.0;
export const EASY_REAL_ROLRM = 0.0;
export const EASY_REAL_GF = 0.0;
export const EASY_REAL_CE = 0.0;
export const EASY_REAL_CM = 0.0;
export const EASY_REAL_PDV = 0.0;
export const EASY_REAL_BI = 0.0;

export const EASY_REAL_MESAL_UH = 8.5;
export const EASY_REAL_SEMESTRAL_UH = 8.5;
export const EASY_REAL_ANUAL_UH = 8.5;

// EASY WEB PLUS
export const EASY_PLUS_REAL_TOTAL_ITC = 2500.0;
export const EASY_PLUS_REAL_GH = 0.4;
export const EASY_PLUS_REAL_TT = 0.0;
export const EASY_PLUS_REAL_ROLRM = 0.11;
export const EASY_PLUS_REAL_GF = 0.13;
export const EASY_PLUS_REAL_CE = 0.06;
export const EASY_PLUS_REAL_CM = 0.15;
export const EASY_PLUS_REAL_PDV = 0.15;
export const EASY_PLUS_REAL_BI = 0.0;

export const EASY_PLUS_REAL_MESAL_UH = 25;
export const EASY_PLUS_REAL_SEMESTRAL_UH = 25;
export const EASY_PLUS_REAL_ANUAL_UH = 25;

export const EASY_PLUS_DOLAR_TOTAL_ITC = 990.0;
export const EASY_PLUS_DOLAR_ITC_GH = 0.38;
export const EASY_PLUS_DOLAR_ITC_TT = 0.018947368421053;
export const EASY_PLUS_DOLAR_ITC_ROLRM = 0.110526315789474;
export const EASY_PLUS_DOLAR_ITC_GF = 0.130526315789474;
export const EASY_PLUS_DOLAR_ITC_CE = 0.058947368421053;
export const EASY_PLUS_DOLAR_ITC_CM = 0.150526315789474;
export const EASY_PLUS_DOLAR_ITC_PDV = 0.150526315789474;
export const EASY_PLUS_DOLAR_ITC_BI = 0.0;
export const EASY_PLUS_DOLAR_GH = 0.38;
export const EASY_PLUS_DOLAR_TT = 0.02;
export const EASY_PLUS_DOLAR_ROLRM = 0.11;
export const EASY_PLUS_DOLAR_GF = 0.13;
export const EASY_PLUS_DOLAR_CE = 0.06;
export const EASY_PLUS_DOLAR_CM = 0.15;
export const EASY_PLUS_DOLAR_PDV = 0.15;
export const EASY_PLUS_DOLAR_BI = 0.0;

export const EASY_PLUS_DOLAR_MESAL_UH = 8;
export const EASY_PLUS_DOLAR_SEMESTRAL_UH = 7.5;
export const EASY_PLUS_DOLAR_ANUAL_UH = 7;

export const EASY_PLUS_EURO_TOTAL_ITC = 890.0;
export const EASY_PLUS_EURO_GH = 0.38;
export const EASY_PLUS_EURO_TT = 0.02;
export const EASY_PLUS_EURO_ROLRM = 0.11;
export const EASY_PLUS_EURO_GF = 0.13;
export const EASY_PLUS_EURO_CE = 0.06;
export const EASY_PLUS_EURO_CM = 0.15;
export const EASY_PLUS_EURO_PDV = 0.15;
export const EASY_PLUS_EURO_BI = 0.0;

export const EASY_PLUS_EURO_MESAL_UH = 7;
export const EASY_PLUS_EURO_SEMESTRAL_UH = 6.6;
export const EASY_PLUS_EURO_ANUAL_UH = 6;
