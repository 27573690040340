import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, FormControl, HelpBlock} from 'react-bootstrap';

class SwitchContainer extends Component {
  renderLabel = () => {
    const {label} = this.props;
    if (label) {
      return (
        <label className="col-sm-3 control-label no-padding-right">
          {label}
        </label>
      );
    }
    return null;
  };

  render() {
    const {
      showError,
      errorText,
      colWidth,
      label,
      inputId,
      checked,
      name,
      disabled,
      onFieldChanged,
    } = this.props;

    const hasError = showError && !!errorText ? 'has-error' : '';
    const colW = colWidth ? `col-sm-${colWidth}` : 'col-sm-9';
    const hasLabelClass = label ? colW : '';
    const id = inputId || undefined;

    return (
      <FormGroup className={hasError}>
        {this.renderLabel()}

        <div className={hasLabelClass}>
          <label>
            <FormControl
              id={id}
              name={name}
              className="ace ace-switch ace-switch-5"
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onChange={e => {
                onFieldChanged(e);
              }}
            />
            <span className="lbl" />
          </label>
          {showError && errorText !== '' ? (
            <HelpBlock>{errorText}</HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

SwitchContainer.defaultProps = {
  label: '',
  errorText: '',
  colWidth: '',
  inputId: '',
  disabled: false,
};

SwitchContainer.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  colWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputId: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SwitchContainer;
