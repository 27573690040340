export const ITC_TEF = 500;
export const getItcTef = () => ITC_TEF;

export const getLmTef = qtd => {
  if (qtd === 1) {
    return 195;
  }
  if (qtd === 2) {
    return 295;
  }
  if (qtd === 3) {
    return 395;
  }
  if (qtd >= 4) {
    const diff = qtd - 3;
    return 395 + diff * 65;
  }
  return 0;
};
