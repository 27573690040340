import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
  closeButton: true,
  debug: true,
  progressBar: true,
  preventDuplicates: true,
  positionClass: 'toast-bottom-right',
  onclick: null,
};

const functions = {
  success(message, title = '') {
    toastr.success(message, title);
  },
  info(message, title = '') {
    toastr.info(message, title);
  },
  warning(message, title = '') {
    toastr.warning(message, title);
  },
  error(message, title = '') {
    toastr.error(message, title);
  },
};

export default functions;
