import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Well} from 'react-bootstrap';

import AceSpinner from 'components/aceSpinner';
import InputDecimal from 'components/form/inputDecimal';
import {getManutencao} from 'helpers/dslFunctions';

const AdicaoUh = ({
  cdnroaptosAdd,
  cdnroaptos,
  fgtipomanut,
  cdidioma,
  valortotaladicao,
  onSpinnerChangedUhs,
  handleChangeValorTotalAdicao,
  onBlurAdicaoUh,
}) => (
  <>
    <Row>
      <Col xs={12}>
        <h4 className="header smaller lighter blue">
          Quantas UHs deseja adicionar?
        </h4>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={2}>
        <AceSpinner
          value={cdnroaptosAdd}
          onChange={value => {
            onSpinnerChangedUhs(value);
          }}
        />
      </Col>
      <Col xs={12} md={10}>
        <Well>
          <div>
            <h5 className="">
              Total de UHs:
              <span className="blue bolder">
                {parseInt(cdnroaptos, 10) + parseInt(cdnroaptosAdd, 10)}
              </span>
            </h5>
          </div>

          <div>
            <h5 className="">
              <span>Acrescenta-se o valor:&nbsp;</span>
              <span className="blue bolder">
                {getManutencao(fgtipomanut, cdidioma)}
              </span>
            </h5>
            <Row>
              <Col xs={12} md={4}>
                <InputDecimal
                  value={valortotaladicao}
                  onValueChange={value => {
                    handleChangeValorTotalAdicao(value);
                  }}
                  onBlur={() => {
                    onBlurAdicaoUh();
                  }}
                />
              </Col>
            </Row>
          </div>
        </Well>
      </Col>
    </Row>
  </>
);

AdicaoUh.defaultProps = {
  cdnroaptosAdd: null,
  cdnroaptos: null,
  fgtipomanut: null,
  valortotaladicao: null,
};

AdicaoUh.propTypes = {
  cdnroaptosAdd: PropTypes.number,
  cdnroaptos: PropTypes.number,
  fgtipomanut: PropTypes.number,
  cdidioma: PropTypes.string.isRequired,
  valortotaladicao: PropTypes.number,
  handleChangeValorTotalAdicao: PropTypes.func.isRequired,
  onSpinnerChangedUhs: PropTypes.func.isRequired,
  onBlurAdicaoUh: PropTypes.func.isRequired,
};

export default AdicaoUh;
