import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Alert} from 'components/alert';

const Modalidade = ({fgmodalidademanut, onModalidadeChange}) => (
  <Row>
    <Col xs={12}>
      <h4 className="header smaller lighter blue">
        Qual a modalidade de implantação seu hotel precisa?
      </h4>

      <Alert>
        <strong>Lembrar!&nbsp;</strong>
        <span>Suporte apenas em horário comercial.</span>
      </Alert>
    </Col>

    <Col sm={12} lg={6} md={6}>
      <div className="radio">
        <label style={{paddingLeft: '10px'}}>
          <input
            name="fgmodalidademanut"
            value="1"
            type="radio"
            className="ace"
            checked={parseInt(fgmodalidademanut, 10) === 1}
            onChange={() => {
              onModalidadeChange(1);
            }}
          />
          <span className="lbl">
            &nbsp;ITC Remoto com 16 horas de consultoria on-line
          </span>
        </label>
      </div>
      <div className="">
        <p className="text-muted align-justify">
          8h/dia somente em horário comercial de segunda a sexta.
        </p>
      </div>
    </Col>

    <Col sm={12} lg={6} md={6}>
      <div className="radio">
        <label style={{paddingLeft: '10px'}}>
          <input
            name="fgmodalidademanut"
            value="0"
            type="radio"
            className="ace"
            checked={parseInt(fgmodalidademanut, 10) === 0}
            onChange={() => {
              onModalidadeChange(0);
            }}
          />
          <span className="lbl">&nbsp;ITC Local</span>
        </label>
      </div>
      <div className="">
        <p className="text-muted align-justify">
          Acrescer 160,00 por dia + deslocamento + alimentação + estadia
        </p>
      </div>
    </Col>
  </Row>
);

Modalidade.defaultProps = {
  fgmodalidademanut: null,
};

Modalidade.propTypes = {
  fgmodalidademanut: PropTypes.number,
  onModalidadeChange: PropTypes.func.isRequired,
};

export default Modalidade;
