import React from 'react';
import PropTypes from 'prop-types';

import ErroBoundary from 'components/errorBoundary';

const LayoutCliente = ({children}) => (
  <ErroBoundary>
    <div id="layout-cliente" className="layout-ace">
      <div className="main-container" id="main-container">
        <div className="main-content">
          <div className="main-content-inner">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </div>
  </ErroBoundary>
);

LayoutCliente.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutCliente;
