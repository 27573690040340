import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import Pricing from 'components/pricing';
import {isLightWeb, isWeb30} from 'helpers/tabPrice';

const PriceTable = ({
  cdproduto,
  moeda,
  fgtipomanut,
  ITC,
  LS,
  LM,
  LA,
  onChangeTotal,
  verificaValorValido,
  onChangeLicenciamento,
}) => {
  const isLight = useMemo(
    () => isLightWeb(cdproduto) || isWeb30(cdproduto),
    [cdproduto],
  );

  return (
    <div>
      <Pricing
        color="red3"
        btn="danger"
        title="ITC"
        selected
        handleLicenciamento={() => {}}
        GH={ITC.GH}
        TT={ITC.TT}
        ROLRM={ITC.ROLRM}
        GF={ITC.GF}
        CE={ITC.CE}
        CM={ITC.CM}
        PDV={ITC.PDV}
        BI={ITC.BI}
        mensal={false}
        moeda={moeda}
        TOTAL={ITC.TOTAL}
        onChangeTotal={value => {
          onChangeTotal('ITC', value);
        }}
        onBlurTotal={value => {
          verificaValorValido('ITC', value);
        }}
      />

      <Pricing
        color="orange"
        btn="warning"
        title="LM"
        selected={fgtipomanut === 0}
        handleLicenciamento={() => {
          onChangeLicenciamento('LM');
        }}
        GH={LM.GH}
        TT={LM.TT}
        ROLRM={LM.ROLRM}
        GF={LM.GF}
        CE={LM.CE}
        CM={LM.CM}
        PDV={LM.PDV}
        BI={LM.BI}
        moeda={moeda}
        TOTAL={LM.TOTAL}
        onChangeTotal={value => {
          onChangeTotal('LM', value);
        }}
        onBlurTotal={value => {
          verificaValorValido('LM', value);
        }}
      />

      {isLight ? (
        <Pricing
          color="blue"
          btn="info"
          title="LS"
          selected={fgtipomanut === 1}
          handleLicenciamento={() => {
            onChangeLicenciamento('LS');
          }}
          GH={LS.GH}
          TT={LS.TT}
          ROLRM={LS.ROLRM}
          GF={LS.GF}
          CE={LS.CE}
          CM={LS.CM}
          PDV={LS.PDV}
          BI={LS.BI}
          moeda={moeda}
          TOTAL={LS.TOTAL}
          onChangeTotal={value => {
            onChangeTotal('LS', value);
          }}
          onBlurTotal={value => {
            verificaValorValido('LS', value);
          }}
        />
      ) : null}

      {isLight ? (
        <Pricing
          color="green"
          btn="success"
          title="LA"
          selected={fgtipomanut === 2}
          handleLicenciamento={() => {
            onChangeLicenciamento('LA');
          }}
          GH={LA.GH}
          TT={LA.TT}
          ROLRM={LA.ROLRM}
          GF={LA.GF}
          CE={LA.CE}
          CM={LA.CM}
          PDV={LA.PDV}
          BI={LA.BI}
          moeda={moeda}
          TOTAL={LA.TOTAL}
          onChangeTotal={value => {
            onChangeTotal('LA', value);
          }}
          onBlurTotal={value => {
            verificaValorValido('LA', value);
          }}
        />
      ) : null}
    </div>
  );
};

PriceTable.defaultProps = {
  fgtipomanut: null,
};

const objType = {
  GH: PropTypes.number,
  TT: PropTypes.number,
  ROLRM: PropTypes.number,
  GF: PropTypes.number,
  CE: PropTypes.number,
  CM: PropTypes.number,
  PDV: PropTypes.number,
  BI: PropTypes.number,
  TOTAL: PropTypes.number,
};

PriceTable.propTypes = {
  cdproduto: PropTypes.string.isRequired,
  moeda: PropTypes.string.isRequired,
  fgtipomanut: PropTypes.number,
  ITC: PropTypes.shape(objType).isRequired,
  LS: PropTypes.shape(objType).isRequired,
  LM: PropTypes.shape(objType).isRequired,
  LA: PropTypes.shape(objType).isRequired,
  onChangeTotal: PropTypes.func.isRequired,
  verificaValorValido: PropTypes.func.isRequired,
  onChangeLicenciamento: PropTypes.func.isRequired,
};

export default PriceTable;
