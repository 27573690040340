import {
  TAB_PRECO_LIGHTWEB_REAL,
  TAB_PRECO_LIGHTWEB_DOLAR,
  TAB_PRECO_LIGHTWEB_EURO,
} from 'helpers/tabPrice';
import {round} from '../calcRounding';
import {
  LIGHT_REAL_BI,
  LIGHT_REAL_CE,
  LIGHT_REAL_CM,
  LIGHT_REAL_PDV,
  LIGHT_REAL_GF,
  LIGHT_REAL_GH,
  LIGHT_REAL_ROLRM,
  LIGHT_REAL_TT,
  LIGHT_REAL_MESAL_UH,
  LIGHT_REAL_SEMESTRAL_UH,
  LIGHT_REAL_ANUAL_UH,
  LIGHT_DOLAR_BI,
  LIGHT_DOLAR_CE,
  LIGHT_DOLAR_CM,
  LIGHT_DOLAR_PDV,
  LIGHT_DOLAR_GF,
  LIGHT_DOLAR_GH,
  LIGHT_DOLAR_ROLRM,
  LIGHT_DOLAR_TT,
  LIGHT_DOLAR_MESAL_UH,
  LIGHT_DOLAR_SEMESTRAL_UH,
  LIGHT_DOLAR_ANUAL_UH,
  LIGHT_EURO_BI,
  LIGHT_EURO_CE,
  LIGHT_EURO_CM,
  LIGHT_EURO_PDV,
  LIGHT_EURO_GF,
  LIGHT_EURO_GH,
  LIGHT_EURO_ROLRM,
  LIGHT_EURO_TT,
  LIGHT_EURO_MESAL_UH,
  LIGHT_EURO_SEMESTRAL_UH,
  LIGHT_EURO_ANUAL_UH,
} from '../values';
import {
  getQtdUserReal,
  calculaValorTabelaMensalReal,
  calculaValorTabelaSemestralReal,
  calculaValorTabelaAnualReal,
} from './calcReal';
import {
  getQtdUserDolar,
  calculaValorTabelaMensalDolar,
  calculaValorTabelaSemestralDolar,
  calculaValorTabelaAnualDolar,
} from './calcDolar';
import {
  getQtdUserEuro,
  calculaValorTabelaMensalEuro,
  calculaValorTabelaSemestralEuro,
  calculaValorTabelaAnualEuro,
} from './calcEuro';

export const calcAdicaoUser = (
  qtdUh,
  qtdUser,
  qtdUserToAdd,
  licenciamento,
  modulos,
  cdtabprecos,
) => {
  if (licenciamento === 0) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_LIGHTWEB_REAL) {
      calc1 = calculaValorTabelaMensalReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaMensalReal(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_DOLAR) {
      calc1 = calculaValorTabelaMensalDolar(qtdUh, qtdUser);
      calc2 = calculaValorTabelaMensalDolar(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_EURO) {
      calc1 = calculaValorTabelaMensalEuro(qtdUh, qtdUser);
      calc2 = calculaValorTabelaMensalEuro(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LM_GH;
          totalCalc2 += calc2.LM_GH;
          break;
        case '801':
          totalCalc1 += calc1.LM_TT;
          totalCalc2 += calc2.LM_TT;
          break;
        case '802':
          totalCalc1 += calc1.LM_ROLRM;
          totalCalc2 += calc2.LM_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LM_CE;
          totalCalc2 += calc2.LM_CE;
          break;
        case '805':
          totalCalc1 += calc1.LM_GF;
          totalCalc2 += calc2.LM_GF;
          break;
        case '806':
          totalCalc1 += calc1.LM_CM;
          totalCalc2 += calc2.LM_CM;
          break;
        case '506':
          totalCalc1 += calc1.LM_BI;
          totalCalc2 += calc2.LM_BI;
          break;
        case '808':
          totalCalc1 += calc1.LM_PDV;
          totalCalc2 += calc2.LM_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round(totalCalc2 - totalCalc1);
  }

  if (licenciamento === 1) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_LIGHTWEB_REAL) {
      calc1 = calculaValorTabelaSemestralReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaSemestralReal(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_DOLAR) {
      calc1 = calculaValorTabelaSemestralDolar(qtdUh, qtdUser);
      calc2 = calculaValorTabelaSemestralDolar(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_EURO) {
      calc1 = calculaValorTabelaSemestralEuro(qtdUh, qtdUser);
      calc2 = calculaValorTabelaSemestralEuro(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LS_GH;
          totalCalc2 += calc2.LS_GH;
          break;
        case '801':
          totalCalc1 += calc1.LS_TT;
          totalCalc2 += calc2.LS_TT;
          break;
        case '802':
          totalCalc1 += calc1.LS_ROLRM;
          totalCalc2 += calc2.LS_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LS_CE;
          totalCalc2 += calc2.LS_CE;
          break;
        case '805':
          totalCalc1 += calc1.LS_GF;
          totalCalc2 += calc2.LS_GF;
          break;
        case '806':
          totalCalc1 += calc1.LS_CM;
          totalCalc2 += calc2.LS_CM;
          break;
        case '506':
          totalCalc1 += calc1.LS_BI;
          totalCalc2 += calc2.LS_BI;
          break;
        case '808':
          totalCalc1 += calc1.LS_PDV;
          totalCalc2 += calc2.LS_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round((totalCalc2 - totalCalc1) * 6);
  }

  if (licenciamento === 2) {
    let calc1;
    let calc2;

    if (cdtabprecos === TAB_PRECO_LIGHTWEB_REAL) {
      calc1 = calculaValorTabelaAnualReal(qtdUh, qtdUser);
      calc2 = calculaValorTabelaAnualReal(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_DOLAR) {
      calc1 = calculaValorTabelaAnualDolar(qtdUh, qtdUser);
      calc2 = calculaValorTabelaAnualDolar(qtdUh, qtdUser + qtdUserToAdd);
    }
    if (cdtabprecos === TAB_PRECO_LIGHTWEB_EURO) {
      calc1 = calculaValorTabelaAnualEuro(qtdUh, qtdUser);
      calc2 = calculaValorTabelaAnualEuro(qtdUh, qtdUser + qtdUserToAdd);
    }

    let totalCalc1 = 0;
    let totalCalc2 = 0;

    modulos.forEach(m => {
      switch (m) {
        case '800':
          totalCalc1 += calc1.LA_GH;
          totalCalc2 += calc2.LA_GH;
          break;
        case '801':
          totalCalc1 += calc1.LA_TT;
          totalCalc2 += calc2.LA_TT;
          break;
        case '802':
          totalCalc1 += calc1.LA_ROLRM;
          totalCalc2 += calc2.LA_ROLRM;
          break;
        case '804':
          totalCalc1 += calc1.LA_CE;
          totalCalc2 += calc2.LA_CE;
          break;
        case '805':
          totalCalc1 += calc1.LA_GF;
          totalCalc2 += calc2.LA_GF;
          break;
        case '806':
          totalCalc1 += calc1.LA_CM;
          totalCalc2 += calc2.LA_CM;
          break;
        case '506':
          totalCalc1 += calc1.LA_BI;
          totalCalc2 += calc2.LA_BI;
          break;
        case '808':
          totalCalc1 += calc1.LA_PDV;
          totalCalc2 += calc2.LA_PDV;
          break;
        default:
          totalCalc1 += 0;
          totalCalc2 += 0;
      }
    });

    return round((totalCalc2 - totalCalc1) * 12);
  }
  return null;
};

export const calcAdicaoUh = (
  qtdUhToAdd,
  qtdUser,
  licenciamento,
  modulos,
  cdtabprecos,
) => {
  let valorTotal = 0;
  if (cdtabprecos === TAB_PRECO_LIGHTWEB_REAL) {
    const valorUsuario = getQtdUserReal(qtdUser);

    let valorTotalPorUh;
    if (licenciamento === 0) {
      valorTotalPorUh = LIGHT_REAL_MESAL_UH + valorUsuario;
    } else if (licenciamento === 1) {
      valorTotalPorUh = LIGHT_REAL_SEMESTRAL_UH + valorUsuario;
    } else {
      valorTotalPorUh = LIGHT_REAL_ANUAL_UH + valorUsuario;
    }

    const valorGH = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_GH));
    const valorTT = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_TT));
    const valorCE = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_CE));
    const valorGF = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_GF));
    const valorCM = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_CM));
    const valorPDV = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_PDV));
    const valorBI = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_BI));
    const valorROLRM = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_REAL_ROLRM));

    const mapModulos = new Map();
    mapModulos.set('800', valorGH);
    mapModulos.set('801', valorTT);
    mapModulos.set('802', valorROLRM);
    mapModulos.set('803', 0);
    mapModulos.set('804', valorCE);
    mapModulos.set('805', valorGF);
    mapModulos.set('806', valorCM);
    mapModulos.set('808', valorPDV);
    mapModulos.set('506', valorBI);

    Array.from(mapModulos.keys()).forEach(item => {
      if (modulos.includes(item)) {
        valorTotal += mapModulos.get(item);
      }
    });
  }

  if (cdtabprecos === TAB_PRECO_LIGHTWEB_DOLAR) {
    const valorUsuario = getQtdUserDolar(qtdUser);

    let valorTotalPorUh;
    if (licenciamento === 0) {
      valorTotalPorUh = LIGHT_DOLAR_MESAL_UH + valorUsuario;
    } else if (licenciamento === 1) {
      valorTotalPorUh = LIGHT_DOLAR_SEMESTRAL_UH + valorUsuario;
    } else {
      valorTotalPorUh = LIGHT_DOLAR_ANUAL_UH + valorUsuario;
    }

    const valorGH = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_GH));
    const valorTT = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_TT));
    const valorCE = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_CE));
    const valorGF = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_GF));
    const valorCM = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_CM));
    const valorPDV = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_PDV));
    const valorBI = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_BI));
    const valorROLRM = round(
      qtdUhToAdd * (valorTotalPorUh * LIGHT_DOLAR_ROLRM),
    );

    const mapModulos = new Map();
    mapModulos.set('800', valorGH);
    mapModulos.set('801', valorTT);
    mapModulos.set('802', valorROLRM);
    mapModulos.set('803', 0);
    mapModulos.set('804', valorCE);
    mapModulos.set('805', valorGF);
    mapModulos.set('806', valorCM);
    mapModulos.set('808', valorPDV);
    mapModulos.set('506', valorBI);

    Array.from(mapModulos.keys()).forEach(item => {
      if (modulos.includes(item)) {
        valorTotal += mapModulos.get(item);
      }
    });
  }

  if (cdtabprecos === TAB_PRECO_LIGHTWEB_EURO) {
    const valorUsuario = getQtdUserEuro(qtdUser);

    let valorTotalPorUh;
    if (licenciamento === 0) {
      valorTotalPorUh = LIGHT_EURO_MESAL_UH + valorUsuario;
    } else if (licenciamento === 1) {
      valorTotalPorUh = LIGHT_EURO_SEMESTRAL_UH + valorUsuario;
    } else {
      valorTotalPorUh = LIGHT_EURO_ANUAL_UH + valorUsuario;
    }

    const valorGH = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_GH));
    const valorTT = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_TT));
    const valorCE = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_CE));
    const valorGF = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_GF));
    const valorCM = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_CM));
    const valorPDV = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_PDV));
    const valorBI = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_BI));
    const valorROLRM = round(qtdUhToAdd * (valorTotalPorUh * LIGHT_EURO_ROLRM));

    const mapModulos = new Map();
    mapModulos.set('800', valorGH);
    mapModulos.set('801', valorTT);
    mapModulos.set('802', valorROLRM);
    mapModulos.set('803', 0);
    mapModulos.set('804', valorCE);
    mapModulos.set('805', valorGF);
    mapModulos.set('806', valorCM);
    mapModulos.set('808', valorPDV);
    mapModulos.set('506', valorBI);

    Array.from(mapModulos.keys()).forEach(item => {
      if (modulos.includes(item)) {
        valorTotal += mapModulos.get(item);
      }
    });
  }

  if (licenciamento === 1) {
    return valorTotal * 6;
  }
  if (licenciamento === 2) {
    return valorTotal * 12;
  }
  return valorTotal;
};
