import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const SubHeaderPage = props => {
  const {nome} = useSelector(reduxState => reduxState.auth);
  return (
    <div>
      <div className="page-header">
        <h1 className="center">
          <span>Olá!</span>
          <span>&nbsp;</span>
          <b>{nome}</b>
          <br />
          <small>
            <i className="ace-icon fa fa-angle-double-right" />
            {props.text}
          </small>
        </h1>
      </div>
    </div>
  );
};

SubHeaderPage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubHeaderPage;
