import accounting from 'accounting';

const TXT_1_PT = 'A Licenciada reconhece neste ato';
const TXT_2_PT = 'vouchers';
const TXT_3_PT = 'o valor de';
const TXT_4_PT =
  'decorrentes de permuta realizada em contrato anterior, pela qual, manifesta pela manutenção destes, mantendo-os exercitável a qualquer tempo.';

const TXT_1_ES = 'El Licenciatario reconoce en este acto';
const TXT_2_ES = 'comprobantes';
const TXT_3_ES = 'el valor de';
const TXT_4_ES =
  ' decurrentes de un canje realizado en un contrato anterior, por el cual, expresa el mantenimiento de la carta de crédito, manteniéndola ejercitable en todo tiempo.';

const TXT_1_EN = 'The Licensee hereby recognizes';
const TXT_2_EN = 'vouchers';
const TXT_3_EN = 'the value of';
const TXT_4_EN =
  'arising from an exchange carried out in a previous contract, by which, it expresses the maintenance of the letter of credit, keeping it exercisable at any time.';

export const OBS_PT = `a) em caso de término do contrato por qualquer causa, o pagamento das importâncias devidas convencionadas em permuta far-se-á mediante conversão em prestação pecuniária do valor permutado ou seu remanescente, se parcial. Da mesma forma, ocorrerá conversão em prestação pecuniária a recusa em providenciar hospedagem mediante utilização das permutas convencionadas como meio de pagamento.\nb) a prestação pecuniária corresponderá ao valor permutado constante no contrato, independente do preço da diária no momento da sua formação ou do seu término, para pagamento em até 07 (sete) dias, contados da recusa/solicitação de cancelamento.\nc) sobre o valor convertido em prestação pecuniária incidirá atualização monetária, consoante o índice pactuado para cada uma das renovações, até seu efetivo pagamento.\n`;

export const OBS_ES = `En caso de término del contrato por cualquier causa:\na) el pago de los importes debidos y convenidos en canje, hacérsela mediante conversión en prestación pecuniaria por el valor permutado o su remanente, si es parcial.  De la misma forma, habrá conversión en prestación pecuniaria el rechazo en providenciar el hospedaje por medio de las permutas convenidas como medio de pago.\nb) la prestación pecuniaria corresponderá al valor constante del Contrato, independientemente del valor de los alojamientos del hotel en el momento de su formación o de su extinción espontánea/rescisión.\nc) sobre el valor convencionalizado se aplicará actualización monetaria, tocante al índice contractual acordado para cada una de las renovaciones hasta su efectivo pago.\n`;

const getCurrencySymbol = currency => {
  if (currency !== '0') {
    return '$';
  }
  return 'R$';
};

export const makePermutaText = (permutas = {}, lng = 1) => {
  if (permutas) {
    if (permutas.total > 0) {
      if (parseInt(lng) === 3) {
        return `${TXT_1_EN} ${
          permutas.tipo === '0'
            ? `${permutas.total} ${TXT_2_EN}`
            : `${TXT_3_EN} ${accounting.formatMoney(
                permutas.total,
                getCurrencySymbol(permutas.moeda),
                2,
                ',',
                '.',
              )}`
        } ${TXT_4_EN}`;
      }
      if (parseInt(lng) === 2) {
        return `${TXT_1_ES} ${
          permutas.tipo === '0'
            ? `${permutas.total} ${TXT_2_ES}`
            : `${TXT_3_ES} ${accounting.formatMoney(
                permutas.total,
                getCurrencySymbol(permutas.moeda),
                2,
                ',',
                '.',
              )}`
        } ${TXT_4_ES}`;
      }
      return `${TXT_1_PT} ${
        permutas.tipo === '0'
          ? `${permutas.total} ${TXT_2_PT}`
          : `${TXT_3_PT} ${accounting.formatMoney(
              permutas.total,
              getCurrencySymbol(permutas.moeda),
              2,
              ',',
              '.',
            )}`
      } ${TXT_4_PT}`;
    }
    return '';
  }
  return '';
};
