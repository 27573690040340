import {TAB_PRECO_EASYWEB_PLUS_REAL} from 'helpers/tabPrice';

export const getITC = cdtabprecos => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
    return 350;
  }
  return 300;
};

export const getLM = (uhs, cdtabprecos) => {
  if (cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL) {
    if (uhs <= 20) {
      return 55;
    }
    if (uhs > 20 && uhs <= 30) {
      return 85;
    }
    if (uhs > 30 && uhs <= 40) {
      return 125;
    }
    if (uhs > 40) {
      return 165;
    }
    return 0;
  }
  if (uhs <= 20) {
    return 45;
  }
  if (uhs > 20 && uhs <= 30) {
    return 95;
  }
  if (uhs > 30 && uhs <= 40) {
    return 145;
  }
  if (uhs > 40) {
    return 195;
  }
  return 0;
};
