import _ from 'lodash';

function getRegiao(regiaocliente) {
  const myMap = new Map();
  myMap.set('98', 'DSL - Cordilheira');
  myMap.set('7', 'SIAH - NATAL DESATIVADA');
  myMap.set('14', 'Blade Brasil - Solucoes em TI');
  myMap.set('15', 'Delamar - Junior');
  myMap.set('22', 'InfoFamily Comercio e Servicos');
  myMap.set('29', 'Anderson Marques Ferreira');
  myMap.set('19', 'Tecmasul - Comercio e Servicos Ltda');
  myMap.set('23', 'Marcelo Figueiredo');
  myMap.set('26', 'Lagos Software & Cyber Evolution');
  myMap.set('99', 'DSL - Chapeco');
  myMap.set('3', 'Logica Tecnologia - Natal');
  myMap.set('16', 'Action Tecnologia');
  myMap.set('17', 'Group Tecnologia Digital');
  myMap.set('18', 'All-Tec Business Ltda');
  myMap.set('12', 'Allianza - Florianopolis');
  myMap.set('13', 'DSL - Argentina');
  myMap.set('9', 'Logica Tecnologia - Fortaleza');
  myMap.set('8', 'Ideale - Brasilia');
  myMap.set('11', 'Logica Tecnologia - Maceio');
  myMap.set('31', 'Onetrix Gestao em Tecnologia da Informacao');
  myMap.set('28', 'TDA Informatica Ltda');
  myMap.set('2', 'Allianza - Porto Alegre');
  myMap.set('1', 'Ledur Tecnologia - Foz do Iguacu');
  myMap.set('30', 'MJ Technology');
  myMap.set('4', 'Allianza - Curitiba');
  myMap.set('21', 'DSL - Paraguai');
  myMap.set('25', 'Suportville');
  myMap.set('32', 'Camara de Dirigentes Lojistas de Chapeco');
  myMap.set('33', '2Bits Servicos de TI Ltda');
  myMap.set('10', 'Ieja Bahia - Salvador');
  myMap.set('27', 'Valdinei Fernandes Maciel');
  myMap.set('24', 'DSL - Florianopolis');
  myMap.set('5', 'DSL - Sao Paulo');
  myMap.set('20', 'DSL - Foz do Iguacu');

  return myMap.get(regiaocliente);
}

function getManutencao(manutencao, idioma) {
  const myMap = new Map();

  const lng = parseInt(idioma, 10);

  switch (lng) {
    case 3:
      myMap.set(0, 'Mensual');
      myMap.set(1, 'Semestral');
      myMap.set(2, 'Anual');

      break;
    case 2:
      myMap.set(0, 'Monthly');
      myMap.set(1, 'Semester');
      myMap.set(2, 'Yearly');

      break;
    default:
      myMap.set(0, 'Mensal');
      myMap.set(1, 'Semestral');
      myMap.set(2, 'Anual');
  }

  return myMap.get(parseInt(manutencao, 10));
}

function getITC(itc, idioma) {
  const myMap = new Map();

  const lng = parseInt(idioma, 10);

  switch (lng) {
    case 3:
      myMap.set('1', 'Instalación, formación y configuración - remoto');
      myMap.set('0', 'Instalación, formación y configuración - Local');

      break;
    case 2:
      myMap.set('1', 'Installation, Training and Configuration - Remote');
      myMap.set('0', 'Installation, Training and Configuration - Local');

      break;
    default:
      myMap.set('1', 'Instalação, Treinamento e Configuração - Remoto');
      myMap.set('0', 'Instalação, Treinamento e Configuração - Local');
  }

  return myMap.get(itc);
}

function getModulosString(modulos, idioma) {
  const myMap = new Map();

  const lng = parseInt(idioma, 10);

  switch (lng) {
    case 3:
      myMap.set('800', 'Gestión Hotelera');
      myMap.set('801', 'Tarifador');
      myMap.set('802', 'Reservas On Line');
      myMap.set('803', 'Revenue Management');
      myMap.set('804', 'Control de Stock');
      myMap.set('805', 'Gestión Financiera');
      myMap.set('806', 'Channel Manager');
      myMap.set('808', 'PDV + Monitor + iPDV');
      myMap.set('506', 'Business Intelligence');
      myMap.set('598', 'iService');

      break;
    case 2:
      myMap.set('800', 'Hotel Management');
      myMap.set('801', 'Tariff');
      myMap.set('802', 'Online Booking');
      myMap.set('803', 'Revenue Management');
      myMap.set('804', 'Inventory control');
      myMap.set('805', 'Financial Management');
      myMap.set('806', 'Channel Manager');
      myMap.set('808', 'PDV + Monitor + iPDV');
      myMap.set('506', 'Business Intelligence');
      myMap.set('598', 'iService');

      break;
    default:
      myMap.set('800', 'Gerencia Hoteleira');
      myMap.set('801', 'Tarifador');
      myMap.set('802', 'Reservas On Line');
      myMap.set('803', 'Revenue Management');
      myMap.set('804', 'Controle de Estoque');
      myMap.set('805', 'Gerencia Financeira');
      myMap.set('806', 'Channel Manager');
      myMap.set('808', 'PDV + Monitor + iPDV');
      myMap.set('506', 'Business Intelligence');
      myMap.set('598', 'iService');
  }

  // STORAGE
  myMap.set('1140', 'Armazenamento Anexos - Familia WEB - 10 GB');
  myMap.set('1141', 'Armazenamento Anexos - Familia WEB - 30 GB');
  myMap.set('1142', 'Armazenamento Anexos - Familia WEB - 50 GB');

  // TEF
  myMap.set('593', 'TEF');

  // CHANNELS
  myMap.set('515', 'OMNIBEES');
  myMap.set('510', 'Synxis');
  myMap.set('524', 'B2B');
  myMap.set('536', 'HSYSTEM');
  myMap.set('522', 'Decolar');
  myMap.set('520', 'TravelClick');
  myMap.set('514', 'Booking');
  myMap.set('511', 'CM Reservas');
  myMap.set('532', 'CVC');
  myMap.set('517', 'Expedia');
  myMap.set('533', 'Siteminder');
  myMap.set('1108', 'CHANNEX - AIRBNB - HOSTELWORLD - HOTEL BEDS');
  myMap.set('526', 'THN');
  myMap.set('518', 'ROL - Integração');

  // INTEGRATIONS
  myMap.set('1148', 'AskSuite - Reserva por Internet');
  myMap.set('505', 'Armazenamento NF-e');
  myMap.set('1100', 'HSPOT - Gerenciamento de Internet');
  myMap.set('1101', 'PMWEB - Motor Reservas');
  myMap.set('1103', 'CLIMBER – RM');
  myMap.set('1104', 'Nonius');
  myMap.set('1105', 'myHotel');
  myMap.set('1109', 'HIJI FFY - Portugal');
  myMap.set('1110', 'Economy Software - Pré Check in');
  myMap.set('1111', 'Vega IT - Internet / Pré checkin / Extrato');
  myMap.set('1112', 'Flutua - Analise de Ocupação');
  myMap.set('1113', 'Totem - Self Checkin /Checkout c/ TEF');
  myMap.set('1114', 'Totem - Self Checkin /Checkout s/ TEF');
  myMap.set('1115', 'RB Analitica - IT Cacau – RM');
  myMap.set('1116', 'Busque Seguros');
  myMap.set('1117', 'Rezerve - Motor Noronha');
  myMap.set('1118', 'R3SS - Internet / CRM / Pré Check-in');
  myMap.set('1122', 'GUIDE 121 - Interação com Hospede');
  myMap.set('1123', 'BeBook');
  myMap.set('1130', 'Upnology - UPGRADE de Reservas');
  myMap.set('1131', 'Amplifique.me - Pesquisa de Satisfação');
  myMap.set('1132', 'Motor VOA Hotéis');
  myMap.set('1147', 'RDStation - CRM');
  myMap.set('1158', 'Evolutix');

  const modulosString = [];

  _.forEach(modulos, value => {
    modulosString.push(myMap.get(value));
  });

  return modulosString;
}

function getEstados() {
  return [
    {
      codigo: '',
      nome: 'Selecione',
    },
    {
      codigo: 'AC',
      nome: 'Acre',
    },
    {
      codigo: 'AL',
      nome: 'Alagoas',
    },
    {
      codigo: 'AP',
      nome: 'Amapá',
    },
    {
      codigo: 'AM',
      nome: 'Amazonas',
    },
    {
      codigo: 'BA',
      nome: 'Bahia',
    },
    {
      codigo: 'CE',
      nome: 'Ceará',
    },
    {
      codigo: 'DF',
      nome: 'Distrito Federal',
    },
    {
      codigo: 'ES',
      nome: 'Espirito Santo',
    },
    {
      codigo: 'GO',
      nome: 'Goiás',
    },
    {
      codigo: 'MA',
      nome: 'Maranhão',
    },
    {
      codigo: 'MT',
      nome: 'Mato Grosso',
    },
    {
      codigo: 'MS',
      nome: 'Mato Grosso do Sul',
    },
    {
      codigo: 'MG',
      nome: 'Minas Gerais',
    },
    {
      codigo: 'PA',
      nome: 'Pará',
    },
    {
      codigo: 'PB',
      nome: 'Paraiba',
    },
    {
      codigo: 'PR',
      nome: 'Paraná',
    },
    {
      codigo: 'PE',
      nome: 'Pernambuco',
    },
    {
      codigo: 'PI',
      nome: 'Piauí',
    },
    {
      codigo: 'RJ',
      nome: 'Rio de Janeiro',
    },
    {
      codigo: 'RN',
      nome: 'Rio Grande do Norte<',
    },
    {
      codigo: 'RS',
      nome: 'Rio Grande do Sul',
    },
    {
      codigo: 'RO',
      nome: 'Rondônia',
    },
    {
      codigo: 'RR',
      nome: 'Roraima',
    },
    {
      codigo: 'SC',
      nome: 'Santa Catarina',
    },
    {
      codigo: 'SP',
      nome: 'São Paulo',
    },
    {
      codigo: 'SE',
      nome: 'Sergipe',
    },
    {
      codigo: 'TO',
      nome: 'Tocantis',
    },
  ];
}

function getPaises() {
  return [
    {
      codigo: '',
      nome: 'Selecione',
    },
    {
      codigo: 'Brasil',
      nome: 'Brasil',
    },
    {
      codigo: 'Argentina',
      nome: 'Argentina',
    },
    {
      codigo: 'Paraguai',
      nome: 'Paraguai',
    },
    {
      codigo: 'Uruguai',
      nome: 'Uruguai',
    },
    {
      codigo: 'Bolivia',
      nome: 'Bolivia',
    },
    {
      codigo: 'Estados Unidos',
      nome: 'Estados Unidos',
    },
    {
      codigo: 'Chile',
      nome: 'Chile',
    },

    {
      codigo: 'Italia',
      nome: 'Italia',
    },
    {
      codigo: 'Peru',
      nome: 'Peru',
    },
    {
      codigo: 'Colombia',
      nome: 'Colombia',
    },
    {
      codigo: 'Portugal',
      nome: 'Portugal',
    },
    {
      codigo: 'Mocambique',
      nome: 'Mocambique',
    },
    {
      codigo: 'Angola',
      nome: 'Angola',
    },
    {
      codigo: 'Mexico',
      nome: 'Mexico',
    },
    {
      codigo: 'Panama',
      nome: 'Panama',
    },
    {
      codigo: 'Guatemala',
      nome: 'Guatemala',
    },
  ];
}

function getNfeOptions() {
  return [
    {
      codigo: '',
      nome: '',
    },
    {
      codigo: 'ECF',
      nome: 'ECF',
    },
    {
      codigo: 'NFC-e',
      nome: 'NFC-e',
    },
    {
      codigo: 'NF-e',
      nome: 'NF-e',
    },
    {
      codigo: 'nfs-E',
      nome: 'nfs-E',
    },
    {
      codigo: 'CF-e SAT',
      nome: 'CF-e SAT',
    },
    {
      codigo: 'MF-e',
      nome: 'MF-e',
    },
  ];
}

function getCodeAndNameByInitials(modulos = [], cdidioma = 1) {
  // GH - CM - TT - GF - ROLRM - CE - BI - IS
  const idioma = parseInt(cdidioma, 10);
  const result = [];
  _.forEach(modulos, value => {
    switch (value) {
      case 'GH':
        result.push({
          codigo: 800,
          nome: idioma === 1 ? 'Gerencia Hoteleira' : 'Gestión Hotelera',
        });
        break;
      case 'CM':
        result.push({
          codigo: 806,
          nome: idioma === 1 ? 'Channel Manager' : 'Channel Manager',
        });
        break;
      case 'TT':
        result.push({
          codigo: 801,
          nome: idioma === 1 ? 'Tarifador' : 'Tarifador Telefónico',
        });
        break;
      case 'GF':
        result.push({
          codigo: 805,
          nome: idioma === 1 ? 'Gerencia Financeira' : 'Gestión Financiera',
        });
        break;
      case 'ROLRM':
        result.push({
          codigo: 802,
          nome: idioma === 1 ? 'Reservas On Line' : 'Reservas Online',
        });
        result.push({
          codigo: 803,
          nome: idioma === 1 ? 'Revenue Management' : 'Revenue Management',
        });
        break;
      case 'CE':
        result.push({
          codigo: 804,
          nome: idioma === 1 ? 'Controle de Estoque' : 'Control de Stock',
        });
        break;
      case 'PDV':
        result.push({
          codigo: 808,
          nome: idioma === 1 ? 'PDV + Monitor + iPDV' : 'PDV + Monitor + iPDV',
        });
        break;
      case 'BI':
        result.push({
          codigo: 506,
          nome:
            idioma === 1 ? 'Business Intelligence' : 'Business Intelligence',
        });
        break;
      case 'IS':
        result.push({
          codigo: 598,
          nome: 'iService',
        });
        break;
      case 'TEF':
        result.push({
          codigo: 593,
          nome: 'TEF',
        });
        break;
      default:
        //
        break;
    }
  });
  return result;
}

export {
  getRegiao,
  getManutencao,
  getITC,
  getModulosString,
  getEstados,
  getPaises,
  getCodeAndNameByInitials,
  getNfeOptions,
};
