import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import TextareaContainer from 'components/form/textareaContainer';
import InputContainer from 'components/form/inputContainer';

const ExpectationForm = ({
  nmexpectativa,
  nmdemonstrado,
  dtdatademo,
  onFieldChanged,
}) => {
  const {errors, touched} = useFormikContext();
  return (
    <Row>
      <Col xs={12}>
        <h4 className="header smaller lighter blue">Expectativa do cliente</h4>

        <TextareaContainer
          label="Expectativa: "
          showError={!!errors.nmexpectativa && !!touched.nmexpectativa}
          text={nmexpectativa || ''}
          onFieldChanged={e => {
            onFieldChanged('nmexpectativa', e.target.value);
          }}
          errorText={errors.nmexpectativa}
        />

        <InputContainer
          label="Demonstrado para:"
          showError={!!errors.nmdemonstrado && !!touched.nmdemonstrado}
          text={nmdemonstrado || ''}
          onFieldChanged={e => {
            onFieldChanged('nmdemonstrado', e.target.value);
          }}
          errorText={errors.nmdemonstrado}
        />

        <InputContainer
          type="date"
          label="Data Demo:"
          showError={!!errors.dtdatademo && !!touched.dtdatademo}
          text={dtdatademo || ''}
          onFieldChanged={e => {
            onFieldChanged('dtdatademo', e.target.value);
          }}
          errorText={errors.dtdatademo}
        />
      </Col>
    </Row>
  );
};

export default ExpectationForm;

ExpectationForm.defaultProps = {
  nmexpectativa: '',
  nmdemonstrado: '',
  dtdatademo: '',
};

ExpectationForm.propTypes = {
  nmexpectativa: PropTypes.string,
  nmdemonstrado: PropTypes.string,
  dtdatademo: PropTypes.string,
  onFieldChanged: PropTypes.func.isRequired,
};
