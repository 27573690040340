import * as Yup from 'yup';
import {messages} from 'helpers/validator/messages';

export const rules = Yup.object().shape({
  nmrazaosocial: Yup.string().nullable().required(messages.required),
  nmfantasia: Yup.string().nullable().required(messages.required),
  nmdoc1: Yup.string().nullable().required(messages.required),
  nmdoc2: Yup.string().nullable().required(messages.required),
  nmpais: Yup.string().nullable().required(messages.required),
  nmcep: Yup.string().nullable().required(messages.required),
  nmendereco: Yup.string().nullable().required(messages.required),
  nmendnumero: Yup.string().nullable().required(messages.required),
  nmbairro: Yup.string().nullable().required(messages.required),
  nmcidade: Yup.string().nullable().required(messages.required),
  nmnomeresponsavel: Yup.string().nullable().required(messages.required),
  nmemailresponsavel: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmnomefinanceiro: Yup.string().nullable().required(messages.required),
  nmemailfinanceiro: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmdirnome: Yup.string().nullable().required(messages.required),
  nmdiremail: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmnomegerente: Yup.string().nullable().required(messages.required),
  nmemailgerente: Yup.string()
    .email(messages.email)
    .nullable()
    .required(messages.required),
  nmtelefone: Yup.string().nullable().required(messages.required),
});
