import i18n from 'locale/i18n';
import {SET_LANG} from './ActionTypes';

export const setLang = lang => {
  i18n.changeLanguage(lang);
  return {
    type: SET_LANG,
    lang,
  };
};
