import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Form, Formik} from 'formik';

import api from 'services/api';

import Loading from 'components/loading';
import notification from 'components/notification';

import SelectContainer from 'components/form/selectContainer';
import InputContainer from 'components/form/inputContainer';
import TextareaContainer from 'components/form/textareaContainer';

import config from 'config/config';
import {OBS_PT, OBS_ES} from 'helpers/permuta';

import {rules} from './formRules';

const VoucherGenerator = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Gerador de Voucher';
  }, []);

  const formikRef = useRef();

  const handleFormSubmit = useCallback(values => {
    setLoading(true);

    api
      .post('/voucher/generate', values)
      .then(response => {
        if (response.data.file) {
          setLoading(false);

          const file = encodeURI(response.data.file);
          window.open(`${config.BACKEND_URL}/download-voucher/${file}`);

          formikRef.current.resetForm();
        }
      })
      .catch(() => {
        setLoading(false);

        notification.error('Algo deu errado', '');

        formikRef.current.resetForm();
      });
  }, []);

  return (
    <div className="container">
      <Loading show={loading} />

      <div>
        <div className="page-header">
          <h1>Gerador de Voucher</h1>
        </div>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={{
          contrato01: '',
          contrato02: '',
          contrato03: '',
          razaosocial: '',
          fantasia: '',
          address: '',
          city: '',
          state: '',
          country: '',
          description: '',
          language: 'PT',
          observation: OBS_PT,
          amount: 1,
        }}
        validationSchema={rules}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(false);

          handleFormSubmit(values);
        }}>
        {({
          errors,
          touched,
          values,
          setFieldValue,
          handleSubmit,
          setValues,
        }) => (
          <>
            <Row>
              <Col md={12}>
                <Form className="form-horizontal">
                  {/* Nome Fantasia */}

                  <InputContainer
                    type="text"
                    label="Número do contrato"
                    showError={!!errors.contrato01 && !!touched.contrato01}
                    text={values.contrato01}
                    onFieldChanged={e => {
                      setFieldValue('contrato01', e.target.value);
                    }}
                    errorText={errors.contrato01}
                  />

                  <InputContainer
                    type="text"
                    label="Número do contrato 2"
                    showError={!!errors.contrato02 && !!touched.contrato02}
                    text={values.contrato02}
                    onFieldChanged={e => {
                      setFieldValue('contrato02', e.target.value);
                    }}
                    errorText={errors.contrato02}
                  />

                  <InputContainer
                    type="text"
                    label="Número do contrato 3"
                    showError={!!errors.contrato03 && !!touched.contrato03}
                    text={values.contrato03}
                    onFieldChanged={e => {
                      setFieldValue('contrato03', e.target.value);
                    }}
                    errorText={errors.contrato03}
                  />

                  <InputContainer
                    type="text"
                    label="Razão Social"
                    showError={!!errors.razaosocial && !!touched.razaosocial}
                    text={values.razaosocial}
                    onFieldChanged={e => {
                      setFieldValue('razaosocial', e.target.value);
                    }}
                    errorText={errors.razaosocial}
                  />

                  <InputContainer
                    type="text"
                    label="Nome Fantasia"
                    showError={!!errors.fantasia && !!touched.fantasia}
                    text={values.fantasia}
                    onFieldChanged={e => {
                      setFieldValue('fantasia', e.target.value);
                    }}
                    errorText={errors.fantasia}
                  />

                  <InputContainer
                    type="text"
                    label="Endereço"
                    showError={!!errors.address && !!touched.address}
                    text={values.address}
                    onFieldChanged={e => {
                      setFieldValue('address', e.target.value);
                    }}
                    errorText={errors.address}
                  />

                  <InputContainer
                    type="text"
                    label="Cidade"
                    showError={!!errors.city && !!touched.city}
                    text={values.city}
                    onFieldChanged={e => {
                      setFieldValue('city', e.target.value);
                    }}
                    errorText={errors.city}
                  />

                  <InputContainer
                    type="text"
                    label="Estado"
                    showError={!!errors.state && !!touched.state}
                    text={values.state}
                    onFieldChanged={e => {
                      setFieldValue('state', e.target.value);
                    }}
                    errorText={errors.state}
                  />

                  <InputContainer
                    type="text"
                    label="País"
                    showError={!!errors.country && !!touched.state}
                    text={values.country}
                    onFieldChanged={e => {
                      setFieldValue('country', e.target.value);
                    }}
                    errorText={errors.country}
                  />

                  <InputContainer
                    type="text"
                    label="Descrição"
                    showError={!!errors.description && !!touched.description}
                    text={values.description}
                    onFieldChanged={e => {
                      setFieldValue('description', e.target.value);
                    }}
                    errorText={errors.description}
                  />

                  <SelectContainer
                    label="Idioma das Considerações Gerais"
                    showError={!!errors.language && !!touched.language}
                    text={values.language}
                    onFieldChanged={e => {
                      const lng = e.target.value;

                      setValues(prevValues => ({
                        ...prevValues,
                        language: lng,
                        observation: lng === 'ES' ? OBS_ES : OBS_PT,
                      }));
                    }}
                    errorText=""
                    options={[
                      {
                        codigo: 'PT',
                        nome: 'PT',
                      },
                      {
                        codigo: 'ES',
                        nome: 'ES',
                      },
                    ]}
                    colWidth="3"
                  />

                  <TextareaContainer
                    label="Considereções Gerais"
                    showError={!!errors.observation && !!touched.observation}
                    text={values.observation}
                    onFieldChanged={e => {
                      setFieldValue('observation', e.target.value);
                    }}
                    errorText={errors.observation}
                  />

                  <InputContainer
                    type="number"
                    label="Quantidade"
                    showError={!!errors.amount && !!touched.amount}
                    text={String(values.amount)}
                    onFieldChanged={e => {
                      setFieldValue('amount', e.target.value);
                    }}
                    errorText={errors.amount}
                    colWidth="3"
                  />
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <div className="clearfix form-actions">
                  <div className="btn-group pull-right">
                    <Button
                      className="btn btn-primary pull-right"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}>
                      <i className="ace-icon fa fa-file-pdf-o bigger-110" />
                      <span>Gerar</span>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
};

export default VoucherGenerator;
