let backendHost = 'http://localhost:5000';

const protocol = window.location.protocol.concat('//');
const hostname = window.location.hostname;
const port = window.location.port;

if (hostname !== 'localhost' && port !== '3000') {
  backendHost = protocol + hostname;
  if (port !== null && port !== '') {
    backendHost += `:${port}`;
  }
}

const config = {
  BACKEND_URL: backendHost,
};

export default config;
