import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

class InputDecimal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.value === '' && props.value === 0) {
      return {
        value: '',
      };
    }
    if (Number.isNaN(state.value) && props.value === 0) {
      return {
        value: '',
      };
    }
    if (props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  onChange = values => {
    let value = values.floatValue;
    if (Number.isNaN(value)) {
      value = null;
    }
    this.props.onValueChange(value);
  };

  render() {
    return (
      <NumberFormat
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        style={this.props.styles}
        value={this.state.value}
        displayType="input"
        fixedDecimalScale
        decimalScale={this.props.decimalScale}
        decimalSeparator={this.props.decimalSeparator}
        thousandSeparator={this.props.thousandSeparator}
        allowNegative={this.props.allowNegative}
        isNumericString={false}
        className={this.props.className}
        onValueChange={this.onChange}
        format={this.props.format}
        onBlur={e => {
          this.props.onBlur(e);
        }}
      />
    );
  }
}

InputDecimal.defaultProps = {
  placeholder: '',
  className: 'form-control',
  decimalScale: 2,
  thousandSeparator: '.',
  decimalSeparator: ',',
  allowNegative: false,
  disabled: false,
  value: null,
  format: undefined,
  styles: {},
};

InputDecimal.propTypes = {
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  format: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default InputDecimal;
