import {LOGOUT_USER} from 'store/actions/ActionTypes';
import api from 'services/api';
import notification from '../../components/notification';

export default {
  setupInterceptors: store => {
    api.interceptors.request.use(
      config => {
        const newConfig = {...config};
        const AUTH_TOKEN = localStorage.getItem('lightweb-token');
        if (AUTH_TOKEN) {
          newConfig.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }
        return newConfig;
      },
      error => Promise.reject(error),
    );

    api.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            store.dispatch({
              type: LOGOUT_USER,
            });
            notification.error(
              'Login expirado! Faça seu login novamente',
              'Mensagem',
            );
            return error;
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
  },
};
