import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import SearchContainer from 'components/form/searchContainer';

const HeaderForm = ({search, handleChangeSearch, onSearchClick}) => (
  <>
    <Row>
      <Col xs={12} md={12}>
        <SearchContainer
          type="number"
          label="Código do pedido"
          text={search}
          onFieldChanged={e => {
            handleChangeSearch(e.target.value);
          }}
          placeholder="Informe o código do pedido"
          onSearchClick={() => {
            onSearchClick();
          }}
          errorText=""
          showError={false}
        />
      </Col>
    </Row>
    <div className="hr hr32 hr-dotted" />
  </>
);

HeaderForm.defaultProps = {
  search: '',
};

HeaderForm.propTypes = {
  search: PropTypes.string,
  handleChangeSearch: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default HeaderForm;
