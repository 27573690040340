import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';

import InputContainer from 'components/form/inputContainer';
import SelectContainer from 'components/form/selectContainer';
import InputMaskContainer from 'components/form/inputMaskContainer';
import SearchContainer from 'components/form/searchContainer';
import notification from 'components/notification';

import {getEstados, getPaises} from 'helpers/dslFunctions';
import {cepSearch} from 'services/cepSearch';

import {rules} from './formRules';

const Step2 = props => {
  const {
    nmrazaosocial,
    nmfantasia,
    nmpais,
    tipopessoa,
    nmdoc1,
    nmdoc2,
    nmcep,
    nmendereco,
    nmendnumero,
    nmbairro,
    nmcidade,
    nmnomeresponsavel,
    nmemailresponsavel,
    nmdirnome,
    nmdiremail,
    nmnomegerente,
    nmemailgerente,
    nmnomefinanceiro,
    nmemailfinanceiro,
    nmtelefone,
    nextStep,
    backStep,
  } = props;
  const {t} = useTranslation();
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          nmrazaosocial,
          nmfantasia,
          nmpais,
          tipopessoa,
          nmdoc1,
          nmdoc2,
          nmcep,
          nmendereco,
          nmendnumero,
          nmbairro,
          nmcidade,
          nmnomeresponsavel,
          nmemailresponsavel,
          nmdirnome,
          nmdiremail,
          nmnomegerente,
          nmemailgerente,
          nmnomefinanceiro,
          nmemailfinanceiro,
          nmtelefone,
        }}
        validationSchema={rules}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(false);
          nextStep(values);
        }}>
        {({errors, touched, values, setFieldValue}) => (
          <Form className="form-horizontal" id="formik-cadastro">
            <div className="step-pane no-padding active">
              {/* DADOS DO CLIENTE */}
              <h3 className="lighter block green">
                {t('paginaCliente.preenchadados')}
              </h3>

              {/* Razao Social */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.razaoSocial')} *`}
                placeholder=""
                showError={!!errors.nmrazaosocial && !!touched.nmrazaosocial}
                text={values.nmrazaosocial}
                onFieldChanged={e => {
                  setFieldValue('nmrazaosocial', e.target.value);
                }}
                errorText={errors.nmrazaosocial}
                toUppercase
              />

              {/* Nome Fantasia */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nomeFantasia')} *`}
                placeholder=""
                showError={!!errors.nmfantasia && !!touched.nmfantasia}
                text={values.nmfantasia}
                onFieldChanged={e => {
                  setFieldValue('nmfantasia', e.target.value);
                }}
                errorText={errors.nmfantasia}
                toUppercase
              />

              {/* País */}
              <SelectContainer
                label={`${t('paginaCliente.cadastro.pais')} *`}
                showError={!!errors.nmpais && !!touched.nmpais}
                text={values.nmpais}
                onFieldChanged={e => {
                  setFieldValue('nmpais', e.target.value);
                  setFieldValue('nmdoc', '');
                  setFieldValue('nmdoc2', '');
                  setFieldValue('tipopessoa', 'J');
                }}
                errorText={errors.nmpais}
                options={getPaises()}
                colWidth="9"
              />

              {values.nmpais === 'Brasil' ? (
                <>
                  <SelectContainer
                    label={`${t('paginaCliente.cadastro.tipoPessoa')} *`}
                    showError={!!errors.tipopessoa && !!touched.tipopessoa}
                    text={values.tipopessoa}
                    onFieldChanged={e => {
                      setFieldValue('tipopessoa', e.target.value);
                    }}
                    errorText={errors.tipopessoa}
                    options={[
                      {
                        codigo: 'F',
                        nome: `${t('paginaCliente.cadastro.pessoaFisica')}`,
                      },
                      {
                        codigo: 'J',
                        nome: `${t('paginaCliente.cadastro.pessoaJuridica')}`,
                      },
                    ]}
                    colWidth="9"
                  />
                  {values.tipopessoa === 'F' ? (
                    <InputMaskContainer
                      type="text"
                      label={`${t('paginaCliente.cadastro.cpf')} *`}
                      showError={!!errors.nmdoc1 && !!touched.nmdoc1}
                      text={values.nmdoc1}
                      onFieldChanged={e => {
                        setFieldValue('nmdoc1', e.target.value);
                      }}
                      errorText={errors.nmdoc1}
                      inputId="cadastro-cpf"
                      mask="111.111.111-11"
                      onFieldBlur={e => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        if (value && value.length !== 11) {
                          setFieldValue('nmdoc1', '');
                        }
                      }}
                    />
                  ) : (
                    <InputMaskContainer
                      type="text"
                      label={`${t('paginaCliente.cadastro.cnpj')} *`}
                      showError={!!errors.nmdoc1 && !!touched.nmdoc1}
                      text={values.nmdoc1}
                      onFieldChanged={e => {
                        setFieldValue('nmdoc1', e.target.value);
                      }}
                      errorText={errors.nmdoc1}
                      inputId="cadastro-cnpj"
                      mask="11.111.111/1111-11"
                      onFieldBlur={e => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        if (value && value.length !== 14) {
                          setFieldValue('nmdoc1', '');
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <InputContainer
                  type="text"
                  label={`${t('paginaCliente.cadastro.doc')} *`}
                  placeholder=""
                  showError={!!errors.nmdoc1 && !!touched.nmdoc1}
                  text={values.nmdoc1}
                  onFieldChanged={e => {
                    setFieldValue('nmdoc1', e.target.value);
                  }}
                  errorText={errors.nmdoc1}
                />
              )}

              {/* Inscricao Estadual */}
              <InputContainer
                type="text"
                label={
                  values.nmpais === 'Brasil'
                    ? `${t('paginaCliente.cadastro.inscricaoEstadual')} *`
                    : `${t('paginaCliente.cadastro.documento2')} *`
                }
                showError={!!errors.nmdoc2 && !!touched.nmdoc2}
                text={values.nmdoc2}
                onFieldChanged={e => {
                  setFieldValue('nmdoc2', e.target.value);
                }}
                errorText={errors.nmdoc2}
              />

              <h4 className="header smaller lighter blue">
                {t('paginaCliente.cadastro.endereco')}
              </h4>

              {/* CEP */}
              <SearchContainer
                type="text"
                label={`${t('paginaCliente.cadastro.cep')} *`}
                showError={!!errors.nmcep && !!touched.nmcep}
                text={values.nmcep}
                onFieldChanged={e => {
                  const value = e.target.value;
                  setFieldValue(
                    'nmcep',
                    value ? String(value).replace(/[^0-9]/g, '') : '',
                  );
                }}
                placeholder=""
                onSearchClick={() => {
                  const cep = values.nmcep;

                  const valida = /^[0-9]{8}$/;
                  if (cep !== '' && valida.test(cep)) {
                    cepSearch(cep)
                      .then(data => {
                        setFieldValue('nmendereco', data.logradouro);
                        setFieldValue('nmestado', data.uf);
                        setFieldValue('nmcidade', data.localidade);
                        setFieldValue('nmbairro', data.bairro);
                      })
                      .catch(() => {
                        notification.error('CEP não encontrado', '');
                      });
                  } else {
                    notification.warning('CEP Inválido', '');
                  }
                }}
                colWidth={4}
                errorText={errors.nmcep}
              />

              {/* Logradouro */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.logradouro')} *`}
                showError={!!errors.nmendereco && !!touched.nmendereco}
                text={values.nmendereco}
                onFieldChanged={e => {
                  setFieldValue('nmendereco', e.target.value);
                }}
                errorText={errors.nmendereco}
              />

              {/* Numero */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.numero')} *`}
                showError={!!errors.nmendnumero && !!touched.nmendnumero}
                text={values.nmendnumero}
                onFieldChanged={e => {
                  setFieldValue('nmendnumero', e.target.value);
                }}
                errorText={errors.nmendnumero}
              />

              {/* Bairro */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.bairro')} *`}
                showError={!!errors.nmbairro && !!touched.nmbairro}
                text={values.nmbairro}
                onFieldChanged={e => {
                  setFieldValue('nmbairro', e.target.value);
                }}
                errorText={errors.nmbairro}
              />

              {/* Cidade */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.cidade')} *`}
                showError={!!errors.nmcidade && !!touched.nmcidade}
                text={values.nmcidade}
                onFieldChanged={e => {
                  setFieldValue('nmcidade', e.target.value);
                }}
                errorText={errors.nmcidade}
              />

              {values.nmpais === 'Brasil' ? (
                <SelectContainer
                  label={`${t('paginaCliente.cadastro.estado')} *`}
                  showError={!!errors.nmestado && !!touched.nmestado}
                  text={values.nmestado}
                  onFieldChanged={e => {
                    setFieldValue('nmestado', e.target.value);
                  }}
                  errorText={errors.nmestado}
                  options={getEstados()}
                  colWidth="9"
                />
              ) : (
                <InputContainer
                  type="text"
                  label={`${t('paginaCliente.cadastro.estado')} *`}
                  showError={!!errors.nmestado && !!touched.nmestado}
                  text={values.nmestado}
                  onFieldChanged={e => {
                    setFieldValue('nmestado', e.target.value);
                  }}
                  errorText={errors.nmestado}
                />
              )}

              <h4 className="header smaller lighter blue">Contato</h4>

              {/* NOME REPRESENTANTE LEGAL */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nomeresponsavel')} *`}
                showError={!!errors.nmdirnome && !!touched.nmdirnome}
                text={values.nmdirnome}
                onFieldChanged={e => {
                  setFieldValue('nmdirnome', e.target.value);
                }}
                errorText={errors.nmdirnome}
                disabled
              />

              {/* EMAIL REPRESENTANTE LEGAL */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.emailresponsavel')} *`}
                showError={!!errors.nmdiremail && !!touched.nmdiremail}
                text={values.nmdiremail}
                onFieldChanged={e => {
                  setFieldValue('nmdiremail', e.target.value);
                }}
                errorText={errors.nmdiremail}
                disabled
              />

              {/* NOME RESPONSAVEL GERAL */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nmdirnome')} *`}
                showError={
                  !!errors.nmnomeresponsavel && !!touched.nmnomeresponsavel
                }
                text={values.nmnomeresponsavel}
                onFieldChanged={e => {
                  setFieldValue('nmnomeresponsavel', e.target.value);
                }}
                errorText={errors.nmnomeresponsavel}
              />

              {/* EMAIL RESPONSAVEL GERAL */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.emailresponsavel')} *`}
                showError={
                  !!errors.nmemailresponsavel && !!touched.nmemailresponsavel
                }
                text={values.nmemailresponsavel}
                onFieldChanged={e => {
                  setFieldValue('nmemailresponsavel', e.target.value);
                }}
                errorText={errors.nmemailresponsavel}
              />

              {/* NOME GERENTE */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nmnomegerente')} *`}
                showError={!!errors.nmnomegerente && !!touched.nmnomegerente}
                text={values.nmnomegerente}
                onFieldChanged={e => {
                  setFieldValue('nmnomegerente', e.target.value);
                }}
                errorText={errors.nmnomegerente}
              />

              {/* EMAIL GERENTE */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nmemailgerente')} *`}
                showError={!!errors.nmemailgerente && !!touched.nmemailgerente}
                text={values.nmemailgerente}
                onFieldChanged={e => {
                  setFieldValue('nmemailgerente', e.target.value);
                }}
                errorText={errors.nmemailgerente}
              />

              {/* Responsável Financeiro */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.nomefinanceiro')} *`}
                showError={
                  !!errors.nmnomefinanceiro && !!touched.nmnomefinanceiro
                }
                text={values.nmnomefinanceiro}
                onFieldChanged={e => {
                  setFieldValue('nmnomefinanceiro', e.target.value);
                }}
                errorText={errors.nmnomefinanceiro}
              />

              {/* E-mail financeiro */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.emailfinanceiro')} *`}
                showError={
                  !!errors.nmemailfinanceiro && !!touched.nmemailfinanceiro
                }
                text={values.nmemailfinanceiro}
                onFieldChanged={e => {
                  setFieldValue('nmemailfinanceiro', e.target.value);
                }}
                errorText={errors.nmemailfinanceiro}
              />

              {/* Telefone responsável */}
              <InputContainer
                type="text"
                label={`${t('paginaCliente.cadastro.telefone')} *`}
                showError={!!errors.nmtelefone && !!touched.nmtelefone}
                text={values.nmtelefone}
                onFieldChanged={e => {
                  setFieldValue('nmtelefone', e.target.value);
                }}
                errorText={errors.nmtelefone}
              />
            </div>
            <hr />
            <div className="wizard-actions">
              <Button
                type="button"
                className="btn btn-prev"
                onClick={() => {
                  backStep();
                }}>
                <i className="ace-icon fa fa-arrow-left" />
                <span>{t('paginaCliente.botao.anterior')}</span>
              </Button>
              &nbsp;
              <Button type="submit" className="btn-success btn-next">
                <span>{t('paginaCliente.botao.proximo')}</span>
                <i className="ace-icon fa fa-arrow-right icon-on-right" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

Step2.defaultProps = {
  nmrazaosocial: '',
  nmfantasia: '',
  nmpais: '',
  tipopessoa: '',
  nmdoc1: '',
  nmdoc2: '',
  nmcep: '',
  nmendereco: '',
  nmendnumero: '',
  nmbairro: '',
  nmcidade: '',
  nmnomeresponsavel: '',
  nmemailresponsavel: '',
  nmdirnome: '',
  nmdiremail: '',
  nmnomegerente: '',
  nmemailgerente: '',
  nmnomefinanceiro: '',
  nmemailfinanceiro: '',
  nmtelefone: '',
};

Step2.propTypes = {
  nmrazaosocial: PropTypes.string,
  nmfantasia: PropTypes.string,
  nmpais: PropTypes.string,
  tipopessoa: PropTypes.string,
  nmdoc1: PropTypes.string,
  nmdoc2: PropTypes.string,
  nmcep: PropTypes.string,
  nmendereco: PropTypes.string,
  nmendnumero: PropTypes.string,
  nmbairro: PropTypes.string,
  nmcidade: PropTypes.string,
  nmnomeresponsavel: PropTypes.string,
  nmemailresponsavel: PropTypes.string,
  nmdirnome: PropTypes.string,
  nmdiremail: PropTypes.string,
  nmnomegerente: PropTypes.string,
  nmemailgerente: PropTypes.string,
  nmnomefinanceiro: PropTypes.string,
  nmemailfinanceiro: PropTypes.string,
  nmtelefone: PropTypes.string,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default Step2;
