import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {INTEGRATIONS} from './options';

const styles = {
  multiValue: (base, state) =>
    state.data.isFixed ? {...base, backgroundColor: 'gray'} : base,
  multiValueLabel: (base, state) =>
    state.data.isFixed
      ? {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
      : base,
  multiValueRemove: (base, state) =>
    state.data.isFixed ? {...base, display: 'none'} : base,
};

const orderOptions = values =>
  values
    ? values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed))
    : [];

class IntegrationsSelect extends Component {
  render() {
    const {value, fixedValue, onChange, isDisabled} = this.props;
    return (
      <div>
        <Select
          isMulti
          value={orderOptions(value)}
          onChange={(v, {action, removedValue}) => {
            let val = v;
            val = orderOptions(v);
            switch (action) {
              case 'remove-value':
              case 'pop-value':
                if (removedValue.isFixed) {
                  return;
                }
                break;
              case 'clear':
                val = fixedValue || [];
                break;
              default:
                break;
            }
            onChange(val);
          }}
          styles={styles}
          isClearable={value.some(v => !v.isFixed)}
          name="channel-list"
          isDisabled={isDisabled}
          options={INTEGRATIONS}
          placeholder="Selecione"
        />
      </div>
    );
  }
}

IntegrationsSelect.defaultProps = {
  isDisabled: false,
  value: [],
  fixedValue: [],
};

IntegrationsSelect.propTypes = {
  isDisabled: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  fixedValue: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

export default IntegrationsSelect;
