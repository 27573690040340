import React, {Component} from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

class Currency extends Component {
  renderCurrency() {
    const value = parseFloat(this.props.value);

    const {moeda} = this.props;

    switch (moeda) {
      case '€':
        return <span>{accounting.formatMoney(value, '€ ', 2, ',', '.')}</span>;
      case 'U$':
        return <span>{accounting.formatMoney(value, 'U$ ', 2, ',', '.')}</span>;
      default:
        return <span>{accounting.formatMoney(value, 'R$ ', 2, '.', ',')}</span>;
    }
  }

  render() {
    return <span>{this.renderCurrency()}</span>;
  }
}

Currency.defaultProps = {
  moeda: 'R$',
  value: undefined,
};

Currency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moeda: PropTypes.oneOf(['R$', 'U$', '€']),
};

export default Currency;
