import {
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  PRODUCT_WEB30_CODE,
  PRODUCT_EASYWEB_PLUS_CODE,
} from 'helpers/tabPrice';
import {
  calcPrice as calcPriceLight,
  recalc as recalcLight,
  recalcITC as recalcLightITC,
  calcTotalWithIService as calcTotalWithIServiceLight,
  calcAdicaoUh as calcAdicaoUhLight,
  calcAdicaoUser as calcAdicaoUserLight,
} from './lightweb/calcPrice';
import {
  calcPrice as calcPriceEasy,
  recalc as recalcEasy,
  calcTotalWithIService as calcTotalWithIServiceEasy,
  calcAdicaoUh as calcAdicaoUhEasy,
  calcAdicaoUser as calcAdicaoUserEasy,
} from './easyweb/calcPrice';
import {
  calcPrice as calcPriceEasyPlus,
  recalc as recalcEasyPlus,
  calcTotalWithIService as calcTotalWithIServiceEasyPlus,
  calcAdicaoUh as calcAdicaoUhEasyPlus,
  calcAdicaoUser as calcAdicaoUserEasyPlus,
} from './easywebplus/calcPrice';

export const calcPrice = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return calcPriceLight(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return calcPriceLight(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return calcPriceEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return calcPriceEasyPlus(...rest);
  }
  return () => {};
};

export const recalc = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return recalcLight(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return recalcLight(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return recalcEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return recalcEasyPlus(...rest);
  }
  return () => {};
};

export const recalcITC = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return recalcLightITC(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return recalcLightITC(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return recalcEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return recalcEasyPlus(...rest);
  }
  return () => {};
};

export const calcTotalWithIService = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return calcTotalWithIServiceLight(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return calcTotalWithIServiceLight(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return calcTotalWithIServiceEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return calcTotalWithIServiceEasyPlus(...rest);
  }
  return () => {};
};

export const calcAdicaoUh = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return calcAdicaoUhLight(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return calcAdicaoUhLight(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return calcAdicaoUhEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return calcAdicaoUhEasyPlus(...rest);
  }
  return () => {};
};

export const calcAdicaoUser = (cdproduto, ...rest) => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return calcAdicaoUserLight(...rest);
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return calcAdicaoUserLight(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return calcAdicaoUserEasy(...rest);
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return calcAdicaoUserEasyPlus(...rest);
  }
  return () => {};
};
