import * as Yup from 'yup';

export const rules = Yup.object().shape({
  contrato01: Yup.string().required('Campo obrigatório'),
  razaosocial: Yup.string().required('Campo obrigatório'),
  fantasia: Yup.string().required('Campo obrigatório'),
  address: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  country: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
  observation: Yup.string().required('Campo obrigatório'),
  language: Yup.string().required('Campo obrigatório').oneOf(['PT', 'ES']),
  amount: Yup.number().required('Campo obrigatório').min(1, 'Valor mínimo é 1'),
});
