import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import SwitchContainer from 'components/form/switchContainer';
import SelectContainer from 'components/form/selectContainer';
import InputContainer from 'components/form/inputContainer';

import {getNfeOptions} from 'helpers/dslFunctions';
import {
  tabprecosLight,
  tabprecosEasy,
  PRODUCT_LIGHTWEB_CODE,
  PRODUCT_EASYWEB_CODE,
  PRODUCT_WEB30_CODE,
  tabprecosEasyPlus,
  PRODUCT_EASYWEB_PLUS_CODE,
} from 'helpers/tabPrice';

const idiomas = [
  {
    codigo: 1,
    nome: 'Português',
  },
  {
    codigo: 2,
    nome: 'Inglês',
  },
  {
    codigo: 3,
    nome: 'Espanhol',
  },
];

const BasicForm = ({
  cdidioma,
  regiaocliente,
  cdlocalatende,
  cdconcorrente,
  cdpromocao,
  cdtabprecos,
  regimeTributario,
  nfe,
  migracaoDados,
  sistemaanterior,
  promocoes,
  onFieldChanged,
  handleTabelaPrecoChange,
  cdproduto,
}) => {
  const tabPriceOptions = useMemo(() => {
    let options = [];
    if (
      cdproduto === PRODUCT_LIGHTWEB_CODE ||
      cdproduto === PRODUCT_WEB30_CODE
    ) {
      options = tabprecosLight;
    } else if (cdproduto === PRODUCT_EASYWEB_CODE) {
      options = tabprecosEasy;
    } else if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
      options = tabprecosEasyPlus;
    }
    return options;
  }, [cdproduto]);

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          {/* Idioma do cliente */}

          <SelectContainer
            label="Idioma do cliente"
            showError={false}
            text={cdidioma}
            onFieldChanged={e => {
              onFieldChanged('cdidioma', e.target.value);
            }}
            errorText=""
            options={idiomas}
            colWidth="9"
          />

          {/* Região do Cliente */}
          <SelectContainer
            label="Região cliente"
            showError={false}
            text={cdlocalatende}
            onFieldChanged={e => {
              onFieldChanged('cdlocalatende', e.target.value);
            }}
            errorText=""
            options={regiaocliente}
            colWidth="9"
          />

          {/* Sistema Anterior */}
          <SelectContainer
            label="Sistema Anterior"
            showError={false}
            text={cdconcorrente}
            onFieldChanged={e => {
              onFieldChanged('cdconcorrente', e.target.value);
            }}
            errorText=""
            options={sistemaanterior}
            colWidth="9"
          />

          {/* Promoção */}
          <SelectContainer
            label="Promoção"
            showError={false}
            text={cdpromocao}
            onFieldChanged={e => {
              onFieldChanged('cdpromocao', e.target.value);
            }}
            errorText=""
            options={promocoes}
            colWidth="9"
          />

          {/* Tabela de Preços */}
          <SelectContainer
            label="Tabela de Preços"
            showError={false}
            text={cdtabprecos}
            onFieldChanged={e => {
              handleTabelaPrecoChange(e.target.value);
            }}
            errorText=""
            options={tabPriceOptions}
            colWidth="9"
          />
        </Col>

        <Col xs={12} md={6}>
          {/* Regime Tributário */}
          <InputContainer
            type="text"
            label="Regime Tributário"
            showError={false}
            text={regimeTributario}
            onFieldChanged={e => {
              onFieldChanged('regimeTributario', e.target.value);
            }}
            errorText=""
            colWidth="9"
          />

          {/* Notas Fiscais */}
          <SelectContainer
            multiple
            label="Notas Fiscais"
            showError={false}
            text={nfe}
            onFieldChanged={e => {
              const {value} = e.target;
              const nfeState = nfe;
              let nfeArray = [...nfeState];
              if (nfeArray.includes(value)) {
                nfeArray = nfeArray.filter(v => v !== value);
              } else {
                nfeArray.push(value);
              }

              onFieldChanged('nfe', nfeArray);
            }}
            errorText=""
            options={getNfeOptions()}
            colWidth="9"
          />

          <SwitchContainer
            type="text"
            name="migracaoDados"
            label="Migração de Dados"
            showError={false}
            checked={migracaoDados}
            onFieldChanged={e => {
              onFieldChanged('migracaoDados', e.target.checked);
            }}
            errorText=""
            colWidth="9"
          />
        </Col>
      </Row>
      <div className="hr hr32 hr-dotted" />
    </>
  );
};

BasicForm.propTypes = {
  cdidioma: PropTypes.string.isRequired,
  regiaocliente: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cdlocalatende: PropTypes.string.isRequired,
  cdconcorrente: PropTypes.string.isRequired,
  cdpromocao: PropTypes.string.isRequired,
  cdtabprecos: PropTypes.string.isRequired,
  regimeTributario: PropTypes.string.isRequired,
  nfe: PropTypes.arrayOf(PropTypes.string).isRequired,
  migracaoDados: PropTypes.bool.isRequired,
  sistemaanterior: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  promocoes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleTabelaPrecoChange: PropTypes.func.isRequired,
  cdproduto: PropTypes.oneOf([
    PRODUCT_LIGHTWEB_CODE,
    PRODUCT_EASYWEB_CODE,
    PRODUCT_EASYWEB_PLUS_CODE,
    PRODUCT_WEB30_CODE,
  ]).isRequired,
};

export default BasicForm;
