import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';

import update from 'immutability-helper';
import _ from 'lodash';
import {connect} from 'react-redux';

import api from 'services/api';

import Loading from 'components/loading';
import notification from 'components/notification';

import {
  filterModulesChannel,
  filterModulesExceptChannelTypes,
  filterModulesOnlyChannelTypes,
  MODULE_ISERVICE,
  MODULE_TEF,
} from 'helpers/modules';
import {ITC_TEF} from 'helpers/calc/calcTef';
import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';
import {
  isEasyWeb,
  TAB_PRECO_LIGHTWEB_REAL,
  PRODUCT_LIGHTWEB_CODE,
  getFirstTabPriceCodeByProduct,
} from 'helpers/tabPrice';
import {MODULE_STATE_OBJ} from './utils';

import SubHeader from '../partials/SubHeader';

import {rules} from './formRules';

import Resume from './components/resume';
import AdicaoType from './components/adicaoType';
import HeaderForm from './components/headerForm';
import Footer from './components/footer';
import Observation from './components/observation';
import AdicaoSuccess from './components/adicaoSuccess';
import AdicaoUser from './components/adicaoUser';
import AdicaoUh from './components/adicaoUh';
import Modalidade from '../novoPedido/components/modalidade';
import AdicaoValues from './components/adicaoValues';
import Channel from './components/channel';
import IntegrationModules from './components/integrationModules';
import Modulos from './components/modulos';
import PriceTable from './components/priceTable';
import IService from '../novoPedido/components/iservice';
import ProductType from './components/productType';
import AttachmentStorage from '../novoPedido/components/attachmentStorage';
import TefForm from './components/tefForm';

import {
  calcPrice,
  calcTotalWithIService,
  recalc,
  recalcITC,
  calcAdicaoUh,
  calcAdicaoUser,
} from './functions/calcPrice';

class AdicaoPedido extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearched: false,
      isFinished: false,

      isSubmitting: false,
      optionSelected: null,

      fgtipovenda: 1,
      cdcliente: 0,

      searchCliente: '',

      nmdirnome: '',
      nmdiremail: '',
      cdidioma: '1',
      cdtabprecos: TAB_PRECO_LIGHTWEB_REAL,
      cdproduto: PRODUCT_LIGHTWEB_CODE,
      cdpromocao: '0',
      moeda: 'R$',
      fantasia: '',
      razaosocial: '',
      modulos: [],
      modulosSelecionados: ['800'],
      channelsSelected: [],
      cdlocalatende: '',
      fgtipomanut: '',
      fgmodalidademanut: 1,
      cdnroaptos: '',
      cdnroaptosAdd: 0,
      cdusuarios: '',
      cdusuariosAdd: 0,
      cdusuariostef: 0,

      vlvalormensal: null,
      vlvalorvenda: null,

      valorAdicionalITC: null,
      valortotaladicao: null,

      txobspagamento: '',
      txobsvenda: '',

      promocoes: [
        {
          codigo: '0',
          nome: 'Nenhuma',
        },
      ],

      ITC: MODULE_STATE_OBJ,
      LM: MODULE_STATE_OBJ,
      LS: MODULE_STATE_OBJ,
      LA: MODULE_STATE_OBJ,

      hasIService: false,
      iserviceITC: 0,
      iserviceLM: 0,
      iserviceITCplus: 0,
      iserviceLMplus: 0,

      hasIntegracoes: false,
      integrationsSelected: [],

      storageSelected: null,

      hasTef: false,
      tefITC: ITC_TEF,
      qtdTef: 1,
    };
  }

  componentDidMount() {
    document.title = 'Adição';

    this.loadData();
  }

  loadData = () => {
    this.setState({
      isLoading: true,
    });

    api.get('/pedido/data').then(response => {
      const {data} = response;

      if (Object.prototype.hasOwnProperty.call(data, 'pedido')) {
        const promocoes = _.concat(
          this.state.promocoes,
          data.pedido.constantes.promocoes,
        );

        this.setState({
          promocoes,
          isLoading: false,
        });
      }
    });
  };

  onSearchClick = async () => {
    const {searchCliente} = this.state;
    if (searchCliente !== '' && searchCliente !== null) {
      this.setState({
        isLoading: true,
      });

      api
        .post('/adicao/search', {cdcliente: searchCliente})
        .then(response => {
          const data = response.data;

          if (Object.prototype.hasOwnProperty.call(data, 'dados')) {
            const dados = data.dados;

            const {cdproduto} = dados;

            this.setState(
              {
                isLoading: false,
                isSearched: true,
                cdproduto,
                cdtabprecos: getFirstTabPriceCodeByProduct(cdproduto),
                cdcliente: parseInt(searchCliente, 10),
                nmdirnome: dados.nmdirnome,
                nmdiremail: dados.nmdiremail,
                fantasia: dados.fantasia,
                razaosocial: dados.razaosocial,
                modulos: dados.modulos,
                modulosSelecionados: filterModulesExceptChannelTypes(
                  dados.modulos,
                ),
                channelsSelected: filterModulesChannel(dados.modulos),
                // hasIService: dados.modulos && dados.modulos.includes('598'),
                hasIService: false,
                cdlocalatende: dados.regiaocliente,
                fgtipomanut: dados.fgtipomanut,
                cdnroaptos: parseInt(dados.apartamentos, 10),
                cdusuarios: parseInt(dados.usuarios, 10),
                cdusuariostef: parseInt(dados.usuariostef),

                storageSelected: null,
              },
              () => {
                this.calculaTabela();
              },
            );
          } else {
            this.setState({
              isLoading: false,
              cdcliente: 0,
            });
            notification.warning('Não encontrado', '');
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
          notification.error('Algo deu errado!', '');
        });
    }
  };

  calculaAdicaoUh = () => {
    const qtdUh = parseInt(this.state.cdnroaptosAdd, 10);
    const qtdUser = parseInt(this.state.cdusuarios, 10);
    const licenciamento = parseInt(this.state.fgtipomanut, 10);
    const {modulos, cdtabprecos, cdproduto} = this.state;

    const valorTotal = calcAdicaoUh(
      cdproduto,
      qtdUh,
      qtdUser,
      licenciamento,
      modulos,
      cdtabprecos,
    );

    this.setState({
      valortotaladicao: valorTotal,
    });
  };

  onBlurAdicaoUh = () => {
    const qtdUh = parseInt(this.state.cdnroaptosAdd, 10);
    const qtdUser = parseInt(this.state.cdusuarios, 10);
    const licenciamento = parseInt(this.state.fgtipomanut, 10);
    const {modulos, cdtabprecos, cdproduto} = this.state;

    const valorTotal = calcAdicaoUh(
      cdproduto,
      qtdUh,
      qtdUser,
      licenciamento,
      modulos,
      cdtabprecos,
    );

    const {valortotaladicao} = this.state;
    if (!valortotaladicao || valortotaladicao < valorTotal) {
      this.setState({valortotaladicao: valorTotal});
    }
  };

  calculaAdicaoUser = () => {
    const qtdUh = parseInt(this.state.cdnroaptos, 10);
    const qtdUser = parseInt(this.state.cdusuarios, 10);
    const qtdUserAdd = parseInt(this.state.cdusuariosAdd, 10);
    const licenciamento = parseInt(this.state.fgtipomanut, 10);
    const {modulos, cdtabprecos, cdproduto} = this.state;

    const valorTotalAdd = calcAdicaoUser(
      cdproduto,
      qtdUh,
      qtdUser,
      qtdUserAdd,
      licenciamento,
      modulos,
      cdtabprecos,
    );

    this.setState({
      valortotaladicao: valorTotalAdd,
    });
  };

  onBlurAdicaoUser = () => {
    const qtdUh = parseInt(this.state.cdnroaptos, 10);
    const qtdUser = parseInt(this.state.cdusuarios, 10);
    const qtdUserAdd = parseInt(this.state.cdusuariosAdd, 10);
    const licenciamento = parseInt(this.state.fgtipomanut, 10);
    const {modulos, cdtabprecos, cdproduto} = this.state;

    const valorTotalAdd = calcAdicaoUser(
      cdproduto,
      qtdUh,
      qtdUser,
      qtdUserAdd,
      licenciamento,
      modulos,
      cdtabprecos,
    );

    const {valortotaladicao} = this.state;
    if (!valortotaladicao || valortotaladicao < valorTotalAdd) {
      this.setState({valortotaladicao: valorTotalAdd});
    }
  };

  enviarAdicao = () => {
    const {optionSelected} = this.state;
    let valid = true;

    const {valortotaladicao, valorAdicionalITC} = this.state;

    let data = {
      cdproduto: this.state.cdproduto,
      cdcliente: this.state.cdcliente,
      cdpromocao: this.state.cdpromocao,
      cdlocalatende: this.state.cdlocalatende,
      fgtipomanut: this.state.fgtipomanut,
      cdtabprecos: this.state.cdtabprecos,
      cdvendedor: this.props.auth.codigo,
      nmemailvendedor: this.props.auth.email,
      fgtipovenda: this.state.fgtipovenda,
      cdusuarios: this.state.cdusuarios,
      nmdirnome: this.state.nmdirnome,
      nmdiremail: this.state.nmdiremail,
      cdidioma: this.state.cdidioma,
      txobspagamento: this.state.txobspagamento,
      txobsvenda: this.state.txobsvenda,

      vlvalormensal: formatNumberToSend(this.state.vlvalormensal),
      vlvalorvenda: valorAdicionalITC
        ? formatNumberToSend(valorAdicionalITC + this.state.vlvalorvenda)
        : formatNumberToSend(this.state.vlvalorvenda),
      vltotalmensal: valortotaladicao
        ? formatNumberToSend(valortotaladicao + this.state.vltotalmensal)
        : formatNumberToSend(this.state.vltotalmensal),

      cdnroaptos: this.state.cdnroaptos,
    };

    if (optionSelected === 'render-adicao-modulos') {
      const modulos = this.state.modulos;
      const modulosSelecionados = this.state.modulosSelecionados;
      const {channelsSelected} = this.state;
      const difference = _.xor(
        filterModulesExceptChannelTypes(modulos),
        modulosSelecionados,
      );

      const channelModulesSelected = channelsSelected.map(m => m.value);

      const differenceChannel = _.xor(
        filterModulesOnlyChannelTypes(modulos),
        channelModulesSelected,
      );

      if (_.includes(difference, '802') && !_.includes(difference, '803')) {
        difference.push('803');
      }

      if (this.state.hasIService && !difference.includes(MODULE_ISERVICE)) {
        difference.push(MODULE_ISERVICE);
      }

      if (
        this.state.hasTef &&
        this.state.qtdTef > 0 &&
        !difference.includes(MODULE_TEF)
      ) {
        difference.push(MODULE_TEF);
      }

      if (this.state.storageSelected) {
        difference.push(this.state.storageSelected);
      }

      _.forEach(this.state.integrationsSelected, ({value}) => {
        difference.push(value);
      });

      data = {
        ...data,
        modulos: _.union(difference, differenceChannel),
      };

      delete data.cdnroaptos;
      delete data.cdusuariostef;

      if (this.state.hasTef && this.state.qtdTef > 0) {
        data.cdusuariostef = this.state.qtdTef;
      }

      if (difference.length === 0 && differenceChannel.length > 0) {
        valid = true;
      } else if (
        this.state.vlvalormensal <= 0 &&
        this.state.valortotaladicao <= 0
      ) {
        notification.error('Informações inválidas!', '');
        valid = false;
      }
    } else if (optionSelected === 'render-adicao-uhs') {
      data.cdnroaptos = this.state.cdnroaptosAdd;
      data.cdusuarios = this.state.cdusuarios;
      data.vltotalmensal = formatNumberToSend(this.state.valortotaladicao);

      if (this.state.valortotaladicao <= 0) {
        notification.error('Informações inválidas!', '');
        valid = false;
      }
    } else if (optionSelected === 'render-adicao-users') {
      data.cdnroaptos = this.state.cdnroaptos;
      data.cdusuarios = this.state.cdusuariosAdd;
      data.vltotalmensal = formatNumberToSend(this.state.valortotaladicao);

      if (this.state.valortotaladicao <= 0) {
        notification.error('Informações inválidas!', '');
        valid = false;
      }
    }

    if (valid) {
      this.setState({
        isLoading: true,
      });

      api
        .post('/adicao/new', {data})
        .then(() => {
          this.setState({
            isLoading: false,
            isFinished: true,
          });

          notification.success('Adição enviada com sucesso!', '');
        })
        .catch(error => {
          console.log(error);
          this.setState({
            isLoading: false,
          });

          notification.error('Não foi possível enviar a adição', '');
        });
    }
  };

  onChangeModulos = codigo => {
    const {modulosSelecionados, channelsSelected, cdproduto} = this.state;
    if (codigo === '800') {
      return;
    }
    if (isEasyWeb(cdproduto) && modulosSelecionados.length >= 2) {
      if (
        (modulosSelecionados.includes('802') && codigo === '806') ||
        (modulosSelecionados.includes('806') && codigo === '802')
      ) {
        this.setState(
          {
            modulosSelecionados: [...modulosSelecionados, codigo],
          },
          () => {
            this.calculaTotal();
          },
        );
      } else {
        return;
      }
    }
    if (_.includes(modulosSelecionados, codigo)) {
      const modulos = this.state.modulosSelecionados;
      const index = modulos.indexOf(codigo);
      modulos.splice(index, 1);
      this.setState(
        {
          modulosSelecionados: modulos,
          channelsSelected: codigo === '806' ? [] : [...channelsSelected],
        },
        () => {
          this.calculaTotal();
        },
      );
    } else {
      this.setState(
        {
          modulosSelecionados: [...modulosSelecionados, codigo],
        },
        () => {
          this.calculaTotal();
        },
      );
    }
  };

  onChangeTotal = (type, value) => {
    const obj = {...this.state[type], TOTAL: value};
    const newState = update(this.state, {
      [type]: {$set: obj},
    });
    this.setState(newState, () => {
      this.recalculaModulos(type);
    });
  };

  verificaValorValido = async (type, value) => {
    const {
      cdproduto,
      cdtabprecos,
      cdnroaptos,
      cdusuarios,
      fgtipomanut,
      modulosSelecionados,
      modulos,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    } = this.state;

    const qtduh = parseInt(cdnroaptos, 10);
    const qtduser = parseInt(cdusuarios, 10);

    const res = await calcPrice(
      cdproduto,
      cdtabprecos,
      qtduh,
      qtduser,
      fgtipomanut,
      modulosSelecionados,
      modulos,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    const minValue = res[type].TOTAL;

    if (value <= minValue) {
      const obj = {...this.state[type], TOTAL: minValue};
      const newState = update(this.state, {
        [type]: {$set: obj},
      });
      this.setState(newState, () => {
        this.recalculaModulos(type);
      });
    }
  };

  recalculaModulos = type => {
    const total = this.state[type].TOTAL;
    const {cdproduto, cdtabprecos} = this.state;

    let recalcResult = null;
    if (type === 'ITC') {
      recalcResult = recalcITC(cdproduto, cdtabprecos, total);
    } else {
      recalcResult = recalc(cdproduto, cdtabprecos, total);
    }

    const newState = update(this.state, {
      [type]: {$set: recalcResult},
    });
    this.setState(newState, () => {
      this.calculaTotal();
    });
  };

  calculaTabela = async () => {
    console.log('CALCULANDO...');
    const {
      cdproduto,
      cdtabprecos,
      cdnroaptos,
      cdusuarios,
      fgtipomanut,
      modulosSelecionados,
      modulos,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    } = this.state;

    const qtduh = parseInt(cdnroaptos, 10);
    const qtduser = parseInt(cdusuarios, 10);

    const res = await calcPrice(
      cdproduto,
      cdtabprecos,
      qtduh,
      qtduser,
      fgtipomanut,
      modulosSelecionados,
      modulos,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    this.setState({...res});
  };

  calculaTotal = async () => {
    const {
      cdproduto,
      ITC,
      LS,
      LM,
      LA,
      fgtipomanut,
      modulosSelecionados,
      modulos,
      hasIService,
      cdnroaptos,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
      storageSelected,
      hasTef,
      tefITC,
      qtdTef,
      cdusuariostef,
      cdtabprecos,
    } = this.state;
    const qtduh = parseInt(cdnroaptos, 10);
    const qtdTefCurrent = cdusuariostef;
    const res = await calcTotalWithIService(
      cdproduto,
      cdtabprecos,
      fgtipomanut,
      modulos,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
      hasIService,
      qtduh,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
      storageSelected,
      hasTef,
      tefITC,
      qtdTef,
      qtdTefCurrent,
    );
    this.setState({...res});
  };

  renderTabelaPreco = () => {
    const {cdtabprecos, moeda, fgtipomanut, ITC, LS, LM, LA} = this.state;
    return (
      <PriceTable
        cdtabprecos={cdtabprecos}
        moeda={moeda}
        fgtipomanut={fgtipomanut}
        ITC={ITC}
        LS={LS}
        LM={LM}
        LA={LA}
        onChangeTotal={(type, value) => {
          this.onChangeTotal(type, value);
        }}
        verificaValorValido={(type, value) => {
          this.verificaValorValido(type, value);
        }}
      />
    );
  };

  handleTabelaPrecoChange = tabela => {
    let moeda = 'R$';
    if (parseInt(tabela, 10) === 444) {
      moeda = 'U$';
    } else if (parseInt(tabela, 10) === 451) {
      moeda = '€';
    }

    this.setState(
      {
        cdtabprecos: tabela,
        moeda,
      },
      () => {
        this.calculaTabela();
      },
    );
  };

  renderAdicaoModulos = () => (
    <>
      <Modulos
        modulosSelecionados={this.state.modulosSelecionados}
        modulos={this.state.modulos}
        onChangeModulos={m => {
          this.onChangeModulos(m);
        }}>
        <div className="pricing-span-body">{this.renderTabelaPreco()}</div>
      </Modulos>

      <IntegrationModules
        checked={this.state.hasIntegracoes}
        handleChange={() => {
          this.setState({
            hasIntegracoes: !this.state.hasIntegracoes,
            integrationsSelected: [],
          });
        }}
        integrationsSelected={this.state.integrationsSelected}
        handleChangeIntegrations={value => {
          this.setState({integrationsSelected: [...value]});
        }}
      />

      <AttachmentStorage
        currency={this.state.moeda}
        tablePriceCode={this.state.cdtabprecos}
        storageSelected={this.state.storageSelected}
        handleChangeStorage={storageSelected => {
          this.setState(
            {
              storageSelected,
            },
            () => {
              this.calculaTotal();
            },
          );
        }}
      />

      <TefForm
        moeda={this.state.moeda}
        disabled={false}
        checked={this.state.hasTef}
        handleChange={() => {
          this.setState(
            {
              hasTef: !this.state.hasTef,
              tefITC: ITC_TEF,
              qtdTef: 1,
            },
            () => {
              this.calculaTotal();
            },
          );
        }}
        qtdTefCurrent={this.state.cdusuariostef}
        qtdTef={this.state.qtdTef}
        handleChangeQtd={qtdTef => {
          this.setState({qtdTef}, () => {
            this.calculaTotal();
          });
        }}
        ITC={this.state.tefITC}
        handleChangeITC={tefITC => {
          this.setState(
            {
              tefITC,
            },
            () => {
              this.calculaTotal();
            },
          );
        }}
      />

      <IService
        disabled={this.state.modulosSelecionados.includes(MODULE_ISERVICE)}
        visible={this.state.moeda === 'R$'}
        checked={this.state.hasIService}
        showPlus
        moeda={this.state.moeda}
        handleChange={() => {
          this.setState({hasIService: !this.state.hasIService}, () => {
            this.calculaTotal();
          });
        }}
        ITC={this.state.iserviceITC}
        ITCplus={this.state.iserviceITCplus}
        LM={this.state.iserviceLM}
        LMplus={this.state.iserviceLMplus}
        handleChangeITCplus={iserviceITCplus => {
          this.setState({iserviceITCplus}, () => {
            this.calculaTotal();
          });
        }}
        handleChangeLMplus={iserviceLMplus => {
          this.setState({iserviceLMplus}, () => {
            this.calculaTotal();
          });
        }}
      />

      <Channel
        modulosSelecionados={this.state.modulosSelecionados}
        channelsSelected={this.state.channelsSelected}
        changeChannel={value => {
          this.setState({channelsSelected: [...value]});
        }}
        fixedValue={filterModulesChannel(this.state.modulos)}
        fgtipomanut={this.state.fgtipomanut}
        cdidioma={this.state.cdidioma}
        valortotaladicao={this.state.valortotaladicao}
        handleChangeValorTotalAdicao={value => {
          this.setState({valortotaladicao: value});
        }}
        valorAdicionalITC={this.state.valorAdicionalITC}
        handleChangeValorAdicionalITC={value => {
          this.setState({valorAdicionalITC: value});
        }}
      />

      <AdicaoValues
        vlvalormensal={this.state.vlvalormensal}
        moeda={this.state.moeda}
        vlvalorvenda={this.state.vlvalorvenda}
        vltotalmensal={this.state.vltotalmensal}
        fgtipomanut={this.state.fgtipomanut}
        cdidioma={this.state.cdidioma}
        valortotaladicao={this.state.valortotaladicao}
        valorAdicionalITC={this.state.valorAdicionalITC}
      />

      <Modalidade
        fgmodalidademanut={this.state.fgmodalidademanut}
        onModalidadeChange={value => {
          this.setState({
            fgmodalidademanut: value,
          });
        }}
      />
    </>
  );

  renderAdicaoUhs = () => (
    <AdicaoUh
      cdnroaptosAdd={this.state.cdnroaptosAdd}
      cdnroaptos={this.state.cdnroaptos}
      fgtipomanut={this.state.fgtipomanut}
      cdidioma={this.state.cdidioma}
      valortotaladicao={this.state.valortotaladicao}
      handleChangeValorTotalAdicao={value => {
        this.setState({valortotaladicao: value});
      }}
      onSpinnerChangedUhs={value => {
        this.setState({cdnroaptosAdd: value}, () => {
          this.calculaAdicaoUh();
        });
      }}
      onBlurAdicaoUh={() => {
        this.onBlurAdicaoUh();
      }}
    />
  );

  renderAdicaoUsers = () => (
    <AdicaoUser
      cdusuariosAdd={this.state.cdusuariosAdd}
      cdusuarios={this.state.cdusuarios}
      fgtipomanut={this.state.fgtipomanut}
      cdidioma={this.state.cdidioma}
      valortotaladicao={this.state.valortotaladicao}
      handleChangeValorTotalAdicao={value => {
        this.setState({valortotaladicao: value});
      }}
      onSpinnerChangedUser={value => {
        this.setState({cdusuariosAdd: value}, () => {
          this.calculaAdicaoUser();
        });
      }}
      onBlurAdicaoUser={() => {
        this.onBlurAdicaoUser();
      }}
    />
  );

  renderContent = () => {
    if (
      !this.state.nmdirnome &&
      !this.state.nmdiremail &&
      !this.state.isSearched
    ) {
      return null;
    }

    if (this.state.optionSelected === 'render-adicao-modulos') {
      return this.renderAdicaoModulos();
    }
    if (this.state.optionSelected === 'render-adicao-uhs') {
      return this.renderAdicaoUhs();
    }
    if (this.state.optionSelected === 'render-adicao-users') {
      return this.renderAdicaoUsers();
    }
    return null;
  };

  render() {
    const {isFinished, isSearched} = this.state;
    if (isFinished) {
      return (
        <div>
          <AdicaoSuccess />
        </div>
      );
    }
    return (
      <div>
        <Loading show={this.state.isLoading} />

        <SubHeader text="Preencha os campos" />

        <div className="container">
          <Formik
            enableReinitialize
            initialValues={{
              nmdiremail: this.state.nmdiremail,
              nmdirnome: this.state.nmdirnome,
              txobspagamento: this.state.txobspagamento,
              txobsvenda: this.state.txobsvenda,
              searchCliente: this.state.searchCliente,
            }}
            validationSchema={rules}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(false);

              this.enviarAdicao();
            }}>
            {({submitForm}) => (
              <Form
                className="form-horizontal"
                id="formik-adicao"
                onKeyDown={keyEvent => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault();
                  }
                }}>
                <HeaderForm
                  cdpromocao={this.state.cdpromocao}
                  promocoes={this.state.promocoes}
                  cdproduto={this.state.cdproduto}
                  searchCliente={this.state.searchCliente}
                  nmdirnome={this.state.nmdirnome}
                  nmdiremail={this.state.nmdiremail}
                  cdidioma={this.state.cdidioma}
                  cdtabprecos={this.state.cdtabprecos}
                  isSearched={isSearched}
                  onFieldChanged={(field, value) => {
                    this.setState({[field]: value});
                  }}
                  onSearchClick={() => {
                    this.onSearchClick();
                  }}
                  handleTabelaPrecoChange={value => {
                    this.handleTabelaPrecoChange(value);
                  }}
                />

                {isSearched ? (
                  <>
                    <ProductType value={this.state.cdproduto} />

                    <Resume
                      fantasia={this.state.fantasia}
                      razaosocial={this.state.razaosocial}
                      modulos={this.state.modulos}
                      cdidioma={this.state.cdidioma}
                      fgtipomanut={this.state.fgtipomanut}
                      cdnroaptos={this.state.cdnroaptos}
                      cdusuarios={this.state.cdusuarios}
                      cdlocalatende={this.state.cdlocalatende}
                    />

                    <AdicaoType
                      cdproduto={this.state.cdproduto}
                      optionSelected={this.state.optionSelected}
                      activeAdicaoModulos={() => {
                        this.setState({
                          optionSelected: 'render-adicao-modulos',
                          fgtipovenda: 1,
                          valortotaladicao: null,
                          valorAdicionalITC: null,
                          hasIntegracoes: false,
                          integrationsSelected: [],
                          storageSelected: null,
                        });
                      }}
                      activeAdicaoUhs={() => {
                        this.setState({
                          optionSelected: 'render-adicao-uhs',
                          fgtipovenda: 6,
                          cdusuariosAdd: 0,
                          cdnroaptosAdd: 0,
                          valortotaladicao: null,
                          valorAdicionalITC: null,
                          hasIntegracoes: false,
                          integrationsSelected: [],
                          storageSelected: null,
                        });
                      }}
                      activeAdicaoUsers={() => {
                        this.setState({
                          optionSelected: 'render-adicao-users',
                          fgtipovenda: 7,
                          cdusuariosAdd: 0,
                          cdnroaptosAdd: 0,
                          valortotaladicao: null,
                          valorAdicionalITC: null,
                          hasIntegracoes: false,
                          integrationsSelected: [],
                          storageSelected: null,
                        });
                      }}
                    />

                    {this.renderContent()}

                    <Observation
                      txobspagamento={this.state.txobspagamento}
                      txobsvenda={this.state.txobsvenda}
                      onFieldChanged={(field, value) => {
                        this.setState({[field]: value});
                      }}
                    />

                    <Footer
                      handleSubmitClicked={e => {
                        e.preventDefault();

                        submitForm();
                      }}
                    />
                  </>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
AdicaoPedido.propTypes = {
  auth: PropTypes.shape({
    nome: PropTypes.string,
    email: PropTypes.string,
    codigo: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdicaoPedido);
