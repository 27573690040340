import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const StepsBreadcrumb = props => {
  const {t} = useTranslation();
  const {step} = props;
  let step1Class = '';
  let step2Class = '';
  let step3Class = '';

  if (step === 1) {
    step1Class = 'active';
    step2Class = '';
    step3Class = '';
  } else if (step === 2) {
    step1Class = 'complete';
    step2Class = 'active';
    step3Class = '';
  } else {
    step1Class = 'complete';
    step2Class = 'complete';
    step3Class = 'active';
  }

  return (
    <div>
      <ul className="steps">
        <li className={step1Class}>
          <button type="button" className="step" onClick={() => {}}>
            1
          </button>
          <span className="title">{t('paginaCliente.step1')}</span>
        </li>

        <li className={step2Class}>
          <button type="button" className="step" onClick={() => {}}>
            2
          </button>
          <span className="title">{t('paginaCliente.step2')}</span>
        </li>

        <li className={step3Class} onClick={() => {}}>
          <span className="step">3</span>
          <span className="title">{t('paginaCliente.step3')}</span>
        </li>
      </ul>
    </div>
  );
};

StepsBreadcrumb.propTypes = {
  step: PropTypes.number.isRequired,
};

export default StepsBreadcrumb;
