import {round} from '../calcRounding';
import {
  EASY_PLUS_EURO_TOTAL_ITC,
  EASY_PLUS_EURO_BI,
  EASY_PLUS_EURO_CE,
  EASY_PLUS_EURO_CM,
  EASY_PLUS_EURO_PDV,
  EASY_PLUS_EURO_GF,
  EASY_PLUS_EURO_GH,
  EASY_PLUS_EURO_ROLRM,
  EASY_PLUS_EURO_TT,
  EASY_PLUS_EURO_MESAL_UH,
  EASY_PLUS_EURO_SEMESTRAL_UH,
  EASY_PLUS_EURO_ANUAL_UH,
} from '../values';

export const getQtdUserEuro = qtduser => {
  if (qtduser <= 3) {
    return 0;
  }
  if (qtduser === 4) {
    return 2;
  }
  if (qtduser === 5) {
    return 4;
  }
  if (qtduser >= 6) {
    return 6;
  }
  return qtduser;
};

const getQtdUhEuro = qtduh => {
  if (qtduh < 10) {
    return 10;
  }
  if (qtduh > 50) {
    return 50;
  }
  return qtduh;
};

const calcPercentual = (valorMesUh, qtdUh) => {
  // Gerencia Hoteleira
  const gh = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_GH));
  // Tarifador
  const tt = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_TT));
  // Estoque
  const ce = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_CE));
  // Financeiro
  const gf = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_GF));
  // Channel
  const cm = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_CM));
  // PDV
  const pdv = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_PDV));
  // BI
  const bi = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_BI));
  // Reservas Online
  const rolrm = round(qtdUh * (valorMesUh * EASY_PLUS_EURO_ROLRM));

  const total = round(gh + tt + rolrm + gf + ce + cm + pdv + bi);

  return {
    gh,
    tt,
    ce,
    gf,
    cm,
    pdv,
    bi,
    rolrm,
    total,
  };
};

export const calcItcEuro = () => ({
  ITC_GH: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_GH,
  ITC_TT: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_TT,
  ITC_ROLRM: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_ROLRM,
  ITC_GF: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_GF,
  ITC_CE: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_CE,
  ITC_CM: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_CM,
  ITC_PDV: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_PDV,
  ITC_BI: EASY_PLUS_EURO_TOTAL_ITC * EASY_PLUS_EURO_BI,
  TOTAL: EASY_PLUS_EURO_TOTAL_ITC,
});

export const calculaValorTabelaMensalEuro = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_PLUS_EURO_MESAL_UH + getQtdUserEuro(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhEuro(qtdUh));

  return {
    LM_GH: result.gh,
    LM_TT: result.tt,
    LM_CE: result.ce,
    LM_GF: result.gf,
    LM_CM: result.cm,
    LM_PDV: result.pdv,
    LM_BI: result.bi,
    LM_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};

export const calculaValorTabelaSemestralEuro = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_PLUS_EURO_SEMESTRAL_UH + getQtdUserEuro(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhEuro(qtdUh));

  return {
    LS_GH: result.gh,
    LS_TT: result.tt,
    LS_CE: result.ce,
    LS_GF: result.gf,
    LS_CM: result.cm,
    LS_PDV: result.pdv,
    LS_BI: result.bi,
    LS_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};

export const calculaValorTabelaAnualEuro = (qtdUh, qtdUser) => {
  const valorMesUh = EASY_PLUS_EURO_ANUAL_UH + getQtdUserEuro(qtdUser);

  const result = calcPercentual(valorMesUh, getQtdUhEuro(qtdUh));

  return {
    LA_GH: result.gh,
    LA_TT: result.tt,
    LA_CE: result.ce,
    LA_GF: result.gf,
    LA_CM: result.cm,
    LA_PDV: result.pdv,
    LA_BI: result.bi,
    LA_ROLRM: result.rolrm,
    TOTAL: result.total,
  };
};
