import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {isEasyWeb, isEasyWebPlus} from 'helpers/tabPrice';

import AceSpinner from 'components/aceSpinner';

const UhAndUser = ({
  cdproduto,
  cdnroaptos,
  cdusuarios,
  uhDisabled,
  userDisabled,
  onChangeUH,
  onChangeUser,
}) => {
  const MAX_UHS = useMemo(() => {
    if (isEasyWeb(cdproduto)) {
      return 50;
    }
    if (isEasyWebPlus(cdproduto)) {
      return 100;
    }
    return 9999;
  }, [cdproduto]);

  return (
    <Row>
      {/* Quantas UH's possui o hotel? */}
      <Col xs={12} md={6}>
        <h4 className="header smaller lighter blue">
          Quantas UHs possui o hotel?
        </h4>
        <div className="form-group">
          <div className="col-sm-12 text-center">
            <label className="control-label">Uhs</label>
          </div>
          <div className="col-sm-12 col-xs-12 text-center">
            <AceSpinner
              disabled={uhDisabled}
              value={cdnroaptos}
              onChange={value => {
                if (value <= MAX_UHS) {
                  onChangeUH(value);
                }
              }}
            />
          </div>
        </div>
      </Col>

      {/* Quantos usuários gostaria de adicionar? */}
      <Col xs={12} md={6}>
        <h4 className="header smaller lighter blue">
          Quantos usuários gostaria de adicionar?
        </h4>
        <div className="form-group">
          <div className="col-sm-12 text-center">
            <label className="control-label">Usuários</label>
          </div>
          <div className="col-sm-12 col-xs-12 text-center">
            <AceSpinner
              disabled={userDisabled}
              value={cdusuarios}
              onChange={value => {
                onChangeUser(value);
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

UhAndUser.defaultProps = {
  cdproduto: null,
  cdnroaptos: null,
  cdusuarios: null,
  uhDisabled: false,
  userDisabled: false,
};

UhAndUser.propTypes = {
  cdproduto: PropTypes.string,
  cdnroaptos: PropTypes.number,
  cdusuarios: PropTypes.number,
  onChangeUH: PropTypes.func.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  uhDisabled: PropTypes.bool,
  userDisabled: PropTypes.bool,
};

export default UhAndUser;
