export const INTEGRATIONS = [
  {
    value: '1148',
    label: '1148 - AskSuite - Reserva por Internet',
  },
  {
    value: '505',
    label: '505 - Armazenamento NF-e',
  },
  {
    value: '1100',
    label: '1100 - HSPOT - Gerenciamento de Internet',
  },
  {
    value: '1101',
    label: '1101 - PMWEB - Motor Reservas',
  },
  {
    value: '1103',
    label: '1103 - CLIMBER – RM',
  },
  {
    value: '1104',
    label: '1104 - Nonius',
  },
  {
    value: '1105',
    label: '1105 - myHotel',
  },
  {
    value: '1109',
    label: '1109 - HIJI FFY - Portugal',
  },
  {
    value: '1110',
    label: '1110 - Economy Software - Pré Check in',
  },
  {
    value: '1111',
    label: '1111 - Vega IT - Internet / Pré checkin / Extrato',
  },
  {
    value: '1112',
    label: '1112 - Flutua - Analise de Ocupação',
  },
  {
    value: '1113',
    label: '1113 - Totem - Self Checkin /Checkout c/ TEF',
  },
  {
    value: '1114',
    label: '1114 - Totem - Self Checkin /Checkout s/ TEF',
  },
  {
    value: '1115',
    label: '1115 - RB Analitica - IT Cacau – RM',
  },
  {
    value: '1116',
    label: '1116 - Busque Seguros',
  },
  {
    value: '1117',
    label: '1117 - Rezerve - Motor Noronha',
  },
  {
    value: '1118',
    label: '1118 - R3SS - Internet / CRM / Pré Check-in',
  },
  {
    value: '1122',
    label: '1122 - GUIDE 121 - Interação com Hospede',
  },
  {
    value: '1123',
    label: '1123 - BeBook',
  },
  {
    value: '1130',
    label: '1130 - Upnology - UPGRADE de Reservas',
  },
  {
    value: '1131',
    label: '1131 - Amplifique.me - Pesquisa de Satisfação',
  },
  {
    value: '1132',
    label: '1132 - Motor VOA Hotéis',
  },
  {
    value: '1147',
    label: '1147 - RDStation - CRM',
  },
  {
    value: '1158',
    label: '1158 - Evolutix',
  },
];
