import React from 'react';
import PropTypes from 'prop-types';

export const ContratoES = ({productName}) => (
  <div className="contrato-es">
    <p>
      Este Contrato de Prestación de Servicios (“CONTRATO”) es un acuerdo legal
      entre la licenciada, debidamente calificada en el acto del formulario de
      registro (la “LICENCIADA”) y la empresa DESBRAVADOR SOFTWARE,
      (“DESBRAVADOR”), para uso del software como servicio (“Servicios”),
      disponiendo en este acto por la empresa DESBRAVADOR, por el plazo
      determinado por la Licenciada en el acto de la contratación de los
      Servicios. Al utilizar los Servicios, mismo que en forma parcial o a
      título de test, la LICENCIADA estará vinculada a los términos de este
      CONTRATO, concordando con los mismos, principalmente CONSINTIENDO CON EL
      ACCESO, RECOLECTAR, USO, ALMACENAMIENTO, TRATAMIENTO Y TÉCNICAS DE
      PROTECCIÓN A LAS INFORMACIONES de la LICENCIADA para la total ejecución de
      las funcionalidades ofrecidas por el Servicio. En caso de discordar sobre
      los términos aquí presentados, la utilización de los Servicios debe ser
      inmediatamente interrumpida.
    </p>
    <h5>
      <b> DEFINICIONES:</b>
    </h5>
    <p>
      Los términos que siguen tendrán los siguientes significados cuando sean
      usados en este Contrato:
    </p>
    <p>
      <b>LICENCIADA.</b> Significa la persona a la que la licencia de uso del
      software es concedida por DESBRAVADOR, en los términos de la Licencia.
    </p>
    <p>
      <b>DESBRAVADOR.</b> Significa la Licenciante DESBRAVADOR SOFTWARE LTDA,
      empresa especializada en proveer productos y servicios en tecnología.
    </p>
    <p>
      <b>Software o Producto.</b> Programa de computador Desbravador{' '}
      {productName}
      de propiedad de DESBRAVADOR.
    </p>
    <p>
      <b>Versión.</b> Nueva versión del Software que incorpora y compila varias
      modificaciones y mejoras en relación a su versión anterior.
    </p>
    <p>
      <b>Portal. </b> Significa el lugar donde el Software se dispondrá por
      DESBRAVADOR para acceso de la LICENCIADA a través de la siguiente
      dirección electrónica: https://desbravador.com.br mediante usuario y seña
      que van a ser dispuestos por DESBRAVADOR.
    </p>
    <p>
      <b>Soporte Técnico.</b> Es la asistencia técnica operacional al Servicio
      que se prestará en los términos dispuestos en el presente Contrato.
    </p>
    <p>
      <b>Mantenimiento.</b> Es la corrección de eventuales “errores” o defectos
      en el Software.
    </p>
    <p>
      <b>Licencia.</b> Significa el derecho temporario, no exclusivo, limitado e
      intransferible de uso del Software mediante remuneración, bajo las
      condiciones y restricciones contenidas en el presente instrumento.
    </p>
    <p>
      <i>Cloud Computing.</i> Significa la tecnología a través de la cual los
      recursos de datos están almacenados en Data Centers y pueden accederse por
      la LICENCIADA.
    </p>
    <p>
      <b>
        <i>Data Centers.</i>
      </b>
      Significan los equipos de informática que hacen el almacenamiento,
      procesamiento y control de acceso de los datos.
    </p>
    <p>
      <b>Informaciones.</b> Es el conjunto de datos operacionales, de naturaleza
      dinámica e intrínseca a las actividades comerciales de la Contratante, que
      constituirá su banco de datos y sobre los que operarán los Softwares
      contratados.
    </p>
    <p>
      <b>Entrenamiento:</b> Significa enseñar al usuario de la LICENCIADA a usar
      correctamente el Software;
    </p>
    <p>
      <b>Instalación:</b> Se entiende por realizar las conexiones necesarias y
      accionar los necesarios comandos para dejar el Producto accesible a la
      LICENCIADA.
    </p>
    <p>
      <b>Implantación/Configuración: </b>
      Se entiende por configurar el Software a las necesidades del usuario,
      preparar bases de datos, registrar datos iniciales y preparar el programa
      para que funcione en beneficio del usuario.
    </p>

    <h5>
      <b> DECLARACIÓN DE VOLUNTAD:</b>
    </h5>
    <p>
      LA LICENCIADA DECLARA TENER CONOCIMIENTO DE LOS DERECHOS Y OBLIGACIONES
      SURGIDAS DEL PRESENTE TÉRMINO, CONSTITUYENDO ESTE INSTRUMENTO O ACUERDO
      COMPLETO ENTRE LAS PARTES. DECLARA, ADEMÁS HABER LEÍDO, COMPRENDIDO Y
      ACEPTADO TODOS LOS TÉRMINOS Y CONDICIONES DE ESTE INSTRUMENTO.
    </p>
    <p>
      A partir del momento en que la LICENCIADA acepta este TÉRMINO, las
      disposiciones que constan aquí regirán la relación entre DESBRAVADOR y la
      LICENCIADA, razón por la cual se recomienda que la LICENCIADA imprima una
      copia de este documento para tener una futura referencia.
    </p>
    <p>
      La LICENCIADA declara tener conocimiento que las operaciones que
      corresponden a aceptar determinadas opciones se registrarán en los bancos
      de datos de DESBRAVADOR, junto con la fecha y hora en que la aceptación
      fue manifestada por la LICENCIADA, pudiendo tal información utilizarse
      como prueba de que la opción fue aceptada por la LICENCIADA, independiente
      de otra formalidad.
    </p>
    <p>
      La información referente a la fecha y hora de acceso y la dirección de
      protocolo de internet utilizada por la LICENCIADA para acceder al Portal o
      la Aplicación permanecerán almacenadas por el Producto durante, un mínimo,
      de 6 (meses) a partir de la fecha de cada acceso realizado,
      independientemente del termino de la relación jurídica y comercial entre
      el Producto y la LICENCIADA, en cumplimiento de lo dispuesto en el
      Artículo 15 de la Ley nº 12.965/2014.
    </p>

    <h5>
      <b> 1. OBJETO</b>
    </h5>
    <p>
      1.1. Por medio del presente Término DESBRAVADOR otorga a la LICENCIADA, en
      carácter temporario, no exclusivo, intransferible y oneroso, el derecho de
      uso del Software para procesamiento de sus Informaciones, a través de
      acceder al Portal.
    </p>
    <p>
      1.2. En carácter de complemento a la licencia del “Producto”, DESBRAVADOR
      prestará servicios de mantenimiento y soporte técnico al Producto, de
      acuerdo a las condiciones establecidas en este instrumento, especialmente
      en la cláusula “Servicios de Mantenimiento y Soporte Técnico”.
    </p>
    <p>
      1.2.1. Si la LICENCIADA contrataba otro Producto con DESBRAVADOR, la
      licencia del Producto contratado por el presente instrumento cancela al
      anterior, constituyéndose el presente instrumento la licencia actual del
      “Producto”.
    </p>

    <h5>
      <b>2. LICENCIA DE SOFTWARE – DERECHOS Y RESTRICCIONES</b>
    </h5>
    <p>2.1. Licencia de Software y Derechos</p>
    <p>
      2.2. DESBRAVADOR concede la “Licencia” solamente para uso en los límites y
      condiciones autorizados por las disposiciones contenidas en este Contrato,
      mediante la liberación de accesos al “Producto” para un número máximo de
      TODO usuarios simultáneos.
    </p>
    <p>2.3. Restricciones de Uso</p>
    <p>
      2.3.1. LA LICENCIADA no adquiere cualesquiera derechos sobre el Producto,
      además de aquellos expresamente previstos en este Contrato, siéndole
      expresamente vedado:
    </p>
    <p className="recuo">
      a) transferir, comercializar, sublicenciar, prestar, alquilar, arrendar o
      de cualquier otra forma de alienar el Producto;
    </p>
    <p className="recuo">
      b) efectuar modificaciones, agregados o derivaciones del Producto, por sí
      misma o a través de la contratación de terceros;
    </p>
    <p className="recuo">
      c) hacer ingeniería reversa, descompilar o desmontar el Producto, o
      cualquier otra medida que permita el acceso al código fuente del mismo,
      sin el consentimiento previo y por escrito de DESBRAVADOR;
    </p>
    <p className="recuo">
      d) copiar, alterar, adaptar, mejorar, corregir, traducir, actualizar,
      desarrollar nuevas versiones o elaborar obras derivadas del Producto o
      cualquiera de sus partes o componentes, como también, usar de modo
      diferente de lo expresamente estipulado en el presente Contrato.
    </p>
    <p className="recuo">
      e) eliminar los avisos de derechos autorales o cualesquiera otros avisos
      de derechos de propiedad contenidos en el Producto y en la documentación.
    </p>
    <p>
      2.3.2. Además de las prohibiciones contenidas en la presente Cláusula
      (Restricciones de Uso), está prohibido a la LICENCIADA duplicar o copiar
      el Producto o su Documentación, en todo o en parte.
    </p>
    <p>
      2.3.3. LA LICENCIADA reconoce que el Producto, la Documentación y
      cualquier eventual modificación, agregado, actualización, customización
      (trabajos especiales) y nuevas versiones, aunque no previstas en este
      Contrato, están protegidos por la legislación de Derechos de Autor y de
      protección a los programas de computador. LA LICENCIADA deberá limitar el
      acceso al Producto a aquellos cuyo conocimiento del mismo sea necesario
      para realizar los servicios a la LICENCIADA.
    </p>

    <h5>
      <b> 3. SOPORTE TÉCNICO Y MANTENIMIENTO</b>
    </h5>
    <p>
      3.1. Durante la vigencia de la presente “Licencia”, DESBRAVADOR prestará a
      la LICENCIADA el siguiente servicio de mantenimiento:
    </p>
    <p>
      3.1.1. DESBRAVADOR corregirá errores o defectos informados por la
      LICENCIADA del Producto, salvo si los errores y/o defectos surgen por
      motivo de la introducción, por la LICENCIADA o por terceros, de cualquier
      alteración o modificación en el Producto, sin la autorización previa y por
      escrito de DESBRAVADOR.
    </p>
    <p>
      3.1.2. DESBRAVADOR identificará eventuales anormalidades existentes en el
      Producto, como así también analizará posibilidades para la solución de la
      anormalidad.
    </p>
    <p>
      3.2. DESBRAVADOR prestará a la LICENCIADA los siguientes servicios de
      soporte técnico/consultoría:
    </p>
    <p>
      3.2.1. Servicio de Instalación, Entrenamiento Inicial y Configuración
      (ITC) al “Producto”, según lo contratado dentro de las siguientes formas:
    </p>
    <p className="recuo">
      a) asistencia remota (por teléfono/ web), con hacia 16 (dieciséis) horas
      de consultoría online, disponibles en horario comercial, de lunes a
      viernes, de las 8:00 - 12:00hs y de las 13:30 – 18:00hs; consultoría
      adicional solamente mediante pago de la correspondiente remuneración;
    </p>
    <p className="recuo">
      b) asistencia local, disponible en horario comercial, de lunes a viernes,
      de las 8:00 - 12:00hs y de las 13:30 – 18:00hs, mediante pago de la
      correspondiente remuneración, además de los gastos con traslado,
      alimentación y estadía de un máximo de 02 (dos) técnicos de DESBRAVADOR,
      excepto bebidas alcohólicas y frigobar.
    </p>
    <p>
      3.2.2. DESBRAVADOR prestará, también, soporte técnico adicional después de
      terminada la implantación del “Producto”, con la finalidad de auxiliar a
      la LICENCIADA para solucionar dudas, prestar asistencia en la operación
      del Producto, además de entrenamientos suplementarios, mediante el pago de
      la correspondiente remuneración, a ser acordada con DESBRAVADOR.
    </p>

    <p>
      3.3. No se comprenden como servicios de soporte y mantenimiento los
      servicios consistentes en:
    </p>
    <p className="recuo">a) cambio de lenguaje de programación;</p>
    <p className="recuo">
      b) realizar en entrenamientos de lenguaje de programación, utilitarios y
      editores de texto;
    </p>
    <p className="recuo">c) cambio de ambiente operacional;</p>
    <p className="recuo">
      d) alojamiento del equipo de profesionales de DESBRAVADOR en las
      dependencias internas de la LICENCIADA durante feriados, finales de semana
      y/o en horario no comercial;
    </p>
    <p className="recuo">
      e) correcciones de errores provenientes de operación, uso indebido del
      Producto o cualquier otro origen en el que no exista culpa de DESBRAVADOR;
    </p>
    <p className="recuo">
      f) recuperación de archivos de datos, cuando sea posible, provocados por
      errores de operación, fallas del equipo, sistema operacional e instalación
      eléctrica;
    </p>
    <p className="recuo">
      g) desarrollar y/o corregir errores originados de programas destinados a
      atender particularidades del negocio de la LICENCIADA (específicos y/o
      customizaciones), los que se tratarán en instrumento contractual por
      separado de este;
    </p>
    <p className="recuo">
      h) servicios de traslado y conversión de datos de/para otros equipos;
    </p>
    <p className="recuo">
      i) desarrollo y/o corrección de programas desarrollados sobre el Producto
      destinados a atender particularidades del negocio de la LICENCIADA
      (customizaciones). Se entiende por customización cualquier necesidad de
      ajuste al Producto patrón para alterar una funcionalidad ya existente o
      agregar una nueva funcionalidad al Producto para atender particularidades
      del negocio de la LICENCIADA.
    </p>

    <p>
      3.4. LA LICENCIADA, con la finalidad de cooperar con DESBRAVADOR en el
      procedimiento de implantación del Producto, colocará a disposición de
      DESBRAVADOR como mínimo un empleado de la LICENCIADA, que va a ser el
      responsable para repasar el conocimiento a los demás empleados de la
      LICENCIADA o, como alternativa, contratar entrenamientos suplementarios,
      en los términos del ítem 4.2.1.
    </p>
    <p>
      3.5. Si la estadía y la alimentación para prestar los servicios no fueran
      proveídas en el mismo establecimiento de hospedaje de la LICENCIADA, esta
      se responsabilizará por el reembolso de los correspondientes gastos en
      otro establecimiento de hospedaje y alimentación proveídas por la
      LICENCIADA, en establecimiento de categoría mínima 2(dos) estrellas,
      situado en las proximidades del establecimiento en que se prestarán los
      servicios. La LICENCIADA también podrá optar por el simple reembolso de
      los correspondientes gastos mediante la presentación de los respectivos
      comprobantes.
    </p>
    <p>
      3.6. En el caso de que se extiendan los entrenamientos por razones
      atribuibles a la LICENCIADA, tales como, pero no se limita, a la falta de
      disponibilidad de empleados para el entrenamiento marcado y/o
      entrenamiento junto y a la vez con el desempeño de actividades de
      funciones de rutina, habrá cobro de remuneración por el tiempo aumentado
      innecesariamente.
    </p>

    <h5>
      <b> 4. OBLIGACIONES DE LA LICENCIADA</b>
    </h5>
    <p>
      Además de las obligaciones ya previstas en este Contrato, constituyen
      obligaciones de la LICENCIADA:
    </p>
    <p>
      4.1. Auxiliar y cooperar con DESBRAVADOR en el análisis y solución de
      supuestos errores y defectos en el Producto.
    </p>
    <p>
      4.2. Proveer a DESBRAVADOR, cuando fuera solicitado, todo el equipo,
      materiales, recursos de comunicación, datos e informaciones relevantes,
      para viabilizar y facilitar la prestación de los servicios de
      mantenimiento y soporte técnico.
    </p>
    <p>
      4.3. Implementar los procedimientos recomendados por DESBRAVADOR para
      remediar, corregir o perfeccionar problemas en el Producto.
    </p>
    <p>
      4.4. Notificar a DESBRAVADOR informando acerca de eventual no solución del
      problema/defecto, en un plazo máximo de 30 (treinta) días, contado de la
      fecha en que DESBRAVADOR entrega a la LICENCIADA la corrección o solución
      alternativa. La falta de notificación por parte de la LICENCIADA se
      considerará como aceptando la solución del problema/defecto por la
      LICENCIADA.
    </p>
    <p>
      4.5. Garantizar que el Producto está siendo usado de conformidad con lo
      dispuesto en el presente Contrato de Licencia.
    </p>
    <p>
      4.6. Permitir el acceso de técnicos de DESBRAVADOR, debidamente
      identificados, en la dependencia internas de la LICENCIADA necesarias para
      prestar los servicios en el local, si fueron contratados.
    </p>
    <p>
      4.6.1. LA LICENCIADA deberá contar con acceso para a la red mundial de
      Internet.
    </p>

    <h5>
      <b>5. GARANTÍA E INDEMNIZACIONES</b>
    </h5>
    <p>5.1. Garantía</p>
    <p>
      5.1.1 DESBRAVADOR garante que es el legítimo titular del “Producto”
      licenciado, que es titular de los derechos referidos al producto y tiene
      autoridad para conceder la presente licencia.
    </p>
    <p>
      5.1.2. DESBRAVADOR garantiza el funcionamiento del programa y se
      responsabiliza por la corrección de problemas o errores originados por
      fallas de concepción que hayan dado origen.
    </p>
    <p>
      5.1.3. DESBRAVADOR no se responsabiliza por problemas, errores, daños o
      perjuicios ocasionados por alteraciones no autorizadas efectuadas por el
      cliente o por terceros en el programa – las que no se permiten bajo
      ninguna forma o modo y constituyen violación a los derechos de autor – o
      originados de mala operación de equipos y/o de decisiones tomadas con base
      en informaciones generadas por el programa.
    </p>
    <p>
      5.1.4. Con respecto al Producto, DESBRAVADOR garantiza que cada versión
      desarrollada funcionará sustancialmente de acuerdo con la finalidad para
      la que fue concebida.
    </p>
    <p>5.1.5. Esta garantía no se aplicará:</p>
    <p className="recuo">
      a) si una falla en el Producto resulta de accidente, violación, mal uso o
      de culpa exclusiva de la LICENCIADA o de terceros;
    </p>
    <p className="recuo">
      b) cuando ocurran problemas, errores o daños causados por uso concomitante
      de otros softwares que no hayan sido licenciados o desarrollados por
      DESBRAVADOR;
    </p>
    <p className="recuo">
      c) producido por cualquier falta de cumplimiento del presente Contrato por
      la LICENCIADA.
    </p>
    <p>
      5.1.6. DESBRAVADOR no garantiza a la LICENCIADA que pueda resolver
      problemas en el Producto o en sus eventuales modificaciones, agregados,
      actualizaciones, customizaciones y nuevas versiones con motivo de ataques
      de programas de terceros al Producto, con o sin el
      conocimiento/consentimiento de la LICENCIADA, incluyendo los de motivados
      por virus.
    </p>
    <p>
      5.1.7. Ninguna otra garantía se ofrece además de las expresamente
      descriptas en el presente instrumento, aunque permitidas por ley,
      exceptuadas solamente las de carácter de mandato legal.
    </p>
    <p>
      5.1.8. DESBRAVADOR no ofrece garantías, ni asegura o acepta cualquier
      condición o reclamación, expresa o implícita, de que el “Producto”
      licenciado deberá cumplir con exigencias y propósitos particulares de la
      LICENCIADA o presentar condiciones comerciales específicas, así como no
      ofrece garantías de que el “Producto” licenciado estará completamente
      exento de errores o que funcionará sin que ocurran cualquier tipo de
      error, responsabilizándose en los términos de lo dispuesto en 5.1.
    </p>

    <h5>
      <b>6. LIMITACIONES DE RESPONSABILIDAD</b>
    </h5>
    <p>
      6.1. No obstante, las garantías establecidas por el presente instrumento,
      cualquier indemnización debida por DESBRAVADOR con motivo del presente
      Contrato, estará limitada al valor pagado por la LICENCIADA en los últimos
      12 (doce) meses a título de licencia por el Producto objeto de este
      instrumento.
    </p>
    <p>
      6.2. DESBRAVADOR no será responsable por cualquier pérdida y daños
      indirectos, de cualquier naturaleza, inclusive lucros cesantes de ellos
      ocasionados, reclamaciones de terceros, pérdidas o daños de registros o
      datos, costo de proveer bienes substituidos, de servicios o de tecnología
      alternativos, costo con paralizaciones o cualquier hecho fuera de su
      control razonable, mismo si DESBRAVADOR tuviera conocimiento de la
      posibilidad de tales daños pudieran ocurrir.
    </p>
    <p>
      6.3. DESBRAVADOR no se responsabiliza por acontecimientos causados por
      compartir la Base de Datos con otro software, no cumpliendo con lo
      dispuesto en la cláusula de las Obligaciones de la Licenciada.
    </p>
    <p>
      6.4. La introducción, por la LICENCIADA, de cualquier modificación o
      alteración en el Producto, como también su mantenimiento por la LICENCIADA
      o por terceros, ocasionará la liberación de DESBRAVADOR de cualquier
      responsabilidad por los servicios ahora contratados, pudiendo DESBRAVADOR
      rescindir este Contrato si tal hecho afecta el funcionamiento del Producto
      o la prestación de los servicios contratados.
    </p>
    <p>
      6.5. DESBRAVADOR está exento de cualquier responsabilidad por la
      utilización incorrecta, sobre las configuraciones, reglas, datos y valores
      utilizados en los servicios de reservas online, (DSL ROL, Motores de
      Reservas, además del sistema MIDDLEWARE), por la LICENCIADA, sus
      establecimientos asociados y/o por los agentes de viajes, empresas y
      terceros.
    </p>
    <p>
      6.6. DESBRAVADOR no se responsabiliza por eventuales interrupciones de los
      servicios de reservas online, originarias de causas externas (internet,
      hardware, software de terceros, entre otros), sin embargo, se compromete a
      ayudar en la gestión de las situaciones con el intuito de minimizar, al
      máximo, tales interrupciones y proporcionar, siempre, el mejor apoyo en la
      resolución de eventuales problemas.
    </p>
    <p>
      6.7. DESBRAVADOR se exenta de la responsabilidad por cualquier daño
      causado por overbooking a LICENCIADA, así como a terceros por la
      utilización de los servicios de reservas online (DSL – ROL y/o Motores de
      Reservas).
    </p>

    <h5>
      <b>7. AUTONOMÍA ENTRE LAS PARTES </b>
    </h5>
    <p>
      7.1. Las Partes declaran y concuerdan que este Contrato no implica que
      exista algún vínculo laboral entre sí, sus administradores, empleados o
      contratados, inclusive y especialmente para expresamente no obligar a
      cualquiera de las Partes con respecto a cargas laborales, de previdencia y
      eventuales fondos del trabajador existentes de la otra Parte, sus
      administradores, empleados o contratados.
    </p>
    <p>
      7.1.1. DESBRAVADOR ejecutará los servicios de manera independiente y será
      responsable por el pago de salarios, obligaciones y demás expensas
      relacionadas a sus respectivos empleados.
    </p>
    <p>
      7.1.2. DESBRAVADOR podrá subcontratar terceros, siempre que continúe
      asegurando el cumplimiento de las obligaciones previstas en este Contrato.
    </p>
    <p>
      7.1.2.1. Se caracterizan por terceros, para los fines de este subítem, los
      profesionales certificados y entrenados por DESBRAVADOR.
    </p>

    <h5>
      <b>8. ACTUALIZACIONES, VERSIONES FUTURAS Y UPGRADES</b>
    </h5>
    <p>
      8.1 El presente Contrato no contempla derechos o expectativas de futuras
      versiones y ni de upgrades, pero DESBRAVADOR podrá liberarlos según
      política interna de conveniencia y de remuneración. Además de eso,
      DESBRAVADOR no tiene obligación de proveer actualizaciones a la LICENCIADA
      que no fueran expresamente contratadas con DESBRAVADOR. Éstas solamente
      serán exigibles si están establecidas en acuerdo separado entre
      DESBRAVADOR y la LICENCIADA. En la ausencia de tal acuerdo entre
      DESBRAVADOR y la LICENCIADA, DESBRAVADOR empeñará esfuerzos razonables
      para dar soporte continuo y medidas para identificar errores y defectos,
      mediante la remuneración vigente en el tiempo que se produzcan.
    </p>
    <p>
      8.1.1. Se entiende por actualización el hecho de adecuar las
      funcionalidades existentes en el Producto a las exigencias legales.
    </p>
    <p>
      8.1.2. Se entiende por versión la liberación de un conjunto de
      modificaciones y/o mejoras al Producto, a criterio de DESBRAVADOR.
    </p>
    <p>
      8.1.3. Se entiende por upgrade la evolución de un Producto para otro
      Producto con perfeccionamientos, funcionalidades agregadas y/o
      innovaciones tecnológicas.
    </p>

    <h5>
      <b>9. CONFIDENCIALIDAD</b>
    </h5>
    <p>
      9.1 DESBRAVADOR y la LICENCIADA, por sí o a través de sus apoderados,
      contratados o sucesores, se obligan a mantener a la más absoluta
      confidencialidad de los datos e informaciones que vayan a tener acceso en
      razón de este Contrato, al largo del período de vigencia y por 05 (cinco)
      años después de su cierre. La no obediencia de lo previsto en esta
      Cláusula podrá ocasionar la rescisión de este Contrato y sujetará a la
      infractora a las penalidades e indemnizaciones que le caben.
    </p>
    <p>
      9.1.1. Las informaciones confidenciales abarcan todas las informaciones
      sobre el Producto, sus actualizaciones, modificaciones y derivaciones, su
      Documentación, así como, cualquier otra información que sea designada como
      tal por DESBRAVADOR y por la LICENCIADA.
    </p>

    <h5>
      <b>10. PLAZO</b>
    </h5>
    <p>
      10.1. El presente contrato conservará su plazo de 36 (treinta y seis)
      meses, siendo automáticamente renovable por el mismo período sucesivo si
      ninguna de las partes presentar oposición. Habiendo renovación, las partes
      tendrán que comunicar el deseo por extinguir el contrato por medio de
      notificación previa por escrito, en el plazo mínimo de 90 (noventa) días
      antes de la finalización del contrato.
    </p>

    <h5>
      <b>11. REMUNERACIÓN Y FORMA DE PAGO</b>
    </h5>
    <p>
      11.1. La licencia de uso, mantenimiento y soporte técnico al “Producto”
      (LM) y los servicios de Instalación, Entrenamientos y Configuración (ITC)
      acordados a través del presente Contrato serán remunerados y sufrirán
      reajustes, intereses y multa por mora según los valores, porcentajes,
      forma y condiciones de pago, publicados en el site de divulgación y
      comercialización de la licencia– www.desbravadorweb.com.br.
    </p>
    <p>
      11.1.1. Los pagos deberán ser realizados por la LICENCIADA, mediante orden
      de pago o transferencia bancaria a la cuenta a ser informada, por escrito,
      por DESBRAVADOR y se refieren a importes líquidos.
    </p>
    <p>
      11.1.2. Los pagos efectuados después de la fecha fijada para el
      vencimiento sufrirán recargos de multa aplicable una única vez e intereses
      mensuales.
    </p>
    <p>
      11.2. La atención local (en las dependencias de la LICENCIADA), para
      ejecutar servicios, como también Entrenamientos Suplementarios/ Agregados,
      en los términos del ítem 3.2.1. solamente se prestarán mediante el pago de
      la correspondiente remuneración, inclusive gastos con locomoción,
      alimentación y estadía de técnicos, excepto los gastos ocasionados del
      consumo de bebidas alcohólicas y frigobar.
    </p>
    <p>
      11.3. Los valores referidos a la ITC y la LM podrán sufrir actualizaciones
      según precios vigentes la fecha de la activación, si la misma no se hace
      en hasta 30 (treinta) días de la fecha de aceptación del presente
      Contrato.
    </p>
    <p>
      11.4. Queda expresamente entendido y acordado por la LICENCIADA que haber
      recibido del principal por DESBRAVADOR no significará la cancelación de
      los demás recargos y multa, por lo que DESBRAVADOR podrá rescindir el
      presente Contrato, en el caso de que tales irregularidades no sean
      resueltas.
    </p>
    <p>
      El atraso en el pago de la contraprestación pecunaria por más de 15
      (quince) días, por la LICENCIADA, ocacionará la suspensión del acceso al
      Producto (desligamiento de funciones), como también en la suspensión del
      servicio de soporte técnico. Una vez realizados los pago atrasados
      DESBRAVADOR tendrá un plazo de 48 (cuarenta y ocho) horas hábiles para
      restablecer el acceso a la LICENCIADA al Producto.
    </p>
    <p>
      11.6. Sobre los valores establecidos en este instrumento están agregados
      los tributos que incidan o que vengan a incidir por motivo de los
      servicios prestados, de acuerdo con la alícuota del lugar donde se
      emitirán las facturas.
    </p>
    <p>
      11.6.1. Si hubiera aumento de las alícuotas que inciden sobre los
      servicios, o también, la creación de un nuevo tributo, el valor
      correspondiente será reajustado con el objetivo de contemplar tal
      alteración.
    </p>
    <p>
      11.7. Los valores establecidos en este Contrato se actualizarán anualmente
      o en la menor periodicidad permitida por la legislación en vigor en la
      época, de acuerdo con el índice que mejor refleje la pérdida del poder
      adquisitivo en el período, para cada uno de los períodos de 12 (doce)
      meses sucesivos.
    </p>
    <p>
      11.7.1. El reajuste aplicado en los términos del subítem anterior no podrá
      incidir de forma de ocasionar la diminución del valor monetario pactado,
      teniendo éste como límite mínimo.
    </p>
    <p>
      11.7.2. En los casos en que fue contratada nueva licencia de productos,
      por razón de la sustitución de productos que sea mantenido el valor
      mensual anterior licenciado, la fecha del reajuste del contrato de
      licencia anterior será mantenida.
    </p>
    <p>
      11.8 Sin perjuicio de lo dispuesto en el ítem 1.2.1, en caso de contrato
      previo a este instrumento con DEBRAVADOR:
    </p>
    <p className="recuo">
      a) el LICENCIATARIO reconoce, en este acto, los comprobantes y/o carta de
      crédito decurrentes de un canje formalizado en contratos y/o negociaciones
      anteriores, por lo que manifiesta su mantenimiento de estos,
      manteniéndolos ejercitables en todo tiempo.
    </p>
    <p className="recuo">
      b) en caso de rescisión del contrato por cualquier motivo, el pago de las
      importancias debidas pactadas en canje se realizará mediante conversión en
      prestación pecuniaria del valor canjeado o su remanente, si fuera parcial.
      Asimismo, ocurrirá conversión en prestación pecuniaria a la negativa a
      prestar los servicios/hospedaje mediante el uso del crédito/canjes
      plasmados en los citados comprobantes o carta de crédito.
    </p>
    <p className="recuo">
      c) la cuota pecuniaria corresponderá al valor canjeado contenido en el
      contrato, independientemente del precio de la diaria al momento de su
      formación o terminación.
    </p>

    <h5>
      <b>12. DEL NIVEL DE SERVICIO DE DISPONIBILIDAD</b>
    </h5>
    <p>
      12.1. El acceso al Software estará disponible para la LICENCIADA
      24(veinticuatro) horas por día, 7 (siete) días por semana, con
      disponibilidad del 99,3% (noventa y nueve, coma tres por ciento), excepto
      en caso de paradas de emergencia o programadas, o también, en los casos de
      caso fortuito o fuerza mayor.
    </p>
    <p>
      12.1.1. La LICENCIADA está autorizada a realizar mantenimiento preventivo
      programado o de emergencia en el Software o Portal, y estos podrán quedar
      imposibilitados de transmitir y/o recibir datos por el tiempo necesario
      para solucionarlo. Al final del procedimiento de emergencia, la LICENCIADA
      será avisada sobre el asunto y motivo de tal interacción. En el caso de
      mantenimientos programados la LICENCIADA será comunicada por e-mail sobre
      el período en que se harán las paradas en los Software o Portal y el
      tiempo necesario para solucionar la situación. En este caso la LICENCIADA
      podrá quedar imposibilitada de accederlos, sin que esto genere alguna
      penalidad para DESBRAVADOR.
    </p>

    <h5>
      <b>13. RESTITUCIÓN DE LAS INFORMACIONES</b>
    </h5>
    <p>
      13.1. Suspendido el acceso al Software o rescindido este Contrato,
      DESBRAVADORE mantendrá las informaciones de la LICENCIADA almacenadas por
      un período de 60 (sesenta) días, contados a partir de la suspensión del
      acceso o del pedido de rescisión. Durante este período, la LICENCIADA
      podrá solicitar, una única vez, un informe de las informaciones que
      contiene datos de personas, títulos, UH, artículos y reservas en el
      formato “.csv”.
    </p>
    <p>
      13.2. Según lo descripto en la cláusula 13.1. a cima, pasados los 60
      (sesenta) días de la suspensión del acceso de la LICENCIADA al Software,
      todas las informaciones de la LICENCIADA en poder de DESBRAVADOR podrán
      ser excluidas permanentemente del banco de datos de la Contratada,
      independientemente de haber sido extraídas o no por la LICENCIADA.
    </p>

    <h5>
      <b>14. RESCISIÓN</b>
    </h5>
    <p>
      14.1. Cualquiera de las Partes podrá rescindir este Contrato, si la otra
      Parte cometiera una infracción o no cumplir con cualquiera de las
      disposiciones establecidas en este Contrato.
    </p>
    <p>
      14.1.1. La Parte afectada por la infracción contractual deberá notificar a
      la Parte incumplidora, la que tendrá el derecho de corregir la infracción
      o falta de cumplimiento en hasta 30 (treinta) días, a partir del recibido
      de la notificación.
    </p>
    <p>
      14.2 Además de las hipótesis de rescisión previstas anteriormente,
      cualquier de las partes podrá rescindir el presente contrato, por medio de
      notificación previa por escrito, en el plazo mínimo de 90 (noventa) días.
      En esta hipótesis, será debido el pago integral de la licencia mensual
      (LM) correspondiente al plazo de 90 (noventa) días de notificación previa,
      período por el cual subsiste la obligación de DESBRAVADOR continuar la
      prestación de soporte técnico al producto contratado.
    </p>
    <p>
      14.2.1 Sin perjuicio del cumplimiento del plazo de notificación previa
      estipulada en el ítem 14.2, la terminación del contrato en el primer
      período contractual, resultará en el pago de 50% del valor de la licencia
      mensual de los meses restantes hasta el término del período contratado,
      considerada la licencia mensual vigente en el mes de la solicitud de
      terminación de contrato.
    </p>
    <p>
      14.3 En caso de opción de pago de Licencia Anual (LA) o Licencia
      Semestral, con el fin de adecuarse a la conveniencia y administración de
      la LICENCIADA, la finalización de la expiración del plazo del contrato, no
      implica la devolución de los importes pagados por la LICENCIADA a
      DESBRAVADOR. La medida se justifica en razón de la concesión
      extraordinaria de descuento en los valores contratados en las referidas
      modalidades de pago.
    </p>
    <p>
      14.4 En caso de rescisión de este contrato, el valor correspondiente a la
      implantación y entrenamiento, eventualmente en cuotas y debido por la
      LICENCIADA, tendrá su vencimiento anticipado y deberá ser pagado
      inmediatamente
    </p>
    <p>
      14.5 Bajo ninguna hipótesis, habrá devolución de ningún importe pagado por
      la LICENCIADA a DESBRAVADOR, incluso en caso de no utilización del
      Servicio, ya vez que el mismo está a disposición de la LICENCIADA.
    </p>

    <h5>
      <b>15. DISPOSICIONES GENERALES</b>
    </h5>
    <p>15.1. Sucesores y Cesiones</p>
    <p>
      15.1.1. El presente Contrato obliga a las Partes y sus herederos o
      sucesores, de cualquier título.
    </p>
    <p>
      15.1.2. La LICENCIADA NO podrá ceder o transferir sus derechos y/o deberes
      surgidos de este Contrato sin el consentimiento previo, por escrito, de
      DESBRAVADOR. Toda cesión o transferencia hecha infligiendo lo dispuesto
      anteriormente será nula.
    </p>
    <p>15.2 Uso de Cookies</p>
    <p>
      15.2.1 DESBRAVADOR utiliza cookies para ejecutar mejor los servicios a
      través del website en que el Producto está instalado. Al contratar la
      licencia del Producto, la LICENCIADA concuerda con la Política de
      utilización de cookies de DESBRAVADOR que se encuentra publicado en el
      site: www.desbravadorweb.com.br.
    </p>
    <p>15.3 Reservas Online</p>
    <p>
      15.3.1 La configuración del equipo, en la fecha de este contrato,
      necesaria para operar el sistema de reservas online y el sistema
      MIDDLEWARE se encuentran descriptas en el site: www.desbravadorweb.com.br.
    </p>
    <p>
      15.3.2. DESBRAVADOR no tiene ninguna responsabilidad en relación a
      cualquier forma de pago o líneas de crédito estipuladas entre la
      LICENCIADA y los agentes de viaje, operadoras, empresas o cualquiera que
      efectúe la reserva.
    </p>
    <p>
      15.3.3. La LICENCIADA ingresará obligatoriamente las descripciones
      (configuraciones, reglas para utilizarlo, datos y valores) de su
      establecimiento en el sistema de reservas online, respetando la
      orientación de DESBRAVADOR en lo respecta al formato de las informaciones,
      siéndole vedado el uso de términos o expresiones no apropiadas y
      cabiéndole la responsabilidad por la veracidad de las informaciones.
    </p>
    <p>
      15.3.4. Al utilizar el servicio de reservas online y/o del uso de las
      páginas del DSL ROL, además del sistema MIDDLEWARE, algunas
      recomendaciones son necesarias:
    </p>
    <p className="recuo">
      a) Los datos registrados en el administrativo del DSL ROL, así como en
      cualquier complemento (integración) del servicio de reservas online que
      traten sobre las configuraciones, reglas, datos y valores utilizados en
      este servicio deberá ser validado por la LICENCIADA.
    </p>
    <p className="recuo">
      b) En el caso de intermediar Agencias de Viajes Online (OTA’s – Online
      Travel Agencies) los datos registrados en la extranet de cada OTA’s
      (subtarifas, tarifas de promociones, restricciones, paquetes, así como la
      fecha de validad de las reglas) deben ser registrados, verificados y
      confirmados por la LICENCIADA.
    </p>
    <p className="recuo">
      c) Las reservas anteriores a la integración, cuando son alteradas, podrán
      ser duplicadas en el Módulo Gerencia Hotelera, pues no existen las
      referencias iniciales. De la misma forma, se podrá proceder con las
      reservas canceladas.
    </p>
    <p className="recuo">
      d) Las tarifas deben registrarse con las siguientes opciones: reembolsable
      o no reembolsable.
    </p>
    <p className="recuo">
      e) Se recomienda, por motivos de seguridad, que la LICENCIADA trabaje con
      disponibilidad de como máximo 80%, a fin de evitar que ocurra overbooking
      (considerar el tiempo de delay para integrar y actualizar las reservas y
      la disponibilidad).
    </p>
    <p className="recuo">
      f) Considerar que los pagos de las reservas online no constituyen
      obligación de DESBRAVADOR. La página accedida para este fin es de
      responsabilidad de la operadora de la tarjeta de crédito.
    </p>
    <p className="recuo">
      g) Certificarse que todos los pagos confirmados ante la operadora de
      tarjeta de crédito, también sean comunicadas a DESBRAVADOR para confirmar
      e integrar la reserva en el sistema.
    </p>
    <p className="recuo">
      h) Acceder la opción Informe/Mapas (administrativo del Reservas Online)
      para ver todas las tarifas activas para ser utilizado por el
      establecimiento de la LICENCIADA.
    </p>
    <p>
      15.3.5. DESBRAVADOR se reserva el derecho de modificar el layout visual
      del Desbravador Reservas Online, sin previa comunicación, sin cualquier
      obligación del servicio de reservas online.
    </p>
    <p>15.4. Totalidad del acuerdo, Caso Fortuito y Fuerza Mayor</p>
    <p>
      15.4.1. Este Contrato constituye la totalidad del acuerdo entre
      DESBRAVADOR y la LICENCIADA, y ningún agregado a los términos de este
      Contrato generarán efectos, salvo si es por escrito y firmado por ambas
      Partes.
    </p>
    <p>
      15.4.2. Salvo la obligación de la LICENCIADA de pagar por la licencia del
      Producto o por servicios ya prestados, si cualquiera de las Partes sea
      impedida de cumplir, en la totalidad o en parte, cualquier disposición del
      presente Contrato en virtud de situación de caso fortuito o de fuerza
      mayor, podrá solicitar a la otra Parte la suspensión de las obligaciones
      surgidas de tales disposiciones, mediante notificación por escrito y con
      la condición que informe inmediatamente a la otra Parte sobre el hecho,
      describiendo su naturaleza y dando una estimación del atraso.
    </p>
    <p>
      15.4.3. La Parte afectada por la falta de cumplimiento podrá rechazar la
      solicitud si no estar comprobada que realmente ocurrió un caso fortuito
      y/o de fuerza mayor.
    </p>
    <p>15.5. Relación entre las Partes</p>
    <p>
      15.5.1. Cualquier omisión o tolerancia de las Partes al exigir el fiel
      cumplimiento de los términos y condiciones de este Contrato, o en
      ejercicio de prerrogativas de él surgidas, no constituirá ni será
      considerada como novación contractual o renuncia, ni afectará el derecho
      de la Parte tolerante de, en cualquier tiempo, exigir de la otra,
      integralmente, el cumplimiento de las condiciones estipuladas o ejercer
      las prerrogativas admisibles.
    </p>
    <p>15.6. Invalidad de decisión judicial</p>
    <p>
      15.6.1. En el caso de un tribunal competente juzgue que cualquier
      disposición de este Contrato es ilegal, ineficaz o inexigible, las
      disposiciones remanentes permanecerán en pleno vigor y mantendrán su
      eficacia y las Partes modificarán el presente contrato para dar efecto a
      la cláusula declarada ilegal, ineficaz o inexigible, en el límite máximo
      posible.
    </p>
    <p>15.7. Notificaciones</p>
    <p>
      15.7.1. Todas las notificaciones/comunicaciones entre las Partes se harán
      por escrito, por e-mail o por carta, u otro medio de comunicación en el
      que se compruebe la entrega al representante competente de la Parte
      destinataria del objeto de la notificación. Las
      notificaciones/comunicaciones deberán ser encaminadas a las respectivas
      direcciones indicadas en el inicio del presente Contrato, a menos que
      otras direcciones sean notificadas por las Partes, por escrito.
    </p>
    <p>15.8. Jurisdicción / Ley Aplicable </p>
    <p>
      15.8.1. Las Partes eligen como competente al foro de Chapecó, Santa
      Catarina, Brasil, y la ley aplicable la legislación brasileña, para
      dirimir cualquier duda y conducir cualquier acción atinente a este
      Contrato que no pudieran ser solucionadas amigablemente, con renuncia
      expresa de cualquier otro, por más privilegiado que sea o venga a ser.
    </p>
    <p>15.9. Revisión de este Acuerdo/Contrato</p>
    <p>
      15.9.1. DESBRAVADOR podrá en cualquier tiempo revisar los términos de este
      Contrato, actualizando sus términos y notificando a la LICENCIADA sobre
      dichas alteraciones.
    </p>
  </div>
);

ContratoES.propTypes = {
  productName: PropTypes.string.isRequired,
};
