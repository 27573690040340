import jwtDecode from 'jwt-decode';
import {tokenExpIsValid} from '../../helpers/date/date';
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
} from '../actions/ActionTypes';

const tokenStorage = localStorage.getItem('lightweb-token');

let INITIAL_STATE = {
  token: null,
  nome: null,
  email: null,
  codigo: null,
  isAuthenticated: false,
  isAuthenticating: false,
};

if (tokenStorage && jwtDecode(tokenStorage)) {
  const decode = jwtDecode(tokenStorage);
  const exp = decode.exp;

  if (tokenExpIsValid(exp * 1000)) {
    INITIAL_STATE = {
      ...INITIAL_STATE,
      isAuthenticating: false,
      isAuthenticated: true,
      token: tokenStorage,
      nome: decode.nome,
      email: decode.email,
      codigo: decode.codigo,
    };
  }
}

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case LOGIN_USER_SUCCESS: {
      const token = action.payload.token;
      const decode = jwtDecode(token);
      localStorage.setItem('lightweb-token', token);

      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        token,
        nome: decode.nome,
        email: decode.email,
        codigo: decode.codigo,
      };
    }
    case LOGIN_USER_FAILURE: {
      localStorage.removeItem('lightweb-token');
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        token: null,
        nome: null,
        email: null,
        codigo: null,
      };
    }
    case LOGOUT_USER: {
      localStorage.removeItem('lightweb-token');
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        nome: null,
        email: null,
        codigo: null,
      };
    }
    default:
      return state;
  }
};
