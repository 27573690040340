import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Button} from 'react-bootstrap';

import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Loading from 'components/loading';
import InputContainer from 'components/form/inputContainer';

import * as LangActions from 'store/actions/LangActions';

import api from 'services/api';
import {myIpFetch} from 'services/getIp';
import {getCurrentPosition} from 'services/geolocation';
import {getModulosString, getManutencao} from 'helpers/dslFunctions';
import alert2 from 'components/alert2';
import notification from 'components/notification';
import Currency from 'components/currency';

import {PRODUCT_LIGHTWEB_CODE, getProductName} from 'helpers/tabPrice';

import {withRouter} from 'routes/withRouter';

import {now} from 'helpers/date/date';
import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';
import {MODULE_TEF} from 'helpers/modules';
import AdicaoSuccess from './components/adicaoSuccess';

class AdicaoCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFinished: false,

      cdproduto: PRODUCT_LIGHTWEB_CODE,

      cdcliente: 0,
      cdpedido: '',
      cdpedidonr: '',
      modulos: '',
      fgtipomanut: '',
      cdnroaptos: null,
      cdusuarios: null,
      cdusuariostef: null,
      nmrazaosocial: '',
      nmfantasia: '',
      txobspagamento: '',
      nroipusuario: '',
      nmemailvendedor: '',
      nmnomevendedor: '',
      nmdirnome: '',
      nmdiremail: '',
      vlvalormensal: null,
      vlvalorvenda: null,
      fgtipovenda: null,
      lat: null,
      lng: null,

      isAcepted: false,
      nmassinatura: '',
    };
  }

  componentDidMount() {
    document.title = 'Adição';

    this.loadData();
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });

    const ipRes = await myIpFetch();
    console.log('IP:', ipRes.ip);

    let lat = null;
    let lng = null;
    try {
      const position = await getCurrentPosition();
      const {coords} = position;
      console.log('COORDS:', coords);
      lat = coords.latitude;
      lng = coords.longitude;
    } catch (e) {
      console.log(e);
    }

    const payload = {
      codigo: this.props.params.codigo,
      numero: this.props.params.numero,
    };

    api
      .post('/adicao/data', payload)
      .then(response => {
        const data = response.data;

        if (
          Object.prototype.hasOwnProperty.call(data, 'dados') &&
          data.dados.nmipaceite === ''
        ) {
          const dados = data.dados;

          this.setState(
            {
              cdproduto: dados.cdproduto,
              isLoading: false,
              nroipusuario: ipRes.ip,
              cdpedidonr: dados.cdpedidonr,
              cdpedido: dados.cdpedido,
              cdcliente: dados.cdcliente,
              cdidioma: dados.cdidioma,
              fgtipomanut: dados.fgtipomanut,
              modulos: dados.modulos,
              txobspagamento: dados.txobspagamento,
              cdnroaptos: parseInt(dados.cdnroaptos, 10),
              cdusuarios: parseInt(dados.cdusuarios, 10),
              cdusuariostef: parseInt(dados.cdusuariostef, 10),
              vlvalorvenda: parseFloat(dados.vlvalorvenda),
              vlvalormensal: parseFloat(dados.vlvalormensal),
              nmnomevendedor: dados.nomevendedor,
              nmemailvendedor: dados.emailvendedor,
              nmdirnome: dados.nmdirnome,
              nmdiremail: dados.nmdiremail,
              nmrazaosocial: dados.nmrazaosocial,
              nmfantasia: dados.nmfantasia,
              fgtipovenda: parseInt(dados.fgtipovenda, 10),
              lat,
              lng,
            },
            () => {
              if (parseInt(this.state.cdidioma, 10) === 3) {
                this.props.setLang('es');
              } else {
                this.props.setLang('pt');
              }
            },
          );
        } else {
          this.setState({
            isLoading: false,
            isFinished: true,
            cdidioma: data?.dados?.cdidioma,
            cdproduto: data?.dados?.cdproduto || PRODUCT_LIGHTWEB_CODE,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleIsAceptedChange = () => {
    const {isAcepted} = this.state;
    if (!isAcepted) {
      this.setState({
        nmassinatura: '',
      });
    }
    this.setState({
      isAcepted: !isAcepted,
    });
  };

  renderSignField = () => {
    const {t} = this.props;
    const {nmassinatura, isAcepted, nmdirnome} = this.state;
    if (isAcepted) {
      return (
        <div>
          <div className="row">
            <label className="col-sm-9 col-sm-offset-3 control-label no-padding-right grey">
              <span>{t('adicao.assinaturaName')}</span>
              <span className="label-nome-responsavel label label-white middle">
                {nmdirnome}
              </span>
            </label>

            {/* Assinatura */}
            <InputContainer
              type="text"
              label={t('adicao.assinaturaLabel')}
              showError={false}
              text={nmassinatura}
              onFieldChanged={e => {
                this.setState({nmassinatura: e.target.value});
              }}
              errorText=""
              placeholder={t('adicao.assinaturaPlaceholder')}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  enviarAdicao = () => {
    const {t} = this.props;
    const {
      isAcepted,
      nmassinatura,
      nmdirnome,
      vlvalormensal,
      vlvalorvenda,
      cdproduto,
      cdidioma,
      modulos,
    } = this.state;
    const productName = getProductName(cdproduto);
    if (!isAcepted) {
      alert2({
        title: `Antes de utilizar o ${productName}, é necessário que você leia, entenda e concorde com estes termos.`,
        icon: 'warning',
      });
      return;
    }

    if (
      String(nmdirnome).toUpperCase().trim() !==
      String(nmassinatura).toUpperCase().trim()
    ) {
      alert2({
        title: `Antes de utilizar o ${productName}, é necessário que você leia e assine o nome do responsável.`,
        icon: 'warning',
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    let totalLicenciamento = formatNumberToSend(vlvalormensal, 'R$');
    let totalItc = formatNumberToSend(vlvalorvenda, 'R$');

    if (parseInt(cdidioma, 10) === 3) {
      totalLicenciamento = formatNumberToSend(vlvalormensal, 'U$');
      totalItc = formatNumberToSend(vlvalorvenda, 'U$');
    }

    let quantidadeTef = 0;
    if (modulos.includes(MODULE_TEF) && this.state.cdusuariostef > 0) {
      quantidadeTef = this.state.cdusuariostef;
    }

    const data = {
      cdidioma: this.state.cdidioma,
      cdproduto: this.state.cdproduto,
      cdcliente: this.state.cdcliente,
      cdpedido: this.state.cdpedido,
      cdpedidonr: this.state.cdpedidonr,
      nroipusuario: this.state.nroipusuario,
      dtaceitecontrato: now(),
      nmdirnome: this.state.nmdirnome,
      nmdiremail: this.state.nmdiremail,
      fgtipovenda: this.state.fgtipovenda,
      modulosString: _.map(
        getModulosString(this.state.modulos, this.state.cdidioma),
      ).join('; '),
      observacao: this.state.txobspagamento,
      quantidadeUh: this.state.cdnroaptos,
      quantidadeUser: this.state.cdusuarios,
      quantidadeTef,
      valorLicenciamento: totalLicenciamento,
      licenciamento: getManutencao(this.state.fgtipomanut, this.state.cdidioma),
      valorITC: totalItc,
      nmfantasia: this.state.nmfantasia,
      nmrazaosocial: this.state.nmrazaosocial,
      responsavel: this.state.nmdirnome,
      vendedor: this.state.nmnomevendedor,
      emailvendedor: this.state.nmemailvendedor,
      lat: this.state.lat,
      lng: this.state.lng,
    };

    api
      .post('/adicao/save', {data})
      .then(() => {
        this.setState({
          isLoading: false,
          isFinished: true,
        });

        notification.success(t('adicao.adicaoSuccess'), '');
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });

        notification.error(t('adicao.adicaoFail'), '');
      });
  };

  renderSucesso = () => {
    const {cdidioma, cdproduto} = this.state;

    return <AdicaoSuccess lang={cdidioma} cdproduto={cdproduto} />;
  };

  renderTitle = () => {
    const {t} = this.props;
    const {fgtipovenda} = this.state;
    if (fgtipovenda === 1) {
      return t('adicao.titleModules');
    }
    if (fgtipovenda === 7) {
      return t('adicao.titleUsers');
    }
    if (fgtipovenda === 6) {
      return t('adicao.titleUhs');
    }
    return null;
  };

  renderModulos = () => {
    const {t} = this.props;
    const {fgtipovenda, modulos, cdidioma} = this.state;
    if (fgtipovenda === 1) {
      const modules = getModulosString(modulos, cdidioma);
      return (
        <li>
          <i className="ace-icon fa fa-caret-right blue" />
          <span>{t('adicao.resumeModules')}</span>
          &nbsp;
          <b className="blue">{_.map(modules).join(', ')}</b>
        </li>
      );
    }
    return null;
  };

  renderUhs = () => {
    const {t} = this.props;
    const {fgtipovenda, cdnroaptos} = this.state;
    if (fgtipovenda === 6) {
      return (
        <li>
          <i className="ace-icon fa fa-caret-right blue" />
          <span>{t('adicao.resumeUhs')}</span>
          &nbsp;
          <b className="blue">{cdnroaptos}</b>
        </li>
      );
    }
    return null;
  };

  renderUsers = () => {
    const {t} = this.props;
    const {fgtipovenda, cdusuarios} = this.state;
    if (fgtipovenda === 7) {
      return (
        <li>
          <i className="ace-icon fa fa-caret-right blue" />
          <span>{t('adicao.resumeUsers')}</span>
          <b className="blue">{cdusuarios}</b>
        </li>
      );
    }
    return null;
  };

  renderTef = () => {
    const {t} = this.props;
    const {fgtipovenda, cdusuariostef, modulos} = this.state;

    if (
      fgtipovenda === 1 &&
      modulos.includes(MODULE_TEF) &&
      cdusuariostef > 0
    ) {
      return (
        <li>
          <i className="ace-icon fa fa-caret-right blue" />
          <span>{t('adicao.resumeUsersTef')}</span>
          <b className="blue">{cdusuariostef}</b>
        </li>
      );
    }
    return null;
  };

  renderITC = () => {
    const {t} = this.props;
    const {fgtipovenda, vlvalorvenda} = this.state;
    if (fgtipovenda === 1) {
      return (
        <p className="paragrafo-termos">
          {t('adicao.itc1')}
          <b>
            <Currency value={vlvalorvenda} />
          </b>
          {t('adicao.itc2')}
        </p>
      );
    }
    return null;
  };

  renderLicenseAddition = () => {
    const {t} = this.props;
    const {vlvalormensal, fgtipomanut, cdidioma} = this.state;
    return (
      <div>
        <p className="paragrafo-termos">
          {t('adicao.acrescenta1')}
          <b>
            <Currency value={vlvalormensal} />
          </b>
          {`${t('adicao.acrescenta2')} ${getManutencao(
            fgtipomanut,
            cdidioma,
          )}.`}
        </p>
      </div>
    );
  };

  render() {
    const {t} = this.props;
    const {
      isFinished,
      isLoading,
      nmrazaosocial,
      nmnomevendedor,
      txobspagamento,
      isAcepted,
    } = this.state;

    if (isFinished) {
      return <div>{this.renderSucesso()}</div>;
    }

    return (
      <div>
        <Loading show={isLoading} />

        <div className="page-content">
          <div className="container">
            {!isLoading ? (
              <>
                <div className="page-header">
                  <h2
                    className=" text-center"
                    style={{
                      fontSize: '24px',
                      fontWeight: 'lighter',
                      color: '#2679B5',
                    }}>
                    {this.renderTitle()}
                  </h2>
                  <p className="sub-heading text-center">
                    {t('adicao.subtitle1')}
                    <br />
                    {t('adicao.subtitle2')}
                  </p>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <p
                      className="paragrafo-termos"
                      style={{paddingBottom: '20px'}}>
                      {t('adicao.intro1')}
                      <b>{t('adicao.intro2')}</b>
                      {t('adicao.intro3')}
                    </p>

                    <h5>{t('adicao.resume')}</h5>
                    <ul className="list-unstyled spaced">
                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{t('adicao.resumeRazao')}</span>
                        <b className="blue">{nmrazaosocial}</b>
                      </li>
                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{t('adicao.resumeVendedor')}</span>
                        <b className="blue">{nmnomevendedor}</b>
                      </li>

                      {this.renderModulos()}

                      {this.renderUhs()}

                      {this.renderUsers()}

                      {this.renderTef()}

                      <li>
                        <i className="ace-icon fa fa-caret-right blue" />
                        <span>{t('adicao.resumeObservacao')}</span>
                        <b className="blue">{txobspagamento}</b>
                      </li>
                    </ul>
                  </div>

                  <div className="col-xs-12">
                    {this.renderITC()}

                    {this.renderLicenseAddition()}

                    <p className="paragrafo-termos">
                      {t('adicao.footerTitle')}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div
                      style={{
                        marginBottom: '15px',
                        paddingBottom: '15px',
                        paddingTop: '10px',
                        borderTop: '1px dotted #b8cfe0',
                        borderBottom: '1px dotted #b8cfe0',
                      }}>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            className="ace"
                            checked={isAcepted}
                            onChange={() => {
                              this.handleIsAceptedChange();
                            }}
                          />
                          <span className="lbl" style={{marginLeft: '-20px'}}>
                            {t('adicao.readTerms')}
                          </span>
                        </label>
                      </div>

                      {this.renderSignField()}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <Button
                      type="button"
                      className="btn btn-info pull-right"
                      id="enviar-aceite-adicao"
                      onClick={() => {
                        this.enviarAdicao();
                      }}>
                      {t('adicao.aceitarAdicao')}
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AdicaoCliente.propTypes = {
  setLang: PropTypes.func.isRequired,
};

const mapDispathToProps = dispatch => bindActionCreators(LangActions, dispatch);

export default withRouter(
  withTranslation()(connect(null, mapDispathToProps)(AdicaoCliente)),
);
