import {ITC_TEF} from 'helpers/calc/calcTef';

export const STATE_DEFAULTS = {
  fgtipomanut: null,
  modulosSelecionados: ['GH'],
  channelsSelected: [],
  cdnroaptos: 10,
  cdusuarios: 3,
  fgmodalidademanut: 1,

  hasIService: false,
  iserviceITC: 0,
  iserviceLM: 0,
  iserviceITCplus: 0,
  iserviceLMplus: 0,

  hasPermuta: false,
  permutas: {},
  vlvalorpermuta: 0,
  vlqtdepermuta: 0,
  txobspermuta: '',

  hasIntegracoes: false,
  integracoesITC: 0,
  integracoesLM: 0,
  integrationsSelected: [],

  storageSelected: null,

  hasTef: false,
  tefITC: ITC_TEF,
  qtdTef: 1,
};

export const MODULE_STATE_OBJ = {
  GH: 0,
  TT: 0,
  ROLRM: 0,
  GF: 0,
  CE: 0,
  CM: 0,
  PDV: 0,
  BI: 0,
  TOTAL: 0,
};
