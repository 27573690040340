export const LM_STORAGE_10GB_REAL = 20;
export const LM_STORAGE_30GB_REAL = 60;
export const LM_STORAGE_50GB_REAL = 90;

export const LM_STORAGE_10GB_DOLAR = 4;
export const LM_STORAGE_30GB_DOLAR = 12;
export const LM_STORAGE_50GB_DOLAR = 15;

export const LM_STORAGE_10GB_EURO = 4;
export const LM_STORAGE_30GB_EURO = 12;
export const LM_STORAGE_50GB_EURO = 15;
