/* eslint-disable no-template-curly-in-string */
export const messages = {
  required: 'Campo obrigatório',
  maxLength: 'Informe no máximo ${max} caracteres',
  minLength: 'Informe no mínimo ${min} caracteres',
  email: 'E-mail inválido',
  password: 'Senha não confere',
  maxDate: 'A data inicial deve ser anterior da data final',
  minDate: 'A data final deve ser posterior da data inicial',
  color: 'Cor inválida',
};
