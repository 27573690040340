import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import Currency from 'components/currency';

import {getCodeAndNameByInitials} from 'helpers/dslFunctions';

const Resume = ({
  cdnroaptos,
  modulosSelecionados,
  fgtipomanut,
  moeda,
  vlvalormensal,
  vlvalorvenda,
  vltotalmensal,
  hasIService,
}) => {
  const modulesActive = useMemo(() => {
    let modules = [];
    modules = [...modulosSelecionados];
    if (hasIService) {
      modules.push('IS');
    }
    return modules;
  }, [hasIService, modulosSelecionados]);
  return (
    <div>
      <div className="hr hr8 hr-double hr-dotted" />

      <Row>
        <div className="col-sm-12 pull-left">
          <h5 className="">
            Total por mês:&nbsp;
            <span className="red">
              <Currency moeda={moeda} value={vlvalormensal} />
            </span>
          </h5>
        </div>
      </Row>

      <div className="hr hr8 hr-double hr-dotted" />

      <Row>
        <Col sm={12}>
          <h4 className="header smaller lighter blue">Resumo da compra:</h4>
        </Col>
        <Col xs={12} sm={6}>
          <div>
            <ul className="list-unstyled spaced">
              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                <span>Quantidade de UHs:&nbsp;</span>
                <label className="red">{cdnroaptos}</label>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                Módulos selecionados:&nbsp;
                <span id="resumo-modulos">
                  {getCodeAndNameByInitials(modulesActive).map(value => (
                    <label className="red" key={value.codigo}>
                      {value.nome}
                      ;&nbsp;
                    </label>
                  ))}
                </span>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                Modalidade de pagamento:&nbsp;
                <label id="resumo-licenciamento" className="red">
                  {(() => {
                    switch (parseInt(fgtipomanut, 10)) {
                      case 0:
                        return 'Mensal';
                      case 1:
                        return 'Semestral';
                      case 2:
                        return 'Anual';
                      default:
                        return 'Não selecionado';
                    }
                  })()}
                </label>
              </li>
            </ul>
          </div>
        </Col>

        <Col xs={12} sm={6}>
          <h5 className=" ">
            Total ITC&nbsp;
            <small className="blue">
              (Instalação, treinamento e configuração):&nbsp;
            </small>
            <span className="red">
              <Currency moeda={moeda} value={vlvalorvenda} />
            </span>
          </h5>
          <h5 className="">
            Total a pagar:&nbsp;
            <span className="red">
              <Currency moeda={moeda} value={vltotalmensal} />
            </span>
          </h5>
        </Col>
      </Row>
    </div>
  );
};

export default Resume;

Resume.defaultProps = {
  fgtipomanut: null,
  vlvalormensal: null,
  vlvalorvenda: null,
  vltotalmensal: null,
};

Resume.propTypes = {
  cdnroaptos: PropTypes.number.isRequired,
  modulosSelecionados: PropTypes.arrayOf(PropTypes.string).isRequired,
  fgtipomanut: PropTypes.number,
  moeda: PropTypes.string.isRequired,
  vlvalormensal: PropTypes.number,
  vlvalorvenda: PropTypes.number,
  vltotalmensal: PropTypes.number,
  hasIService: PropTypes.bool.isRequired,
};
