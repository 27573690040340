import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

const Item = ({title, value}) => (
  <li>
    <i className="ace-icon fa fa-caret-right blue" />
    <span>{title}</span>
    &nbsp;
    <b className="blue nome-fantasia">{value}</b>
  </li>
);

Item.defaultProps = {
  value: null,
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export const PedidoData = ({data}) => {
  if (!data) {
    return null;
  }
  return (
    <>
      <Row>
        <Col xs={12}>
          <ul className="list-unstyled spaced">
            <Item
              title="Link:"
              value={`https://light.desbravador.com.br/cadastro/${data.cdpedido}/${data.cdpedidonr}`}
            />
            <Item title="Número:" value={data.cdpedido} />
            <Item title="Data do Pedido:" value={data.dtdatapedido} />
            <Item title="Vendedor:" value={data.nomevendedor} />
            <Item title="Razão Social:" value={data.nmrazaosocial} />
            <Item title="Nome Fantasia:" value={data.nmfantasia} />
            <Item title="Endereço:" value={data.nmendereco} />
            <Item title="Bairro:" value={data.nmbairro} />
            <Item title="Estado:" value={data.nmestado} />
            <Item title="Cidade:" value={data.nmcidade} />
            <Item title="Data do aceite:" value={data.nmdataaceite} />
          </ul>
        </Col>
      </Row>
    </>
  );
};

PedidoData.defaultProps = {
  data: null,
};

PedidoData.propTypes = {
  data: PropTypes.shape({
    cdpedido: PropTypes.string,
    cdpedidonr: PropTypes.string,
    dtdatapedido: PropTypes.string,
    nomevendedor: PropTypes.string,
    nmrazaosocial: PropTypes.string,
    nmfantasia: PropTypes.string,
    nmendereco: PropTypes.string,
    nmbairro: PropTypes.string,
    nmestado: PropTypes.string,
    nmcidade: PropTypes.string,
    nmdataaceite: PropTypes.string,
  }),
};
