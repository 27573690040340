import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import _ from 'lodash';
import ChannelSelect from '../../../components/channelSelect';

const Channel = ({modulosSelecionados, channelsSelected, changeChannel}) => (
  <Row>
    <Col md={12}>
      <h4 className="header smaller lighter blue">Channels</h4>
      <ChannelSelect
        isDisabled={!_.includes(modulosSelecionados, 'CM')}
        onChange={value => {
          if (value) {
            changeChannel([...value]);
          } else {
            changeChannel([]);
          }
        }}
        value={channelsSelected}
      />
    </Col>
  </Row>
);

Channel.defaultProps = {
  modulosSelecionados: [],
  channelsSelected: [],
};

Channel.propTypes = {
  modulosSelecionados: PropTypes.arrayOf(PropTypes.string),
  channelsSelected: PropTypes.arrayOf(PropTypes.shape({})),
  changeChannel: PropTypes.func.isRequired,
};

export default Channel;
