import {
  calcItcReal,
  calculaValorTabelaMensalReal,
  calculaValorTabelaSemestralReal,
  calculaValorTabelaAnualReal,
} from 'helpers/calc/easywebplus/calcReal';

export const calcItcRealFunc = () => {
  console.log('CALCULANDO ITC REAL...');
  return new Promise(resolve => {
    const calc = calcItcReal();

    const ITC = {
      GH: calc.ITC_GH,
      TT: calc.ITC_TT,
      ROLRM: calc.ITC_ROLRM,
      GF: calc.ITC_GF,
      CE: calc.ITC_CE,
      CM: calc.ITC_CM,
      PDV: calc.ITC_PDV,
      BI: calc.ITC_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(ITC);
  });
};

export const calculaValorTabelaMensalRealFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO REAL LM...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaMensalReal(qtduh, qtduser);

    const LM = {
      GH: calc.LM_GH,
      TT: calc.LM_TT,
      ROLRM: calc.LM_ROLRM,
      GF: calc.LM_GF,
      CE: calc.LM_CE,
      CM: calc.LM_CM,
      PDV: calc.LM_PDV,
      BI: calc.LM_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LM);
  });
};

export const calculaValorTabelaSemestralRealFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO REAL LS...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaSemestralReal(qtduh, qtduser);

    const LS = {
      GH: calc.LS_GH,
      TT: calc.LS_TT,
      ROLRM: calc.LS_ROLRM,
      GF: calc.LS_GF,
      CE: calc.LS_CE,
      CM: calc.LS_CM,
      PDV: calc.LS_PDV,
      BI: calc.LS_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LS);
  });
};

export const calculaValorTabelaAnualRealFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO REAL LA...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaAnualReal(qtduh, qtduser);

    const LA = {
      GH: calc.LA_GH,
      TT: calc.LA_TT,
      ROLRM: calc.LA_ROLRM,
      GF: calc.LA_GF,
      CE: calc.LA_CE,
      CM: calc.LA_CM,
      PDV: calc.LA_PDV,
      BI: calc.LA_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LA);
  });
};
