import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import _ from 'lodash';

import {getManutencao} from 'helpers/dslFunctions';
import InputDecimal from 'components/form/inputDecimal';

import ChannelSelect from '../../../components/channelSelect';

const Channel = ({
  modulosSelecionados,
  channelsSelected,
  changeChannel,
  fixedValue,
  fgtipomanut,
  cdidioma,
  valortotaladicao,
  handleChangeValorTotalAdicao,
  valorAdicionalITC,
  handleChangeValorAdicionalITC,
}) => (
  <Row>
    <Col md={12}>
      <h5>Channels</h5>
      <ChannelSelect
        isDisabled={!_.includes(modulosSelecionados, '806')}
        onChange={value => {
          if (value) {
            changeChannel([...value]);
          } else {
            changeChannel([]);
          }
        }}
        value={channelsSelected}
        fixedValue={fixedValue}
      />
    </Col>
    <Col md={12}>
      <br />
      <h6>
        <span>Acrescentar valor&nbsp;</span>
        <span>
          <b>ITC</b>
        </span>
      </h6>
      <InputDecimal
        value={valorAdicionalITC}
        onValueChange={value => {
          handleChangeValorAdicionalITC(value);
        }}
        onBlur={() => {}}
      />
    </Col>
    <Col md={12}>
      <br />
      <h6>
        <span>Acrescentar valor&nbsp;</span>
        <span>
          <b>{`${getManutencao(fgtipomanut, cdidioma)}:`}</b>
        </span>
      </h6>
      <InputDecimal
        value={valortotaladicao}
        onValueChange={value => {
          handleChangeValorTotalAdicao(value);
        }}
        onBlur={() => {}}
      />
    </Col>
  </Row>
);

Channel.defaultProps = {
  modulosSelecionados: [],
  channelsSelected: [],
  fixedValue: [],
  fgtipomanut: null,
  valortotaladicao: null,
  valorAdicionalITC: null,
};

Channel.propTypes = {
  modulosSelecionados: PropTypes.arrayOf(PropTypes.string),
  channelsSelected: PropTypes.arrayOf(PropTypes.shape({})),
  fixedValue: PropTypes.arrayOf(PropTypes.shape({})),
  fgtipomanut: PropTypes.number,
  cdidioma: PropTypes.string.isRequired,
  valortotaladicao: PropTypes.number,
  valorAdicionalITC: PropTypes.number,
  handleChangeValorTotalAdicao: PropTypes.func.isRequired,
  handleChangeValorAdicionalITC: PropTypes.func.isRequired,
  changeChannel: PropTypes.func.isRequired,
};

export default Channel;
