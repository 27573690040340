import {
  calcItcDolar,
  calculaValorTabelaMensalDolar,
  calculaValorTabelaSemestralDolar,
  calculaValorTabelaAnualDolar,
} from 'helpers/calc/lightweb/calcDolar';

export const calcItcDolarFunc = () => {
  console.log('CALCULANDO DOLAR ITC');
  return new Promise(resolve => {
    const calc = calcItcDolar();

    const ITC = {
      GH: calc.ITC_GH,
      TT: calc.ITC_TT,
      ROLRM: calc.ITC_ROLRM,
      GF: calc.ITC_GF,
      CE: calc.ITC_CE,
      CM: calc.ITC_CM,
      PDV: calc.ITC_PDV,
      BI: calc.ITC_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(ITC);
  });
};

export const calculaValorTabelaMensalDolarFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO DOLAR LM...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaMensalDolar(qtduh, qtduser);

    const LM = {
      GH: calc.LM_GH,
      TT: calc.LM_TT,
      ROLRM: calc.LM_ROLRM,
      GF: calc.LM_GF,
      CE: calc.LM_CE,
      CM: calc.LM_CM,
      PDV: calc.LM_PDV,
      BI: calc.LM_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LM);
  });
};

export const calculaValorTabelaSemestralDolarFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO DOLAR LS...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaSemestralDolar(qtduh, qtduser);

    const LS = {
      GH: calc.LS_GH,
      TT: calc.LS_TT,
      ROLRM: calc.LS_ROLRM,
      GF: calc.LS_GF,
      CE: calc.LS_CE,
      CM: calc.LS_CM,
      PDV: calc.LS_PDV,
      BI: calc.LS_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LS);
  });
};

export const calculaValorTabelaAnualDolarFunc = (qtduh, qtduser) => {
  console.log('CALCULANDO DOLAR LA...');
  return new Promise(resolve => {
    const calc = calculaValorTabelaAnualDolar(qtduh, qtduser);

    const LA = {
      GH: calc.LA_GH,
      TT: calc.LA_TT,
      ROLRM: calc.LA_ROLRM,
      GF: calc.LA_GF,
      CE: calc.LA_CE,
      CM: calc.LA_CM,
      PDV: calc.LA_PDV,
      BI: calc.LA_BI,
      TOTAL: calc.TOTAL,
    };
    resolve(LA);
  });
};
