import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';

import update from 'immutability-helper';
import _ from 'lodash';

import {connect} from 'react-redux';

import api from 'services/api';
import notification from 'components/notification';
import Loading from 'components/loading';

import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';
import {now} from 'helpers/date/date';
import {getCodeAndNameByInitials} from 'helpers/dslFunctions';
import {
  TAB_PRECO_LIGHTWEB_REAL,
  isEasyWeb,
  isLightWeb,
  PRODUCT_LIGHTWEB_CODE,
  getCurrencyFromTabPriceCode,
  BLACK_FRIDAY_CODE,
} from 'helpers/tabPrice';
import {ITC_TEF} from 'helpers/calc/calcTef';
import {MODULE_TEF, MODULE_ISERVICE} from 'helpers/modules';
import {STATE_DEFAULTS, MODULE_STATE_OBJ} from './utils';

import SubHeader from '../partials/SubHeader';
import PedidoSuccess from './components/pedidoSuccess';
import Footer from './components/footer';
import Header from './components/header';
import Modalidade from './components/modalidade';
import Resume from './components/resume';
import Modulos from './components/modulos';
import UhAndUser from './components/uhsAndUser';
import BasicForm from './components/basicForm';
import FullForm from './components/fullForm';
import Observation from './components/observation';
import Channel from './components/channel';
import IntegrationModules from './components/integrationModules';
import HeaderForm from './components/headerForm';
import PriceTable from './components/priceTable';
import IService from './components/iservice';
import ProductSelect from './components/productSelect';
import PermutaForm from './components/permutaForm';
import AttachmentStorage from './components/attachmentStorage';
import TefForm from './components/tefForm';
import ExpectationForm from './components/expectationForm';

import {rules} from './formRules';

import {
  calcPrice,
  recalc,
  recalcITC,
  calcTotalWithIService,
} from './functions/calcPrice';

class NovoPedido extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFinished: false,

      isSubmitting: false,

      fgtipovenda: 0,
      cdcliente: 0,
      fantasia: '',

      isCovid: false,
      isBlackFriday: false,

      isUpgrade: false,
      isDowngrade: false,
      isReturn: false,
      isOpenCollapseUsuario: false,
      searchCliente: '',

      nmemailresponsavel: '',
      nmnomeresponsavel: '',
      nmdiremail: '',
      nmdirnome: '',

      nmrazaosocial: '',
      nmfantasia: '',
      tipopessoa: 'J',
      nmdoc1: '',
      nmdoc2: '',
      nmpais: '',
      nmcep: '',
      nmendereco: '',
      nmendnumero: '',
      nmbairro: '',
      nmcidade: '',
      nmestado: '',
      nmnomefinanceiro: '',
      nmemailfinanceiro: '',
      nmtelefone: '',

      cdidioma: '1',
      cdlocalatende: '99',
      cdconcorrente: '0',
      cdpromocao: '0',
      moeda: 'R$',
      cdtabprecos: TAB_PRECO_LIGHTWEB_REAL,
      cdproduto: PRODUCT_LIGHTWEB_CODE,

      nfe: [],
      regimeTributario: '',
      migracaoDados: false,

      cdnroaptos: 10,
      cdusuarios: 3,
      modulos: {
        GH: '800',
        TT: '801',
        ROLRM: '802',
        GF: '805',
        CE: '804',
        CM: '806',
        PDV: '808',
        BI: '506',
      },
      modulosSelecionados: ['GH'],
      channelsSelected: [],
      fgmodalidademanut: 1,

      sistemaanterior: [
        {
          codigo: '0',
          nome: 'Nenhum',
        },
      ],
      regiaocliente: [],
      promocoes: [
        {
          codigo: '0',
          nome: 'Nenhuma',
        },
      ],

      ITC: MODULE_STATE_OBJ,
      LM: MODULE_STATE_OBJ,
      LS: MODULE_STATE_OBJ,
      LA: MODULE_STATE_OBJ,

      fgtipomanut: null, // TIPO DE LICENCIAMENTO
      vlvalormensal: null, // VALOR MES
      vltotalmensal: null, // VALOR TOTAL
      vlvalorvenda: null, // VALOR TOTAL ITC

      txobspagamento: '',
      txobsvenda: '',

      hasIService: false,
      iserviceITC: 0,
      iserviceLM: 0,
      iserviceITCplus: 0,
      iserviceLMplus: 0,

      hasPermuta: false,
      permutas: {},
      vlvalorpermuta: 0,
      vlqtdepermuta: 0,
      txobspermuta: '',

      hasIntegracoes: false,
      integracoesITC: 0,
      integracoesLM: 0,
      integrationsSelected: [],

      storageSelected: null,

      hasTef: false,
      tefITC: ITC_TEF,
      qtdTef: 1,

      nmexpectativa: '',
      nmdemonstrado: '',
      dtdatademo: '',
    };
  }

  componentDidMount() {
    document.title = 'Novo Pedido';
    this.loadData();
  }

  handleTabelaPrecoChange = cdtabprecos => {
    const moeda = getCurrencyFromTabPriceCode(cdtabprecos);

    this.setState(
      {
        cdtabprecos,
        moeda,
        ...STATE_DEFAULTS,
      },
      () => {
        this.calculaTabela();
      },
    );
  };

  handleProductChange = (cdproduto, cdtabprecos) => {
    const moeda = getCurrencyFromTabPriceCode(cdtabprecos);
    this.setState(
      {
        cdproduto,
        cdtabprecos,
        moeda,
        ...STATE_DEFAULTS,
      },
      () => {
        this.calculaTabela();
      },
    );
  };

  sendPdfProposta = event => {
    event.preventDefault();
    const {
      vlvalorvenda,
      vltotalmensal,
      nmdirnome,
      nmdiremail,
      txobspagamento,
      isCovid,
      hasIService,
      modulosSelecionados,
      hasTef,
    } = this.state;

    if (!nmdiremail) {
      notification.info('E-mail não informado', '');
      return;
    }

    if (!nmdirnome) {
      notification.info('Nome não informado', '');
      return;
    }

    if ((vlvalorvenda <= 0 || vltotalmensal <= 0) && !isCovid) {
      notification.info('Valores inválidos', '');
      return;
    }

    this.setState({
      isLoading: true,
    });

    const modulos = modulosSelecionados;

    if (hasIService && !modulos.includes('IS')) {
      modulos.push('IS');
    }

    if (hasTef && !modulos.includes('TEF')) {
      modulos.push('TEF');
    }

    const tipoManutencao = this.state.fgtipomanut;
    const modulosList = getCodeAndNameByInitials(modulos, this.state.cdidioma);

    const data = {
      dateTime: now(),
      moeda: this.state.moeda,
      qtdModulos: modulosSelecionados.length,
      tipoManutencao,
      totalItc: formatNumberToSend(vlvalorvenda),
      totalLicenciamento: formatNumberToSend(vltotalmensal),
      modulosSelecionados: modulosList,
      emailCliente: nmdiremail,
      nomeCliente: nmdirnome,
      emailVendedor: this.props.auth.email,
      nomeVendedor: this.props.auth.nome,
      cdidioma: this.state.cdidioma,
      obs: txobspagamento,
    };

    api
      .post('/pedido/proposta', {data})
      .then(() => {
        this.setState({
          isLoading: false,
        });
        notification.success('Proposta enviada com sucesso!', '');
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        notification.error('Não foi possível enviar proposta', '');
      });
  };

  loadData = () => {
    this.setState({
      isLoading: true,
    });

    api.get('/pedido/data').then(response => {
      const {data} = response;

      if (Object.prototype.hasOwnProperty.call(data, 'pedido')) {
        const promocoes = _.concat(
          this.state.promocoes,
          data.pedido.constantes.promocoes,
        );
        const {regiaocliente} = data.pedido.constantes;
        const sistemaanterior = _.concat(
          this.state.sistemaanterior,
          data.pedido.constantes.sistemaanterior,
        );

        this.setState(
          {
            promocoes,
            regiaocliente,
            sistemaanterior,
            isLoading: false,
          },
          () => {
            this.calculaTabela();
          },
        );
      }
    });
  };

  enviarPedido = () => {
    this.setState({
      isLoading: true,
    });

    const modulos = [];
    _.forEach(this.state.modulosSelecionados, value => {
      modulos.push(this.state.modulos[value]);
    });

    _.forEach(this.state.channelsSelected, ({value}) => {
      modulos.push(value);
    });

    _.forEach(this.state.integrationsSelected, ({value}) => {
      modulos.push(value);
    });

    // Adiciona o modulo RM caso o ROL esteja adicionado
    if (_.includes(modulos, '802')) {
      modulos.push('803');
    }

    if (this.state.hasIService) {
      modulos.push(MODULE_ISERVICE);
    }

    if (this.state.hasTef) {
      modulos.push(MODULE_TEF);
    }

    if (this.state.storageSelected) {
      modulos.push(this.state.storageSelected);
    }

    const {regimeTributario, migracaoDados, nfe} = this.state;
    let obs = `${this.state.txobspagamento};`;

    if (regimeTributario) {
      obs = `${obs} Regime Tributario: ${regimeTributario};`;
    }
    if (nfe.length > 0) {
      obs = `${obs} Notas Fiscais:${nfe.join()};`;
    }
    if (migracaoDados) {
      obs += ' Migracao Dados: Sim;';
    }

    const data = {
      cdvendedor: this.props.auth.codigo,
      nmemailvendedor: this.props.auth.email,
      fgtipovenda: this.state.fgtipovenda,
      cdcliente: this.state.cdcliente,
      nmnomeresponsavel: this.state.nmnomeresponsavel,
      nmemailresponsavel: this.state.nmemailresponsavel,
      cdidioma: this.state.cdidioma,
      cdlocalatende: this.state.cdlocalatende,
      cdconcorrente: this.state.cdconcorrente,
      cdpromocao: this.state.cdpromocao,
      cdtabprecos: this.state.cdtabprecos,
      cdproduto: this.state.cdproduto,
      cdnroaptos: this.state.cdnroaptos,
      cdusuarios: this.state.cdusuarios,
      cdusuariostef: this.state.hasTef ? this.state.qtdTef : null,
      modulos,
      vlvalormensal: formatNumberToSend(this.state.vlvalormensal),
      fgtipomanut: this.state.fgtipomanut,
      vlvalorvenda: formatNumberToSend(this.state.vlvalorvenda),
      vltotalmensal: formatNumberToSend(this.state.vltotalmensal),
      fgmodalidademanut: this.state.fgmodalidademanut,
      txobspagamento: obs,
      txobsvenda: this.state.txobsvenda,

      nmrazaosocial: this.state.nmrazaosocial,
      nmfantasia: this.state.nmfantasia,
      nmdoc1: this.state.nmdoc1,
      nmdoc2: this.state.nmdoc2,
      nmpais: this.state.nmpais,
      nmcep: this.state.nmcep,
      nmendereco: this.state.nmendereco,
      nmendnumero: this.state.nmendnumero,
      nmbairro: this.state.nmbairro,
      nmcidade: this.state.nmcidade,
      nmestado: this.state.nmestado,
      nmnomefinanceiro: this.state.nmnomefinanceiro,
      nmemailfinanceiro: this.state.nmemailfinanceiro,
      nmdirnome: this.state.nmdirnome,
      nmdiremail: this.state.nmdiremail,
      nmtelefone: this.state.nmtelefone,
      fgcovid: this.state.isCovid,

      vlvalorpermuta: formatNumberToSend(this.state.vlvalorpermuta),
      vlqtdepermuta: this.state.vlqtdepermuta,
      txobspermuta: this.state.txobspermuta,

      nmexpectativa: this.state.nmexpectativa,
      nmdemonstrado: this.state.nmdemonstrado,
      dtdatademo: this.state.dtdatademo,
    };

    api
      .post('/pedido/new', {data})
      .then(() => {
        this.setState({
          isLoading: false,
          isFinished: true,
        });
        notification.success('Pedido realizado com sucesso!', '');
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        notification.error('Não foi possível realizar o pedido!', 'Ops');
      });
  };

  onSearchClick = () => {
    const search = this.state.searchCliente;
    if (search !== '' && search !== null) {
      this.setState({
        isLoading: true,
      });
      api
        .post('/pedido/search', {cdcliente: search})
        .then(response => {
          const {data} = response;

          if (Object.prototype.hasOwnProperty.call(data, 'dados')) {
            const {dados} = data;

            this.setState({
              isLoading: false,
              cdcliente: parseInt(search, 10),
              fantasia: dados.fantasia,
              permutas: dados.permutas,
            });
          } else {
            this.setState({
              isLoading: false,
              permutas: {},
            });
            notification.warning('Não encontrado', '');
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onChangeModulos = name => {
    const {cdproduto, modulosSelecionados, channelsSelected} = this.state;
    if (name === 'GH') {
      return;
    }
    if (_.includes(modulosSelecionados, name)) {
      const modulos = modulosSelecionados;
      const index = modulos.indexOf(name);
      modulos.splice(index, 1);
      this.setState(
        {
          modulosSelecionados: modulos,
          channelsSelected: name === 'CM' ? [] : [...channelsSelected],
        },
        () => {
          this.calculaTotal();
        },
      );
    } else if (isEasyWeb(cdproduto) && modulosSelecionados.length >= 2) {
      if (
        (modulosSelecionados.includes('ROLRM') && name === 'CM') ||
        (modulosSelecionados.includes('CM') && name === 'ROLRM')
      ) {
        this.setState(
          {
            modulosSelecionados: [...modulosSelecionados, name],
          },
          () => {
            this.calculaTotal();
          },
        );
      }
    } else {
      this.setState(
        {
          modulosSelecionados: [...modulosSelecionados, name],
        },
        () => {
          this.calculaTotal();
        },
      );
    }
  };

  onChangeLicenciamento = licenciamento => {
    let fgtipomanut = null;
    if (licenciamento === 'LM') {
      fgtipomanut = 0;
    }
    if (licenciamento === 'LS') {
      fgtipomanut = 1;
    }
    if (licenciamento === 'LA') {
      fgtipomanut = 2;
    }
    this.setState(
      {
        fgtipomanut,
      },
      () => {
        this.calculaTotal();
      },
    );
  };

  calculaTabela = async () => {
    console.log('CALCULANDO TABELA...');
    const {
      cdtabprecos,
      cdnroaptos,
      cdusuarios,
      fgtipomanut,
      modulosSelecionados,
      hasIService,
      cdproduto,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    } = this.state;
    const qtduh = parseInt(cdnroaptos, 10);
    const qtduser = parseInt(cdusuarios, 10);

    const res = await calcPrice(
      cdproduto,
      cdtabprecos,
      qtduh,
      qtduser,
      fgtipomanut,
      modulosSelecionados,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    this.setState({...res});
  };

  calculaTotal = async () => {
    console.log('CALCULA TOTAL');
    const {
      ITC,
      LS,
      LM,
      LA,
      fgtipomanut,
      modulosSelecionados,
      hasIService,
      cdnroaptos,
      iserviceITCplus,
      iserviceLMplus,
      cdproduto,
      cdpromocao,
      hasIntegracoes,
      integracoesITC,
      integracoesLM,
      storageSelected,
      hasTef,
      tefITC,
      qtdTef,
      cdtabprecos,
    } = this.state;

    const qtduh = parseInt(cdnroaptos, 10);
    const res = await calcTotalWithIService(
      cdproduto,
      cdtabprecos,
      fgtipomanut,
      modulosSelecionados,
      ITC,
      LS,
      LM,
      LA,
      hasIService,
      qtduh,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
      hasIntegracoes,
      integracoesITC,
      integracoesLM,
      storageSelected,
      hasTef,
      tefITC,
      qtdTef,
    );

    this.setState({...res});
  };

  onChangeTotal = (type, value) => {
    const obj = {...this.state[type], TOTAL: value};
    const newState = update(this.state, {
      [type]: {$set: obj},
    });
    this.setState(newState, () => {
      this.recalculaModulos(type);
    });
  };

  verificaValorValido = async (type, value) => {
    const {
      cdtabprecos,
      cdnroaptos,
      cdusuarios,
      fgtipomanut,
      modulosSelecionados,
      isCovid,
      isBlackFriday,
      hasIService,
      cdproduto,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    } = this.state;

    const qtduh = parseInt(cdnroaptos, 10);
    const qtduser = parseInt(cdusuarios, 10);

    const res = await calcPrice(
      cdproduto,
      cdtabprecos,
      qtduh,
      qtduser,
      fgtipomanut,
      modulosSelecionados,
      hasIService,
      iserviceITCplus,
      iserviceLMplus,
      cdpromocao,
    );

    let minValue = res[type].TOTAL;

    if (type === 'ITC') {
      minValue /= 2;
    }

    if (type === 'ITC' && (isCovid || isBlackFriday)) {
      // minValue = 0;
      minValue /= 2;
    }

    if (value <= minValue) {
      const obj = {...this.state[type], TOTAL: minValue};
      const newState = update(this.state, {
        [type]: {$set: obj},
      });
      this.setState(newState, () => {
        this.recalculaModulos(type);
      });
    }
  };

  recalculaModulos = type => {
    const total = this.state[type].TOTAL;
    const {cdtabprecos, cdproduto} = this.state;

    let recalcResult = null;
    if (type === 'ITC') {
      recalcResult = recalcITC(cdproduto, cdtabprecos, total);
    } else {
      recalcResult = recalc(cdproduto, cdtabprecos, total);
    }

    const newState = update(this.state, {
      [type]: {$set: recalcResult},
    });
    this.setState(newState, () => {
      this.calculaTotal();
    });
  };

  renderTabelaPreco = () => {
    const {cdproduto, moeda, fgtipomanut, ITC, LS, LM, LA} = this.state;
    return (
      <PriceTable
        cdproduto={cdproduto}
        moeda={moeda}
        fgtipomanut={fgtipomanut}
        ITC={ITC}
        LS={LS}
        LM={LM}
        LA={LA}
        onChangeTotal={(type, value) => {
          this.onChangeTotal(type, value);
        }}
        verificaValorValido={(type, value) => {
          this.verificaValorValido(type, value);
        }}
        onChangeLicenciamento={licenciamento => {
          this.onChangeLicenciamento(licenciamento);
        }}
      />
    );
  };

  render() {
    if (this.state.isFinished) {
      return (
        <div>
          <PedidoSuccess />
        </div>
      );
    }
    return (
      <div>
        <Loading show={this.state.isLoading} />

        <SubHeader text="Preencha os campos" />

        <Header />

        <div className="container">
          <Formik
            enableReinitialize
            initialValues={{
              nmemailresponsavel: this.state.nmemailresponsavel,
              nmnomeresponsavel: this.state.nmnomeresponsavel,
              nmdiremail: this.state.nmdiremail,
              nmdirnome: this.state.nmdirnome,
              txobspagamento: this.state.txobspagamento,
              txobsvenda: this.state.txobsvenda,
            }}
            validationSchema={rules}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(false);
              const {vlvalormensal, fgtipomanut} = this.state;

              if (!vlvalormensal && !fgtipomanut) {
                notification.error('Selecione a forma de licenciamento!', '');
              } else {
                this.enviarPedido();
              }
            }}>
            {({submitForm}) => (
              <Form
                className="form-horizontal"
                id="formik-pedido"
                onKeyDown={keyEvent => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault();
                  }
                }}>
                <ProductSelect
                  value={this.state.cdproduto}
                  onChange={(cdproduto, cdtabprecos) => {
                    this.handleProductChange(cdproduto, cdtabprecos);
                  }}
                />

                {/* Dados básico do cliente */}
                <HeaderForm
                  isUpgrade={this.state.isUpgrade}
                  isDowngrade={this.state.isDowngrade}
                  isReturn={this.state.isReturn}
                  disableIsUpgrade={this.state.cdpromocao === BLACK_FRIDAY_CODE}
                  disableIsDowngrade={
                    this.state.cdpromocao === BLACK_FRIDAY_CODE
                  }
                  disableIsReturn={this.state.cdpromocao === BLACK_FRIDAY_CODE}
                  nmnomeresponsavel={this.state.nmnomeresponsavel}
                  nmemailresponsavel={this.state.nmemailresponsavel}
                  nmdirnome={this.state.nmdirnome}
                  nmdiremail={this.state.nmdiremail}
                  onFieldChanged={(field, value) => {
                    this.setState({[field]: value});
                  }}
                  handleIsUpdateChange={() => {
                    const {isUpgrade} = this.state;
                    if (!isUpgrade) {
                      this.setState({
                        searchCliente: '',
                        cdcliente: 0,
                        fgtipovenda: 2,
                        isUpgrade: true,
                        isDowngrade: false,
                        isReturn: false,
                        isBlackFriday: false,
                      });
                    } else {
                      this.setState({
                        fgtipovenda: 0,
                        isUpgrade: false,
                        isBlackFriday: false,
                      });
                    }
                  }}
                  handleIsDowngradeChange={() => {
                    const {isDowngrade} = this.state;
                    if (!isDowngrade) {
                      this.setState({
                        searchCliente: '',
                        cdcliente: 0,
                        fgtipovenda: 8,
                        isUpgrade: false,
                        isDowngrade: true,
                        isReturn: false,
                        isBlackFriday: false,
                      });
                    } else {
                      this.setState({
                        fgtipovenda: 0,
                        isDowngrade: false,
                        isBlackFriday: false,
                      });
                    }
                  }}
                  handleIsReturnChange={() => {
                    const {isReturn} = this.state;
                    if (!isReturn) {
                      this.setState({
                        searchCliente: '',
                        cdcliente: 0,
                        fgtipovenda: 3,
                        isUpgrade: false,
                        isDowngrade: false,
                        isReturn: true,
                        isBlackFriday: false,
                      });
                    } else {
                      this.setState({
                        fgtipovenda: 0,
                        isReturn: false,
                        isBlackFriday: false,
                      });
                    }
                  }}
                  fantasia={this.state.fantasia}
                  searchCliente={this.state.searchCliente}
                  onSearchClick={() => {
                    this.onSearchClick();
                  }}
                />

                <FullForm
                  collapsed={this.state.isOpenCollapseUsuario}
                  toggleCollapse={() => {
                    this.setState({
                      isOpenCollapseUsuario: !this.state.isOpenCollapseUsuario,
                    });
                  }}
                  nmrazaosocial={this.state.nmrazaosocial}
                  nmfantasia={this.state.nmfantasia}
                  nmpais={this.state.nmpais}
                  nmdoc1={this.state.nmdoc1}
                  nmdoc2={this.state.nmdoc2}
                  tipopessoa={this.state.tipopessoa}
                  nmcep={this.state.nmcep}
                  nmendereco={this.state.nmendereco}
                  nmendnumero={this.state.nmendnumero}
                  nmbairro={this.state.nmbairro}
                  nmcidade={this.state.nmcidade}
                  nmtelefone={this.state.nmtelefone}
                  nmnomefinanceiro={this.state.nmnomefinanceiro}
                  nmemailfinanceiro={this.state.nmemailfinanceiro}
                  nmestado={this.state.nmestado}
                  onFieldChanged={(field, value) => {
                    this.setState({[field]: value});
                  }}
                />

                <BasicForm
                  cdidioma={this.state.cdidioma}
                  cdproduto={this.state.cdproduto}
                  regiaocliente={this.state.regiaocliente}
                  cdlocalatende={this.state.cdlocalatende}
                  cdconcorrente={this.state.cdconcorrente}
                  cdpromocao={this.state.cdpromocao}
                  cdtabprecos={this.state.cdtabprecos}
                  regimeTributario={this.state.regimeTributario}
                  nfe={this.state.nfe}
                  migracaoDados={this.state.migracaoDados}
                  sistemaanterior={this.state.sistemaanterior}
                  promocoes={this.state.promocoes}
                  b={(field, value) => {
                    this.setState({[field]: value});
                  }}
                  handleTabelaPrecoChange={value => {
                    this.handleTabelaPrecoChange(value);
                  }}
                  onFieldChanged={(field, value) => {
                    const isBlackFriday =
                      field === 'cdpromocao' && value === BLACK_FRIDAY_CODE;
                    this.setState(
                      prevState => ({
                        [field]: value,
                        isBlackFriday,
                        isUpgrade: isBlackFriday ? false : prevState.isUpgrade,
                        fgtipovenda: isBlackFriday ? 0 : prevState.fgtipovenda,
                      }),
                      () => {
                        this.calculaTabela();
                      },
                    );
                  }}
                />

                <UhAndUser
                  cdnroaptos={this.state.cdnroaptos}
                  cdusuarios={this.state.cdusuarios}
                  cdproduto={this.state.cdproduto}
                  uhDisabled={false}
                  userDisabled={isEasyWeb(this.state.cdproduto)}
                  onChangeUH={value => {
                    this.setState({cdnroaptos: value}, () => {
                      this.calculaTabela();
                    });
                  }}
                  onChangeUser={value => {
                    this.setState({cdusuarios: value}, () => {
                      this.calculaTabela();
                    });
                  }}
                />

                {/* Modulos */}
                <Modulos
                  modulosDisabled={
                    isEasyWeb(this.state.cdproduto) ? ['BI'] : []
                  }
                  modulosSelecionados={this.state.modulosSelecionados}
                  onChangeModulos={m => {
                    this.onChangeModulos(m);
                  }}>
                  <div className="pricing-span-body">
                    {this.renderTabelaPreco()}
                  </div>
                </Modulos>

                <Channel
                  modulosSelecionados={this.state.modulosSelecionados}
                  channelsSelected={this.state.channelsSelected}
                  changeChannel={value => {
                    this.setState({channelsSelected: [...value]});
                  }}
                />

                <IntegrationModules
                  checked={this.state.hasIntegracoes}
                  handleChange={() => {
                    this.setState(
                      {
                        hasIntegracoes: !this.state.hasIntegracoes,
                        integrationsSelected: [],
                        integracoesITC: 0,
                        integracoesLM: 0,
                      },
                      () => {
                        this.calculaTotal();
                      },
                    );
                  }}
                  integrationsSelected={this.state.integrationsSelected}
                  handleChangeIntegrations={value => {
                    this.setState({integrationsSelected: [...value]});
                  }}
                  ITC={this.state.integracoesITC}
                  LM={this.state.integracoesLM}
                  handleChangeITC={integracoesITC => {
                    this.setState({integracoesITC}, () => {
                      this.calculaTotal();
                    });
                  }}
                  handleChangeLM={integracoesLM => {
                    this.setState({integracoesLM}, () => {
                      this.calculaTotal();
                    });
                  }}
                />

                <AttachmentStorage
                  currency={this.state.moeda}
                  tablePriceCode={this.state.cdtabprecos}
                  storageSelected={this.state.storageSelected}
                  handleChangeStorage={storageSelected => {
                    this.setState(
                      {
                        storageSelected,
                      },
                      () => {
                        this.calculaTotal();
                      },
                    );
                  }}
                />

                <TefForm
                  moeda={this.state.moeda}
                  disabled={false}
                  checked={this.state.hasTef}
                  handleChange={() => {
                    this.setState(
                      {
                        hasTef: !this.state.hasTef,
                        tefITC: ITC_TEF,
                        qtdTef: 1,
                      },
                      () => {
                        this.calculaTotal();
                      },
                    );
                  }}
                  qtdTef={this.state.qtdTef}
                  handleChangeQtd={qtdTef => {
                    this.setState({qtdTef}, () => {
                      this.calculaTotal();
                    });
                  }}
                  ITC={this.state.tefITC}
                  handleChangeITC={tefITC => {
                    this.setState(
                      {
                        tefITC,
                      },
                      () => {
                        this.calculaTotal();
                      },
                    );
                  }}
                />

                <IService
                  visible={
                    this.state.moeda === 'R$' &&
                    !isEasyWeb(this.state.cdproduto)
                  }
                  checked={this.state.hasIService}
                  showPlus
                  moeda={this.state.moeda}
                  handleChange={() => {
                    this.setState(
                      {
                        hasIService: !this.state.hasIService,
                        iserviceITCplus: 0,
                        iserviceLMplus: 0,
                      },
                      () => {
                        this.calculaTotal();
                      },
                    );
                  }}
                  ITC={this.state.iserviceITC}
                  ITCplus={this.state.iserviceITCplus}
                  LM={this.state.iserviceLM}
                  LMplus={this.state.iserviceLMplus}
                  handleChangeITCplus={iserviceITCplus => {
                    this.setState({iserviceITCplus}, () => {
                      this.calculaTotal();
                    });
                  }}
                  handleChangeLMplus={iserviceLMplus => {
                    this.setState({iserviceLMplus}, () => {
                      this.calculaTotal();
                    });
                  }}
                />

                {/* <CheckboxCovid
                  isCovid={this.state.isCovid}
                  handleChange={() => {
                    if (this.state.isCovid === true) {
                      this.setState({isCovid: false, txobsvenda: ''}, () => {
                        this.verificaValorValido('ITC', this.state.ITC.TOTAL);
                      });
                    } else {
                      this.setState({isCovid: true, txobsvenda: msgCovid});
                    }
                  }}
                /> */}

                {/* Resumo da Compra */}
                <Resume
                  cdnroaptos={this.state.cdnroaptos}
                  modulosSelecionados={this.state.modulosSelecionados}
                  fgtipomanut={this.state.fgtipomanut}
                  moeda={this.state.moeda}
                  vlvalormensal={this.state.vlvalormensal}
                  vlvalorvenda={this.state.vlvalorvenda}
                  vltotalmensal={this.state.vltotalmensal}
                  hasIService={this.state.hasIService}
                />

                {/* Permuta */}
                <PermutaForm
                  checked={this.state.hasPermuta}
                  permutas={this.state.permutas}
                  disabled={false}
                  handleChange={() => {
                    this.setState({
                      hasPermuta: !this.state.hasPermuta,
                      vlvalorpermuta: 0,
                      vlqtdepermuta: 0,
                      txobspermuta: '',
                    });
                  }}
                  vlvalorpermuta={this.state.vlvalorpermuta}
                  vlqtdepermuta={this.state.vlqtdepermuta}
                  txobspermuta={this.state.txobspermuta}
                  handleChangeValue={vlvalorpermuta => {
                    this.setState({
                      vlvalorpermuta,
                    });
                  }}
                  handleChangeQtd={vlqtdepermuta => {
                    this.setState({
                      vlqtdepermuta,
                    });
                  }}
                  handleChangeObservation={txobspermuta => {
                    this.setState({
                      txobspermuta,
                    });
                  }}
                />

                {/* Modalidade */}
                <Modalidade
                  fgmodalidademanut={this.state.fgmodalidademanut}
                  onModalidadeChange={value => {
                    this.setState({
                      fgmodalidademanut: value,
                    });
                  }}
                />

                {/* Observações */}
                <Observation
                  txobspagamento={this.state.txobspagamento}
                  txobsvenda={this.state.txobsvenda}
                  onFieldChanged={(field, value) => {
                    this.setState({[field]: value});
                  }}
                />

                <ExpectationForm
                  nmexpectativa={this.state.nmexpectativa}
                  nmdemonstrado={this.state.nmdemonstrado}
                  dtdatademo={this.state.dtdatademo}
                  onFieldChanged={(field, value) => {
                    this.setState({[field]: value});
                  }}
                />

                <Footer
                  showProposta={isLightWeb(this.state.cdproduto)}
                  sendPdfProposta={e => {
                    this.sendPdfProposta(e);
                  }}
                  handleSubmitClicked={e => {
                    e.preventDefault();

                    submitForm();
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

NovoPedido.propTypes = {
  auth: PropTypes.shape({
    nome: PropTypes.string,
    email: PropTypes.string,
    codigo: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NovoPedido);
