import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './App.scss';

import cfgStore from 'store';
import AppRoutes from './routes/AppRoutes';
import i18n from './locale/i18n';

import AxiosInterceptor from './config/interceptors/axiosInterceptors';

const store = cfgStore();

AxiosInterceptor.setupInterceptors(store);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </I18nextProvider>,
  document.querySelector('#root'),
);
