import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import InputDecimal from 'components/form/inputDecimal';
import AceSpinner from 'components/aceSpinner';
import Currency from 'components/currency';

import {getLmTef} from 'helpers/calc/calcTef';

const TefForm = ({
  moeda,
  disabled,
  checked,
  handleChange,
  ITC,
  handleChangeITC,
  qtdTef,
  qtdTefCurrent,
  handleChangeQtd,
}) => {
  const lmDiff = useMemo(() => {
    const lmValueTotal = getLmTef(qtdTef + qtdTefCurrent);
    const lmValueCurrent = getLmTef(qtdTefCurrent);

    return lmValueTotal - lmValueCurrent;
  }, [qtdTef, qtdTefCurrent]);

  return (
    <div>
      <div className="hr hr8 hr-double hr-dotted" />

      <Row>
        <Col sm={12}>
          <h4 className="header smaller lighter blue">TEF:</h4>
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <div className="pull-left" style={{marginBottom: '5px'}}>
            <div className="checkbox mb-2">
              <label>
                <input
                  disabled={disabled}
                  type="checkbox"
                  className="ace"
                  checked={checked}
                  onChange={() => {
                    handleChange();
                  }}
                />
                <span className="lbl" style={{marginLeft: '-20px'}}>
                  &nbsp;Habilitar TEF
                </span>
              </label>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <Row>
            <Col xs={4}>
              <h5 className=" ">ITC</h5>

              <>
                <InputDecimal
                  disabled={!checked}
                  placeholder="0,00"
                  className="form-control"
                  value={ITC}
                  onValueChange={value => {
                    handleChangeITC(value || 0);
                  }}
                  onBlur={() => {}}
                />
              </>
            </Col>
            <Col xs={4}>
              <h5 className="">Quantidade de caixas</h5>
              <AceSpinner
                disabled={!checked}
                value={qtdTef}
                onChange={value => {
                  handleChangeQtd(value);
                }}
              />
            </Col>
            <Col xs={4}>
              {checked ? (
                <div style={{marginTop: '34px'}}>
                  LM{' '}
                  <span className="red">
                    <Currency moeda={moeda} value={lmDiff} />
                  </span>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h5 className="">
            <span>TEFs ativos:&nbsp;</span>
            <span className="blue bolder">{qtdTefCurrent}</span>
          </h5>
        </Col>
      </Row>
    </div>
  );
};

TefForm.defaultProps = {
  checked: true,
  disabled: false,
  ITC: 0,
  handleChangeITC: () => {},
  qtdTef: 0,
  qtdTefCurrent: 0,
  handleChangeQtd: () => {},
};

TefForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  ITC: PropTypes.number,
  handleChangeITC: PropTypes.func,
  qtdTef: PropTypes.number,
  qtdTefCurrent: PropTypes.number,
  handleChangeQtd: PropTypes.func,
  moeda: PropTypes.string.isRequired,
};

export default TefForm;
