import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import {useTranslation} from 'react-i18next';

import dispositivos from 'assets/img/cadastro/dispositivos.png';
import iconesInferior from 'assets/img/cadastro/icones_inferior.png';
import nuvem from 'assets/img/cadastro/nuvem.png';

import {getProductName} from 'helpers/tabPrice';

const nuvemStyle = {
  height: '150px',
  backgroundImage: `url(${nuvem})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
};

const CadastroContainer = ({children, cdproduto, loading}) => {
  const {t} = useTranslation();
  const productName = getProductName(cdproduto);
  return (
    <div
      className="jumbotron no-padding-bottom no-margin"
      style={{
        background:
          'linear-gradient(to top, rgba(212, 228, 239, 1) 0%, rgba(53, 118, 187, 1) 100%)',
      }}>
      {!loading ? (
        <div className="container pos-rel">
          <Row>
            <Col xs={12} md={6}>
              <div
                className="wow slideInLeft"
                style={{paddingLeft: '12%', paddingRight: '12%'}}>
                <div className="text-center">
                  <h2 className="white">
                    <b>{t('paginaCliente.bemvindo')}</b>
                    &nbsp;
                    <span>
                      {t('paginaCliente.bemvindo2')} {productName}!
                    </span>
                  </h2>
                  <h4 className="white lighter" style={{lineHeight: '1.3'}}>
                    {productName} {t('paginaCliente.subtitulo')}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <img
                src={dispositivos}
                className="img-responsive center-block"
                alt="dispositivos"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div
                className="text-center"
                style={{
                  border: '4px solid white',
                  padding: '15px',
                  margin: '30px',
                }}>
                <h4 className="white lighter">
                  <b>{t('paginaCliente.parabens')}</b>
                </h4>
                <h4 className="white lighter">
                  {t('paginaCliente.parabens2')}
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <img
                src={iconesInferior}
                className="img-responsive center-block"
                alt="icones inferior"
                style={{maxWidth: '300px'}}
              />
            </Col>
          </Row>
        </div>
      ) : null}

      <div style={nuvemStyle} />

      <div className="clearfix" />

      <div className="page-content no-margin-right no-margin-left no-padding-right no-padding-left">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="widget-body">
                <div className="widget-main">
                  <div id="fuelux-wizard-container">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CadastroContainer.propTypes = {
  children: PropTypes.element.isRequired,
  cdproduto: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CadastroContainer;
