import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AceSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      };
    }
    return null;
  }

  onRemove = () => {
    const {disabled} = this.props;
    const value = parseInt(this.state.value, 10);
    if (value > 0 && !disabled) {
      const newValue = value - 1;
      this.props.onChange(newValue);
    }
  };

  onAdd = () => {
    const {disabled} = this.props;
    const value = parseInt(this.state.value, 10);
    if (value < 999 && !disabled) {
      const newValue = value + 1;
      this.props.onChange(newValue);
    }
  };

  onBlur = () => {
    let value = parseInt(this.state.value, 10);
    if (value > 999) {
      value = 999;
    }
    if (value < 1 || Number.isNaN(value)) {
      value = 1;
    }
    this.props.onChange(value);
  };

  onChange = event => {
    const {disabled} = this.props;
    let value = event.target.value;
    if (Number.isNaN(value) || value === '' || !value) {
      value = 0;
    }
    value = parseInt(value, 10);
    if (!disabled) {
      this.props.onChange(value);
    }
  };

  render() {
    const {disabled} = this.props;
    const style = {
      maxWidth: '150px',
    };

    return (
      <div>
        <div className="ace-spinner middle touch-spinner" style={style}>
          <div className="input-group">
            <div className="spinbox-buttons input-group-btn">
              <button
                disabled={disabled}
                type="button"
                className="btn spinbox-down btn-sm btn-primary"
                onClick={e => {
                  this.onRemove(e);
                }}>
                <i className="icon-only  ace-icon ace-icon fa fa-minus" />
              </button>
            </div>

            <input
              disabled={disabled}
              type="tel"
              className="spinbox-input form-control text-center"
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.value}
              onBlur={e => {
                this.onBlur(e);
              }}
            />

            <div className="spinbox-buttons input-group-btn">
              <button
                disabled={disabled}
                type="button"
                className="btn spinbox-down btn-sm btn-primary"
                onClick={e => {
                  this.onAdd(e);
                }}>
                <i className="icon-only  ace-icon ace-icon fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AceSpinner.defaultProps = {
  disabled: false,
};

AceSpinner.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AceSpinner;
