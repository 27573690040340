import {round} from '../calcRounding';
import {
  EASY_REAL_BI,
  EASY_REAL_CE,
  EASY_REAL_CM,
  EASY_REAL_PDV,
  EASY_REAL_GF,
  EASY_REAL_GH,
  EASY_REAL_ROLRM,
  EASY_REAL_TT,
} from '../values';

export const recalcReal = total => ({
  GH: round(total * EASY_REAL_GH),
  TT: round(total * EASY_REAL_TT),
  ROLRM: round(total * EASY_REAL_ROLRM),
  GF: round(total * EASY_REAL_GF),
  CE: round(total * EASY_REAL_CE),
  CM: round(total * EASY_REAL_CM),
  PDV: round(total * EASY_REAL_PDV),
  BI: round(total * EASY_REAL_BI),
  TOTAL: round(total),
});
