import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import SubHeader from '../partials/SubHeader';

class HomePedido extends Component {
  componentDidMount() {
    document.title = 'Pedidos - Home';
  }

  render() {
    const style = {
      margin: '10px',
      width: '160px',
    };

    return (
      <div>
        <SubHeader text="Escolha uma opção" />

        <div className="container">
          <Row>
            <Col xs={12} className="text-center">
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <Link
                    to="/app/pedidos/novo"
                    className="btn btn-app btn-primary radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-pencil-square-o bigger-230" />
                    Novo pedido
                  </Link>
                  <Link
                    to="/app/pedidos/adicao"
                    className="btn btn-app btn-light radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-cogs bigger-230" />
                    Adição
                  </Link>
                  <Link
                    to="/app/reimpressaocontrato"
                    className="btn btn-app btn-light radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-file-pdf-o bigger-230" />
                    Reimpressão
                  </Link>
                  <Link
                    to="/app/voucher-generator"
                    className="btn btn-app btn-light radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-ticket bigger-230" />
                    Gerador de Voucher
                  </Link>
                  <Link
                    to="/app/pedidos/consulta"
                    className="btn btn-app btn-light radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-search bigger-230" />
                    Consultar Pedido
                  </Link>
                  {/* <Link
                    to="/app/reenvioemail"
                    className="btn btn-app btn-light radius-4 btn-sm"
                    style={style}>
                    <i className="ace-icon fa fa-envelope-o bigger-230" />
                    Renvio de email
                  </Link> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default HomePedido;
