import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import _ from 'lodash';
import {getModulosString, getManutencao, getRegiao} from 'helpers/dslFunctions';

const Resume = ({
  fantasia,
  razaosocial,
  modulos,
  cdidioma,
  fgtipomanut,
  cdnroaptos,
  cdusuarios,
  cdlocalatende,
}) => (
  <Row>
    <Col xs={12}>
      <div className="clearfix well">
        <Row>
          <Col sm={6}>
            <ul className="list-unstyled spaced">
              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                <span>Nome fantasia:</span>
                &nbsp;
                <b className="blue nome-fantasia">{fantasia}</b>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                <span>Razão social:</span>
                &nbsp;
                <b className="blue razao-social">{razaosocial}</b>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right blue" />
                <span>Módulos:</span>
                &nbsp;
                <b className="red modulos">
                  {_.map(getModulosString(modulos, cdidioma)).join(', ')}
                </b>
              </li>
            </ul>
          </Col>

          <Col sm={6}>
            <ul className="list-unstyled  spaced">
              <li>
                <i className="ace-icon fa fa-caret-right green" />
                <span>Forma de licenciamento:</span>
                &nbsp;
                <b className="red licenciamento">
                  {getManutencao(fgtipomanut, cdidioma)}
                </b>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right green" />
                <span>Quantidade de Uhs:</span>
                &nbsp;
                <b className="red quantidade-uh">{cdnroaptos}</b>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right green" />
                <span>Quantidade de usuários:</span>
                &nbsp;
                <b className="red quantidade-usuario">{cdusuarios}</b>
              </li>

              <li>
                <i className="ace-icon fa fa-caret-right green" />
                <span>Região</span>
                &nbsp;
                <b className="red regiao">{getRegiao(cdlocalatende)}</b>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
);

Resume.defaultProps = {
  fantasia: '',
  razaosocial: '',
  modulos: [],
  fgtipomanut: null,
  cdnroaptos: null,
  cdusuarios: null,
  cdlocalatende: null,
};

Resume.propTypes = {
  cdidioma: PropTypes.string.isRequired,
  fantasia: PropTypes.string,
  razaosocial: PropTypes.string,
  modulos: PropTypes.arrayOf(PropTypes.string),
  fgtipomanut: PropTypes.number,
  cdnroaptos: PropTypes.number,
  cdusuarios: PropTypes.number,
  cdlocalatende: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Resume;
