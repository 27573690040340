import {round} from '../calcRounding';
import {
  EASY_PLUS_REAL_BI,
  EASY_PLUS_REAL_CE,
  EASY_PLUS_REAL_CM,
  EASY_PLUS_REAL_PDV,
  EASY_PLUS_REAL_GF,
  EASY_PLUS_REAL_GH,
  EASY_PLUS_REAL_ROLRM,
  EASY_PLUS_REAL_TT,
  EASY_PLUS_DOLAR_BI,
  EASY_PLUS_DOLAR_CE,
  EASY_PLUS_DOLAR_CM,
  EASY_PLUS_DOLAR_PDV,
  EASY_PLUS_DOLAR_GF,
  EASY_PLUS_DOLAR_GH,
  EASY_PLUS_DOLAR_ROLRM,
  EASY_PLUS_DOLAR_TT,
  EASY_PLUS_EURO_BI,
  EASY_PLUS_EURO_CE,
  EASY_PLUS_EURO_CM,
  EASY_PLUS_EURO_PDV,
  EASY_PLUS_EURO_GF,
  EASY_PLUS_EURO_GH,
  EASY_PLUS_EURO_ROLRM,
  EASY_PLUS_EURO_TT,
  EASY_PLUS_DOLAR_ITC_GH,
  EASY_PLUS_DOLAR_ITC_TT,
  EASY_PLUS_DOLAR_ITC_ROLRM,
  EASY_PLUS_DOLAR_ITC_GF,
  EASY_PLUS_DOLAR_ITC_CE,
  EASY_PLUS_DOLAR_ITC_CM,
  EASY_PLUS_DOLAR_ITC_PDV,
  EASY_PLUS_DOLAR_ITC_BI,
} from '../values';

export const recalcReal = total => ({
  GH: round(total * EASY_PLUS_REAL_GH),
  TT: round(total * EASY_PLUS_REAL_TT),
  ROLRM: round(total * EASY_PLUS_REAL_ROLRM),
  GF: round(total * EASY_PLUS_REAL_GF),
  CE: round(total * EASY_PLUS_REAL_CE),
  CM: round(total * EASY_PLUS_REAL_CM),
  PDV: round(total * EASY_PLUS_REAL_PDV),
  BI: round(total * EASY_PLUS_REAL_BI),
  TOTAL: round(total),
});

export const recalcDolar = total => ({
  GH: round(total * EASY_PLUS_DOLAR_GH),
  TT: round(total * EASY_PLUS_DOLAR_TT),
  ROLRM: round(total * EASY_PLUS_DOLAR_ROLRM),
  GF: round(total * EASY_PLUS_DOLAR_GF),
  CE: round(total * EASY_PLUS_DOLAR_CE),
  CM: round(total * EASY_PLUS_DOLAR_CM),
  PDV: round(total * EASY_PLUS_DOLAR_PDV),
  BI: round(total * EASY_PLUS_DOLAR_BI),
  TOTAL: round(total),
});

export const recalcDolarITC = total => ({
  GH: round(total * EASY_PLUS_DOLAR_ITC_GH),
  TT: round(total * EASY_PLUS_DOLAR_ITC_TT),
  ROLRM: round(total * EASY_PLUS_DOLAR_ITC_ROLRM),
  GF: round(total * EASY_PLUS_DOLAR_ITC_GF),
  CE: round(total * EASY_PLUS_DOLAR_ITC_CE),
  CM: round(total * EASY_PLUS_DOLAR_ITC_CM),
  PDV: round(total * EASY_PLUS_DOLAR_ITC_PDV),
  BI: round(total * EASY_PLUS_DOLAR_ITC_BI),
  TOTAL: round(total),
});

export const recalcEuro = total => ({
  GH: round(total * EASY_PLUS_EURO_GH),
  TT: round(total * EASY_PLUS_EURO_TT),
  ROLRM: round(total * EASY_PLUS_EURO_ROLRM),
  GF: round(total * EASY_PLUS_EURO_GF),
  CE: round(total * EASY_PLUS_EURO_CE),
  CM: round(total * EASY_PLUS_EURO_CM),
  PDV: round(total * EASY_PLUS_EURO_PDV),
  BI: round(total * EASY_PLUS_EURO_BI),
  TOTAL: round(total),
});
