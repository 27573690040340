import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import _ from 'lodash';

import {Alert} from 'components/alert';

const Modulos = ({
  modulosSelecionados,
  modulosDisabled,
  onChangeModulos,
  children,
}) => (
  <Row>
    <Col md={12}>
      <h4 className="header smaller lighter blue">Quais módulos deseja ter?</h4>

      <Alert>
        <strong>Lembrar!</strong>
        <span>&nbsp;Formas de licencimento</span>
        <br />
        <strong>LM</strong>
        <span>&nbsp;- Licenciamento Mensal&nbsp;</span>
        <strong>LS</strong>
        <span>&nbsp;- Licenciamento Semestral&nbsp;</span>
        <strong>LA</strong>
        <span>&nbsp;- Licenciamento Anual</span>
        <br />
      </Alert>
    </Col>

    <Col xs={12} sm={3}>
      <div className="pricing-span-header">
        <div className="widget-box transparent">
          <div className="widget-header">
            <h5 className="widget-title bigger lighter">Módulos</h5>
          </div>

          <div className="widget-body">
            <div className="widget-main no-padding">
              <ul className="list-unstyled list-striped pricing-table-header">
                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        type="checkbox"
                        name="modulos"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'GH')}
                        disabled="disabled"
                      />
                      <span className="lbl label-gh">
                        &nbsp;&nbsp;Gerencia Hoteleira
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        type="checkbox"
                        name="modulos"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'TT')}
                        disabled={_.includes(modulosDisabled, 'TT')}
                        onChange={() => {
                          onChangeModulos('TT');
                        }}
                      />
                      <span className="lbl label-tt">
                        &nbsp;&nbsp;Tarifador
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        type="checkbox"
                        name="modulos"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'ROLRM')}
                        disabled={_.includes(modulosDisabled, 'ROLRM')}
                        onChange={() => {
                          onChangeModulos('ROLRM');
                        }}
                      />
                      <span className="lbl">&nbsp;&nbsp;ROL / RM</span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        name="modulos"
                        type="checkbox"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'GF')}
                        disabled={_.includes(modulosDisabled, 'GF')}
                        onChange={() => {
                          onChangeModulos('GF');
                        }}
                      />
                      <span className="lbl label-gf">
                        &nbsp;&nbsp;Gerencia Financeira
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        name="modulos"
                        type="checkbox"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'CE')}
                        disabled={_.includes(modulosDisabled, 'CE')}
                        onChange={() => {
                          onChangeModulos('CE');
                        }}
                      />
                      <span className="lbl label-ce">
                        &nbsp;&nbsp;Controle de Estoque
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        name="modulos"
                        type="checkbox"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'CM')}
                        disabled={_.includes(modulosDisabled, 'CM')}
                        onChange={() => {
                          onChangeModulos('CM');
                        }}
                      />
                      <span className="lbl label-cm">
                        &nbsp;&nbsp;Channel Manager
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        name="modulos"
                        type="checkbox"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'PDV')}
                        disabled={_.includes(modulosDisabled, 'PDV')}
                        onChange={() => {
                          onChangeModulos('PDV');
                        }}
                      />
                      <span className="lbl label-cm">
                        &nbsp;&nbsp;PDV + Monitor + iPDV
                      </span>
                    </label>
                  </div>
                </li>

                {/* <li>
                  <div className="checkbox no-padding">
                    <label>
                      <input
                        name="modulos"
                        type="checkbox"
                        className="ace"
                        checked={_.includes(modulosSelecionados, 'BI')}
                        disabled={_.includes(modulosDisabled, 'BI')}
                        onChange={() => {
                          onChangeModulos('BI');
                        }}
                      />
                      <span className="lbl label-bi">
                        &nbsp;&nbsp;BI - Business Inteligence
                      </span>
                    </label>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Col>

    <Col xs={12} sm={9}>
      {children}
    </Col>
  </Row>
);

Modulos.defaultProps = {
  modulosSelecionados: [],
  modulosDisabled: [],
};

Modulos.propTypes = {
  modulosSelecionados: PropTypes.arrayOf(PropTypes.string),
  modulosDisabled: PropTypes.arrayOf(PropTypes.string),
  onChangeModulos: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default Modulos;
