import React, {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import {Button} from 'react-bootstrap';

import api from 'services/api';

import Loading from 'components/loading';
import notification from 'components/notification';
import InputContainer from 'components/form/inputContainer';

import {
  getModulosString,
  getITC,
  getManutencao,
  getRegiao,
} from 'helpers/dslFunctions';
import {BLACK_FRIDAY_CODE, getCurrencyFromTabPriceCode} from 'helpers/tabPrice';
import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';

import {rules} from './formRules';

const ReenvioEmail = () => {
  const [state, setState] = useState({
    loading: false,
    numero: '',
  });

  useEffect(() => {
    document.title = 'Reenvio email';
  }, []);

  const print = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    const {numero} = state;

    api.post('/cadastro/data', {numero}).then(response => {
      const res = response.data;

      if (
        Object.prototype.hasOwnProperty.call(res, 'dados') &&
        res.dados.nmipaceite !== ''
      ) {
        const cdtabprecos = res.dados.cdtabprecos;
        const cdidioma = parseInt(res.dados.cdidioma, 10);
        const moeda = getCurrencyFromTabPriceCode(cdtabprecos);
        const totalLicenciamento = formatNumberToSend(
          res.dados.vlvalormensal,
          moeda,
        );
        const totalItc = formatNumberToSend(res.dados.vlvalorvenda, moeda);
        const idioma = cdidioma;

        const {
          cdproduto,
          fgtipomanut,
          modulos,
          fgmodalidademanut,
          txobspagamento,
          cdnroaptos,
          cdusuarios,
          cdlocalatende,
          nmipaceite,
          nmdataaceite,
          nmdoc1,
          nmnomeresponsavel,
          nmfantasia,
          nmrazaosocial,
          cdpedidonr,
          cdpromocao,
          txobspermuta,
          vlvalorpermuta,
          emailvendedor,
          nmemailresponsavel,
          fgcovid,
        } = res.dados;

        const data = {
          cdproduto,
          quantidadeUsuario: cdusuarios,
          cdpedidonr,
          licenciamento: getManutencao(fgtipomanut, idioma),
          modulos: getModulosString(modulos, idioma),
          itc: getITC(fgmodalidademanut, idioma),
          observacao: txobspagamento,
          quantidadeUh: cdnroaptos,
          regiao: getRegiao(cdlocalatende),
          totalLicenciamento,
          totalItc,
          nroipusuario: nmipaceite,
          dtaceitecontrato: nmdataaceite,
          nmdoc1,
          nmnomeresponsavel,
          nmfantasia,
          nmrazaosocial,
          fgcovid,
          fgblackfriday: cdpromocao === BLACK_FRIDAY_CODE,
          cdidioma,
          emailvendedor,
          nmemailresponsavel,
          txobspermuta,
          vlvalorpermuta: parseFloat(vlvalorpermuta)
            ? formatNumberToSend(vlvalorpermuta, moeda)
            : null,
        };

        api
          .post('/cadastro/reenvioemail', {data})
          .then(() => {
            setState(prevState => ({
              ...prevState,
              loading: false,
              numero: '',
            }));

            notification.success('Enviado com sucesso!', '');
          })
          .catch(() => {
            setState(prevState => ({
              ...prevState,
              loading: false,
              numero: '',
            }));

            notification.error('Não foi possível realizar o envio', '');
          });
      } else {
        setState({
          loading: false,
          numero: '',
        });
        notification.error('Erro ao buscar pedido', '');
      }
    });
  };

  return (
    <div>
      <Loading show={state.loading} />

      <div className="page-content no-margin-right no-margin-left no-padding-right no-padding-left">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-xs-offset-0 col-md-6 col-md-offset-3">
              <div className="widget-box">
                <div className="widget-header">
                  <h4 className="smaller">Reenvio email - Contrato</h4>
                </div>

                <div className="widget-body">
                  <div className="widget-main">
                    <div className="row">
                      <div className="col-xs-12">
                        <Formik
                          enableReinitialize
                          initialValues={{numero: state.numero}}
                          validationSchema={rules}
                          onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(false);
                            print();
                          }}>
                          {({errors, touched, values}) => (
                            <Form id="login-form">
                              <InputContainer
                                type="text"
                                placeholder="Número do pedido"
                                showError={!!errors.numero && !!touched.numero}
                                text={values.numero}
                                onFieldChanged={e => {
                                  setState(prevState => ({
                                    ...prevState,
                                    numero: e.target.value,
                                  }));
                                }}
                                errorText={errors.numero}
                              />

                              <div className="clearfix" />

                              <Button
                                block
                                bsStyle="primary"
                                bsSize="sm"
                                type="submit"
                                className="full-width m-b">
                                OK
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReenvioEmail;
