import React, {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import {Button, Row, Col} from 'react-bootstrap';

import api from 'services/api';

import Loading from 'components/loading';
import notification from 'components/notification';
import InputContainer from 'components/form/inputContainer';

import {
  getModulosString,
  getITC,
  getManutencao,
  getRegiao,
} from 'helpers/dslFunctions';
import {BLACK_FRIDAY_CODE, getCurrencyFromTabPriceCode} from 'helpers/tabPrice';
import config from 'config/config';
import {formatNumberToSend} from 'helpers/calc/formatNumberToSend';
import {makePermutaText} from 'helpers/permuta';

import {rules} from './formRules';

const ReimpressaoContrato = () => {
  const [state, setState] = useState({
    loading: false,
    numero: '',
  });

  useEffect(() => {
    document.title = 'Reimpressão Contrato';
  }, []);

  const print = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    const {numero} = state;

    api.post('/cadastro/data', {numero}).then(response => {
      const data = response.data;

      if (data?.dados && data?.dados?.nmipaceite) {
        const {
          fgtipovenda,
          cdproduto,
          fgtipomanut,
          modulos,
          fgmodalidademanut,
          txobspagamento,
          cdnroaptos,
          cdusuarios,
          cdusuariostef,
          cdlocalatende,
          nmipaceite,
          nmdataaceite,
          nmdoc1,
          nmdirnome,
          nmfantasia,
          nmrazaosocial,
          cdpedidonr,
          cdpromocao,
          txobspermuta,
          vlvalorpermuta,
          permutas,
          nomevendedor,
        } = data.dados;
        const cdidioma = parseInt(data.dados.cdidioma, 10);
        const idioma = cdidioma;

        const cdtabprecos = data.dados.cdtabprecos;

        const moeda = getCurrencyFromTabPriceCode(cdtabprecos);

        const totalLicenciamento = formatNumberToSend(
          data.dados.vlvalormensal,
          moeda,
        );
        const totalItc = formatNumberToSend(data.dados.vlvalorvenda, moeda);

        if (fgtipovenda === '1' || fgtipovenda === '6' || fgtipovenda === '7') {
          const pedido = {
            numero,
            cdidioma,
            cdpedidonr,
            fgtipovenda: parseInt(fgtipovenda),
            observacao: txobspagamento,
            modulos: getModulosString(modulos, idioma),
            quantidadeUh: parseInt(cdnroaptos),
            quantidadeUsuario: parseInt(cdusuarios),
            quantidadeTef: parseInt(cdusuariostef) || '',
            valorLicenciamento: totalLicenciamento,
            licenciamento: getManutencao(fgtipomanut, idioma),
            valorITC: totalItc,
            nroipusuario: nmipaceite,
            dtaceitecontrato: nmdataaceite,
            nmfantasia,
            nmrazaosocial,
            responsavel: nmdirnome,
            vendedor: nomevendedor,
          };

          api
            .post('/pedido/reprint_addition', pedido)
            .then(res => {
              if (res.data.file) {
                setState({
                  loading: false,
                  numero: '',
                });

                const file = encodeURI(res.data.file);
                window.open(`${config.BACKEND_URL}/download/${file}`);
              }
            })
            .catch(() => {
              setState({
                loading: false,
                numero: '',
              });
              notification.error('Algo deu errado', '');
            });
        } else {
          const pedido = {
            cdproduto,
            licenciamento: getManutencao(fgtipomanut, idioma),
            modulos: getModulosString(modulos, idioma),
            itc: getITC(fgmodalidademanut, idioma),
            observacao: txobspagamento,
            quantidadeUh: parseInt(cdnroaptos),
            quantidadeUsuario: parseInt(cdusuarios),
            quantidadeTef: parseInt(cdusuariostef) || '',
            regiao: getRegiao(cdlocalatende),
            totalLicenciamento,
            totalItc,
            nroipusuario: nmipaceite,
            dtaceitecontrato: nmdataaceite,
            documento: nmdoc1,
            nmdirnome,
            nmfantasia,
            nmrazaosocial,
            cdidioma: idioma,
            cdpedidonr,
            numero,
            fgblackfriday: cdpromocao === BLACK_FRIDAY_CODE,
            txobspermuta,
            txobspermuta2: makePermutaText(permutas, idioma),
            vlvalorpermuta: parseFloat(vlvalorpermuta)
              ? formatNumberToSend(vlvalorpermuta, moeda)
              : null,
          };

          api
            .post('/pedido/reprint', pedido)
            .then(res => {
              if (res.data.file) {
                setState({
                  loading: false,
                  numero: '',
                });

                const file = encodeURI(res.data.file);
                window.open(`${config.BACKEND_URL}/download/${file}`);
              }
            })
            .catch(() => {
              setState({
                loading: false,
                numero: '',
              });
              notification.error('Algo deu errado', '');
            });
        }
      } else {
        setState({
          loading: false,
          numero: '',
        });
        notification.error('Pedido não encontrado', '');
      }
    });
  };

  return (
    <div>
      <Loading show={state.loading} />

      <div className="page-content no-margin-right no-margin-left no-padding-right no-padding-left">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-xs-offset-0 col-md-6 col-md-offset-3">
              <div className="widget-box">
                <div className="widget-header">
                  <h4 className="smaller">Reimpressão Contrato</h4>
                </div>

                <div className="widget-body">
                  <div className="widget-main">
                    <Row>
                      <Col xs={12}>
                        <Formik
                          enableReinitialize
                          initialValues={{numero: state.numero}}
                          validationSchema={rules}
                          onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(false);
                            print();
                          }}>
                          {({errors, touched, values}) => (
                            <Form id="login-form">
                              <InputContainer
                                type="text"
                                placeholder="Número do pedido"
                                showError={!!errors.numero && !!touched.numero}
                                text={values.numero}
                                onFieldChanged={e => {
                                  setState(prevState => ({
                                    ...prevState,
                                    numero: e.target.value,
                                  }));
                                }}
                                errorText={errors.numero}
                              />

                              <div className="clearfix" />

                              <Button
                                block
                                bsStyle="primary"
                                bsSize="sm"
                                type="submit"
                                className="full-width m-b">
                                OK
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimpressaoContrato;
