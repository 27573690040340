export const TAB_PRECO_LIGHTWEB_REAL = '442';
export const TAB_PRECO_LIGHTWEB_DOLAR = '444';
export const TAB_PRECO_LIGHTWEB_EURO = '451';

export const TAB_PRECO_EASYWEB_REAL = '479';

export const TAB_PRECO_EASYWEB_PLUS_REAL = '498';
export const TAB_PRECO_EASYWEB_PLUS_DOLAR = '444';
export const TAB_PRECO_EASYWEB_PLUS_EURO = '451';

export const PRODUCT_LIGHTWEB_CODE = '60';
export const PRODUCT_EASYWEB_CODE = '61';
export const PRODUCT_EASYWEB_PLUS_CODE = '78';
export const PRODUCT_WEB30_CODE = '74';

export const tabprecosLight = [
  {
    codigo: TAB_PRECO_LIGHTWEB_REAL,
    nome: 'Light Web - Real - 2023',
  },
  {
    codigo: TAB_PRECO_LIGHTWEB_DOLAR,
    nome: 'Light Web - Dólar - 2023',
  },
  {
    codigo: TAB_PRECO_LIGHTWEB_EURO,
    nome: 'Light Web - Euro - 2023',
  },
];

export const tabprecosEasy = [
  {
    codigo: TAB_PRECO_EASYWEB_REAL,
    nome: 'Easy Web - Real - 2023',
  },
];

export const tabprecosEasyPlus = [
  {
    codigo: TAB_PRECO_EASYWEB_PLUS_REAL,
    nome: 'Easy Web Plus - Real - 2025',
  },
  {
    codigo: TAB_PRECO_EASYWEB_PLUS_DOLAR,
    nome: 'Easy Web Plus - Dólar - 2025',
  },
  {
    codigo: TAB_PRECO_EASYWEB_PLUS_EURO,
    nome: 'Easy Web Plus - Euro - 2025',
  },
];

export const getCurrencyFromTabPriceCode = cdtabprecos => {
  if (
    cdtabprecos === TAB_PRECO_LIGHTWEB_REAL ||
    cdtabprecos === TAB_PRECO_EASYWEB_PLUS_REAL ||
    cdtabprecos === TAB_PRECO_EASYWEB_REAL
  ) {
    return 'R$';
  }
  if (
    cdtabprecos === TAB_PRECO_LIGHTWEB_DOLAR ||
    cdtabprecos === TAB_PRECO_EASYWEB_PLUS_DOLAR
  ) {
    return 'U$';
  }
  if (
    cdtabprecos === TAB_PRECO_LIGHTWEB_EURO ||
    cdtabprecos === TAB_PRECO_EASYWEB_PLUS_EURO
  ) {
    return '€';
  }
  return 'R$';
};

export const isLightWeb = cdproduto => cdproduto === PRODUCT_LIGHTWEB_CODE;

export const isEasyWeb = cdproduto => cdproduto === PRODUCT_EASYWEB_CODE;

export const isEasyWebPlus = cdproduto =>
  cdproduto === PRODUCT_EASYWEB_PLUS_CODE;

export const isWeb30 = cdproduto => cdproduto === PRODUCT_WEB30_CODE;

export const getProductName = code => {
  if (code === PRODUCT_LIGHTWEB_CODE) {
    return 'Light Web';
  }
  if (code === PRODUCT_EASYWEB_CODE) {
    return 'Easy Web';
  }
  if (code === PRODUCT_WEB30_CODE) {
    return '3.0 Web';
  }
  if (code === PRODUCT_EASYWEB_PLUS_CODE) {
    return 'Easy Web Plus';
  }
  return null;
};

export const getFirstTabPriceCodeByProduct = cdproduto => {
  if (cdproduto === PRODUCT_LIGHTWEB_CODE) {
    return tabprecosLight[0].codigo;
  }
  if (cdproduto === PRODUCT_WEB30_CODE) {
    return tabprecosLight[0].codigo;
  }
  if (cdproduto === PRODUCT_EASYWEB_CODE) {
    return tabprecosEasy[0].codigo;
  }
  if (cdproduto === PRODUCT_EASYWEB_PLUS_CODE) {
    return tabprecosEasyPlus[0].codigo;
  }
  return null;
};

export const BLACK_FRIDAY_CODE = '22';
